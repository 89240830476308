import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { SubSink } from 'subsink';

import { PaginatorSearchResultsTopicsService } from '../../shared/services/paginator-search-results-topics.service';
import { PaginatorSearchResultsTopicMappingsService } from '../../shared/services/paginator-search-results-topic-mappings.service';

import { PaginatorItemTopic } from '../../shared/models/paginator-item-topic';
import { SearchCriteria } from '../../shared/models/search-criteria';
import { DropDownFilterOption, DropDownFilterDirection } from '../../shared/models/dropdown-filter';

@Component({
  selector: 'app-search-results-topics-panel',
  templateUrl: './search-results-topics-panel.component.html',
  styleUrls: ['./search-results-topics-panel.component.scss'],
  providers: [ 
    PaginatorSearchResultsTopicsService,
    PaginatorSearchResultsTopicMappingsService 
  ]
})
export class SearchResultsTopicsPanelComponent implements OnInit, OnDestroy {
  
  private subscriptions = new SubSink();

  public initialLoadingDone: boolean = false;
  public loadingMoreTopics: boolean = false;
  public noMoreResultsWereFound = false;
  public noResultsWereFound = false;

  public searchExpression: string | null = '';
  public selectedFilter: DropDownFilterDirection  = 'DESC';

  public paginatorItemTopics: PaginatorItemTopic[] = [];
  public paginatorItemTopicsPreviouslyLoaded: PaginatorItemTopic[] = [];

  public dropDownFilters: DropDownFilterOption[] = [
    { dropDownFilterLabel: 'Más miembros', dropDownFilterDirection: 'DESC' },
    { dropDownFilterLabel: 'Menos miembros', dropDownFilterDirection: 'ASC' }
  ];

  constructor(
    private readonly router: Router,
    private readonly currentRoute: ActivatedRoute,
    private readonly paginatorSearchResultsTopics: PaginatorSearchResultsTopicsService
  ) {
    
    this.subscriptions.sink = this.paginatorSearchResultsTopics.listenPagination$.subscribe((topics) => {     
        
      this.initialLoadingDone = true;
      this.loadingMoreTopics = false;

      // Si no hay resultados disponibles, terminamos.
      if ( topics.length <= 0 ) {

        this.noResultsWereFound = true;

        return;
      }

      // Solo en caso que haya resultados disponibles, pasamos a las acciones de Paginación.
      this.paginateResults(topics);

      if ( this.paginatorSearchResultsTopics.hasReachedPaginationEnding() ) {

        this.noMoreResultsWereFound = true;
      } 
    });
  }

  ngOnInit(): void {
    
    this.subscriptions.sink = this.currentRoute.queryParamMap.subscribe((queryParamMap) => {
      
      this.searchExpression = queryParamMap.get('q');
      this.selectedFilter = 'DESC';

      if ( !this.searchExpression ) {

        this.router.navigateByUrl('/404');
        return;
      }

      this.initialLoadingDone = false;

      // Reiniciamos el Paginador y limpiamos los resultados de búsqueda.
      this.clearResultSet();

      // En este caso avanza a la primer Página comunicando el término de búsqueda y el orden de los datos
      this.paginatorSearchResultsTopics.moveToNextPage(
        { searchFor: this.searchExpression, sortDirection: this.selectedFilter } as SearchCriteria
      );
    });
  }

  ngOnDestroy(): void {

    this.subscriptions.unsubscribe();
  }

  public onScroll(): void {

    if ( this.paginatorSearchResultsTopics.hasReachedPaginationEnding() ) {

      this.noMoreResultsWereFound = true;

      return;
    }

    // Avanzar a la siguiente Página comunicando el término de búsqueda y el orden de los datos.
    this.loadingMoreTopics = true;

    this.paginatorSearchResultsTopics.moveToNextPage(
      { searchFor: this.searchExpression, sortDirection: this.selectedFilter } as SearchCriteria
    );
  }

  public onChangeFilter(selectedFilter: DropDownFilterDirection): void {
    
    this.initialLoadingDone = false;

    // Reiniciamos el Paginador y limpiamos los resultados de búsqueda.
    this.clearResultSet();

    // Al reiniciar debido a la selección del nuevo filtro, avanzamos a la primera Página comunicando 
    // el término de búsqueda y el orden de los datos.
    this.paginatorSearchResultsTopics.moveToNextPage(
      { searchFor: this.searchExpression, sortDirection: selectedFilter } as SearchCriteria
    );
  }

  private clearResultSet(): void {

    // Reiniciamos el Servicio de Paginación.
    this.paginatorSearchResultsTopics.resetPaginator();
    // Limpiamos cualquier valor almacenado en memoria.
    this.paginatorItemTopics = this.paginatorItemTopicsPreviouslyLoaded = [];
    // Reinicia los valores de los Flags de Mensajes. 
    this.noMoreResultsWereFound = false;
    this.noResultsWereFound = false;
  }

  private paginateResults(topics: PaginatorItemTopic[]): void {

    // Fusionamos los posts previamente cargadados con los topics que provengan del Servidor.
    this.paginatorItemTopicsPreviouslyLoaded = [ ...this.paginatorItemTopicsPreviouslyLoaded, ...topics ];

    // Actualizamos la vista con la nueva información cargada en el Property Binding correspondiente.
    this.paginatorItemTopics = this.paginatorItemTopicsPreviouslyLoaded;
  }
}
