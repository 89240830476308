import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
    selector: 'app-topic-call-to-action-button',
    templateUrl: './topic-call-to-action-button.component.html',
    styleUrls: ['./topic-call-to-action-button.component.scss']
})
export class TopicCallToActionButtonComponent implements OnInit {

    @Input() suscriptionLoading: boolean = false;
    @Input() owned: boolean = false;
    @Input() joined: boolean = false;

    @Output() onEdit: EventEmitter<void> = new EventEmitter<void>();
    @Output() onJoin: EventEmitter<void> = new EventEmitter<void>();
    @Output() onDisjoin: EventEmitter<void> = new EventEmitter<void>();

    constructor() { }

    ngOnInit(): void {
    }

    public onEditTopic(): void {

        this.onEdit.emit();
    }   

    public onSubscribeTopic(): void {

        this.joined ? this.onDisjoin.emit() : this.onJoin.emit();
    }

}
