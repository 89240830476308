import { Component, OnInit } from '@angular/core';

import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

import { Team } from '../../shared/models/team';

@Component({
  selector: 'app-pick-a-team-dialog',
  templateUrl: './pick-a-team-dialog.component.html',
  styleUrls: ['./pick-a-team-dialog.component.scss']
})
export class PickATeamDialogComponent implements OnInit {

    public currentTeamId:number | undefined = undefined;

    constructor( 
        private dialogRef:DynamicDialogRef,
        private dialogConfig:DynamicDialogConfig 
    ) { }

    ngOnInit(): void {

        this.currentTeamId = this.dialogConfig.data.currentTeamId;
    }

    onTeamSelected(team:Team | undefined): void {

        this.close(team);
    }

    public close(team?:Team): void {

        this.dialogRef.close(team || undefined);
    }
}
