import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SingleBase64imageUploaderFieldComponent } from './single-base64image-uploader-field/single-base64image-uploader-field.component';
import { SelectedImageContentAreaComponent } from './components/selected-image-content-area/selected-image-content-area.component';

@NgModule({
  declarations: [
    SingleBase64imageUploaderFieldComponent, 
    SelectedImageContentAreaComponent
  ],
  imports: [
    CommonModule
  ],
  exports: [
    SingleBase64imageUploaderFieldComponent
  ]
})
export class SingleBase64imageUploaderFieldModule { }
