<!--
    The Dropzone.
    Visible solamente cuando NO hay un video seleccionado.
-->
<ng-container *ngIf="!isVideoSelected">
    <div class="uploader" (drop)="onFileSelected($event)" (dragover)="onDragOver($event)">
        <p class="top">
          <span>Arrastra y suelta o</span>
          <span class="btn">Añade <input type="file" [accept]="accept" (change)="onFileSelected($event)" required></span>
        </p>
        <p class="bottom">Elige un Video</p>
    </div>
</ng-container>

<!--
    The selected Video Zone.
    Visible solamente cuando EXISTE un video seleccionado.
 -->
<ng-container *ngIf="isVideoSelected">
    <app-selected-video-content-area
        [videoContent]="videoContent"
        (videoContentCleared)="onVideoContentCleared()">>
    </app-selected-video-content-area>
</ng-container>
