import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';

import {AuthenticateService} from '@app/auth/shared/guards/authenticate.service';
import {VerifyTeamPreferenceService} from '@app/auth/shared/guards/verify-team-preference.service';
import {WelcomeFirstTimeUserService} from '@app/auth/shared/guards/welcome-first-time-user.service';

// Pages.
import {ComunidadLandingComponent} from './pages/comunidad-landing/comunidad-landing.component';
import {ComunidadPostDetailComponent} from './pages/comunidad-post-detail/comunidad-post-detail.component';
import {ComunidadTopicComponent} from './pages/comunidad-topic/comunidad-topic.component';

// Panels.
import {CommunityHomePanelComponent} from './components/community-home-panel/community-home-panel.component';
import {CommunityFavoriteTeamPanelComponent} from './components/community-favorite-team-panel/community-favorite-team-panel.component';
import {CommunityFollowingPanelComponent} from './components/community-following-panel/community-following-panel.component';

const routes: Routes = [
  {
    path: 'community',
    component: ComunidadLandingComponent,
    canActivate: [AuthenticateService, VerifyTeamPreferenceService],
    children: [
      {
        path: '', 
        component: CommunityHomePanelComponent, 
        canActivate: [AuthenticateService, VerifyTeamPreferenceService] 
      },
      {
        path: 'favorite-team', 
        component: CommunityFavoriteTeamPanelComponent, 
        canActivate: [AuthenticateService, VerifyTeamPreferenceService] 
      },
      {
        path: 'following', 
        component: CommunityFollowingPanelComponent, 
        canActivate: [AuthenticateService, VerifyTeamPreferenceService] 
      }
    ]
  },
  {
    path: 'community/post/:id',
    component: ComunidadPostDetailComponent,
    canActivate: [AuthenticateService, WelcomeFirstTimeUserService, VerifyTeamPreferenceService]
  },
  {
    path: 'community/topic/:id',
    component: ComunidadTopicComponent,
    canActivate: [AuthenticateService,  WelcomeFirstTimeUserService, VerifyTeamPreferenceService]
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ComunidadRoutingModule {
}
