import { Component, OnInit, Output, EventEmitter, Input} from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';

// @ts-ignore
import * as Emoji from 'quill-emoji';

import { PostType } from '../../../shared/models/post-type';
import { PostText } from '../../../shared/models/post-text';

declare var Quill: any;

@Component({
	selector: 'app-edit-text-post-form',
	templateUrl: './edit-text-post-form.component.html',
	styleUrls: ['./edit-text-post-form.component.scss']
})
export class EditTextPostFormComponent implements OnInit {

	@Input() editTextPostLoading: boolean = false;  
	@Input() editablePostText!: Partial<PostText>;

	@Output() onUpdate: EventEmitter<Partial<PostText>> = new EventEmitter<Partial<PostText>>();
    @Output() onCancel: EventEmitter<void> = new EventEmitter<void>();

	public textTopic = '';
    public editTextPostForm!: FormGroup;

    constructor(private readonly fb: FormBuilder) {

		Quill.register('modules/emoji', Emoji);
	}

    ngOnInit(): void {

        this.loadEditTextPostForm();
    }

    private loadEditTextPostForm(): void {

        const { description } = this.editablePostText;
        
		this.editTextPostForm = this.fb.group({
            description:    [ description, [ Validators.required, Validators.maxLength(1000) ] ]
        });
    }

    public onEditTextPost(): void {

        // En caso que el usuario de click al botón iniciar sin haber ingresado los datos que se le piden.
        this.editTextPostForm.markAllAsTouched();

        // Una vez los datos del formulario sean correctos
        if ( this.editTextPostForm.valid ) {

            const postType: PostType = PostType.TEXT;
            const { description } = this.editTextPostForm.value;

            this.onUpdate.emit({id: this.editablePostText.id, description, postType} as Partial<PostText>);
        }
    }

    public onEditTextPostCancellation(): void {

        this.onCancel.emit();
    }

    get description() {

        return this.editTextPostForm.get('description');
    }

}
