
<div class="single-post-grid main-grid">
  <div class="grid-left">
    <div class="form">
      <app-topic-joined-type-ahead-list
        [topicId]="selectedTopicId"
        (onSelect)="onSelectTopic($event)"></app-topic-joined-type-ahead-list>

      <ul class="types">
        <li [ngClass]="{'active': this.postType === 2}">Link</li>
        <li [ngClass]="{'active': this.postType === 3}">Imagen</li>
        <li [ngClass]="{'active': this.postType === 4}">Video</li>
        <li [ngClass]="{'active': this.postType === 1}">Texto</li>
      </ul>

      <router-outlet></router-outlet>

      <div class="delete">
        <p-menu styleClass="clean-menu" #menu [popup]="true" [model]="postMenuOptions"></p-menu>
        <button type="button" (click)="menu.toggle($event)">. . .</button>
      </div>
    </div>
  </div>
</div>




<p-toast position="top-center" key="postManagerToastNotifications"></p-toast>
