import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { PostMediaService } from './post-media.service';
import { PostActionStatus } from '../models/post-action';
import { PostTextMappingService } from './post-text-mapping.service';

import { PostText } from '../models/post-text';
import { PostTextPayload } from '../models/post-text-payload';

@Injectable({
    providedIn: 'root'
})
export class PostTextService {

    constructor(
        private readonly postMedia: PostMediaService,
        private readonly postTextMapping: PostTextMappingService
    ) { }

    public create(postText: Partial<PostText>): Observable<PostActionStatus>  {

        // Mapeamos nuestro post para poder enviarlo al Servidor.
        const postTextPayload: Partial<PostTextPayload> = this.postTextMapping.mapPostTextPayload(postText);

        return this.postMedia.createPostMedia(postTextPayload);
    }

    public update(postText: Partial<PostText>) {

        // Mapeamos nuestro post para poder enviarlo al Servidor.
        const postTextPayload: Partial<PostTextPayload> = this.postTextMapping.mapPostTextPayload(postText);

        return this.postMedia.editPostMedia(postTextPayload);
    }
}
