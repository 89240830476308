import { Injectable } from '@angular/core';

import { ErrorEncoding } from '../models/error-encoding';

@Injectable()
export class ImageBase64ConverterService {

    public validMaxFileSize:number = 0;
    public allowedMimeTypes:string = '';
    
    constructor() {}
    
    public getImageInfoFrom(source: any): File | null {

        const [ file ] = source.files;
    
        return file as File;
    }
    
    public encodeBase64(file: File): Promise<string> {
    
        const reader: FileReader = new FileReader();
        
        return new Promise((resolve, reject) => {
            
            if ( !this.validMimeType(file.type) ) {

                reject({ 
                    code: 'invalid_mime_type', 
                    message: 'Invalid MIME Type'
                } as ErrorEncoding );

            } else if ( !this.validSize(file.size) ) {

                reject({ 
                    code: 'invalid_file_size', 
                    message: 'Invalid File Size'
                } as ErrorEncoding );

            } else {

                reader.readAsDataURL(file);
                reader.onload = () => resolve(reader.result?.toString() || '');
                reader.onerror = (err) => reject({ 
                    code: 'unsuccesful_encoding', 
                    message: 'File could not be Encoded' 
                } as ErrorEncoding );
            }
        });
    }

    private validSize(size: number): boolean {

        const fileSizeInKB: number = (size / 1024);

        return fileSizeInKB <= this.validMaxFileSize;
    }

    private validMimeType(type: string): boolean {

        return this.isAllowedMimeType( type );
    }

    private isAllowedMimeType(type: string): boolean {

        const allowedMimeTypes: string[] = this.allowedMimeTypes.split(',')
                                                                .map( (mimeType) => mimeType.trim() );
        
        return allowedMimeTypes.includes( type );
    }
}
