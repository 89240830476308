import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor() { }

  public getUserAvatar(): string {

    const defaultAvatar: string = 'assets/iconos/navbar/default_avatar.png';
    const avatar: string  = localStorage.getItem('avat') || defaultAvatar;

    return this.isValidField(avatar) ? avatar : defaultAvatar;
  }

  public getUserId(): number {
    const id: string | null = localStorage.getItem('usid');
    const usid: number = id ? +id : 0;
    return usid;
  }

  public getUsername(): string {
    const username: string | null = localStorage.getItem('usrn');

    return username ? username : 'Invalid User';
  }


  private isValidField(field: string): boolean {

    return (typeof field === 'string') && field.trim().length > 0 && field !== 'null';
  }
}
