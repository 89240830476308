import { Component, OnInit } from '@angular/core';
import {LayoutConfigService} from '@app/layout-config/shared/services/layout-config.service';

@Component({
    selector: 'app-disable-my-account-page',
    templateUrl: './disable-my-account-page.component.html',
    styleUrls: ['./disable-my-account-page.component.scss']
})
export class DisableMyAccountPageComponent implements OnInit {

    constructor(
        private readonly layoutConfigService: LayoutConfigService
    ) {
                // Establecemos la configuración inicial de la barra de navegación y del footer.
        layoutConfigService.setLayoutConfig({
            layout: {
                navbar: {visible: true},
                footer: {visible: false}
            }
        });
    }

    ngOnInit(): void {
    }

}
