import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { Location } from '@angular/common';

import { concatMap, tap } from 'rxjs/operators';

import { SubSink } from 'subsink';

import { LayoutConfigService } from '@app/layout-config/shared/services/layout-config.service';
import { ModalService } from '@app/ui/modals/shared/services/modal.service';
import { TopicService } from '../../shared/services/topic.service';

import { Topic } from '../../shared/models/topic';
import { TeamPreference } from '../../shared/models/team-preference';

@Component({
    selector: 'app-create-topic-page',
    templateUrl: './create-topic-page.component.html',
    styleUrls: ['./create-topic-page.component.scss']
})
export class CreateTopicPageComponent implements OnInit, OnDestroy {

    private subscriptions = new SubSink();

    public selectedCurrentTeam!: TeamPreference | undefined;
    public loadingCreateTopicAction: boolean = false;

    // Flag para mostrar/ocultar los Anuncios.
    public showAdTypeC: boolean = true;
    
    constructor( 
        private router: Router,
        private location: Location,
        private modalService: ModalService,
        private topicService: TopicService,
        private layoutConfigService: LayoutConfigService
    ) {

        // Establecemos la configuración inicial de la barra de navegación y del footer.
        layoutConfigService.setLayoutConfig({
            layout: { 
                navbar: { visible: true },
                footer: { visible: false }
            }
        });
    }

    ngOnInit(): void {

        this.selectedCurrentTeam = this.topicService.getCurrentTeamPreference();
    }

    ngOnDestroy(): void {

        this.subscriptions.unsubscribe();
    }

    public onCreateTopic($event: Topic): void {

        const topic: Topic = $event;
        let topicId: number = 0;

        this.loadingCreateTopicAction = true;

        this.subscriptions.sink = this.topicService.create(topic)
            .pipe(
                tap((topicCreated) => {
                    
                    this.loadingCreateTopicAction = false;

                    topicId = topicCreated.id;
                }),
                concatMap(() => {
                    
                    return this.modalService.successDialog('Tu Topic fue publicado').onClose;
                })
            ).subscribe(() => {

                this.router.navigate(['community/topic', topicId ]);
            });
    }

    public onCancelTopicCreation(): void {

        this.location.back();
    }
}
