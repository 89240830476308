import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, ActivatedRouteSnapshot} from '@angular/router';

import {forkJoin} from 'rxjs';

import {SubSink} from 'subsink';
import {Urls} from '@app/core/utilities/urls';
import {LayoutConfigService} from '@app/layout-config/shared/services/layout-config.service';
import {TeamsService} from '../../shared/services/teams.service';
import {TopicsService} from '../../shared/services/topics.service';

import {Topic} from '../../shared/models/topic';
import {TeamDetail} from '../../shared/models/team-detail';
import {Paginator} from '../../shared/models/paginator';
import { Location } from '@angular/common';

@Component({
  selector: 'app-team-detail-page',
  templateUrl: './team-detail-page.component.html',
  styleUrls: ['./team-detail-page.component.scss']
})
export class TeamDetailPageComponent implements OnInit, OnDestroy {

  // Flag para mostrar/ocultar los Anuncios.
  public showAdTypeC: boolean = true;

  constructor(
    private currentRoute: ActivatedRoute,
    private teams: TeamsService,
    private topics: TopicsService,
    private layoutConfig: LayoutConfigService,
    private readonly location: Location,

  ) {
    layoutConfig.setLayoutConfig({
      layout: {
        navbar: {visible: true},
        footer: {visible: false}
      }
    });
  }

  private subscriptions = new SubSink();

  public teamId: string = '';
  public teamName: string = '';
  public teamFlagIcon: string = '';
  public teamHomeUrl: string | null = '';
  public teamFB: string | null = '';
  public teamTW: string | null = '';
  public teamIG: string | null = '';
  public teamFBClean: string | null = '';
  public teamTWClean: string | null = '';
  public teamIGClean: string | null = '';
  private nextPage: number = 1;
  private lastPage: number = 0;

  public topicsByTeam: Topic[] = [];
  public previouslyLoadedTopics: Topic[] = [];
  public loadingMoreTopics: boolean = false;
  public showGotoFirstButton: boolean = false;
  public topicsLoaded: boolean = false;


  ngOnInit(): void {

    const routeSnapshot: ActivatedRouteSnapshot = this.currentRoute.snapshot;

    this.teamId = routeSnapshot.paramMap.get('id') || '';

    this.topicsLoaded = false;

    this.subscriptions.sink = forkJoin({
      teamDetail: this.teams.getTeamDetail(+this.teamId),
      paginatedTopics: this.topics.paginate(this.nextPage, +this.teamId)
    }).subscribe(({teamDetail, paginatedTopics}) => {

      this.topicsLoaded = true;

      this.loadTeamDetail(teamDetail);
      this.paginateTopics(paginatedTopics);

      this.layoutConfig.setLayoutConfig({
        layout: {
          navbar: {visible: true},
          footer: {visible: true}
        }
      });
    });
  }

  ngOnDestroy(): void {

    this.subscriptions.unsubscribe();
  }

  public onScroll(): void {

    if (this.hasNotReachedTheEndOfScrolling()) {

      this.loadingMoreTopics = true;

      this.topics.paginate(this.nextPage, +this.teamId).subscribe((paginatedTopics) => {

        this.loadingMoreTopics = false;
        this.paginateTopics(paginatedTopics);
      });

    } else {

      // Hemos alcanzado el final de la información obtenida y por lo tanto del scroll.
      this.showGotoFirstButton = true;
    }
  }

  private loadTeamDetail(teamDetail: TeamDetail): void {

    const {name, flagIcon, homeUrl, teamFB, teamTW, teamIG} = teamDetail;

    this.teamName = name;
    this.teamFlagIcon = flagIcon;
    this.teamHomeUrl = homeUrl;
    this.teamFB = teamFB || '';
    this.teamTW = teamTW || '';
    this.teamIG = teamIG || '';
    this.teamFBClean = Urls.getPathname(this.teamFB).replace(/[^\w\s]/gi, '');
    this.teamTWClean = Urls.getPathname(this.teamTW).replace(/[^\w\s]/gi, '');
    this.teamIGClean = Urls.getPathname(this.teamIG).replace(/[^\w\s]/gi, '');

    console.log('TeamIG ' + this.teamIG);
  }

  private paginateTopics(paginator: Paginator): void {

    const {lastPage, currentPage, topics} = paginator;

    // Actualizamos la información del Paginador.
    this.nextPage = currentPage + 1;
    this.lastPage = lastPage;

    // Fusionamos los topics previamente cargadados con los topics que provengan del Servidor.
    this.previouslyLoadedTopics = [...this.previouslyLoadedTopics, ...topics];

    // Actualizamos la vista con la nueva información cargada en el Property Binding correspondiente.
    this.topicsByTeam = this.previouslyLoadedTopics;
  }

  private hasNotReachedTheEndOfScrolling(): boolean {

    return (this.nextPage <= this.lastPage);
  }
  public back(): void {

    this.location.back();
  }
}
