import { Injectable } from '@angular/core';
import {Observable} from 'rxjs';
import {Topic, TopicResponse} from '../models/topic';
import {environment} from '@env/environment';
import {map} from 'rxjs/operators';
import {HttpClient} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class TopicService {

  constructor(
    private httpClient: HttpClient,
  ) { }

  public getTopicPublicById(topicId: number): Observable<Topic> {
    return this.httpClient.get<TopicResponse>(`${environment.host}/api/topics/${topicId}/nosession`)
      .pipe(
        map((topicResponse) => {
          const {content} = topicResponse;
          const {id, name, is_official, cover, totals, user_id, currentUser, description} = content;
          return {
            id, name, is_official, cover, totals, user_id, currentUser, description
          };
        })
      );
  }

}
