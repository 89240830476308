import {Component, OnInit} from '@angular/core';
import { Location } from '@angular/common';
import { Router } from '@angular/router';
import {ProfileService} from '../../shared/services/profile.service';
import {LayoutConfigService} from '@app/layout-config/shared/services/layout-config.service';

@Component({
  selector: 'app-profile-page',
  templateUrl: './profile-page.component.html',
  styleUrls: ['./profile-page.component.scss']
})
export class ProfilePageComponent implements OnInit {

  public username: string = '';
  public avatar: string = '';
  public fanLevel: string = '';
  public fanLevelPlate: string = '';
  public pointsReached: number = 0;
  public followedTopics: number = 0;
  public postsMade: number = 0;
  public commentsMade: number = 0;
  public teamImg = '';

  public profileMetricsLoaded: boolean = false;

  constructor(
    private readonly router: Router,
    private readonly profileService: ProfileService,
    private readonly layoutConfigService: LayoutConfigService
  ) {

    // Establecemos la configuración inicial de la barra de navegación y del footer.
    layoutConfigService.setLayoutConfig({
      layout: {
        navbar: {visible: true},
        footer: {visible: false}
      }
    });
  }

  ngOnInit(): void {

    this.profileMetricsLoaded = false;

    this.teamImg = this.profileService.getFavoriteTeamLogo();
    this.profileService.getProfileSummary().subscribe((profileSummary) => {

      this.profileMetricsLoaded = true;

      const {username, avatar, fanLevel, fanLevelPlate, summaryMetrics} = profileSummary;

      this.username = username;
      this.avatar = avatar;
      this.fanLevel = fanLevel;
      this.fanLevelPlate = fanLevelPlate;
      this.pointsReached = summaryMetrics.pointsReached;
      this.postsMade = summaryMetrics.postsMade;
      this.commentsMade = summaryMetrics.commentsMade;
    });
    this.profileService.getTotalTopicsIFollow().subscribe((response) => {
      this.followedTopics = response;
    });
  }

  public back(): void {

    this.router.navigateByUrl('/');
  }
}
