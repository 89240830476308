<button *ngIf="owned" (click)="onEditTopic()" type="button">
    Editar
</button>

<button *ngIf="!owned" (click)="onSubscribeTopic()" class="label join" [disabled]="suscriptionLoading" type="button" [ngClass]="{'btn-call-to-action joined': joined, 'btn-call-to-action': !joined}">
    <ng-container *ngIf="!suscriptionLoading; else loading">
        <span style="display: flex" *ngIf="!joined">Unirme +
        </span>

        <span *ngIf="joined"> Unido
            <svg width="11" height="9" viewBox="0 0 11 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M1 4.66667L4.73846 8L10 1" stroke="white"/>
            </svg>
        </span>
    </ng-container>

    <ng-template #loading>
        <i class="pi pi-spin pi-spinner button-spinner spinner-sm"></i>
      </ng-template>
</button>
