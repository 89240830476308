<div class="scrolling-area"
     infiniteScroll
     [infiniteScrollDistance]="2"
     [infiniteScrollThrottle]="150"
     (scrolled)="getMoreTopics()"
     [scrollWindow]="false">

  <div class="main-grid">
    <section class="user-topics grid-left">


      <div class="back mb-40">
        <a [routerLink]="['/account']"><img src="assets/iconos/icono_arrowdown.svg" alt="Regresar">
          Regresar
        </a>
      </div>
      <div>

        <div class="select-info banner">
          <h3>Mis <b>Topics Creados</b></h3>
          <p> Ve todos los topics que has creado en la comunidad de <b><i>Fans LigaMX</i></b></p>
          <img src="assets/topics-banner.png" alt="Tus topics Creados">
        </div>

        <section class="controls">
          <!--<div class="filters">
            Ordenar por:
          </div>-->
          <div class="filters-dropdown">
            <img src="assets/iconos/icon_ordenar.svg" alt="Ordenar">
            <span>Ordenar:</span>
            <p-dropdown
              [options]="dropDownFilters"
              optionLabel="dropDownFilterLabel"
              optionValue="dropDownFilterDirection"
              [dropdownIcon]="selectedFilter === 'DESC' ? 'pi pi-arrow-up' : 'pi pi-arrow-down'"
              [(ngModel)]="selectedFilter"
              (ngModelChange)="onChangeFilter($event)"></p-dropdown>
          </div>


        </section>
        <div class="items">
          <ng-container *ngIf="loaded; else loading">
            <ng-container *ngIf="topics.length > 0; else empty">
              <div class="item" *ngFor="let topic of topics">
                <app-topic-expanded
                  [id]="topic.id"
                  [isOfficial]="topic.is_official"
                  name="{{topic.name}}"
                  cover="{{topic.cover}}"
                  [totalFollowers]="topic.totals.followers"
                  description="{{topic.description}}"
                  [date]="topic.created_at"
                ></app-topic-expanded>
              </div>
            </ng-container>
            <ng-template #empty>
              <div class="no-results">
                <img src="assets/fan.png" alt="Sin resultados">
                <p><b>¡Ups! Aún no has creado Topics...</b></p>
                <p>Te invitamos a crear los Topics <a [routerLink]="['/topic/create']">Aqui</a></p>
                <img src="assets/iconos/icono_guardar.svg" alt="Guardar">
              </div>
            </ng-template>
          </ng-container>

          <ng-template #loading>
            <div style="text-align: center;">
              <i class="pi pi-spin pi-spinner spinner spinner-md"></i>
            </div>
          </ng-template>


          <ng-container *ngIf="loadingTopics">
            <div style="text-align: center;">
              <i class="pi pi-spin pi-spinner spinner spinner-md"></i>
            </div>
          </ng-container>
        </div>

      </div>

    </section>
    <div class="grid-right pt-100">
      <app-advertisement-type-c *ngIf="showAdTypeC" (loaded)="showAdTypeC = $event"></app-advertisement-type-c>
      <app-banner-community></app-banner-community>
    </div>
  </div>

</div>

