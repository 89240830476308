import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable, of } from 'rxjs';
import { map, tap } from 'rxjs/operators';

import { AuthProfileResponse } from '../models/auth-profile-response';

import { environment } from '@env/environment';

@Injectable({
    providedIn: 'root'
})
export class AuthProfileService {

    // Nos va a servir para mantener el id del usuario autenticado en memoria.
    public userId: number | undefined = undefined;
    public favoriteTeam: string | undefined = undefined;

    constructor( private httpClient: HttpClient ) { }

    public getAuthId(): Observable<number> {

        // El propósito es cachear el id del usuario para no volver a lanzar una peticiones
        // subsecuentes al servidor.
        if ( this.userId ) {

            return of(this.userId)
        }

        return this.httpClient.get<AuthProfileResponse>(`${ environment.host }/api/auth/profile`)
            .pipe(
                map((authProfileResponse) => {

                    const { content: { id:userId } } = authProfileResponse;

                    return userId;
                }),
                tap((userId) => {

                    this.userId = userId;
                })
            );
    }

    public getFavoriteTeamName(): Observable<string> {

        // El propósito es cachear el id del usuario para no volver a lanzar una peticiones
        // subsecuentes al servidor.
        if ( this.favoriteTeam ) {

            return of(this.favoriteTeam);
        }

        return this.httpClient.get<AuthProfileResponse>(`${ environment.host }/api/auth/profile`)
            .pipe(
                map((authProfileResponse) => {

                    const { content: { favorite_team } } = authProfileResponse;
                    return favorite_team ? favorite_team.name : 'Equipo Favorito';
                }),
                tap((favoriteTeam) => {

                    this.favoriteTeam = favoriteTeam;
                })
            );
    }
}
