import { Component, OnInit, OnDestroy } from '@angular/core';

import { SubSink } from 'subsink';

import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

import { PostVideoService } from '../../shared/services/post-video/post-video.service';
import { PostVideoMappingService } from '../../shared/services/post-video/post-video-mapping.service';

import { PostVideo } from '../../shared/models/post-video/post-video';
import { PostVideoPublicationMode } from '../../shared/models/post-video/post-video-publication-mode';
import { PostActionStatus } from '../../shared/models/post-video/post-action-status';

import { uploadProgress } from '../../shared/operators/post-video/upload-progress';
import { toResponseBody } from '../../shared/operators/post-video/to-response-body';

@Component({
  selector: 'app-video-progress-bar',
  templateUrl: './video-progress-bar.component.html',
  styleUrls: ['./video-progress-bar.component.scss'],
  providers: [ PostVideoService, PostVideoMappingService ]
})
export class VideoProgressBarComponent implements OnInit, OnDestroy {

    // La responsabilidad de este Componente es crear o actualizar una Publicación de Video.
    // Bajo este contexto, aqui suponemos que el objeto video siempre contendra una Instancia 
    // de tipo File dentro de su contenido.
    private subscriptions = new SubSink();

    public progress: number = 0;
    public postActionStatus: PostActionStatus | null = null; 

    public isLoadingVideo: boolean = false;
    public successMessage: string = '';
    public errorMessage: string = '';

    constructor(
        private readonly dialogRef: DynamicDialogRef,
        private readonly dialogConfig: DynamicDialogConfig,
        private readonly postVideo: PostVideoService,
        private readonly postVideoMapping: PostVideoMappingService
    ) { }

    ngOnInit(): void {

        this.isLoadingVideo = true;

        const postVideo: PostVideo = (this.dialogConfig.data?.postVideo) as PostVideo;
        const publicationMode = (this.dialogConfig.data?.postPublicationMode) as PostVideoPublicationMode;
        
        // console.log(postVideo);
        // console.log(publicationMode);

        // En este punto hemos recibido una Publicación de Video. Solo existen 2 opciones:
        // 1. Crear una Publicación nueva.
        // 2. Actualizar una Publicación existente.

        // Dependiendo de si estamos creando o actualizando una Publicación, mapeamos la información que será enviada 
        // al webservice.
        const payload: FormData = publicationMode === 'CreationMode' ? 
                                        this.postVideoMapping.mapVideoPostPayloadForCreation(postVideo) : this.postVideoMapping.mapVideoPostPayloadForUpdate(postVideo);
        
        // Dependiendo de si estamos creando o actualizando una Publicación, realizamos la acción correspondiente.
        this.subscriptions.sink = (publicationMode === 'CreationMode' ? this.postVideo.create(payload) : this.postVideo.update(payload) )
        .pipe(
            uploadProgress((progress) => this.progress = progress),
            toResponseBody()
        )
        .subscribe(
            (postActionStatus) => {

                this.isLoadingVideo = false;
                this.postActionStatus = postActionStatus;

                this.showSuccessMessage();
            },
            (err) => {

                this.isLoadingVideo = false;
                this.postActionStatus = null;

                this.showErrorMessage();
            }
        );
    }

    ngOnDestroy(): void {

        this.subscriptions.unsubscribe();
    }

    private showSuccessMessage(): void {

        this.successMessage = 'Su publicación ha sido guardada exitosamente';
        this.errorMessage = '';
    }

    private showErrorMessage(): void {

        this.successMessage = '';
        this.errorMessage = 'Ocurrio un error al crear la publicación, ¡intentelo nuevamente!'
    }

    public close(): void {

        this.dialogRef.close(this.postActionStatus);
    }
}
