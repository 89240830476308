import { Component, OnInit } from '@angular/core';
@Component({
  selector: 'app-coming-soon-landing-page',
  templateUrl: './coming-soon-landing-page.component.html',
  styleUrls: ['./coming-soon-landing-page.component.scss']
})
export class ComingSoonLandingPageComponent implements OnInit {

  sliderImages = [
    'assets/coming-soon/foto1.jpg',
    'assets/coming-soon/foto2.jpg',
    'assets/coming-soon/foto3.jpg',
    'assets/coming-soon/foto4.jpg',
    'assets/coming-soon/foto5.jpg'
  ];

  constructor() { }

  ngOnInit(): void {
  }

}
