<div class="main-grid">
  <div class="grid-left">
    <section class="section privacy">
      <h4 class="subtitle"><b>AVISO DE PRIVACIDAD</b></h4>
      <p>
        <img src="assets/iconos/icono_politica.svg" alt="Politica de Privacidad">
        <!--<b>I.-Introducción</b>-->
      </p>

      <p>De acuerdo a lo Previsto en la “Ley Federal de Protección de Datos Personales”, declara CANTON MERCHANDISING,
        ser una empresa legalmente constituida de conformidad con las leyes mexicanas, con domicilio en Blvd. Puerta de
        Hierro, número 5225, Torre Jade, Departamento 502. Zapopan Jalisco.; y como responsable del tratamiento de sus
        datos personales, hace de su conocimiento que la información de nuestros clientes es tratada de forma
        estrictamente confidencial por lo que al proporcionar sus datos personales, tales como:</p>
      <ol>
        <li>Nombre Completo.</li>
        <li>Dirección.</li>
        <li>Registro Federal de Contribuyentes.</li>
        <li>Teléfonos de Hogar, Oficina y móviles.</li>
        <li>Correo Electrónico.</li>
      </ol>

      <p>Estos serán utilizados única y exclusivamente para los siguientes fines:</p>
      <ol>
        <li>Información y Prestación de Servicios.</li>
        <li>Actualización de la Base de Datos.</li>
        <li>Cualquier finalidad análoga o compatible con las anteriores.</li>
      </ol>

      <p>En el caso de Datos sensibles, tales como:</p>

      <ol>
        <li>Datos Financieros (Ingresos, Estados de Cuenta, y demás relacionados)</li>
        <li>Datos Patrimoniales (Bienes Materiales, Inmuebles, y demás relacionados)</li>
        <li>Datos Personales (Cónyuge, Estado Civil, Nacionalidad, Educación, Hijos, y demás relacionados).</li>
        <li>Referencias familiares y no familiares (Nombre, Dirección, Teléfono, relación, etc.).</li>

      </ol>

      <p>Estos serán utilizados única y exclusivamente para los siguientes fines:</p>

      <ol>
        <li>Investigación y/u Obtención de Créditos ante las Instituciones Financieras.</li>
        <li>Cualquier finalidad análoga o compatible con la anterior.</li>
        <li>Información y Prestación de Servicios</li>

      </ol>

      <p>Para prevenir el acceso no autorizado a sus datos personales y con el fin de asegurar que la información sea
        utilizada para los fines establecidos en este aviso de privacidad, hemos establecido diversos procedimientos con
        la finalidad de evitar el uso o divulgación no autorizados de sus datos, permitiéndonos tratarlos
        debidamente.</p>
      <p>Así mismo, le informamos que sus datos personales pueden ser Transmitidos para ser tratados por personas
        distintas a esta empresa.</p>
      <p>Todos sus datos personales son tratados de acuerdo a la legislación aplicable y vigente en el país, por ello le
        informamos que usted tiene en todo momento los derechos (ARCO) de acceder, rectificar, cancelar u oponerse al
        tratamiento que le damos a sus datos personales; derecho que podrá hacer valer a través del Área de Privacidad
        encargada de la seguridad de datos personales por medio de su correo electrónico: developer@serouno.com</p>
      <p>A través de estos canales usted podrá actualizar sus datos y especificar el medio por el cual desea recibir
        información, ya que en caso de no contar con esta especificación de su parte, CANTON MERCHANDISING S.A. DE C.V.
        establecerá libremente el canal que considere pertinente para enviarle información.</p>
      <p>Este aviso de privacidad podrá ser modificado por CANTON MERCHANDISING S.A. DE C.V., dichas modificaciones
        serán oportunamente informadas a través de correo electrónico, teléfono, o cualquier otro medio de comunicación
        que CANTON MERCHANDISING S.A. DE C.V. determine para tal efecto.</p>


    </section>
  </div>
</div>

