import { Injectable } from '@angular/core';

import { Video } from '../models/video';

@Injectable()
export class VideoConverterService {

    public validMaxFileSize:number = 0;
    public allowedMimeTypes:string = '';
    
    constructor() { }

    public getVideo(source: any): Video {

        const [ file ] = source.files as File[];

        const video: Video = {
            name:   null,
            content:null,
            type:   null,
            size:   null,
            isLoadedLocally: false,
        };

        if ( !file ) {

            return {
                ...video,
                errorCode: 'unselected_file'
            } as Video;
        }

        const { name, type, size } = file;

        if ( !this.validMimeType(type) ) {
            
            return {
                ...video,
                errorCode: 'invalid_mime_type'
            } as Video;
        } 
        
        if ( !this.validSize(size) ) {

            return {
                ...video,
                errorCode: 'invalid_file_size'
            } as Video;

        }

        return {
            ...video,
            name,
            content: file,
            isLoadedLocally: true,
            type,
            size
        } as Video;
    }

    private validSize(size: number): boolean {

        const fileSizeInKB: number = (size / 1024);

        return fileSizeInKB <= this.validMaxFileSize;
    }

    private validMimeType(type: string): boolean {

        return this.isAllowedMimeType( type );
    }

    private isAllowedMimeType(type: string): boolean {

        const allowedMimeTypes: string[] = this.allowedMimeTypes.split(',')
                                                                .map((mimeType) => mimeType.trim() );
        
        return allowedMimeTypes.includes( type );
    }
}

