<ul class="results">
  <li *ngFor="let poll of pollingMetrics">
    <!-- Seria cuestión de ver si la selección del usuario es representada por un icono o por un color -->
    <div class="item-result" [ngClass]="{'winner-poll': poll.isWinner, 'selected-poll': poll.isSelected}">
      <span class="percentage" [style]="{width: (poll.weightedVoting * 100) + '%'}"></span>
      <p>{{ poll.title }}</p>
    </div>
    <div class="result-img">
      <img *ngIf="poll.isWinner" src="assets/iconos/icono_trofeo.svg" alt="Winner Trophy Image">
    </div>
    <!-- Metemos un pipe para la conversión a porcentaje -->
    <div class="result-percentage">
      <span>{{ poll.weightedVoting | percent }}</span>
    </div>
  </li>
</ul>
