import { Component, OnInit, Input, ChangeDetectionStrategy } from '@angular/core';

@Component({
  selector: 'app-new',
  templateUrl: './new.component.html',
  styleUrls: ['./new.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NewComponent implements OnInit {

  @Input() id!: number;
  @Input() title: string = '';
  @Input() urlImage: string = '';
  @Input() url: string = '';
  @Input() avatarDomain: string = '';
  @Input() hostDomain: string = '';
  @Input() date!: Date;

  constructor() { }

  ngOnInit(): void {
  }

}
