import { Input, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import {InputTextModule} from 'primeng/inputtext';

import { PasswordFieldComponent } from './password-field/password-field.component';

@NgModule({
  declarations: [PasswordFieldComponent],
  imports: [
    CommonModule,
    InputTextModule
  ],
  exports: [ PasswordFieldComponent ]
})
export class PasswordFieldModule { }
