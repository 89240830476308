import { Injectable } from '@angular/core';

import { Polling, PollingMetrics } from '../../shared/models/post-polling';

@Injectable({
  providedIn: 'root'
})
export class PostPollingMetricsService {

    constructor() {}

    // Para calcular las métricas de votación necesitamos:
    // 1. El estado (ABIERTO/CERRADO) de la Publicación de Votación.
    // 2. El total de todos los votos contados de la Publicación de Votación.
    // 3. La lista de opciones de Votación a partir de la cual generamos las Métricas.
    public getPollingMetrics(pollIsOpen: boolean, pollTotalVotesCounted: number, polls: Polling[]): PollingMetrics[] {

        // La forma en que se muestran las Métricas dependerá de si el estado de la Votación se encuentra CERRADO o ABIERTO.
        if ( pollIsOpen ) {

            // 1. Cuando una Votación se encuentra ABIERTA:

            // Creamos las Métricas a partir de la lista de opciones de votación.
            return polls.map((poll) => {

                // Datos de interés para crear esta métrica.
                const { title, voteCounting, isSelected } = poll;

                return {
                    title,
                    weightedVoting: (pollTotalVotesCounted === 0) ? 0 : (voteCounting / pollTotalVotesCounted),
                    isSelected, // Por si alguna opción de votación esta seleccionada, incluimos este estado en la Métrica.
                    isWinner: false
                };
            });

        } else {

            // 2. Cuando una Votación se encuentra CERRADA.

            // Agrupamos nuestra lista de opciones de votación.
            const winningPollingGroupIdxs = this.getWinningPollingGroupIdxs(polls);

            // Una vez agrupada la información debemos comprobar 2 cosas:
            // 1) Si no existe por lo menos una agrupación de datos, entonces mostramos las Métricas en su formato simple.
            // 2) Si existe una agrupación con más de 3 opciones ganadoras con el mismo valor en su propiedad vouteCounting, básicamente podemos 
            //    afirmar que no existen opciones ganadoras, puesto que hay un empate múltiple, en este caso solamente debemos mostrar las Métricas en su formato simple.
            if ( winningPollingGroupIdxs.length === 0 || winningPollingGroupIdxs.length >= 3 ) {

                // Dado que no hay que calcular ninguna Métrica con un estado ganador, simplemente mostras las Métricas en su formato simple.
                // Creamos las Métricas a partir de la lista de opciones de votación.
                return polls.map((poll) => {

                    // Datos de interés para crear esta métrica.
                    const { title, voteCounting } = poll;

                    return {
                        title,
                        weightedVoting: (pollTotalVotesCounted === 0) ? 0 : (voteCounting / pollTotalVotesCounted),
                        isSelected: false,  // No es necesario calcular un estado de selección.
                        isWinner: false     // Tampoco es necesario calcular un estado ganador.
                    };
                });

            } 

            // En este punto, existe una agrupación ganadora cuyo tamaño de elementos se encuentre entre 1 y 2 elementos, 
            // En el caso de una agrupación con 1 solo elemento, significa que hay una opción de votación ganadora.
            // En el caso de una agrupación con 2 elementos, significa que hay 2 opciones de votaciónes ganadoras diferentes pero empatadas.
            return polls.map((poll, currentIdx) => {

                // Datos de interés para crear esta métrica.
                const { title, voteCounting } = poll;
                
                return {
                    title,
                    weightedVoting: (pollTotalVotesCounted === 0) ? 0 : (voteCounting / pollTotalVotesCounted),
                    isSelected: false,  // No es necesario calcular un estado de selección.
                    // Si el currentIdx actual se encuentra dentro del conjunto de índices de los elementos ganadores entonces tenemos un ganador.
                    // dado que la agrupación de elementos se encuentre entre 1 y 2 elementos, utilizamos el método includes() para no tener que 
                    // estar checando: winningPollingGroupIdxs.length === 1 o winningPollingGroupIdxs.length === 2
                    isWinner: winningPollingGroupIdxs.includes(currentIdx)  
                };
            });
        }
    }

    private getWinningPollingGroupIdxs(polls: Polling[]): number[] {
        
        // Para poder determinar si hay opciones ganadores o no dentro de una lista de votaciones debemos: 
        // 1. Tomar la lista de votaciones y crear grupos/categorías en función del campo voteCounting.
        // 2. Cada grupo tiene como clave el valor de voteCounting y como valor las posiciones o índices de cada votación donde su propiedad voteCounting se repite.
        // 3. Tomamos el grupo cuya clave es la más alta entre las diferentes agrupaciones.
        
        const pollingVotesGroupedByVoteCounting = polls
            // Solo nos interesa el valor del campo voteCounting de cada opción de votación.
            .map((poll) => poll.voteCounting)
            // Llevamos a cabo el proceso de agrupación de las votaciones por el campo voteCounting
            .reduce((state, voteCounting, idx) => {

                // Excluimos todas aquellas votaciones que tienen como valor 0.
                if ( voteCounting !== 0 ) {

                    state[voteCounting] = state[voteCounting] || [];
                    state[voteCounting] = [ ...state[voteCounting], idx ];                              
                }

                return state;

            }, {} as { [key:number]: number[] });

        // Si no se encuentra alguna agrupación de información, entonces no hay datos con los cuales trabajar para 
        // calcular el estado de las opciones ganadoras. Esto es común cuando todas las opciones de votación tienen 
        // en su campo voteCounting como valor 0 (voteCounting = 0)
        if ( Object.keys(pollingVotesGroupedByVoteCounting).length === 0 ) {

            return []; 
        }

        // Si llegamos a este punto, entonces con seguridad podemos decir que existe por lo menos 1 (o más agrupaciones) agrupación. De entre ellas, vamos a 
        // seleccionar la agrupación con la clave más alta, en esta se encontrarán las posiciones de los elementos ganadores dentro de la lista de Votación.
        const highestScoreKey = Math.max( ...Object.keys(pollingVotesGroupedByVoteCounting).map((groupKey) => Number(groupKey)) );
        const highestScoreGroup = pollingVotesGroupedByVoteCounting[ highestScoreKey ];

        return highestScoreGroup;
    }
}
