import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { MenuModule } from 'primeng/menu';

// Post.
import { PostMediaExpandedComponent } from './post-media-expanded/post-media-expanded.component';
import { PostMediaSmallComponent } from './post-media-small/post-media-small.component';
import { PostPollingComponent } from './post-polling/post-polling.component';

// Post Subcomponents.
import { ReactionsComponent } from './components/reactions/reactions.component';
import { LikesComponent } from './components/likes/likes.component';
import { SocialSharingComponent } from './components/social-sharing/social-sharing.component';
import { FollowComponent } from './components/follow/follow.component';
import { ReactionsSelectedComponent } from './components/reactions/reactions-selected/reactions-selected.component';
import { DislikeComponent } from './components/likes/dislike/dislike.component';
import { LikeComponent } from './components/likes/like/like.component';
import { TopicCallToActionComponent } from './components/topic-call-to-action/topic-call-to-action.component';
import { TopicCallToActionButtonComponent } from './components/topic-call-to-action/topic-call-to-action-button/topic-call-to-action-button.component';
import { PostThumbnailComponent } from './post-thumbnail/post-thumbnail.component';
import { PostPollingMetricsComponent } from './post-polling/post-polling-metrics/post-polling-metrics.component';
import { TotalVotesCountedFormatPipe } from './pipes/total-votes-counted-format.pipe';
import { TimeToLiveFormatPipe } from './pipes/time-to-live-format.pipe';

@NgModule({
  declarations: [
    ReactionsComponent,
    LikesComponent,
    SocialSharingComponent,
    FollowComponent,
    ReactionsSelectedComponent,
    DislikeComponent,
    LikeComponent,
    TopicCallToActionComponent,
    TopicCallToActionButtonComponent,
    PostMediaExpandedComponent,
    PostMediaSmallComponent,
    PostPollingComponent,
    PostThumbnailComponent, PostPollingMetricsComponent, TotalVotesCountedFormatPipe, TimeToLiveFormatPipe,
  ],
  imports: [
    CommonModule,
    RouterModule,
    MenuModule
  ],
  exports: [
    PostMediaExpandedComponent,
    PostMediaSmallComponent,
    PostPollingComponent,
  ]
})
export class PostModule { }
