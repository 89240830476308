import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AuthenticateService } from '@app/auth/shared/guards/authenticate.service';
import { VerifyTeamPreferenceService } from '@app/auth/shared/guards/verify-team-preference.service';

import { SearchResultsPagesComponent } from './pages/search-results-pages/search-results-pages.component';
import { SearchResultsTopicsPanelComponent } from './components/search-results-topics-panel/search-results-topics-panel.component';
import { SearchResultsPostsPanelComponent } from './components/search-results-posts-panel/search-results-posts-panel.component';
import { SearchResultsNewsPanelComponent } from './components/search-results-news-panel/search-results-news-panel.component';

const routes: Routes = [
  { 
    path: 'search-results', 
    component: SearchResultsPagesComponent,
    canActivate: [ AuthenticateService, VerifyTeamPreferenceService ],
    children: [
      { path: '', component: SearchResultsPostsPanelComponent },
      { path: 'topics', component: SearchResultsTopicsPanelComponent },
      { path: 'news', component: SearchResultsNewsPanelComponent }
    ] 
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class SearchResultsRoutingModule { }
