import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {InfiniteScrollModule} from 'ngx-infinite-scroll';

import {CalendarModule} from 'primeng/calendar';
import {InputSwitchModule} from 'primeng/inputswitch';
import {ToastModule} from 'primeng/toast';

import {ModalsModule} from '@app/ui/modals/modals.module';
import {SelectTeamFieldModule} from '@app/ui/select-team-field/select-team-field.module';
import {PasswordFieldModule} from '@app/ui/password-field/password-field.module';
import {ProfileAvatarFieldModule} from '@app/ui/profile-avatar-field/profile-avatar-field.module';
import {MediaSourceModule} from '@app/ui/media-source/media-source.module';
import {AccountSettingsRoutingModule} from './account-settings-routing.module';


// Pages.
import {ProfilePageComponent} from './pages/profile-page/profile-page.component';
import {MyPostsPageComponent} from './pages/my-posts-page/my-posts-page.component';
import {SavedNewsPageComponent} from './pages/saved-news-page/saved-news-page.component';
import {TopicsCreatedPageComponent} from './pages/topics-created-page/topics-created-page.component';
import {ProfileSettingsPageComponent} from './pages/profile-settings-page/profile-settings-page.component';
import {DisableMyAccountPageComponent} from './pages/disable-my-account-page/disable-my-account-page.component';

// Panels.
import {EditProfilePanelComponent} from './components/edit-profile-panel/edit-profile-panel.component';
import {ChangePasswordPanelComponent} from './components/change-password-panel/change-password-panel.component';
import {NotificationsPanelComponent} from './components/notifications-panel/notifications-panel.component';
import {PrivacyPolicyPanelComponent} from './components/privacy-policy-panel/privacy-policy-panel.component';
import {TermsAndConditionsPanelComponent} from './components/terms-and-conditions-panel/terms-and-conditions-panel.component';

// Forms & other Components.
import {EditProfileFormComponent} from './components/edit-profile-form/edit-profile-form.component';
import {ChangePasswordFormComponent} from './components/change-password-form/change-password-form.component';
import {NotificationControlsComponent} from './components/notification-controls/notification-controls.component';
import {NotificationComponent} from './components/notification/notification.component';
import { FollowedNewComponent } from './components/followed-new/followed-new.component';
import {TopicModule} from '@app/ui/topic/topic.module';
import {MenuModule} from 'primeng/menu';
import {PostModule} from '@app/ui/post/post.module';
import {LinkPreviewModule} from '@app/ui/link-preview/link-preview.module';
import {ConfirmDialogModule} from "primeng/confirmdialog";
import {AdvertisementModule} from "@app/ui/advertisement/advertisement.module";
import {BannerCommunityModule} from "@app/ui/banner-community/banner-community.module";
import {DropdownModule} from "primeng/dropdown";

@NgModule({
  declarations: [
    ProfilePageComponent,
    MyPostsPageComponent,
    SavedNewsPageComponent,
    TopicsCreatedPageComponent,
    ProfileSettingsPageComponent,
    EditProfilePanelComponent,
    ChangePasswordPanelComponent,
    NotificationsPanelComponent,
    PrivacyPolicyPanelComponent,
    TermsAndConditionsPanelComponent,
    EditProfileFormComponent,
    ChangePasswordFormComponent,
    NotificationControlsComponent,
    NotificationComponent,
    FollowedNewComponent,
    DisableMyAccountPageComponent,
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    CalendarModule,
    InputSwitchModule,
    ToastModule,
    ModalsModule,
    SelectTeamFieldModule,
    PasswordFieldModule,
    ProfileAvatarFieldModule,
    MediaSourceModule,
    AccountSettingsRoutingModule,
    InfiniteScrollModule,
    TopicModule,
    MenuModule,
    PostModule,
    LinkPreviewModule,
    ConfirmDialogModule,
    AdvertisementModule,
    BannerCommunityModule,
    DropdownModule,
    FormsModule,
  ]
})
export class AccountSettingsModule {
}
