import {Component, OnDestroy, OnInit} from '@angular/core';

import {SubSink} from 'subsink';

import {LayoutConfigService} from '@app/layout-config/shared/services/layout-config.service';
import {Topic} from '../../shared/models/topic';
import {ActivatedRoute, ActivatedRouteSnapshot, Router} from '@angular/router';
import {PostsService} from '../../shared/services/posts.service';
import {UserService} from '../../shared/services/user.service';
import {MessageService} from 'primeng/api';
import {switchMap, distinctUntilChanged} from 'rxjs/operators';
import {CommunityPaginatorService} from '../../shared/services/community-paginator.service';
import {PostElement} from '../../shared/models/post-list';
import {PaginatorItemPost} from '../../shared/models/paginator-item-post';

@Component({
  selector: 'app-comunidad-topic',
  templateUrl: './comunidad-topic.component.html',
  styleUrls: ['./comunidad-topic.component.scss']
})
export class ComunidadTopicComponent implements OnInit, OnDestroy {

  constructor(
    private readonly router: Router,
    private readonly currentRoute: ActivatedRoute,
    private readonly userService: UserService,
    private readonly postsService: PostsService,
    private readonly messageService: MessageService,
    private readonly communityPaginatorService: CommunityPaginatorService,
    private readonly layoutConfigService: LayoutConfigService,
  ) {
    layoutConfigService.setLayoutConfig({
      layout: {
        navbar: {visible: true},
        footer: {visible: false}
      }
    });
  }

  private subscriptions = new SubSink();
  public topicId = '';
  public userId = 0;
  public href = '';
  public loadingFollowing = false;
  public following: boolean | undefined = false;
  public topicIsLoaded = false;
  public topic?: Topic;
  public previouslyLoadedPosts: PostElement[] = [];
  public paginatorItemPostsPreviouslyLoaded: PaginatorItemPost[] = [];
  public loadingMorePosts: boolean = false;
  public initialLoadingDone: boolean = false;
  public userImg = '';
  public paginatorItemPosts: PaginatorItemPost[] = [];

  // Flag para mostrar/ocultar los Anuncios.
  public showAdTypeB: boolean = true;

  ngOnInit(): void {
    this.userImg = this.userService.getUserAvatar();
    const routeSnapshot: ActivatedRouteSnapshot = this.currentRoute.snapshot;
    this.topicId = routeSnapshot.paramMap.get('id') || '';
    this.userId = this.userService.getUserId();
    this.loadTopicDetail(+this.topicId);
    this.href = 'https://share.serouno.com/topic/' + this.topicId + '/share';
    this.subscriptions.sink = this.communityPaginatorService.listenPagination$
      .pipe(
        distinctUntilChanged(),
        switchMap((page) => this.communityPaginatorService.getPostsByTopic(page, +this.topicId))
      )
      .subscribe((posts) => {

        const isFirstPage: boolean = ((this.communityPaginatorService.nextPage - 1) === 1);

        if (isFirstPage) {

          this.initialLoadingDone = true;
        }

        this.loadingMorePosts = false;
        this.paginatePosts(posts);
        console.log(posts);
      });

    // Fire first Pagination Page.
    this.communityPaginatorService.paginate(1);

  }

  ngOnDestroy(): void {

    this.subscriptions.unsubscribe();
  }

  public onScroll(): void {

    if (this.communityPaginatorService.hasNotReachedPaginationEnd()) {

      const nextPage: number = this.communityPaginatorService.nextPage;

      this.loadingMorePosts = true;
      this.communityPaginatorService.paginate(nextPage);
    }
  }

  private paginatePosts(posts: PaginatorItemPost[]): void {

    // Fusionamos los posts previamente cargadados con los posts que provengan del Servidor.
    this.paginatorItemPostsPreviouslyLoaded = [...this.paginatorItemPostsPreviouslyLoaded, ...posts];

    // Actualizamos la vista con la nueva información cargada en el Property Binding correspondiente.
    this.paginatorItemPosts = this.paginatorItemPostsPreviouslyLoaded;
  }

  loadTopicDetail(topicId: number): void {
    this.subscriptions.sink = this.postsService.getTopicById(topicId).subscribe({
      next: (topic) => {
        this.topic = topic;
      },
      complete: () => {
        this.topicIsLoaded = true;
        this.following = this.topic?.currentUser.isFollower;
      }
    });
  }

  public follow(topicId: number): void {
    this.loadingFollowing = true;
    this.subscriptions.sink = this.postsService.followTopic(topicId).subscribe({
      next: (res) => {
        this.following = res;
      },
      complete: () => {
        this.loadingFollowing = false;
      }
    });
  }

  public unFollow(topicId: number): void {
    this.loadingFollowing = true;
    this.subscriptions.sink = this.postsService.unFollowTopic(topicId).subscribe({
      next: (res) => {
        this.following = res;
      },
      complete: () => {
        this.loadingFollowing = false;
      }
    });
  }

  public shareTopic(inputElement: HTMLInputElement): void {
    inputElement.select();
    document.execCommand('copy');
    inputElement.setSelectionRange(0, 0);
    this.messageService.add({
      severity: 'success',
      summary: 'Éxito',
      detail: 'La liga se a guardado en tu portapapeles'
    });
  }

  public onBookmarkPost(isFollowingPost: boolean): void {

    this.messageService.add({
      severity: isFollowingPost ? 'success' : 'info',
      summary: 'Éxito',
      detail: isFollowingPost ? 'Has guardado esta noticia.' : 'Has eliminado de tus guardados esta noticia.'
    });
  }
}
