import { PostType } from './post-type';
import { Topic } from './post-media';

export interface PostPolling {
    id:                 number;     // Este es el id del Post de Votación
    description:        string;
    type:               PostType;
    pollingId:          number;     // Este es el id de la Votación que agrupa las opciones.
    polls:              Polling[];
    isOpen:             boolean;    // true - votación abierta, false - votación cerrada.
    TTL:                number;     // Time To Live, duración que le queda a la publicación.
    totalVotesCounted:  number;     // Es la suma de votos de todas los opciones.
    topic?:             Topic;
}

export interface Polling {
    title:          string;
    option:         PollingOption;
    voteCounting:   number;         // El total de votos solo de la opción.
    isSelected:     boolean;
}

export enum PollingOption {
    A = 1,
    B = 2,
    C = 3,
    D = 4
}

export interface PollingMetrics {
    title:                  string;
    weightedVoting:         number;
    isSelected:            boolean;
    isWinner:              boolean;
}
