export class FileUtility {

    static extractName(file: string): string { 

        if ( !file ) { return ''; }

        return file.slice(0, file.indexOf('.') );
    }
   
    static extractExtension(mimeExtension: string): string { 

        if ( !mimeExtension ) { return ''; }
        
        return mimeExtension.slice( mimeExtension.indexOf('/') + 1 );
    }

    static extractOnlyBase64Text(encodedImage: string): string {

        if ( !encodedImage ) { return ''; }

        return encodedImage.slice( encodedImage.indexOf(',') + 1 );
    }
}