import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';

import { TopicCoverImageValidators } from '../../shared/validators/topic-cover-image-validators';
import { Topic } from '../../shared/models/topic';
import { TeamPreference } from '../../shared/models/team-preference';

@Component({
    selector: 'app-create-topic-form',
    templateUrl: './create-topic-form.component.html',
    styleUrls: ['./create-topic-form.component.scss']
})
export class CreateTopicFormComponent implements OnInit {

    @Input() createTopicLoading: boolean = false;
    @Input() currentTeamPreference!: TeamPreference | undefined;
    @Output() create: EventEmitter<Topic> = new EventEmitter<Topic>();
    @Output() cancel: EventEmitter<void> = new EventEmitter<void>();

    public createTopicForm!: FormGroup;

    constructor(private fb: FormBuilder) { }

    ngOnInit(): void {

        this.loadCreateTopicForm();
    }

    private loadCreateTopicForm(): void {
        
        this.createTopicForm = this.fb.group({
            title:          [ '', [ Validators.required ] ],
            description:    [ '', [ Validators.required ] ],
            teamPreference: [ this.currentTeamPreference, [ Validators.required ] ],
            cover:          [ null, [ TopicCoverImageValidators.mustBeValidImage ] ]
        });
    }

    public onCreateTopic(): void {

        // En caso que el usuario de click al botón iniciar sin haber ingresado los datos que se le piden.
        this.createTopicForm.markAllAsTouched();
        
        // Una vez los datos del formulario sean correctos y tengamos una imagen seleccionada como cover
        // realizamos el envio.
        if ( this.createTopicForm.valid ) {

            const { title, description, cover, teamPreference } = this.createTopicForm.value;

            this.create.emit({ title, description, cover, teamPreference } as Topic);
        }
    }

    public onCreateTopicCancellation():void {

        this.cancel.emit();
    }

    get title() {

        return this.createTopicForm.get('title');
    }

    get description() {

        return this.createTopicForm.get('description');
    }

    get teamPreference() {

        return this.createTopicForm.get('teamPreference');
    }

    get cover() {

        return this.createTopicForm.get('cover');
    }
}
