import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { TextEditorComponent } from './text-editor/text-editor/text-editor.component';
import {EditorModule} from 'primeng/editor';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';

@NgModule({
  declarations: [TextEditorComponent],
  imports: [
    CommonModule,
    RouterModule,
    EditorModule,
    ReactiveFormsModule,
    FormsModule
  ],
  exports: [ TextEditorComponent ]
})
export class TextEditorModule { }
