import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
    selector: 'app-follow',
    templateUrl: './follow.component.html',
    styleUrls: ['./follow.component.scss']
})
export class FollowComponent {

    @Input('status') isFollowingPost: boolean = false;
    @Output() onFollow: EventEmitter<void> = new EventEmitter<void>();
    @Output() onUnfollow: EventEmitter<void> = new EventEmitter<void>();

    public bookmarkPost(): void {
        
        this.isFollowingPost ? this.onUnfollow.emit() : this.onFollow.emit();
        this.isFollowingPost = !this.isFollowingPost;
    }
}
