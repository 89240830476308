<section class="section notifications scrolling-area"
         infiniteScroll
         [infiniteScrollDistance]="2"
         [infiniteScrollThrottle]="150"
         (scrolled)="loadMoreNotices()"
         [scrollWindow]="false">>

  <div class="main-grid">
    <div class="grid-left">
      <div class="notifications-container">
        <h4><b>Notificaciones</b></h4>

        <app-notification-controls></app-notification-controls>

        <p-menu [popup]="true" #menu [model]="menuNotices"></p-menu>

        <button type="button" pButton class="menu-notices" label=""
                (click)="menu.toggle($event)">
          <img src="{{'assets/iconos/icon_noti'+noticesActive+'.svg'}}" alt="Notification">
          {{titleNotice}}</button>

        <div class="notification-list">
          <ng-container *ngIf="!isLoading; else loading">
            <div class="item" *ngFor="let notice of notices">
              <app-notification
                [date]="notice.date"
                [dateDifference]="notice.dateDifference"
                [id]="notice.id"
                [urlApp]="notice.urlApp"
                title="{{notice.title}}"
                [body]="notice.body"
                [from]="notice.from"
                [imageFrom]="notice.imageFrom"
              ></app-notification>
            </div>

            <div *ngIf="loadingMore" style="text-align: center">
              <i class="pi pi-spin pi-spinner spinner spinner-md"></i>
            </div>
          </ng-container>
          <ng-template #loading>
            <div style="text-align: center; margin-top: 20px">
              <i class="pi pi-spin pi-spinner spinner spinner-md"></i>
            </div>
          </ng-template>
        </div>
      </div>
    </div>
  </div>

</section>
