import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { PostMediaService } from './post-media.service';
import { PostActionStatus } from '../models/post-action';
import { PostLinkMappingService } from './post-link-mapping.service';

import { PostLink } from '../models/post-link';
import { PostLinkPayload } from '../models/post-link-payload';

@Injectable({
    providedIn: 'root'
})
export class PostLinkService {

    constructor(
        private readonly postMedia: PostMediaService,
        private readonly postLinkMapping: PostLinkMappingService
    ) { }

    public create(postLink: Partial<PostLink>): Observable<PostActionStatus>  {

        // Mapeamos nuestro post para poder enviarlo al Servidor.
        const postLinkPayload: Partial<PostLinkPayload> = this.postLinkMapping.mapPostLinkPayload(postLink);

        return this.postMedia.createPostMedia(postLinkPayload);
    }

    public update(postLink: Partial<PostLink>) {

        // Mapeamos nuestro post para poder enviarlo al Servidor.
        const postLinkPayload: Partial<PostLinkPayload> = this.postLinkMapping.mapPostLinkPayload(postLink);

        return this.postMedia.editPostMedia(postLinkPayload);
    }
}
