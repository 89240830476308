<article class="post-polling">
    <div class="post-polling-header">
        <h4><img src="assets/iconos/icono_fanmade.svg" alt="Fan Votación"> FAN VOTE</h4>
        <p>{{ description  }}</p>
    </div>

    <div class="post-polling-body">
        <!-- Estación de Voto -->
        <ul *ngIf="openPollingPanel">
            <li *ngFor="let poll of polls">
                <!-- Matzh cambialo por otro elemento que tu consideres mejor -->
                <button
                    type="button"
                    (click)="onVote(poll)">{{ poll.title }}</button>
            </li>
        </ul>

        <!-- Métricas de Votación -->
        <div *ngIf="openPollingMetricsPanel">
            <app-post-polling-metrics
                [pollIsOpen]="isOpen"
                [pollTotalVotesCounted]="totalVotesCounted"
                [polls]="polls"></app-post-polling-metrics>
        </div>
    </div>

    <div class="post-polling-footer">
        <!-- Meter un pipe para formatear el mensaje total de votos -->
        <p>{{ totalVotesCounted | totalVotesCountedFormat }} </p>
        <p *ngIf="!isOpen">Votación Cerrada</p>
        <!-- Meter un pipe para formatear el mensaje de días faltantes para el cierre -->
        <p *ngIf="isOpen">{{ TTL | timeToLiveFormat }}</p>
    </div>
</article>
