import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';

import { Profile } from '../../shared/models/profile';
import { ProfileAvatarValidators } from '../../shared/validators/profile-avatar-validators';

import { CommonValidators } from '@app/core/validators/common-validators';

@Component({
    selector: 'app-edit-profile-form',
    templateUrl: './edit-profile-form.component.html',
    styleUrls: ['./edit-profile-form.component.scss']
})
export class EditProfileFormComponent implements OnInit {

    @Input() profile!: Profile;
    @Input() changeProfileLoading: boolean = false;
    @Output() changeProfile: EventEmitter<Profile> = new EventEmitter<Profile>();
    
    public editProfileForm!: FormGroup;

    constructor(private fb: FormBuilder) { }

    ngOnInit(): void {

        this.loadEditProfileForm();
    }

    private loadEditProfileForm(): void {

        const { 
            name, 
            email, 
            phone, 
            city, 
            birthDate, 
            gender, 
            avatar, 
            teamPreference } = this.profile;

        this.editProfileForm = this.fb.group({
            avatar:         [ avatar, [ ProfileAvatarValidators.mustBeValidImage ] ],
            name:           [ name, [ Validators.required, Validators.maxLength(255), CommonValidators.mustNotBeBlank ] ],
            email:          [{ value: email, disabled: true }, [ Validators.required ]],
            phone:          [ phone, [ Validators.required ] ],
            city:           [ city, Validators.required ],
            birthDate:      [ birthDate, Validators.required ],
            teamPreference: [ teamPreference, Validators.required ],
            gender:         [ gender ]
        });
    }

    public onEditProfile(): void {

        this.editProfileForm.markAllAsTouched();

        if ( this.editProfileForm.valid ) {

            this.changeProfile.emit( this.editProfileForm.value );
        }
    }

    get avatar() {

        return this.editProfileForm.get('avatar');
    }

    get name() {

        return this.editProfileForm.get('name');
    }

    get phone() {

        return this.editProfileForm.get('phone');
    }

    get city() {

        return this.editProfileForm.get('city');
    }

    get birthDate() {

        return this.editProfileForm.get('birthDate');
    }

    get teamPreference() {

        return this.editProfileForm.get('teamPreference');
    }

    // Error fields.
    get errorOnNameField() {

        // Nos interesa solo mostrar el primer error generado. En esta caso si el campo 'name' genera más 
        // de 1 error, no queremos que se empalmen mostrando 2 mensajes de errores al mismo tiempo.
        return Object.keys(this.editProfileForm.get('name')?.errors || {})[0];
    }
}
