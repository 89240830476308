import { FormGroup, ValidationErrors } from '@angular/forms';

export class ProfileAvatarValidators {

    static mustBeValidImage(profileAvatarControl: FormGroup): ValidationErrors | null {
        
        const { errorCode } = profileAvatarControl.value;

        const errorMessages: { [name: string]: string } = {
            'unselected_file':      'unSelectedFile',
            'invalid_mime_type':    'invalidMimeType',
            'invalid_file_size':    'invalidFileSize',
            'unsuccesful_encoding': 'unsuccessfulEncoding'
        };

        if ( errorCode ) {
            
            return { [ errorMessages[errorCode] ]: true } as ValidationErrors;    
        }

        return null;
    }
}