import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import { environment } from '@env/environment';

@Injectable()
export class CallToActionTopicService {

    constructor(private httpClient: HttpClient) { }

    public join(topicId: number) {

        return this.httpClient.post(`${ environment.host }/api/topics/${ topicId }/follow`, {})
            .pipe(
                tap( (res) => console.log(res) )
            );
    }

    public disjoin(topicId: number) {

        return this.httpClient.post(`${ environment.host }/api/topics/${ topicId }/unfollow`, {})
            .pipe(
                tap( (res) => console.log(res) )
            );
    }
}
