import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ComingSoonLandingRoutingModule } from './coming-soon-landing-routing.module';

import { ComingSoonLandingPageComponent } from './pages/coming-soon-landing-page/coming-soon-landing-page.component';
import {CarouselModule} from "primeng/carousel";

@NgModule({
  declarations: [ComingSoonLandingPageComponent],
    imports: [
        CommonModule,
        ComingSoonLandingRoutingModule,
        CarouselModule
    ]
})
export class ComingSoonLandingModule { }
