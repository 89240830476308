import { Pipe, PipeTransform } from '@angular/core';

const TOTAL_CONSTRAINT_ACTIVITY: number = 1000;

@Pipe({
    name: 'rateTotalizer'
})
export class RateTotalizerPipe implements PipeTransform {

    transform(total: number): string {

        return total < TOTAL_CONSTRAINT_ACTIVITY ? total.toString() : this.getTotalFormat(total);
    }

    private getTotalFormat(total: number): string {
        
        const totalRate: number = (total / TOTAL_CONSTRAINT_ACTIVITY);

        let [ wholeNumberPart, decimalNumberPart ] = totalRate.toFixed(3).split('.');

        decimalNumberPart = decimalNumberPart.charAt(0) === '0' ? '' : '.' + decimalNumberPart.charAt(0);

        return wholeNumberPart + decimalNumberPart + 'K';
    }
}
