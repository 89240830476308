import {Component, OnDestroy, OnInit} from '@angular/core';
import {Router, ActivatedRoute, ActivatedRouteSnapshot, NavigationExtras} from '@angular/router';
import {SubSink} from 'subsink';
import {TopicService} from '../../shared/services/topic.service';
import {LayoutConfigService} from '@app/layout-config/shared/services/layout-config.service';
import {Topic} from '../../shared/models/topic';
import {MessageService} from 'primeng/api';
@Component({
  selector: 'app-topic-preview-page',
  templateUrl: './topic-preview-page.component.html',
  styleUrls: ['./topic-preview-page.component.scss']
})
export class TopicPreviewPageComponent implements OnInit, OnDestroy {

  private topicId: number = 0;
  public href = '';
  private subscriptions = new SubSink();
  public topic?: Topic;
  public topicIsLoaded = false;
  constructor(
    private readonly router: Router,
    private readonly currentRoute: ActivatedRoute,
    private readonly layoutConfig: LayoutConfigService,
    private readonly topicService: TopicService,
    private readonly messageService: MessageService,

  ) {

    layoutConfig.setLayoutConfig({
      layout: {
        navbar: {visible: false},
        footer: {visible: false}
      }
    });
  }


  ngOnInit(): void {

    // Obtenemos el id del Topic a partir de la Url.
    const routeSnapshot: ActivatedRouteSnapshot = this.currentRoute.snapshot;
    this.topicId = +(routeSnapshot.paramMap.get('id') || 0);
    this.loadTopicDetail(+this.topicId);
    this.href = 'https://share.serouno.com/topic/' + this.topicId + '/share';

  }

  loadTopicDetail(topicId: number): void {
    this.subscriptions.sink = this.topicService.getTopicPublicById(topicId).subscribe({
      next: (topic) => {
        this.topic = topic;
      },
      complete: () => {
        this.topicIsLoaded = true;
      }
    });
  }

  public gotoLogin(): void {

    // Definimos manualmente la url de retorno, en este caso será la url del detalle del Topic que se encuentra en Comunidad.
    const intendedUrl: string = `/community/topic/${this.topicId}`;
    // Construimos la navegación con la url de retorno codificada en base64
    const navigationExtras: NavigationExtras = {
      // La funcion btoa() nos permite codificar un string a base64
      queryParams: {intendedUrl: btoa(intendedUrl)}
    };

    // Redireccionamos al login con laa url de retorno.
    this.router.navigate(['/login'], navigationExtras);
  }


  ngOnDestroy(): void {

    this.subscriptions.unsubscribe();
  }
}
