import {Component, ChangeDetectionStrategy} from '@angular/core';
import {Router} from "@angular/router";
import {of, Observable} from 'rxjs';
import {catchError} from 'rxjs/operators';

import {RecommendedTopicsService} from '../../shared/services/recommended-topics.service';
import {RecommendedTopic} from '../../shared/models/recommended-topic';

@Component({
  selector: 'app-recommended-topic-list',
  templateUrl: './recommended-topic-list.component.html',
  styleUrls: ['./recommended-topic-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [RecommendedTopicsService]
})
export class RecommendedTopicListComponent {

  public errorMessage: string = '';
  public recommendedTopics$: Observable<RecommendedTopic[]> = this.recommendedTopics.topics$
    .pipe(
      catchError((err) => {

        this.errorMessage = 'Los Topics no puedieron ser cargados...';

        return of([]);
      })
    );

  constructor(
    private readonly recommendedTopics: RecommendedTopicsService,
    private router: Router
  ) {
  }

  public redirectToTopic(id: number): void {
    this.router.navigateByUrl('/', {skipLocationChange: true}).then(() =>
      this.router.navigate(['/community/topic', id]));
  }

}
