<div class="comunidad-grid main-grid">

  <div class="zona grid-full">
    <!--<img src="assets/comunidad.png" alt="SeroUno">-->
  </div>

  <div class="links grid-full">
    <a [routerLink]="['./']" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">Inicio</a>
    <a [routerLink]="['./favorite-team']" routerLinkActive="active">Club <b>{{teamName}}</b></a>
    <a [routerLink]="['./following']" routerLinkActive="active">Siguiendo</a>
  </div>

</div>

<!-- Top TOPICS -->
<div class="left grid-left">
  <router-outlet></router-outlet>
</div>

<p-toast position="top-left"></p-toast>

