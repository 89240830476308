export class BirthDateFormatUtility {

    static fromDateObjectToStringFormat(dateInSlashesFormat: Date): string { 

        let year: string = dateInSlashesFormat.getFullYear().toString();
        let month: string = (dateInSlashesFormat.getMonth() + 1).toString();
        let day: string = dateInSlashesFormat.getDate().toString();
        
        month = (month.length === 1) ? ('0' +  month) : month;
        day = (day.length === 1) ? ('0' + day) : day;

        return `${ year }-${ month }-${ day }`;
    }
   
    static fromStringFormatToDateObject(dateInDashesFormat: string): Date { 

        const date: Date = new Date(`${ dateInDashesFormat } 00:00:00`);

        let year: string = date.getFullYear().toString();
        let month: string = (date.getMonth() + 1).toString();
        let day: string = date.getDate().toString();

        month = (month.length === 1) ? ('0' +  month) : month;
        day = (day.length === 1) ? ('0' + day) : day;

        return new Date(`${ year }/${ month }/${ day }`);
    }
}