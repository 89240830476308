import {Component, Input, OnInit} from '@angular/core';
import {ProfileService} from '../../shared/services/profile.service';
import {Router} from '@angular/router';

@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss']

})
export class NotificationComponent implements OnInit {

  constructor(
    private profileService: ProfileService,
    private router: Router,
  ) {
  }

  @Input() title?: string;
  @Input() body?: string;
  @Input() date?: Date;
  @Input() dateDifference?: string;
  @Input() from?: string;
  @Input() id = 0;
  @Input() imageFrom?: string;
  @Input() urlApp = ['/'];

  ngOnInit(): void {
    console.log(this.urlApp);
  }


  public readAndGo(): void {
    this.profileService.markNotificationAsRead(this.id).subscribe({
      complete: () => {

        this.router.navigate(this.urlApp);
      }
    });
  }

}
