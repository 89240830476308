import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AuthenticateService } from '@app/auth/shared/guards/authenticate.service';

import { TeamTopicsPageComponent } from './pages/team-topics-page/team-topics-page.component';
import { TeamDetailPageComponent } from './pages/team-detail-page/team-detail-page.component';

const routes: Routes = [
    { path: 'team-topics', component: TeamTopicsPageComponent, canActivate: [ AuthenticateService ] },
    { path: 'team/:id', component: TeamDetailPageComponent, canActivate: [ AuthenticateService ] }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class TeamsRoutingModule { }
