<form [formGroup]="editProfileForm" (ngSubmit)="onEditProfile()" novalidate autocomplete="off">
   <div>
        <app-profile-avatar-field
            accept="image/png,image/jpeg"
            [maxFileSize]="3072"
            formControlName="avatar"></app-profile-avatar-field>

        <ng-container *ngIf="avatar?.invalid && (avatar?.dirty || avatar?.touched)">
            <span *ngIf="avatar?.hasError('invalidFileSize')"
                    class="invalid-feedback-message"><small>No pudimos cargar su imagen, el tamaño máximo permitido es de hasta 3 MB.</small></span>
            <span *ngIf="avatar?.hasError('invalidMimeType')"
                    class="invalid-feedback-message"><small>No pudimos cargar su imagen, solo se aceptan formatos jpg, jpeg y png.</small></span>
            <span *ngIf="avatar?.hasError('unsuccessfulEncoding')"
                    class="invalid-feedback-message"><small>No pudimos cargar su imagen, intentelo nuevamente.</small></span>
        </ng-container>
    </div>

    <div class="icon-input bg-gray">
        <input
            type="text"
            class="input-box-face input-box-green-text"
            name="name"
            placeholder="Nombre"
            formControlName="name"
            pInputText>
        <img src="assets/iconos/icono_nombre.svg" alt="Nombre">

        <ng-container *ngIf="name?.invalid && (name?.dirty || name?.touched)">
            <ng-container [ngSwitch]="errorOnNameField">
                <span *ngSwitchCase="'required'"
                    class="invalid-feedback-message"><small>Ingrese su nombre.</small></span>
                <span *ngSwitchCase="'maxlength'"
                    class="invalid-feedback-message"><small>Máximo permitido 255 caracteres.</small></span>
                <span *ngSwitchCase="'blank'"
                    class="invalid-feedback-message"><small>Ingrese un nombre válido.</small></span>
            </ng-container>
        </ng-container>
    </div>

    <div class="icon-input bg-gray">
        <input
            type="email"
            class="input-box-face input-box-green-text"
            name="email"
            placeholder="Correo Electrónico"
            formControlName="email"
            pInputText>
        <img src="assets/iconos/icono_correo_verde.svg" alt="Mail">
    </div>

    <div class="icon-input bg-gray">
        <input
            type="text"
            class="input-box-face input-box-green-text"
            name="phone"
            placeholder="Teléfono"
            formControlName="phone"
            pInputText>
        <img src="assets/iconos/icono_telefono.svg" alt="Teléfono">

        <ng-container *ngIf="phone?.invalid && (phone?.dirty || phone?.touched)">
            <span *ngIf="phone?.hasError('required')"
                    class="invalid-feedback-message"><small>Ingrese un número telefónico.</small></span>
            <span *ngIf="phone?.hasError('containsMoreThanNumbers')"
                    class="invalid-feedback-message"><small>Ingrese un número telefónico válido.</small></span>
        </ng-container>
    </div>

    <div class="icon-input bg-gray">
        <input
            type="text"
            class="input-box-face input-box-green-text"
            name="city"
            placeholder="Ciudad"
            formControlName="city"
            pInputText>
        <img src="assets/iconos/icono_ciudad.svg" alt="Teléfono">

        <ng-container *ngIf="city?.invalid && (city?.dirty || city?.touched)">
            <span *ngIf="city?.hasError('required')"
                    class="invalid-feedback-message"><small>Ingrese su ciudad.</small></span>
        </ng-container>
    </div>

    <div class="icon-input bg-gray calendar">
        <p-calendar
            class="input-box-face input-box-green-text"
            placeholder="Fecha de Nacimiento"
            dateFormat="dd/mm/yy"
            [yearNavigator]="true"
            yearRange="1900:2100"
            appendTo="body"
            [monthNavigator]="true"
            [readonlyInput]="true"
            [touchUI]="true"
            formControlName="birthDate"></p-calendar>
        <img src="assets/iconos/icono_cumpleanos.svg" alt="Nacimiento">

        <ng-container *ngIf="birthDate?.invalid && (birthDate?.dirty || birthDate?.touched)">
            <span *ngIf="birthDate?.hasError('required')"
                    class="invalid-feedback-message"><small>Seleccione su fecha de nacimiento.</small></span>
        </ng-container>
    </div>

    <div class="mb-20">
        <app-select-team-field
            className="bg-gray"
            placeholder="Club de fútbol"
            formControlName="teamPreference"></app-select-team-field>

        <ng-container *ngIf="teamPreference?.invalid && (teamPreference?.dirty || teamPreference?.touched)">
            <span *ngIf="teamPreference?.hasError('required')"
                    class="invalid-feedback-message"><small>Seleccione un club de su preferencia.</small></span>
        </ng-container>
    </div>

    <div class="group-input bg-gray">
        <div class="radio-input">
            <input type="radio" id="gender-m" name="gender" value="M" formControlName="gender">
            <label for="gender-m">Hombre</label>
        </div>

        <div class="radio-input">
            <input type="radio" id="gender-f" name="gender" value="F" formControlName="gender">
            <label for="gender-f">Mujer</label>
        </div>

        <div class="radio-input">
            <input type="radio" name="gender" id="gender-x" value="S" formControlName="gender">
            <label for="gender-x">Sin especificar</label>
        </div>
    </div>

    <button type="submit" class="submit icon-right" [disabled]="changeProfileLoading">
        <ng-container *ngIf="!changeProfileLoading; else loading">
            Actualizar <img src="assets/iconos/icono_arrow.svg" alt="Actualizar">
        </ng-container>

        <ng-template #loading>
            <i class="pi pi-spin pi-spinner button-spinner"></i>
          </ng-template>
    </button>
</form>
