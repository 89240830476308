import {Directive, ElementRef} from '@angular/core';

@Directive({
  selector: '[appScrollableToBeginning]'
})
export class ScrollableToBeginningDirective {

  constructor(private elementRef: ElementRef) {
  }

  public gotoBeggining(): void {
    if (this.elementRef.nativeElement && this.elementRef.nativeElement.scrollTo) {

      this.elementRef.nativeElement.scrollTo(0, 0);

    } else {

      console.log('La propiedad scrollTo no esta soportada...');
    }
  }
}
