import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';

import { SubSink } from 'subsink';

import { ScrollableToBeginningDirective } from '../../directives/scrollable-to-beginning.directive';

import { LayoutConfigService } from '@app/layout-config/shared/services/layout-config.service';
import { NewsService } from '../../shared/services/news.service';
import { Paginator } from '../../shared/models/paginator';
import { NewOrAdType } from '../../shared/models/new-or-ad';
import { New } from '../../shared/models/new';

@Component({
    selector: 'app-news-page',
    templateUrl: './news-page.component.html',
    styleUrls: ['./news-page.component.scss']
})
export class NewsPageComponent implements OnInit, OnDestroy {

    @ViewChild(ScrollableToBeginningDirective) scroller!:ScrollableToBeginningDirective;

    private subscriptions = new SubSink();

    // Top 5 News.
    public topFiveNews: New[] = [];
    public topFiveNewsLoaded:boolean = false;

    // Scrolled News.
    public news: NewOrAdType[] = [];
    private previouslyLoadedNews: NewOrAdType[] = [];

    public firstPageFromPaginatorIsLoaded: boolean = false;
    public loadingMoreNews: boolean = false;

    private paginatorCurrentPage: number = 0;
    private paginatorNextPage: string | null = '';

    public newsSelected: string = 'team';

    // Flag para mostrar/ocultar los Anuncios.
    public showAdTypeA: boolean = true;
    public showAdTypeD: boolean = true;
    public showAdTypeC: boolean = true;

    constructor(
        private layoutConfigService: LayoutConfigService,
        private newsService: NewsService
    ) {

        // Establecemos la configuración inicial de la barra de navegación y del footer.
        layoutConfigService.setLayoutConfig({
            layout: {
                navbar: { visible: true },
                footer: { visible: false }
            }
        });
    }


    public changeNewsSelected(by: string = 'all'): void {
      this.news = [];
      this.previouslyLoadedNews = [];
      this.firstPageFromPaginatorIsLoaded = false;
      this.newsSelected = by;
      this.loadFirstPageFromPaginator();
    }

    ngOnInit(): void {

        // Cargamos las primeras 5 noticias dentro del carousel.
        this.loadTopFiveNews();

        // Al cargar este componente, cargamos la información relativa a la primera página del páginador.
        this.loadFirstPageFromPaginator();
    }

    ngOnDestroy(): void {

      this.subscriptions.unsubscribe();
    }

    private loadTopFiveNews(): void {

        this.subscriptions.sink = this.newsService.getTopFiveNews().subscribe( (topFiveNews) => {

            this.topFiveNewsLoaded = true;
            this.topFiveNews = topFiveNews;
        });
    }

    private loadFirstPageFromPaginator(): void {

        this.firstPageFromPaginatorIsLoaded = false;
        this.paginatorCurrentPage = 1;

        this.newsService.paginate(this.paginatorCurrentPage, this.newsSelected).subscribe( (chunk) => {

            this.renderNewsOnTheScrollingArea(chunk);
            this.firstPageFromPaginatorIsLoaded = true;

            console.log( this.news );
        });
    }

    // Métodos relacionados al Funcionamiento del Paginador.
    public lazyLoadMoreNews(): void {

        // Con la propiedad infiniteScrollDistance=3 de la directiva infiniteScroll hemos configurado para que un evento
        // scroll sea disparado cuando se haya recorrido alrededor del 70% del area.
        this.paginate();
    }

    private paginate(): void {

        if ( this.paginatorHasNextPage() ) {

            this.loadingMoreNews = true;
            this.paginatorCurrentPage ++;

            this.newsService.paginate(this.paginatorCurrentPage, this.newsSelected).subscribe( (chunk) => {

                this.renderNewsOnTheScrollingArea(chunk);
                this.loadingMoreNews = false;

                console.log( this.news );
            });
        }
    }

    private paginatorHasNextPage(): boolean {

        return (this.paginatorNextPage !== null);
    }

    private renderNewsOnTheScrollingArea(chunk:[ Paginator, NewOrAdType[] ]): void {

        const [ paginatorInfo, incomingNews ] = chunk;

        // Actualizamos la información del Paginador.
        this.paginatorNextPage = paginatorInfo.nextPageUrl;
        // Fusionamos las noticias previamente cargadadas con las noticias que provengan del Servidor.
        this.previouslyLoadedNews = [ ...this.previouslyLoadedNews, ...incomingNews ];
        // Actualizamos la vista con la nueva información cargada en el Property Binding correspondiente.
        this.news = this.previouslyLoadedNews;
    }

    public gotoFirstPage(): void {

        this.scroller.gotoBeggining();
    }
}
