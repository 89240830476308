import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';

import { AuthCredentials } from '../../shared/models/auth-credentials';

@Component({
    selector: 'app-signin-form',
    templateUrl: './signin-form.component.html',
    styleUrls: ['./signin-form.component.scss']
})
export class SigninFormComponent implements OnInit {

    @Input() public signInLoading: boolean = false;
    @Output() private signIn: EventEmitter<AuthCredentials> = new EventEmitter<AuthCredentials>();
    @Output() private passwordRecovery: EventEmitter<void> = new EventEmitter<void>();
    @Output() private cancel: EventEmitter<void> = new EventEmitter<void>();

    public signInForm!: FormGroup;

    constructor(private fb: FormBuilder) { }

    ngOnInit(): void {

        this.loadSignInForm();
    }

    private loadSignInForm(): void {

        this.signInForm = this.fb.group({
            email: ['', [ Validators.required, Validators.email ] ],
            password: ['', Validators.required ]
        });
    }

    public onSignIn(): void {

        // En caso que el usuario de click al botón iniciar sin haber ingresado sus credenciales.
        this.signInForm.controls.email.markAsDirty();
        this.signInForm.controls.password.markAsDirty();

        // Obtenemos las credenciales a partir del formulario.
        const { email, password } = this.signInForm.value;

        // Comprobamos que el formulario sea válido.
        if ( this.signInForm.valid ) {

            // Una vez el formulario es completamente válido, comunicamos al contenedor las
            // credenciales obtenidas.
            this.signIn.emit({ email, password } as AuthCredentials);
        }
    }

    public onPasswordRecovery(): void {

        this.passwordRecovery.emit();
    }

    public back(): void {

        this.cancel.emit();
    }

    // Getters.
    get email() {

        return this.signInForm.get('email');
    }

    get password() {

        return this.signInForm.get('password');
    }
}
