import { Component, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
    selector: 'app-not-account-yet',
    templateUrl: './not-account-yet.component.html',
    styleUrls: ['./not-account-yet.component.scss']
})
export class NotAccountYetComponent implements OnInit {

    @Output() private getAnAccount:EventEmitter<void> = new EventEmitter<void>();

    constructor() { }

    ngOnInit(): void {
    }

    public register():void {

        this.getAnAccount.emit();
    }
}
