import {RouterLink} from '@angular/router';

export class Urls {
  static getHostName(urlString: string): string {
    const domain = (new URL(urlString));
    return decodeURI(domain.hostname);
  }

  static getPathname(urlString: string): string {

    if ( !urlString) { return ''; }
    
    const domain = (new URL(urlString));
    return decodeURI(domain.pathname);
  }

  static getUrlApp(type: string, id: number | null): Array<any> {
    let baseUrl: any[] = ['/'];
    console.log(type);
    if (type.includes('POST') && id) {
      baseUrl = ['/community/post/', id];
    } else {
      // TODO si existen links a comunidades o algo asi poner
    }
    return baseUrl;
  }
}
