import {Component, OnInit, Output, EventEmitter, Renderer2, OnDestroy} from '@angular/core';
import {Router} from '@angular/router';

import {SubSink} from 'subsink';

import {MenuItem} from 'primeng/api';

import {AuthService} from '@app/auth/shared/services/auth.service';
import {AuthSocialService} from '@app/auth/shared/services/auth-social.service';
import {ModalService} from '@app/ui/modals/shared/services/modal.service';
import {NavbarActionListenerService} from '@app/core/services/navbar-action-listener.service';

import {NavbarService} from '../shared/services/navbar.service';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
  providers: [NavbarService]
})
export class NavbarComponent implements OnInit, OnDestroy {

  @Output('onSearch') searchTerm: EventEmitter<string> = new EventEmitter<string>();

  private subscriptions = new SubSink();

  public username: string = '';
  public avatar: string = '';
  public favoriteTeamLogo: string = '';
  public fanLevel: string = '';
  public fanLevelPlate: string = '';

  public pagesMenu: MenuItem[] = [];
  public userMenu: MenuItem[] = [];
  public generalMenu: MenuItem[] = [];
  public generalButtonActive = false;

  constructor(
    private navbarService: NavbarService,
    private navbarActionListenerService: NavbarActionListenerService,
    private authService: AuthService,
    private readonly authSocialService: AuthSocialService,
    private modalService: ModalService,
    private router: Router,
    private renderer: Renderer2
  ) {
  }

  ngOnInit(): void {

    this.loadNavbarInfo();

    // Permanece a la escucha de algun cambio para refrescar el estado de la barra de navegación.
    this.subscriptions.sink = this.navbarActionListenerService.actionListener$.subscribe((action) => {

      // Refresca el estado de la barra de navegación.
      if (action.type === 'refresh') {

        this.loadNavbarInfo();
      }
    });

    this.pagesMenu = [
      {
        label: 'Inicio',
        icon: 'custom-icon home-icon',
        routerLink: ['/'],
        command: () => {
          this.removeGeneralMenu();
        }
      },
      {
        label: 'Noticias',
        icon: 'custom-icon news-icon',
        routerLink: ['/news'],
        command: () => {
          this.removeGeneralMenu();
        }
      },
      {
        label: 'Comunidad',
        icon: 'custom-icon community-icon',
        routerLink: ['/community'],
        command: () => {
          this.removeGeneralMenu();
        }
      },
      {
        label: 'Equipos',
        icon: 'custom-icon team-icon',
        routerLink: ['/team-topics'],
        command: () => {
          this.removeGeneralMenu();
        }
      }
    ];
    this.userMenu = [
      {
        label: 'Mi Perfil',
        icon: 'custom-icon profile-icon',
        routerLink: ['/account'],
        command: () => {
          this.removeGeneralMenu();
        }
      },
      {
        label: 'Mis Posts',
        icon: 'custom-icon posts-icon',
        routerLink: ['/account/my-posts'],
        command: () => {
          this.removeGeneralMenu();
        }
      },
      {
        label: 'Mis Topics Creados',
        icon: 'custom-icon topic-icon',
        routerLink: ['/account/topics-created'],
        command: () => {
          this.removeGeneralMenu();
        }
      },
      {
        label: 'Mis Guardados',
        icon: 'custom-icon saved-icon',
        routerLink: ['/account/saved-news'],
        command: () => {
          this.removeGeneralMenu();
        }
      },
      {
        label: 'Ajustes',
        icon: 'custom-icon settings-icon',
        routerLink: ['/account/profile-settings'],
        command: () => {
          this.removeGeneralMenu();
        }
      },
      {
        styleClass: 'logout',
        label: 'Cerrar Sesión',
        icon: 'custom-icon logout-icon',
        command: () => {
          this.logout();
        }
      }
    ];

    this.generalMenu = this.userMenu.concat(this.pagesMenu);

    this.loadWebviews();


  }

  ngOnDestroy(): void {

    this.subscriptions.unsubscribe();
    this.renderer.removeClass(document.body, 'overflow-hidden');

  }

  public changeTeamPreference(): void {

    const favoriteTeamId: number | undefined = this.navbarService.getFavoriteTeamId();

    this.modalService.changeTeamPreferenceDialog(favoriteTeamId);
  }

  private loadNavbarInfo(): void {

    this.username = this.navbarService.getUsername();
    this.avatar = this.navbarService.getUserAvatar();
    this.favoriteTeamLogo = this.navbarService.getFavoriteTeamLogo();
    this.fanLevel = this.navbarService.getFanLevel();
    this.fanLevelPlate = this.navbarService.getFanLevelPlate();
  }

  private logout(): void {

    this.authService.logout();
    this.authSocialService.signOutFacebook();
    this.authSocialService.signOut();
    this.router.navigateByUrl('/login');
  }

  private loadWebviews(): void {
    this.subscriptions.sink = this.navbarService.getWebviewListMenu().subscribe({
      next: (res) => {
        res.map((webView) => {
          const newMenu = {
            label: webView.name,
            icon: '',
            style: {
              backgroundImage: `url(${webView.icon})`,
            },
            styleClass: 'admin-menu',
            command: () => {
              this.removeGeneralMenu()
              this.router.navigateByUrl('/', {skipLocationChange: true}).then(() =>
                this.router.navigate(['/webviews', webView.id]));
            },
          };
          this.pagesMenu.push(newMenu);
        });
      },
      complete: () => {
        // console.log(this.pagesMenu);
        this.generalMenu = this.userMenu.concat(this.pagesMenu);
      }
    });
  }

  public showGeneralMenu(): void {
    this.generalButtonActive = !this.generalButtonActive;
    if (this.generalButtonActive) {
      this.renderer.addClass(document.body, 'overflow-hidden');
    } else {
      this.renderer.removeClass(document.body, 'overflow-hidden');
    }
  }

  public removeGeneralMenu(): void {
   
    this.generalButtonActive = false;
    this.renderer.removeClass(document.body, 'overflow-hidden');
  }
  
  public onSearch(term: string): void {

    term = term.trim();

    if ( term && term.length > 0 ) {
      
      this.searchTerm.emit(term);
    }
  }
}
