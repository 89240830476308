<!-- Botón de Like -->
<app-like
    [like]="likeAction"
    [totalLikes]="totalLikes"
    (onToggle)="onLike($event)"></app-like>

    <!-- Botón de Dislike-->
<app-dislike
    [dislike]="dislikeAction"
    [totalDislikes]="totalDislikes"
    (onToggle)="onDislike($event)"></app-dislike>