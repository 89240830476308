import {Injectable} from '@angular/core';
import {WebviewMenu, WebviewsResponseMenu} from '../models/webviews';
import {HttpClient, HttpParams} from '@angular/common/http';
import {filter, map} from 'rxjs/operators';
import {Observable} from 'rxjs';
import {environment} from '@env/environment';

@Injectable()
export class NavbarService {

  constructor(
    private httpClient: HttpClient
  ) {
  }

  public getUsername(): string {

    const username: string | null = localStorage.getItem('usrn');

    return username ? username : 'Jhon Doe';
  }

  public getUserAvatar(): string {

    const defaultAvatar: string = 'assets/iconos/navbar/default_avatar.png';
    const avatar: string = localStorage.getItem('avat') || defaultAvatar;

    return this.isValidField(avatar) ? avatar : defaultAvatar;
  }

  public getFavoriteTeamId(): number | undefined {

    const rawFavoriteTeamId: string | null = localStorage.getItem('ftid');

    if (!rawFavoriteTeamId) {

      return undefined;
    }

    try {

      const favoriteTeamId: number = JSON.parse(rawFavoriteTeamId);

      return (typeof favoriteTeamId === 'number' && favoriteTeamId > 0) ? favoriteTeamId : undefined;

    } catch (e) {

      return undefined;
    }
  }

  public getFavoriteTeamLogo(): string {

    const favoriteTeamLogo: string = localStorage.getItem('ftlg') || '';

    return this.isValidField(favoriteTeamLogo) ? favoriteTeamLogo : '';
  }

  public getFanLevel(): string {

    const fanLevel: string = localStorage.getItem('fnlv') || '';

    return this.isValidField(fanLevel) ? fanLevel : '';
  }

  public getFanLevelPlate(): string {

    const fanLevelPlate: string = localStorage.getItem('fnpl') || '';

    return this.isValidField(fanLevelPlate) ? fanLevelPlate : '';
  }

  private isValidField(field: string): boolean {

    return (typeof field === 'string') && field.trim().length > 0 && field !== 'null';
  }

  // WebViews

  public getWebviewListMenu(): Observable<WebviewMenu[]> {
    return this.httpClient.get<WebviewsResponseMenu>(`${environment.host}/api/webviews/menu`)
      .pipe(
        map((webviewsResponseMenu) => {
          const {content} = webviewsResponseMenu;
          return content.map((webViewMenu) => {
            const {id, name, icon} = webViewMenu;
            return {
              id, name, icon
            };
          });
        })
      );
  }


}
