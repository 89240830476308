import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { TopicSharingRoutingModule } from './topic-sharing-routing.module';
import { TopicPreviewPageComponent } from './pages/topic-preview-page/topic-preview-page.component';
import {ToastModule} from 'primeng/toast';


@NgModule({
  declarations: [TopicPreviewPageComponent],
  imports: [
    CommonModule,
    TopicSharingRoutingModule,
    ToastModule
  ]
})
export class TopicSharingModule { }
