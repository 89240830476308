import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';

@Component({
    selector: 'app-banner-community',
    templateUrl: './banner-community.component.html',
    styleUrls: ['./banner-community.component.scss']
})
export class BannerCommunityComponent implements OnInit {

    constructor(private router: Router) { }

    ngOnInit(): void {
    }

    public gotoCommunity(): void {

        this.router.navigateByUrl('/community');
    }
}
