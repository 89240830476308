import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

import { LikeStatus } from '../../shared/models/post-media';

@Component({
    selector: 'app-likes',
    templateUrl: './likes.component.html',
    styleUrls: ['./likes.component.scss']
})
export class LikesComponent implements OnInit {

    @Input('status') likeStatus: LikeStatus = LikeStatus.Disliked;
    @Input() likes: number = 0;
    @Input() dislikes: number = 0;
    @Output() onClickLike: EventEmitter<LikeStatus> = new EventEmitter<LikeStatus>();
    @Output() onClickDislike: EventEmitter<LikeStatus> = new EventEmitter<LikeStatus>();

    public likeAction: LikeStatus = LikeStatus.Liked;
    public dislikeAction: LikeStatus = LikeStatus.Disliked;
    public totalLikes: number = 0;
    public totalDislikes: number = 0;

    constructor() { }

    ngOnInit(): void {

        switch ( this.likeStatus ) {

            case LikeStatus.Unsettled:

                this.likeAction = LikeStatus.Unsettled;
                this.dislikeAction= LikeStatus.Unsettled;
            break;

            case LikeStatus.Liked:
                 
                this.likeAction = LikeStatus.Liked;
                this.dislikeAction = LikeStatus.Unsettled;
            break;

            case LikeStatus.Disliked:
                
                this.likeAction = LikeStatus.Unsettled;
                this.dislikeAction = LikeStatus.Disliked;
            break;
        }

        this.totalLikes = this.likes;
        this.totalDislikes = this.dislikes;
    }

    public onLike(action: LikeStatus): void {

        // Antes de desactivar el estado del Componente Dislike, revisa su estado, y en caso, reseta su estado.
        if ( this.dislikeAction === LikeStatus.Disliked ) {

            this.dislikeAction = LikeStatus.Unsettled;
            this.totalDislikes =  this.totalDislikes - 1;
        }

        // Actualiza al nuevo estado.
        this.likeAction = action;
        // Actualiza el totalizador.
        this.totalLikes = (action === LikeStatus.Liked) ? this.totalLikes + 1 : this.totalLikes - 1;

        // Comunica el evento Like o Unsettled.
       this.onClickLike.emit( 
           (this.likeAction === LikeStatus.Liked) ? LikeStatus.Liked : LikeStatus.Unsettled
        );
    }

    public onDislike(action: LikeStatus): void {
        
        // Antes de desactivar el estado del Componente Like, revisa su estado, y en caso, reseta su estado.
        if ( this.likeAction === LikeStatus.Liked ) {

            this.likeAction = LikeStatus.Unsettled;
            this.totalLikes = this.totalLikes - 1;
        }

        // Actualiza al nuevo estado.
        this.dislikeAction = action;
        // Actualiza el totalizador.
        this.totalDislikes = (action === LikeStatus.Disliked) ? this.totalDislikes + 1 : this.totalDislikes - 1; 

        // Comunica el evento Dislike o Unsettled.
        this.onClickDislike.emit(
            (this.dislikeAction === LikeStatus.Disliked) ? LikeStatus.Disliked : LikeStatus.Unsettled
        );
    }
}
