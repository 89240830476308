import { Injectable } from '@angular/core';

import { FileUtility } from '@app/core/utilities/file-utility';

import { PostMediaDetailsResponse } from '../models/post-response';
import { PostImagePayload } from '../models/post-image-payload';
import { PostImage } from '../models/post-image';
import { PostImageCover } from '../models/post-image-cover';
import { PostType } from '../models/post-type';

@Injectable({
    providedIn: 'root'
})
export class PostImageMappingService {

    constructor() { }

    public mapPostImagePayload(postImage: Partial<PostImage>): Partial<PostImagePayload> {

        const payload: Partial<PostImagePayload> = {};
        const { id, description, cover, postType, topicId } = postImage;
        
        // Al crear una Publicación de Imagen, no habrá un id asociado para la Publicación.
        // Al actualizar una Publicación de Image, es OBLIGATORIO que haya un id asociado a la Publicación.
        if ( id ) {

            payload.id = id;
        }

        // Al crear una Publicación de Imagen, puede haber o no un Topic asociado. Cuando no viene un Topic asociado, 
        // la publicación se asocia con el Topic General con identificador 24.
        // Al actualizar una Publicación de Imagen, OBLIGATORIAMENTE debe venir un Topic asociado.
        if ( topicId ) {

            payload.topic_id = topicId;
        }

        // La descripción de la Publicación.
        payload.description = description?.trim();

        // Al crear una Publicación de Imagen, la imagen vendrá codificada en Base64.
        // Al actualizar una Publicación, puede ocurrir lo siguiente:
        //  a) El usuario no selecciona una nueva imagen, en este caso, no es necesario enviar nada con respecto a la imagen.
        //  b) El usuario selecciona una nueva imagen, en este caso, la nueva imagen vendrá codificada en Base64.
        if ( cover && cover.image && cover.isEncoded ) {

            payload.image = {
                name:   FileUtility.extractName(cover.name || ''),
                ext:    FileUtility.extractExtension(cover.type || ''),
                encode: FileUtility.extractOnlyBase64Text(cover.image)
            };
        }

        // Al crear una Publicación de Imagen, debe venir el tipo de Publicación que es.
        // Al actualizar una Publicación de Imagen,  NO debe venir el tipo de Publicación, este ya lo conoce 
        // la API de antemano a través del identificador del mismo.
        if ( postType ) {

            payload.type_post_id = postType;
        }

        return payload;
    }

    public mapPostImageDetails(postMediaDetailsResponse: PostMediaDetailsResponse): PostImage {

        const { content } = postMediaDetailsResponse;
        const { id, description, image, topic } = content;

        const postImageCover: PostImageCover = {
            name:       null,
            image,
            isEncoded:  false,
            type:       null,
            size:       null,
        };

        return {
            id, 
            description,
            cover:          postImageCover,
            postType:       PostType.IMAGE,
            topicId:        topic.id
        } as PostImage;
    }
}
