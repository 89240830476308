<div class="not-logged-page">
  <div class="not-logged">
    <div class="content">
      <img src="assets/logo_full.svg" alt="SEROUNO">
      <button type="button" (click)="gotoLogin()">Ingresa a Fan Zone SEROUNO</button>
    </div>
  </div>
  <div class="topic-share">
    <ng-container *ngIf="topicIsLoaded; else loading">
      <div class="grid-full">
        <span class="bg" [ngStyle]="{'background-image': 'url(' + topic!.cover + ')'}"></span>
        <div class="header-topic">
          <div class="zona">
            <img src="assets/comunidad.png" alt="SeroUno">
          </div>
        </div>
      </div>
      <div class="main-grid">
        <div class="grid-left">
          <div class="title">
            <div class="icon">
              <ng-container *ngIf="topic!.is_official; else noOficial">
                <img src="assets/iconos/icono_shield.svg" alt="Oficial">
              </ng-container>
              <ng-template #noOficial>
                <img src="assets/iconos/icono_fanmade.svg" alt="Fan Made">
              </ng-template>
            </div>
            <div class="info">
              <h2>{{topic!.name}}</h2>
              <p>
              <span>
                {{topic!.totals.followers}}
                <img src="assets/iconos/icono_usuarios.svg" alt="Seguidores">
              </span>
                <span>{{topic!.description}}</span>
              </p>

            </div>

          </div>
        </div>
        <div class="grid-left">
          <p>Ingresa para descubrir éste y otros topics más.</p>
        </div>
      </div>
    </ng-container>

    <p-toast position="top-left"></p-toast>

    <ng-template #loading>
      <div class="loading" style="text-align: center;">
        <i class="pi pi-spin pi-spinner spinner spinner-lg"></i>
      </div>
    </ng-template>
  </div>
</div>
