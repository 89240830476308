<!-- Navbar -->
<ng-container *ngIf="isNavbarAllowedToBeSeen">
    <app-navbar (onSearch)="onSearchFor($event)"></app-navbar>
</ng-container>

<router-outlet></router-outlet>

<!-- Footer -->
<ng-container *ngIf="isFooterAllowedToBeSeen">
    <app-footer></app-footer>
</ng-container>