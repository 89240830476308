import {Component, Input, OnInit} from '@angular/core';

@Component({
    selector: 'app-new-slide',
    templateUrl: './new-slide.component.html',
    styleUrls: ['./new-slide.component.scss']
})
export class NewSlideComponent implements OnInit {

    @Input() id!: number;
    @Input() title: string = '';
    @Input() urlImage: string = '';
    @Input() url: string = '';
    @Input() avatarDomain: string = '';
    @Input() hostDomain: string = '';
    @Input() date!: Date;
  
    constructor() { }

    ngOnInit(): void {
    }
}
