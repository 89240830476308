import { PostType } from './post-type';

export interface PostMedia {
    id:                 number;
    type:               PostType;
    media: {
        content:        any;
    };
    description:        string;
    backgroundCover:    string;
    publishedBy:        string;
    publishedDate:      Date;
    totalLikes:         number;
    totalDislikes:      number;
    totalComments:      number;
    isOfficial:         boolean;
    following:          boolean;
    ownership:          boolean;
    topic?:             Topic;
    likeStatus:         LikeStatus;
    reactions:          Reaction[];
}

export interface Topic {
    id:             number;
    title:          string;
    ownership:      boolean;
    isOfficial:     boolean;
    following:      boolean;
}

export interface Reaction {
    id:             number;
    name:           string;
    htmlCode:       string;
    totalReactions: number;
    isSelected:     boolean;
}

export enum LikeStatus {
    Liked = 'Liked',
    Disliked = 'Disliked',
    Unsettled = 'Unsettled'
}