import { Component, OnInit } from '@angular/core';
import { Router, Event, NavigationEnd } from '@angular/router';
import { Meta, Title } from '@angular/platform-browser';
import { PrimeNGConfig } from 'primeng/api';

import { LayoutConfigService } from '@app/layout-config/shared/services/layout-config.service';

declare let gtag: Function;

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

    public isNavbarAllowedToBeSeen: boolean = false;
    public isFooterAllowedToBeSeen: boolean = false;
    private title: string = 'SEROUNO | Be More';

    constructor(
        private readonly router: Router,
        private readonly config: PrimeNGConfig,
        private readonly layoutConfigService: LayoutConfigService,
        private readonly meta: Meta,
        private readonly titleService: Title,
    ) {

        this.router.events
            .subscribe((event: Event) => {

                if( event instanceof NavigationEnd ){

                    // Monitorea los eventos de Google Analytics.
                    gtag('config', 'G-E3B24D187X', { 'page_path': event.urlAfterRedirects });
                }
            });
    }

    ngOnInit():void {

        // Configuración relativa a la traducción de días y meses.
        // Es necesario para el Componente de Calendario de PrimeNg.
        this.config.setTranslation({
            "dayNames":         [ "Domingo", "Lunes", "Martes", "Miércoles", "Jueves", "Viernes", "Sábado" ],
            "dayNamesShort":    [ "Dom", "Lun", "Mar", "Mié", "Jue", "Vie", "Sab" ],
            "dayNamesMin":      [ "Do","Lu","Ma","Mi","Ju","Vi","Sa" ],
            "monthNames":       [ "Enero","Febrero","Marzo","Abril","Mayo","Junio","Julio","Agosto","Septiembre","Octubre","Noviembre","Diciembre" ],
            "monthNamesShort":  [ "Ene", "Feb", "Mar", "Abr", "May", "Jun","Jul", "Ago", "Sep", "Oct", "Nov", "Dic" ],
            "today":            "Hoy"
        });

        // Configuración meta tags del sitio de serouno.com
        this.meta.updateTag({ property: 'og:title', content: 'SEROUNO | Be More' });
        this.meta.updateTag({ property: 'og:description', content: 'Únete a la comunidad oficial de fútbol soccer, donde podrás encontrar contenido relevante de tus equipos y de los fans que comparten tu pasión por este deporte. ¡Publica, comparte, infórmate, opina y debate! Todo en un solo lugar. SEROUNO. Be more!' });
        this.meta.updateTag({ property: 'og:image', content: 'https://serouno.com/assets/serouno_noticia_placeholder_s.png' });
        this.meta.updateTag({ property: 'og:image:secure_url', content: 'https://serouno.com/assets/serouno_noticia_placeholder_s.png' });
        this.meta.updateTag({ property: 'og:url', content: 'https://serouno.com' });

        // Escuchador del estado de visibilidad de la barra de Navegación y de Footer de la aplicación.
        this.layoutConfigService.layoutConfig$.subscribe( (layoutConfig) => {

            this.isNavbarAllowedToBeSeen = layoutConfig.layout.navbar.visible;
            this.isFooterAllowedToBeSeen = layoutConfig.layout.footer.visible;
        });

        this.titleService.setTitle(this.title);
        this.meta.addTags([
          { name: 'keywords', content: 'Fútbol, Fans, Balon, Equipos, Comunidad' },
          { name: 'viewport', content: 'width=device-width, initial-scale=1' },
          { name: 'description', content: 'Únete a la comunidad oficial de fútbol soccer, donde podrás encontrar contenido relevante de tus equipos y de los fans que comparten tu pasión por este deporte. ¡Publica, comparte, infórmate, opina y debate! Todo en un solo lugar. SEROUNO. Be more!r' },
          { name: 'og:image', content: window.location.origin + '/assets/serouno_noticia_placeholder_s.png'},
          { charset: 'UTF-8' }]);

    }

    public onSearchFor(searchTerm: string): void {
        this.router.navigate(['search-results'], { queryParams: { q: searchTerm } });
    }
}
