<form [formGroup]="createTextPostForm" (ngSubmit)="onCreateTextPost()" novalidate>
    <div class="input-group">
        <p-editor
            formControlName="description" 
            placeholder="Escribe algo interesante..."
            [style]="{'height':'250px'}" styleClass="liga-editor" [modules]="{ 'emoji-toolbar': true }">
            <p-header>
                <span class="ql-formats">
                    <button class="ql-bold" aria-label="Bold"></button>
                    <button class="ql-italic" aria-label="Italic"></button>
                    <button class="ql-underline" aria-label="Underline"></button>
                    <button class="ql-emoji" aria-label="Emoji"></button>
                </span>
            </p-header>
        </p-editor>

        <ng-container *ngIf="description?.invalid && (description?.dirty || description?.touched)">
            <span *ngIf="description?.hasError('required')" class="invalid-feedback-message"><small>Escribe algo interesante</small></span>
            <span *ngIf="description?.hasError('maxlength')" class="invalid-feedback-message"><small>Haz alcanzado el límite máximo de caracteres permitido</small></span>
        </ng-container>
    </div>

    <div class="controls">
        <button type="button" (click)="onCreateTextPostCancellation()">
            Cancelar <img src="assets/iconos/x_cancelar.svg" alt="Cancelar">
        </button>

        <button type="submit" [disabled]="createTextPostLoading">
            <ng-container *ngIf="!createTextPostLoading; else loading">
                Publicar <img src="assets/iconos/arrow_publica.svg" alt="Publicar">
            </ng-container>

            <ng-template #loading>
                <i class="pi pi-spin pi-spinner button-spinner"></i>
            </ng-template>
        </button>
    </div>
</form>
