import {Injectable} from '@angular/core';
import {environment} from '@env/environment';
import {HttpClient} from '@angular/common/http';
import {map} from 'rxjs/operators';
import {Observable} from 'rxjs';
import {AuthProfileService} from '@app/auth/shared/services/auth-profile.service';
import {DetailPostMappingService} from '../services/detail-post-mapping.service';

import {Topic, TopicResponse, FollowResponse} from '../models/topic';
import {DetailPostResponse} from '../models/post-response';
import {PostMedia} from '@app/ui/post/shared/models/post-media';
import {PostPolling} from '@app/ui/post/shared/models/post-polling';
import {VoteResponse} from '../models/comments-responses';
import {SaveResponse} from '../models/post';


@Injectable({
  providedIn: 'root'
})
export class PostsService {

  constructor(private httpClient: HttpClient,
              private authProfileService: AuthProfileService,
              private detailPostMappingService: DetailPostMappingService) {
  }

  public getPostById(postId: number): Observable<PostMedia | PostPolling> {
    let userId = 0;
    this.authProfileService.getAuthId().subscribe((res) => {
      userId = res;
    });
    return this.httpClient.get<DetailPostResponse>(`${environment.host}/api/posts/${postId}`)
      .pipe(
        map((postResponse) => {
          const {content} = postResponse;
          return this.detailPostMappingService.mapPost(userId, content);
        })
      );
  }

  public getTopicById(topicId: number): Observable<Topic> {
    return this.httpClient.get<TopicResponse>(`${environment.host}/api/topics/${topicId}`)
      .pipe(
        map((topicResponse) => {
          const {content} = topicResponse;
          const {id, name, is_official, cover, totals, user_id, currentUser, description} = content;
          return {
            id, name, is_official, cover, totals, user_id, currentUser, description
          };
        })
      );
  }

  public followTopic(topicId: number): Observable<boolean> {
    return this.httpClient.post<FollowResponse>(`${environment.host}/api/topics/${topicId}/follow`, {})
      .pipe(
        map((followResponse) => {
          const {success} = followResponse;
          return success;
        })
      );
  }

  public unFollowTopic(topicId: number): Observable<boolean> {
    return this.httpClient.post<FollowResponse>(`${environment.host}/api/topics/${topicId}/follow`, {})
      .pipe(
        map((followResponse) => {
          const {success} = followResponse;
          return !success;
        })
      );
  }

  public votePost(action: string, postId: number): Observable<VoteResponse> {
    const api: string = `${environment.host}/api/posts/`;

    const endpoints: { [name: string]: string } = {
      like: `${api}${postId}/like`,
      unlike: `${api}${postId}/unlike`,
      dislike: `${api}${postId}/dislike`,
      undislike: `${api}${postId}/undislike`
    };
    const endpointSelected: string = endpoints[action];


    return this.httpClient.post<VoteResponse>(`${endpointSelected}`, {})
      .pipe(
        map((response) => {
          const {code, success, message} = response;
          return {
            code, success, message
          };
        })
      );
  }

  public followPost(postId: number, isFollowing: boolean): Observable<SaveResponse> {
    const api: string = isFollowing ? 'unfollow' : 'follow';
    return this.httpClient.post<SaveResponse>(`${environment.host}/api/posts/${postId}/${api}`, {})
      .pipe(
        map((followResponse) => {
          return followResponse;
        })
      );
  }
}

