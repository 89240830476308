<div class="filters-dropdown">
  <img src="assets/iconos/icon_ordenar.svg" alt="Ordenar">
  <span>Ordenar:</span>
  <p-dropdown *ngIf="!noResultsWereFound"
              [options]="dropDownFilters"
              optionLabel="dropDownFilterLabel"
              optionValue="dropDownFilterDirection"
              [dropdownIcon]="selectedFilter === 'DESC' ? 'pi pi-arrow-up' : 'pi pi-arrow-down'"
              [(ngModel)]="selectedFilter"
              (ngModelChange)="onChangeFilter($event)"></p-dropdown>
</div>

<div *ngIf="initialLoadingDone; else showLoadingIndicator"
    class="search-posts mt-30"
    infiniteScroll
    [infiniteScrollDistance]="3"
    [infiniteScrollThrottle]="100"
    (scrolled)="onScroll()"
    [scrollWindow]="true">

    <ng-container *ngFor="let paginatorItemPost of paginatorItemPosts">
        <ng-container
            [ngTemplateOutlet]="postTemplate"
            [ngTemplateOutletContext]="{ $implicit: paginatorItemPost }"></ng-container>
    </ng-container>

    <!-- Mensaje de que hemos llegado al final de los resultados -->
    <p *ngIf="noMoreResultsWereFound" style="color: white; text-align: center;">No hay más resultados</p>

    <!-- Mensaje de que no se encontraron resultados -->
    <p *ngIf="noResultsWereFound" style="color: white; text-align: center;">No se encontraron resultados</p>

    <!-- Este spinner es visible al final de la pantalla cuando se dispara el evento (scroll) -->
    <div style="text-align: center;" *ngIf="loadingMorePosts">
        <i class="pi pi-spin pi-spinner spinner spinner-md"></i>
    </div>
</div>


<ng-template #postTemplate let-post>
    <ng-container [ngSwitch]="true">
        <!-- Post de Contenido Media -->
        <ng-container *ngSwitchCase="post.type === 1 || post.type === 2 || post.type === 3 || post.type === 4"
            [ngTemplateOutlet]="postMediaTemplate"
            [ngTemplateOutletContext]="{ $implicit: post }"></ng-container>
        <!-- Post de Votación -->
        <ng-container *ngSwitchCase="post.type === 5"
            [ngTemplateOutlet]="postPollingTemplate"
            [ngTemplateOutletContext]="{ $implicit: post }"></ng-container>
    </ng-container>


    <!-- Template para la Publicación de Contenido Media. -->
    <ng-template #postMediaTemplate let-postMedia>
        <div class="item mb-20">
          <app-post-media-small [post]="postMedia" [showTopicAsPostHeader]="true">
            <!-- Aqui incluimos el contenido Media dependiendo del tipo de Post: texto, link, imagen o video -->

            <!-- Es un Post de Link? -->
            <!-- Link Preview content goes here -->
            <app-link-preview
              *ngIf="postMedia.type === 2"
              [originalLinkSource]="postMedia.media.content.originalLinkSource"
              [metaTags]="postMedia.media.content.metaTags"></app-link-preview>

            <!-- Es un Post de Imagen? -->
            <!-- Image content goes here -->
            <img
              *ngIf="postMedia.type === 3"
              [appMediaSource]="postMedia.media.content"
              [appFallbackAssetMedia]="'assets/serouno_noticia_placeholder.png'"
              [alt]="postMedia.description">

            <!-- Es un Post de Video? -->
            <!-- Video content goes here -->
            <video
              *ngIf="postMedia.type === 4"
              width="100%"
              height="360px"
              controls>
              <source type="video/mp4" [src]="postMedia.media.content">
            </video>

            <!-- Es un Post de Texto? -->
            <!-- Un Post de Texto no posee ningún tipo de contenido media -->
          </app-post-media-small>
        </div>
    </ng-template>

    <!-- Template para la Publicación de Votación. -->
    <ng-template #postPollingTemplate let-postPolling>
        <!-- Es un Post de Votaciones? -->
      <div class="item mb-20">
        <app-post-polling [post]="postPolling"></app-post-polling>
      </div>
    </ng-template>

</ng-template>

<ng-template #showLoadingIndicator>
    <!-- Este spinner es cuando se cargan inicialmente los Posts por vez primera. -->
    <div style="text-align: center;">
        <i class="pi pi-spin pi-spinner spinner spinner-lg"></i>
    </div>
</ng-template>
