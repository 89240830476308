<form [formGroup]="createPollingPostForm" (ngSubmit)="onCreatePollingPost()" novalidate>

  <div class="input-group">
    <input type="text" class="description input-box-face input-box-white-text" placeholder="Escribe algo interesante..."
           formControlName="description" pInputText>

    <ng-container *ngIf="description?.invalid && (description?.dirty || description?.touched)">
      <span *ngIf="description?.hasError('required')"
            class="invalid-feedback-message"><small>Escribe algo interesante</small></span>
      <span *ngIf="description?.hasError('maxlength')" class="invalid-feedback-message"><small>El límite máximo permitido son 400 caracteres</small></span>
    </ng-container>
  </div>

  <div class="options">
    <div class="input-group">
      <div formArrayName="pollingOptions">
        <div *ngFor="let pollingOption of pollingOptions.controls; let i=index;">
          <label>
            <input
              type="text"
              class="input-box-face input-box-white-text"
              placeholder="Opción Número {{ i + 1 }}"
              [formControlName]="i"
              pInputText>
          </label>

          <ng-container *ngIf="pollingOption?.invalid && (pollingOption?.dirty || pollingOption?.touched)">
            <span *ngIf="pollingOption?.hasError('required')" class="invalid-feedback-message"><small>Escribe tu opción de votación</small></span>
            <span *ngIf="pollingOption?.hasError('maxlength')" class="invalid-feedback-message"><small>El límite máximo permitido son 30 caracteres</small></span>
          </ng-container>
        </div>
      </div>

      <button type="button" (click)="addPollingOption()">Agregar opción</button>
    </div>
    <div class="right">
      <p><b>Tips Para mejores Votaciones.</b></p>
      <p>1.- Se sugieren opciones cortas y claras. <br>
        2.- Recuerda poner dos o más opciones. <br>
        3.- Elige la duración de la encuesta.</p>
      <p>Importante: las opciones no se pueden editar una vez creada la publicación</p>
    </div>
  </div>

  <div class="input-group duration">
    <span>DURACIÓN</span>
    <p-dropdown
      [options]="pollingDurations"
      optionLabel="labelDurationDays"
      optionValue="durationInDays"
      formControlName="duration"></p-dropdown>
  </div>

  <div class="controls">
    <button type="button" (click)="onCreatePollingPostCancellation()">Cancelar <img src="assets/iconos/x_cancelar.svg"
                                                                                    alt="Cancelar"></button>
    <button class="submit" type="submit" [disabled]="createPollingPostLoading">
      <ng-container *ngIf="!createPollingPostLoading; else loading">
        Publicar <img src="assets/iconos/arrow_publica.svg" alt="Publicar"></ng-container>
      <ng-template #loading>
        <i class="pi pi-spin pi-spinner button-spinner"></i>
      </ng-template>
    </button>
  </div>

</form>
