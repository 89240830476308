import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';

import { PostType } from '../../../shared/models/post-type';
import { PostLink } from '../../../shared/models/post-link';

@Component({
    selector: 'app-create-link-post-form',
    templateUrl: './create-link-post-form.component.html',
    styleUrls: ['./create-link-post-form.component.scss']
})
export class CreateLinkPostFormComponent implements OnInit {

    @Input() public createLinkPostLoading: boolean = false;
    @Output() onCreate: EventEmitter<Partial<PostLink>> = new EventEmitter<Partial<PostLink>>();
    @Output() onCancel: EventEmitter<void> = new EventEmitter<void>();

    public createLinkPostForm!: FormGroup;

    constructor(private fb: FormBuilder) { }

    ngOnInit(): void {

        this.loadCreateLinkPostForm();
    }

    private loadCreateLinkPostForm(): void {

        this.createLinkPostForm = this.fb.group({
            description:    [ '', [ Validators.required ] ],
            homeUrl:        [ '', [ Validators.required ] ]
        });
    }

    public onCreateLinkPost(): void {

        // En caso que el usuario de click al botón iniciar sin haber ingresado los datos que se le piden.
        this.createLinkPostForm.markAllAsTouched();

        // Una vez los datos del formulario sean correctos
        if ( this.createLinkPostForm.valid ) {  

            const postType: PostType = PostType.LINK;
            const { description, homeUrl } = this.createLinkPostForm.value;
            
            this.onCreate.emit({ description, homeUrl, postType } as Partial<PostLink>);
        }
    }

    public onCreateLinkPostCancellation(): void {
        
        this.onCancel.emit();
    }

    get description() {

        return this.createLinkPostForm.get('description');
    }

    get homeUrl() {

        return this.createLinkPostForm.get('homeUrl');
    }
}
