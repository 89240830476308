import {Component, HostListener, OnDestroy, OnInit} from '@angular/core';
import {switchMap, distinctUntilChanged} from 'rxjs/operators';

import { MessageService } from 'primeng/api';

import { SubSink } from 'subsink';

import { LayoutConfigService } from '@app/layout-config/shared/services/layout-config.service';
import { PaginatorLandingService } from '../../shared/services/paginator/paginator-landing.service';
import { LandingUserService } from '../../shared/services/landing-user.service';

import { PaginatorItem } from '../../shared/models/paginator-item';
import { New } from '../../shared/models/new';

const MAX_CAROUSEL_VISIBLE_ITEMS = 4;

@Component({
  selector: 'app-main-landing',
  templateUrl: './main-landing.component.html',
  styleUrls: ['./main-landing.component.scss']
})
export class MainLandingComponent implements OnInit, OnDestroy {

    private subscriptions = new SubSink();

    // Para la bienvenida al Usuario.
    public username: string = '';
    public teamname: string = '';

    // Para el bloque de Noticias.
    public missedNews: New[] = [];
    public latestNews: New[] = [];
    public featuredNews: New[] = [];

    // Para el total de Noticias que mostrar en los Carouseles.
    public totalMissedNews: number = 0;
    public totalLatestNews: number = 0;
    public totalFeaturedNews: number = 0;

    // Items Para el Paginador.
    public paginatorItems: PaginatorItem[] = [];
    public paginatorItemsPreviouslyLoaded: PaginatorItem[] = [];

    // Flags de carga.
    public isPaginationInitialContentDoneLoading: boolean = false;
    public isPaginatorLoadingMoreItems: boolean = false;
    public screenWidth = window.innerWidth;

    // Flag para mostrar/ocultar los Anuncios.
    public showAdTypeA: boolean = true;
    public showAdTypeB: boolean = true;
    public showAdTypeC: boolean = true;

    constructor(
        private readonly toastMessage: MessageService,
        private readonly landingUser: LandingUserService,
        private readonly paginatorLanding: PaginatorLandingService,
        private readonly layoutConfigService: LayoutConfigService
    ) {
        this.getScreenSize();
        // Establecemos la configuración inicial de la barra de navegación y del footer.
        layoutConfigService.setLayoutConfig({
            layout: {
                navbar: { visible: true },
                footer: { visible: false }
            }
        });
    }

    @HostListener('window:resize', ['$event'])
    private getScreenSize(): void {
        this.screenWidth = window.innerWidth;
    }
    ngOnInit(): void {
        // Al cargar este Componente deben ocurrir 3 cosas:

        // 1. Damos la bienvenida al Usuario.
        this.username = this.landingUser.getUsername();
        this.teamname = this.landingUser.getFavoriteTeamName();

        // 2. Cargamos el listener encargado de la Paginación de resultados. Al inicio NO entrega resultado alguno.
        this.subscriptions.sink = this.paginatorLanding.listenPagination$
            .pipe(
                distinctUntilChanged(),
                switchMap(() => this.paginatorLanding.getNextPage())
            )
            .subscribe((paginatorItems) => {

                this.paginateItems(paginatorItems);
                this.isPaginatorLoadingMoreItems = false;
            });

        this.isPaginationInitialContentDoneLoading  = false;

        // 3. Cargamos el contenido inicial que contempla la Sección de Noticias y la primera Página del Paginador.
        this.subscriptions.sink = this.paginatorLanding.loadInitialPaginationContent().subscribe((content) => {

            const { featuredNews, latestNews, missedNews, paginatorItems } = content;

            this.featuredNews = featuredNews;
            this.latestNews = latestNews;
            this.missedNews = missedNews;

            this.totalFeaturedNews = (featuredNews.length >= MAX_CAROUSEL_VISIBLE_ITEMS) ? MAX_CAROUSEL_VISIBLE_ITEMS : featuredNews.length;
            this.totalLatestNews = (latestNews.length >= MAX_CAROUSEL_VISIBLE_ITEMS)     ? MAX_CAROUSEL_VISIBLE_ITEMS : latestNews.length;
            this.totalMissedNews = (missedNews.length >= MAX_CAROUSEL_VISIBLE_ITEMS)     ? MAX_CAROUSEL_VISIBLE_ITEMS : missedNews.length

            this.paginateItems(paginatorItems);

            // El contenido inicial ya ha sido cargado exitosamente.
            this.isPaginationInitialContentDoneLoading = true;
        });
    }

    ngOnDestroy(): void {

        this.subscriptions.unsubscribe();
    }

    public onScroll(): void {

        if ( this.paginatorLanding.hasNotReachedPaginationEnd() ) {

            this.isPaginatorLoadingMoreItems = true;
            this.paginatorLanding.paginateNextResult();
        }
    }

    public onBookmarkPost(isFollowingPost: boolean): void {

        this.toastMessage.add({
            severity: isFollowingPost ? 'success' : 'info',
            summary: 'Éxito',
            detail: isFollowingPost ? 'Has guardado esta noticia.' : 'Has eliminado de tus guardados esta noticia.'
        });
    }

    private paginateItems(items: PaginatorItem[]): void {

        // Fusionamos los posts previamente cargadados con los posts que provengan del Servidor.
        this.paginatorItemsPreviouslyLoaded = [ ...this.paginatorItemsPreviouslyLoaded, ...items ];

        // Actualizamos la vista con la nueva información cargada en el Property Binding correspondiente.
        this.paginatorItems = this.paginatorItemsPreviouslyLoaded;
    }
}
