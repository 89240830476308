import { Component, OnInit, Input, ChangeDetectionStrategy } from '@angular/core';

@Component({
    selector: 'app-topic-joined-selected-item',
    templateUrl: './topic-joined-selected-item.component.html',
    styleUrls: ['./topic-joined-selected-item.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class TopicJoinedSelectedItemComponent implements OnInit {

    @Input() title: string = '';
    @Input() cover: string = '';
    @Input() isOfficial: boolean = false;

    constructor() { }

    ngOnInit(): void {
    }

}
