import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';

import { AuthService } from '@app/auth/shared/services/auth.service';

@Injectable({
  providedIn: 'root'
})
export class RedirectWhenSharingTopicGuard implements CanActivate {

    constructor(
        private router: Router,
        private authService: AuthService
    ) { }
    
    canActivate(route: ActivatedRouteSnapshot, routerState: RouterStateSnapshot): true | UrlTree {
    
        const intendedUrl: string = routerState.url;

        if ( !(this.authService.isAuthenticated()) ) {
            
            // Podemos activar la página donde se despliega el Preview del Topic.
            return true;
        }

        // En este punto, la aplicación nos reconoce como usuarios autentificados, por lo tanto, podemos 
        // ir al detalle del Topic de nuestro interes.

        // Obtenemos el id del Topic a partir de la Url de retorno.
        const topicId: number = this.getTopicId(intendedUrl);
        
        // Redireccionamos al Login con una url de retorno.
        return this.router.createUrlTree([`/community/topic/${ topicId }`]);
    }

    // Nos va a permitir trae obtener el id del Topic a partir de la url de retorno.
    // Url de prueba: /topic/share/264
    private getTopicId(url: string): number {

        const urlParts: string[] = url.split('/');
        // En la tercera posición se encuentra el id del topic, esto es por que cuando hacemo split obtenemos lo siguiente.
        // ["", "topic", "share", "264"]
        return +(urlParts[3] || 0);
    }
}
