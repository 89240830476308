import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
    selector: 'app-button-call-to-action',
    templateUrl: './button-call-to-action.component.html',
    styleUrls: ['./button-call-to-action.component.scss']
})
export class ButtonCallToActionComponent implements OnInit {

    @Input() suscriptionLoading: boolean = false;
    @Input() topicOwned: boolean = false;
    @Input() topicJoined: boolean = false;

    @Output() onEdit: EventEmitter<void> = new EventEmitter<void>();
    @Output() onJoin: EventEmitter<void> = new EventEmitter<void>();
    @Output() onDisjoin: EventEmitter<void> = new EventEmitter<void>();

    constructor() { }

    ngOnInit(): void {
    }

    public onEditTopic(): void {

        this.onEdit.emit();
    }   

    public onSubscribeTopic(): void {

        this.topicJoined ? this.onDisjoin.emit() : this.onJoin.emit();
    }
}
