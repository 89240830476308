import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';

@Component({
    selector: 'app-selected-video-content-area',
    templateUrl: './selected-video-content-area.component.html',
    styleUrls: ['./selected-video-content-area.component.scss']
})
export class SelectedVideoContentAreaComponent implements OnInit {

    // Acepta el archivo o url del video.
    @Input() videoContent: File | string | null = null;
    // Comunica  que el usuario ha eliminado la preselección del video.
    @Output() videoContentCleared: EventEmitter<void> = new EventEmitter<void>();

    public videoUrl: SafeUrl | string = '';

    constructor(protected sanitizer: DomSanitizer) {}

    ngOnInit(): void {

        // En este punto en video viene:
        // a) Objeto File que es cargado localmente a través del Input File.
        // b) Una Cadena de texto que representa la URL Remota del recurso.

        // En caso de que sea un Objeto File debemos convertir este objeto a una url que el elemento
        // <video> pueda entender.
        // Url Remota de Prueba: https://fanzoneliga.s3.us-east-2.amazonaws.com/posts/190/file_example_MP4_480_1_5MG.mp4
        if ( this.videoContent ) {

            this.videoUrl = (this.videoContent instanceof File) ? 
                this.sanitizer.bypassSecurityTrustUrl(URL.createObjectURL(this.videoContent)) : this.videoContent;
        } 
    }

    public onClearVideo(): void {

        this.videoContentCleared.emit();
    }
}
