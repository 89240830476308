<button type="button" class="close" (click)="close()">
  <img src="/assets/iconos/x_closepopup.svg" alt="Cerrar">
</button>

<div class="content">
  <p class="message">{{ message }}</p>

  <div class="buttons">
    <button type="button" (click)="cancel()">Cancelar</button>
    <button type="button" (click)="accept()">Aceptar</button>
  </div>

</div>
