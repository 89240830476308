<div class="quick-view">
  <div class="top">
    <button (click)="setTopicsBy('top')" class="{{activeTop == 'top' ? 'active' : ''}}"><img src="assets/iconos/icono_top5.svg" alt="Hot">Top 5</button>
    <button (click)="setTopicsBy('hot')" class="{{activeTop == 'hot' ? 'active' : ''}}"><img src="assets/iconos/icono_hot.svg" alt="Hot">Hot <b>Hoy</b></button>
    <button (click)="setTopicsBy('new')" class="{{activeTop == 'new' ? 'active' : ''}}"><img src="assets/iconos/icono_nuevo.svg" alt="Hot">Nuevos</button>
  </div>
  <div class="items" *ngIf="!loading; else showLoading">
    <a *ngFor="let topic of topTopics" [routerLink]="['/community/topic/', topic.id]" class="item">
      <img [appMediaSource]="topic.cover" [appFallbackAssetMedia]="'assets/serouno_noticia_placeholder_s.png'" alt="Topic">
      <p>{{topic.name}}</p>
    </a>
  </div>
  <ng-template #showLoading>
    <div style="text-align: center; margin-top: 20px">
      <i class="pi pi-spin pi-spinner spinner spinner-md"></i>
    </div>
  </ng-template>

</div>
