<div class="verification-code">
    <p>Código de Verificación</p>
    <p>En breve recibirás un correo electrónico con un código de verificación.</p>

    <!-- Formulario de activación de cuenta -->
    <div>
        <app-account-activation-form
            [accountActivationLoading]="loadingAccountActivationAction"
            [accountActivationCodeMessage]="activationCodeMessage"
            (activateAccount)="onActivateAccount($event)"
            (resendActivationCode)="onResendActivationCode()"
            ></app-account-activation-form>
    </div>
</div>
