import { Injectable } from '@angular/core';

import { Observable, Subject } from 'rxjs';

export interface Action {
    type:       string;
    payload?:    any;
}

@Injectable({
    providedIn: 'root'
})
export class NavbarActionListenerService {

    private actionListenerSubject: Subject<Action> = new Subject<Action>();
    public actionListener$: Observable<Action> = this.actionListenerSubject.asObservable();

    constructor() { }

    public dispatchAction(action: Action): void {

        this.actionListenerSubject.next(action);
    } 
}
