import { Injectable } from '@angular/core';

import { PaginatorItemPost } from '../../models/paginator-item-post';
import { PaginatorItemNew } from '../../models/paginator-item-new';

import { PostMedia } from '@app/ui/post/shared/models/post-media';
import { PostPolling } from '@app/ui/post/shared/models/post-polling';
import { New } from '../../models/new';

import { AdvertisementType } from '@app/ui/advertisement/shared/models/advertisement-type';
import { AdvertisementTypeA } from '@app/ui/advertisement/shared/models/advertisement-type-A';
import { AdvertisementTypeB } from '@app/ui/advertisement/shared/models/advertisement-type-B';

@Injectable({
    providedIn: 'root'
})
export class PaginatorLandingAdvertisementIntercalationService {

    constructor() { }
    
    public insertAdsInPosts(state: PaginatorItemPost[], currentPost: (PostMedia | PostPolling), idx: number): PaginatorItemPost[] {

        // Por cada 10 Publicaciones insertamos 1 Anuncio al Inicio.
        const isBeggining: boolean = (idx === 3);

        // Agrega un anuncio de Tipo B al inicio de los elementos Post.
        if ( isBeggining ) {
            
            // Crea la marca de anuncio de Tipo B.
            const ad: AdvertisementTypeB = { classification: AdvertisementType.B };

            // Empuja la marca de Anuncio de Tipo B.
            state.push(ad);
            
            // Empuja el Post Original.
            state.push(currentPost);

        } else {

            // Empuja solamente el Post Original.
            state.push(currentPost);
        }

        return state;
    }

    public insertAdsInNews(state: PaginatorItemNew[], currentNew: New, idx: number) {
 
        const isBeggining: boolean = (idx === 2);

        // Agrega un anuncio de Tipo A a la mitad del total de los elementos Noticias.
        if ( isBeggining ) {
            
            // Crea la marca de anuncio de Tipo A.
            const ad: AdvertisementTypeA = { classification: AdvertisementType.A };

            // Empuja la marca de Anuncio de Tipo A.
            state.push(ad);

            // Empuja el Post Original.
            state.push(currentNew);

        } else {

            // Empuja solamente el Post Original.
            state.push(currentNew);
        }

        return state;
    }
}
