import { AbstractControl, FormControl, ValidationErrors, AsyncValidatorFn } from '@angular/forms';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { RegistrationService } from '../services/registration.service';

export class RegistrationValidators {
    
    static uniqueEmail(registrationService: RegistrationService): AsyncValidatorFn {

        return (control: AbstractControl): Observable<ValidationErrors | null> => {

            const email: string = control.value.trim();

            return registrationService.emailHasBeenTaken(email)
                    .pipe(
                        map( (emailTaken) => (emailTaken ? { 'emailTaken': true } as ValidationErrors : null ))
                    );
        }
    }
}