<div class="bg-landing">
  <ng-container *ngIf="isPaginationInitialContentDoneLoading; else showOneOffLoadingIndicator">
    <!-- Scroll Component -->
    <div
      class="scrolling-area"
      infiniteScroll
      [infiniteScrollDistance]="3"
      [infiniteScrollThrottle]="100"
      (scrolled)="onScroll()"
      [scrollWindow]="false">

      <div class="main-grid">
        <div class="grid-left">
          <!-- Bloque de Bienvenida del Usuario -->
          <div class="greetings">
            <h4>¡Hola <b>{{ username }}</b></h4>
            <p>Echa un vistazo al <b>feed</b> de hoy</p>
          </div>

          <!-- Bloque de Caroussel de Noticias-->
          <div class="news-feed">
            <h4>Noticias del <b>{{ teamname }}</b></h4>

            <!-- Noticias Destacadas -->
            <div class="row" *ngIf="totalFeaturedNews > 0">
              <p-carousel [value]="featuredNews" [numVisible]="screenWidth > 980 ? totalFeaturedNews : 1" [numScroll]="1">
                <ng-template let-featuredNew pTemplate="item">
                  <app-new-min-slide
                    [id]="featuredNew.id"
                    [title]="featuredNew.title"
                    [urlImage]="featuredNew.urlImage"
                    [url]="featuredNew.url"
                    avatarDomain="{{featuredNew.favicon}}"
                    [hostDomain]="featuredNew.hostDomain"
                    [date]="featuredNew.date">
                  </app-new-min-slide>
                </ng-template>
              </p-carousel>

              <p class="min"><i>Destacadas</i></p>
            </div>

            <!-- Noticias más Recientes -->
            <div class="row" *ngIf="totalLatestNews > 0">
              <p-carousel [value]="latestNews" [numVisible]="screenWidth > 980 ? totalLatestNews : 1" [numScroll]="1">
                <ng-template let-latestNew pTemplate="item">
                  <app-new-min-slide
                    [id]="latestNew.id"
                    [title]="latestNew.title"
                    [urlImage]="latestNew.urlImage"
                    [url]="latestNew.url"
                    avatarDomain="{{latestNew.favicon}}"
                    [hostDomain]="latestNew.hostDomain"
                    [date]="latestNew.date">
                  </app-new-min-slide>
                </ng-template>
              </p-carousel>

              <p class="min"><i>Más Recientes</i></p>
            </div>

            <!-- Noticias por si te lo perdiste -->
            <div class="row" *ngIf="totalMissedNews > 0">
              <p-carousel [value]="missedNews" [numVisible]="screenWidth > 980 ? totalMissedNews : 1" [numScroll]="1">
                <ng-template let-missedNew pTemplate="item">
                  <app-new-min-slide
                    [id]="missedNew.id"
                    [title]="missedNew.title"
                    [urlImage]="missedNew.urlImage"
                    [url]="missedNew.url"
                    avatarDomain="{{missedNew.favicon}}"
                    [hostDomain]="missedNew.hostDomain"
                    [date]="missedNew.date">
                  </app-new-min-slide>
                </ng-template>
              </p-carousel>

              <p class="min"><i>Por si te lo perdiste</i></p>
            </div>
          </div>

          <!-- Bloque Principal de items del Paginador -->
          <div class="items">
            <ng-container *ngFor="let paginatorItem of paginatorItems">
              <ng-container [ngSwitch]="paginatorItem.type">
                <!-- Item de Publicaciones -->
                <ng-container *ngSwitchCase="'post'"
                              [ngTemplateOutlet]="postTemplate"
                              [ngTemplateOutletContext]="{ $implicit: paginatorItem.item }"></ng-container>
                <!-- Item de Topics -->
                <ng-container *ngSwitchCase="'topics'"
                              [ngTemplateOutlet]="topicsTemplate"
                              [ngTemplateOutletContext]="{ $implicit: paginatorItem.item }"></ng-container>
                <!-- Item de Noticias -->
                <ng-container *ngSwitchCase="'news'"
                              [ngTemplateOutlet]="newsTemplate"
                              [ngTemplateOutletContext]="{ $implicit: paginatorItem.item }"></ng-container>
              </ng-container>
            </ng-container>
            <!-- Template de la Publicación para mostrar en el Paginador. -->
            <ng-template #postTemplate let-post>
              <ng-container [ngSwitch]="true">
                <!-- Post de Contenido Media -->
                <ng-container *ngSwitchCase="post.type === 1 || post.type === 2 || post.type === 3 || post.type === 4"
                              [ngTemplateOutlet]="postMediaTemplate"
                              [ngTemplateOutletContext]="{ $implicit: post }"></ng-container>
                <!-- Post de Votación -->
                <ng-container *ngSwitchCase="post.type === 5"
                              [ngTemplateOutlet]="postPollingTemplate"
                              [ngTemplateOutletContext]="{ $implicit: post }"></ng-container>
                <!-- Anuncio de tipo B -->
                <ng-container *ngSwitchDefault
                              [ngTemplateOutlet]="advertisementTypeBTemplate"
                              [ngTemplateOutletContext]="{ $implicit: post }"></ng-container>
              </ng-container>

              <!-- Template para la Publicación de Contenido Media. -->
              <ng-template #postMediaTemplate let-postMedia>
                <app-post-media-expanded [post]="postMedia" [showTopicAsPostHeader]="true" (onBookmark)="onBookmarkPost($event)">
                  <!-- Aqui incluimos el contenido Media dependiendo del tipo de Post: texto, link, imagen o video -->

                  <!-- Es un Post de Link? -->
                  <!-- Link Preview content goes here -->
                  <app-link-preview
                    *ngIf="postMedia.type === 2"
                    [originalLinkSource]="postMedia.media.content.originalLinkSource"
                    [metaTags]="postMedia.media.content.metaTags"></app-link-preview>

                  <!-- Es un Post de Imagen? -->
                  <!-- Image content goes here -->
                  <img
                    *ngIf="postMedia.type === 3"
                    [src]="postMedia.media.content"
                    [alt]="postMedia.description">

                  <!-- Es un Post de Video? -->
                  <!-- Video content goes here -->
                  <video
                    *ngIf="postMedia.type === 4"
                    width="100%"
                    height="360px"
                    controls>
                      <source type="video/mp4" [src]="postMedia.media.content">
                  </video>

                  <!-- Es un Post de Texto? -->
                  <!-- Un Post de Texto no posee ningún tipo de contenido media -->
                </app-post-media-expanded>
              </ng-template>

              <!-- Template para la Publicación de Votación. -->
              <ng-template #postPollingTemplate let-postPolling>
                <!-- Es un Post de Votaciones? -->
                <app-post-polling [post]="postPolling"></app-post-polling>
              </ng-template>

              <!-- Template para el Anuncio de tipo B que se va a insertar. -->
              <ng-template #advertisementTypeBTemplate let-advertisement>
                <app-advertisement-type-b *ngIf="showAdTypeB" (loaded)="showAdTypeB = $event"></app-advertisement-type-b>
              </ng-template>
            </ng-template>

            <!-- Template para mostrar los Topics en el Paginador. -->
            <ng-template #topicsTemplate let-topics>
              <div *ngFor="let topic of topics">
                <app-topic-call-to-action
                  [id]="topic.id"
                  [title]="topic.title"
                  [cover]="topic.cover"
                  [isOfficial]="topic.isOfficial"
                  [following]="topic.following"
                  [ownership]="topic.ownership"
                  [totalFollowers]="topic.totalFollowers"
                  [totalPublishedPosts]="topic.totalPublishedPosts">
                </app-topic-call-to-action>
              </div>
            </ng-template>

            <!-- Template para mostrar las Noticias en el Paginador. -->
            <ng-template #newsTemplate let-news>
              <div *ngFor="let new of news">
                <app-new *ngIf="new.id; else advertisementTypeATemplate"
                         [id]="new.id"
                         [title]="new.title"
                         [urlImage]="new.urlImage"
                         [url]="new.url"
                         avatarDomain="{{new.favicon}}"
                         [hostDomain]="new.hostDomain"
                         [date]="new.date"></app-new>
              </div>
              <!-- Template para el Anuncio de tipo A que se va a insertar. -->
              <ng-template #advertisementTypeATemplate let-advertisement>
                <app-advertisement-type-a *ngIf="showAdTypeA" (loaded)="showAdTypeA = $event"></app-advertisement-type-a>
              </ng-template>
            </ng-template>
            <div style="text-align: center;" *ngIf="isPaginatorLoadingMoreItems">
              <i class="pi pi-spin pi-spinner spinner spinner-md"></i>
            </div>

          </div>

          <!-- Este spinner es visible solamente al final de la pantalla cuando se dispara el evento (scroll) -->
        </div>

        <div class="grid-right pt-100">
          <!-- Banner para ir a Comunidad. -->
          <app-banner-community></app-banner-community>
          <!-- Lista de Topics Recomendados. -->
          <app-recommended-topic-list></app-recommended-topic-list>
          <!-- Anuncio de tipo C -->
          <app-advertisement-type-c *ngIf="showAdTypeC" (loaded)="showAdTypeC = $event"></app-advertisement-type-c>
        </div>
      </div>



    </div>

  </ng-container>

  <!-- Este spinner es visible solamente cuando se carga el Contenido del Scroller por vez primera. -->
  <ng-template #showOneOffLoadingIndicator>
    <div class="loading" style="text-align: center;">
      <i class="pi pi-spin pi-spinner spinner spinner-lg"></i>
    </div>
  </ng-template>
</div>

<p-toast position="top-left"></p-toast>
