import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';

import { PostVideo } from '../../../shared/models/post-video';
import { PostType } from '../../../shared/models/post-type';

import { PostVideoValidators } from '../../../shared/validators/post-video-validators';

@Component({
    selector: 'app-edit-video-post-form',
    templateUrl: './edit-video-post-form.component.html',
    styleUrls: ['./edit-video-post-form.component.scss']
})
export class EditVideoPostFormComponent implements OnInit {
    
	@Input() editablePostVideo!: Partial<PostVideo>;

    @Output() onUpdate: EventEmitter<Partial<PostVideo>> = new EventEmitter<Partial<PostVideo>>();
    @Output() onCancel: EventEmitter<void> = new EventEmitter<void>();

    public editVideoPostForm!: FormGroup;

    constructor(private fb: FormBuilder) { }

    ngOnInit(): void {

        this.loadEditVideoPostForm();
    }
    
    private loadEditVideoPostForm(): void {

        const { description, video } = this.editablePostVideo;

        this.editVideoPostForm = this.fb.group({
            description:    [ description, [ Validators.required ] ],
            video:          [ video, [ PostVideoValidators.mustBeValidVideo ] ]
        });
    }   

    public onEditVideoPost(): void {

        // En caso que el usuario de click al botón iniciar sin haber ingresado los datos que se le piden.
        this.editVideoPostForm.markAllAsTouched();

        // Una vez los datos del formulario sean correctos y tengamos una video seleccionado realizamos el envio.
        if ( this.editVideoPostForm.valid ) {

            const postType: PostType = PostType.VIDEO
            const { description, video } = this.editVideoPostForm.value;

            this.onUpdate.emit({ id: this.editablePostVideo.id, description, video, postType } as Partial<PostVideo>);
        }
    }

    public onEditVideoPostCancellation(): void {

        this.onCancel.emit();
    }

    get description() {

        return this.editVideoPostForm.get('description');
    }

    get video() {

        return this.editVideoPostForm.get('video');
    }
}
