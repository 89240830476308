import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ComplianceAndTransparencyRoutingModule } from './compliance-and-transparency-routing.module';
import { TermsAndConditionsPageComponent } from './pages/terms-and-conditions-page/terms-and-conditions-page.component';
import { PrivacyPolicyPageComponent } from './pages/privacy-policy-page/privacy-policy-page.component';


@NgModule({
  declarations: [
    TermsAndConditionsPageComponent, 
    PrivacyPolicyPageComponent
  ],
  imports: [
    CommonModule,
    ComplianceAndTransparencyRoutingModule
  ]
})
export class ComplianceAndTransparencyModule { }
