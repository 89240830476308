import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-followed-new',
  templateUrl: './followed-new.component.html',
  styleUrls: ['./followed-new.component.scss']
})
export class FollowedNewComponent implements OnInit {
  @Input() id!: number;
  @Input() title: string = '';
  @Input() urlImage: string = '';
  @Input() url: string = '';
  @Input() avatarDomain: string = '';
  @Input() hostDomain: string = '';
  @Input() date!: Date;

  constructor() {
  }

  ngOnInit(): void {
  }

}
