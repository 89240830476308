import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { concatMap, tap } from 'rxjs/operators';

import { ModalService } from '@app/ui/modals/shared/services/modal.service';

import { PasswordSettingsService } from '../../shared/services/password-settings.service';
import { PasswordSettings } from '../../shared/models/password-settings';

@Component({
    selector: 'app-change-password-panel',
    templateUrl: './change-password-panel.component.html',
    styleUrls: ['./change-password-panel.component.scss']
})
export class ChangePasswordPanelComponent implements OnInit {

    public loadingChangingPasswordAction: boolean = false;
    
    constructor(
        private readonly router: Router,
        private modalService: ModalService,
        private passwordSettingsService: PasswordSettingsService
    ) { }

    ngOnInit(): void {
    }

    public onChangePassword($event: PasswordSettings): void {

        const { currentPassword, newPassword, confirmPassword } = $event;

        if ( newPassword !== confirmPassword ) {

            this.modalService.failureDialog('Las contraseñas introducidas no concuerdan.');

            return;
        }
        
        this.loadingChangingPasswordAction = true;

        this.passwordSettingsService.changePassword({currentPassword, newPassword, confirmPassword})
            .pipe(
                tap((authNewCredentials) => {

                    const { token, tokenExpiration } = authNewCredentials;

                    this.loadingChangingPasswordAction = false;
                    this.passwordSettingsService.refreshAuthCredentialsInLocalStorage(token, tokenExpiration);
                }),
                concatMap(() => {

                    return this.modalService.successDialog('Su contraseña ha sido actualizada.').onClose;
                })
            ).subscribe({
                next: () => {

                    // Redireccionamos al usuario a la sección de "Editar mi Cuenta"
                    this.router.navigateByUrl('account/profile-settings/edit-profile');
                },
                complete: () => {

                    this.loadingChangingPasswordAction = false;
                }
            });
    }
}
