import { FormGroup, ValidationErrors } from '@angular/forms';

import { PostImageCover } from '../models/post-image-cover';

export class PostImageCoverValidators {

    static mustBeValidImage(coverControl: FormGroup):  ValidationErrors | null {

        const image: PostImageCover | null = coverControl.value;

        if ( !image ) {

            return {
                'fileRequired': true
            };
        }

        const errorMessages: { [name: string]: string } = {
            'unselected_file':      'unSelectedFile',
            'invalid_mime_type':    'invalidMimeType',
            'invalid_file_size':    'invalidFileSize',
            'unsuccesful_encoding': 'unsuccessfulEncoding'
        };

        const { errorCode } = image;

        if ( errorCode ) {
            
            return { [ errorMessages[errorCode] ]: true } as ValidationErrors;    
        }

        return null;
    }
}