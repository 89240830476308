<div class="single-post-grid main-grid">
  <div class="left grid-left">
    <a [routerLink]="['/community/following']" class="back">
      <img src="assets/iconos/icono_arrowdown.svg" alt="Regresar">Regresar
    </a>

    <div class="title">
      <h3>Crear Topic</h3>
    </div>

    <app-create-topic-form
      [createTopicLoading]="loadingCreateTopicAction"
      [currentTeamPreference]="selectedCurrentTeam"
      (create)="onCreateTopic($event)"
      (cancel)="onCancelTopicCreation()"></app-create-topic-form>
  </div>
  <div class="grid-right pt-50">
    <app-recommended-topic-list></app-recommended-topic-list>
    <app-advertisement-type-c *ngIf="showAdTypeC" (loaded)="showAdTypeC = $event"></app-advertisement-type-c>
  </div>
</div>

