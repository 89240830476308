import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { concatMap, tap } from 'rxjs/operators';

import { SubSink } from 'subsink';

import { PostCommunicatorService } from '../../shared/services/post-communicator.service';
import { PostTextService } from '../../shared/services/post-text.service';
import { PostLinkService } from '../../shared/services/post-link.service';
import { PostImageService } from '../../shared/services/post-image.service';
import { PostPollingService } from '../../shared/services/post-polling.service';
import { ModalService } from '@app/ui/modals/shared/services/modal.service';
import { LayoutConfigService } from '@app/layout-config/shared/services/layout-config.service';

import { PostAction, PostActionType } from '../../shared/models/post-action';
import { PostLink } from '../../shared/models/post-link';
import { PostText } from '../../shared/models/post-text';
import { PostImage } from '../../shared/models/post-image';
import { PostVideo } from '../../shared/models/post-video';
import { PostPolling } from '../../shared/models/post-polling';

@Component({
    selector: 'app-create-post-page',
    templateUrl: './create-post-page.component.html',
    styleUrls: ['./create-post-page.component.scss']
})
export class CreatePostPageComponent implements OnInit, OnDestroy {

    private subscriptions = new SubSink();

    public selectedTopicId: number | undefined = undefined;

    // Flag para mostrar/ocultar los Anuncios.
    public showAdTypeC: boolean = true;
    
    constructor(
        private readonly router: Router,
        private readonly currentRoute: ActivatedRoute,
        private readonly postCommunicator: PostCommunicatorService,
        private readonly postText: PostTextService,
        private readonly postLink: PostLinkService,
        private readonly postImage: PostImageService,
        private readonly postPolling: PostPollingService, 
        private readonly modal: ModalService,
        private readonly layoutConfig: LayoutConfigService
    ) {

        // Establecemos la configuración inicial de la barra de navegación y del footer.
        layoutConfig.setLayoutConfig({
            layout: {
                navbar: { visible: true },
                footer: { visible: false }
            }
        });
    }

    ngOnInit(): void {

        // Cuando cargamos la Página de Crear Publicación, suceden 2 cosas:
        
        // 1. En caso de que venga el id de un Topic en la url como Párametro Query, lo leemos..
        const incomingTopicId: string | null = this.currentRoute.snapshot.queryParamMap.get('topic');
        this.selectedTopicId =  incomingTopicId ?  +incomingTopicId : undefined;

        // 2. Cargamos el listener que se encarga de escuchar que Publicación desea el usuario publicar.
        this.subscriptions.sink = this.postCommunicator.incomingAction$.subscribe((post) => {

            this.createPost(post);
        });
    }

    ngOnDestroy(): void {

        this.subscriptions.unsubscribe();
    }

    public onSelectTopic(topicId: number | undefined): void {

        this.selectedTopicId = topicId;
    }

    private createPost(post: PostAction): void {
        
        switch (post.action) {

            case PostActionType.CreateLinkPost: 
                
                this.createLinkPost(post.payload);
            break;

            case PostActionType.CreateTextPost: 
                
                this.createTextPost(post.payload);
            break;

            case PostActionType.CreateImagePost: 

                this.createImagePost(post.payload);
            break;

            case PostActionType.CreateVideoPost: 

                this.createVideoPost(post.payload);
            break;

            case PostActionType.CreatePolllingPost:

                this.createPollingPost(post.payload);
            break;
        }
    }

    private createLinkPost(post: Partial<PostLink>): void {
        
        let postId: number | undefined = undefined;

        // Agregamos a la publicación el topicId seleccionado.
        const postLink: Partial<PostLink> = { ...post, topicId: this.selectedTopicId };

        // Creamos el post.
        this.subscriptions.sink = this.postLink.create(postLink)
            .pipe(
                tap((post) => {

                    // Recibimos la información del estado de la Publicación que se intentó publicar.
                    postId = post.payload?.postId;

                    // Notificamos al panel de Publicación de Link sobre lo que se intentó publicar.
                    this.postCommunicator.notifyBackActionStatus(post);
                }),
                concatMap((post) => {

                    return (post.state === 'Published') ? 
                        this.modal.successDialog('Tu Post fue publicado').onClose : 
                        this.modal.failureDialog('Ocurrió un error, intentelo de nuevo').onClose;
                })
            )
            .subscribe(() => {

                // En caso de que tengamos el identificador de una Publicación.
                if ( postId ) {

                    // Manejamos la respuesta exítosa.
                    this.handleSuccesfulPublishing(postId);
                }
            });
    }
    
    private createTextPost(post: Partial<PostText>): void {

        let postId: number | undefined = undefined;

        // Agregamos a la publicación el topicId seleccionado.
        const postText: Partial<PostText> = { ...post, topicId: this.selectedTopicId };

        // Creamos el post.
        this.subscriptions.sink = this.postText.create(postText)
            .pipe(
                tap((post) => {

                    // Recibimos la información del estado de la Publicación que se intentó publicar.
                    postId = post.payload?.postId;

                    // Notificamos al panel de Publicación de Texto sobre lo que se intentó publicar.
                    this.postCommunicator.notifyBackActionStatus(post);
                }),
                concatMap((post) => {

                    return (post.state === 'Published') ? 
                        this.modal.successDialog('Tu Post fue publicado').onClose : 
                        this.modal.failureDialog('Ocurrió un error, intentelo de nuevo').onClose;
                })
            )
            .subscribe(() => {
               
                // En caso de que tengamos el identificador de una Publicación.
                if ( postId ) {
                    
                    // Manejamos la respuesta exítosa.
                    this.handleSuccesfulPublishing(postId) 
                }
            });
    }

    private createImagePost(post: Partial<PostImage>): void {

        let postId: number | undefined = undefined;

        // Agregamos a la publicación el topicId seleccionado.
        const postImage: Partial<PostImage> = { ...post, topicId: this.selectedTopicId };

        // Creamos el post.
        this.subscriptions.sink = this.postImage.create(postImage)
            .pipe(
                tap((post) => {

                    postId = post.payload?.postId;

                    // Notifica al panel de que hay una respuesta sobre un post publicado.       
                    this.postCommunicator.notifyBackActionStatus(post);
                }),
                concatMap(() => {

                    return this.modal.successDialog('Tu Post fue publicado').onClose;
                })
            ).subscribe(() => {

                this.handleSuccesfulPublishing(postId);
            });
    }

    private createVideoPost(post: Partial<PostVideo>): void {
        
        let postId: number | undefined = undefined;

        // Enviamos la Publicación de Video de nuestro interés al responsable correspondiente.
        const videoPost: Partial<PostVideo> = { ...post, topicId: this.selectedTopicId };

        this.subscriptions.sink = this.modal.publishPostVideoDialog('CreationMode', videoPost).onClose
            .pipe(
                tap((post) => {

                    postId = post?.payload?.postId;

                    // NOTA: En una Publicación de Video realmente no es necesario notificar el estado de la publicación a su panel 
                    // y formulario asociado. Lo mejor es omitir la comunicación del lado del panel no hay nadie escuchando.
                })
            )
            .subscribe(() => {

                if ( postId ) {

                    this.handleSuccesfulPublishing(postId);
                }
            });
    }

    private createPollingPost(post: Partial<PostPolling>): void {
        
        let postId: number | undefined = undefined;

        // Agregamos a la publicación el topicId seleccionado.
        const pollingPost: Partial<PostPolling> = { ...post, topicId: this.selectedTopicId };

        // Creamos el post.
        this.subscriptions.sink = this.postPolling.create(pollingPost)
            .pipe(
                tap((post) => {

                    postId = post.payload?.postId;

                    // Notifica al panel de que hay una respuesta sobre un post publicado.       
                    this.postCommunicator.notifyBackActionStatus(post);
                }),
                concatMap(() => {

                    return this.modal.successDialog('Tu Post fue publicado').onClose;
                })
            ).subscribe(() => {

                this.handleSuccesfulPublishing(postId);
            });
    }

    private handleSuccesfulPublishing(postId: number | undefined): void {

        this.router.navigate(['community/post', postId ]);
    }
}
