import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';

import { Observable, of } from 'rxjs';
import { map, catchError } from 'rxjs/operators';

import { environment } from '@env/environment';

export interface PostModificationPermission {
    code:       number;
    success:    boolean;
    message:    string;
    content: {
        type_post_id:   number;
        currentUser: {
            isOwner:    boolean;
        }
    }
};

const POLLING_POST = 5;

@Injectable({
    providedIn: 'root'
})
export class VerifyPostModificationPermissionGuard implements CanActivate {

    constructor(
        private readonly router: Router,
        private readonly httpClient: HttpClient
    ) {}
    
    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<true | UrlTree> | true | UrlTree {

        const postId: string | null = route.paramMap.get('id');

        if ( !postId ) {

            return this.router.createUrlTree(['/404']);
        }

        if ( isNaN(+postId) ) {

            return this.router.createUrlTree(['/404']);
        }

        return this.isPostEditable(+postId);
    }
  
    private isPostEditable(postId: number): Observable<true | UrlTree> {

        // Una publicación NO es editable en los siguientes casos:
        // 1. La publicación NO nos pertenece.
        // 2. Es una publicación de Votación.
        // 3. TODO: ¿Qué pasa si la Publicación NO Existe? redireccionar a página 404
        return this.httpClient.get<PostModificationPermission>(`${ environment.host }/api/posts/${ postId }`)
            .pipe(
                map((postModificationPermission) => {

                    const { content } = postModificationPermission;
                    const { type_post_id, currentUser } = content;

                    if ( !currentUser.isOwner ) {

                        return this.router.createUrlTree(['/403']);
                    }

                    if ( type_post_id === POLLING_POST ) {

                        return this.router.createUrlTree(['/404']);                    
                    }

                    return true;
                }),
                catchError((err: HttpErrorResponse) => {

                    return of( this.router.createUrlTree(['/404']));
                })
            );
    }
}
