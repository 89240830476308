import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
    selector: 'app-selected-image-content-area',
    templateUrl: './selected-image-content-area.component.html',
    styleUrls: ['./selected-image-content-area.component.scss']
})
export class SelectedImageContentAreaComponent implements OnInit {

    // Acepta la imagen ya sea venga codificada en base64 o sea simplemente una url.
    @Input() image: string = '';
    // Comunica  que el usuario ha borrado la imagen preseleccionada.
    @Output() imageCleared: EventEmitter<void> = new EventEmitter<void>();

    constructor() { }

    ngOnInit(): void {}

    public onClearImage():void {

        this.imageCleared.emit();
    }
}
