import { Injectable } from '@angular/core';

import { Router, CanActivate, CanActivateChild, UrlTree, ActivatedRouteSnapshot, RouterStateSnapshot, NavigationExtras } from '@angular/router';
import { TeamPreferenceService } from '../../shared/services/team-preference.service';

@Injectable({
  providedIn: 'root'
})
export class VerifyTeamPreferenceService implements CanActivate, CanActivateChild {

    constructor(
        private router: Router,
        private teamPreferenceService: TeamPreferenceService
    ) {}
    
    canActivate(route: ActivatedRouteSnapshot, routerState: RouterStateSnapshot): boolean | UrlTree {

        if ( !this.teamPreferenceService.hasTeamAssociated() ) {
            
            const intendedUrl: string = routerState.url;
            const navigationExtras: NavigationExtras = {
                // Si la url a la que se pretende ir es la raíz, no tiene caso crear una url de retorno.
                // La funcion btoa() nos permite codificar un string a base64
                queryParams: (intendedUrl === '/') ? {} : { intendedUrl: btoa(intendedUrl) }
            };

            return this.router.createUrlTree(['/team-preference'], navigationExtras);
        }

        return true;
    }

    canActivateChild(route: ActivatedRouteSnapshot, routerState: RouterStateSnapshot): boolean | UrlTree {
        
        return this.canActivate(route, routerState);
    }
}
