  <div class="news-page scrolling-area"
     appScrollableToBeginning
     infiniteScroll
     [infiniteScrollDistance]="3"
     [infiniteScrollThrottle]="150"
     (scrolled)="lazyLoadMoreNews()"
     [scrollWindow]="false">
  <div class="main-grid">


    <div class="grid-left">
      <div class="controls">
        <button (click)="changeNewsSelected('team')" class="{{newsSelected === 'team' ? 'active' : ''}}">Noticias <b>Equipo
          Favorito</b></button>
        <button (click)="changeNewsSelected('all')" class="{{newsSelected === 'all' ? 'active' : ''}}">Noticias <b>Generales</b>
        </button>
      </div>

      <ng-container *ngIf="firstPageFromPaginatorIsLoaded; else showLoadingIndicator">
        <!-- Este es el nuevo Scroller -->
        <!-- Voy a tener que poner aca el carousel para que el scroll se sienta natural -->
        <div class="header-news">
          <ng-container *ngIf="topFiveNewsLoaded; else loadingTopFiveNews">
            <p-carousel [value]="topFiveNews" [numVisible]=1 [autoplayInterval]="5000" [circular]="true">
              <ng-template let-topNew pTemplate="item">
                <app-new-slide
                  [id]="topNew.id"
                  [title]="topNew.title"
                  [urlImage]="topNew.urlImage"
                  [url]="topNew.url"
                  avatarDomain="{{topNew.favicon}}"
                  [hostDomain]="topNew.hostDomain"
                  [date]="topNew.date"></app-new-slide>
              </ng-template>
            </p-carousel>
          </ng-container>

          <ng-template #loadingTopFiveNews>
            <div style="text-align: center;">
              <i class="pi pi-spin pi-spinner spinner spinner-lg"></i>
            </div>
          </ng-template>
        </div>


        <!-- Estas son las noticias que se van cargando -->
        <div *ngFor="let item of news">
          <ng-container
            [ngTemplateOutlet]="item.type === 'new' ? showNew : showAd"
            [ngTemplateOutletContext]="{ $implicit: item }"></ng-container>
        </div>

        <!-- Este es el botón que nos va a llevar al inicio una vez hemos alcanzado el final del Scroller -->
        <div style="text-align: center; background-color: green;">
          <button type="button" (click)="gotoFirstPage()">Ir al inicio</button>
        </div>

        <ng-template #showNew let-new>
          <!-- Es la noticia en si -->
          <app-new
            [id]="new.id"
            [title]="new.title"
            [urlImage]="new.urlImage"
            [url]="new.url"
            avatarDomain="{{new.favicon}}"
            [hostDomain]="new.hostDomain"
            [date]="new.date"></app-new>
        </ng-template>

        <ng-template #showAd let-ad>
          <!-- Es el anunció, esto luego se va a extraer a su propio Módulo, es opcional si quieres maquetarlo -->
          <app-advertisement-type-a *ngIf="showAdTypeA" (loaded)="showAdTypeA = $event"></app-advertisement-type-a>
        </ng-template>
      </ng-container>

      <ng-template #showLoadingIndicator>
        <!-- Este spinner se muestra cuando se cargan inicialmente las noticias en el scroller. -->
        <div style="text-align: center; margin-top: 40px">
          <i class="pi pi-spin pi-spinner spinner spinner-lg"></i>
        </div>
      </ng-template>

      <ng-container *ngIf="loadingMoreNews">
        <div style="text-align: center; margin: 20px auto">
          <i class="pi pi-spin pi-spinner spinner spinner-md"></i>
        </div>
      </ng-container>
    </div>
    <div class="grid-right pt-50">
      <div class="item">
        <app-advertisement-type-d *ngIf="showAdTypeD" (loaded)="showAdTypeD = $event"></app-advertisement-type-d>
      </div>
      <div class="item">
        <app-advertisement-type-c *ngIf="showAdTypeC" (loaded)="showAdTypeC = $event"></app-advertisement-type-c>
      </div>
    </div>

  </div>
</div>
