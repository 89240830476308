import { Injectable } from '@angular/core';
import { Router, CanActivate } from '@angular/router';

import { TeamPreferenceService } from '../../shared/services/team-preference.service';

@Injectable({
    providedIn: 'root'
})
export class RedirectIfTeamSelectedService implements CanActivate {

    constructor(
        private router: Router,
        private teamPreferenceService: TeamPreferenceService
    ) { }

    canActivate(): boolean {

        if ( this.teamPreferenceService.hasTeamAssociated() ) {

            this.router.navigateByUrl('/');
            return false;
        }

        return true;
    }
}
