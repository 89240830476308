import { Injectable, ErrorHandler, Injector, NgZone } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { Router } from  '@angular/router';

import { AuthService } from '@app/auth/shared/services/auth.service';

import { NotifierService } from '../services/notifier.service';
import { LoggerService } from '../services/logger.service';

// Este Manejador de Errores Global es nuestra última linea de batalla para atrapar cualquier error 
// que no haya sido previamente manejado durante nuestra aplicación.
@Injectable()
export class LigamxErrorHandlerService implements ErrorHandler {

    constructor( private injector:Injector ) { }

    handleError( error:Error | HttpErrorResponse | any):void {
        
        // Obten una instancia del Enrutador.
        const router = this.injector.get(Router);
        const ngZone = this.injector.get(NgZone);

        // Auth Service.
        const auth = this.injector.get(AuthService);
        // Logger Service.        
        const logger = this.injector.get(LoggerService);
        // Notifier Service.
        const notifier = this.injector.get(NotifierService);

        if ( error instanceof HttpErrorResponse ) {
            // Server Error happened.
            
            // Checa primero si hay conexión a Internet.
            if ( !navigator.onLine ) {

                // Posibles causas:
                // 1. No ha conexión a internet.
                ngZone.run( () => router.navigateByUrl('/offline') );
            
            } else {
            // Existe conexión a Internet.

                // Inspecciona la propiedad error de HttpErrorResponse para determinar el tipo de error producido
                // y manejarlo acorde.
                const httpError:any = error.error;
                
                if ( httpError instanceof ErrorEvent ) {
                    
                    // Unkown Errors.

                    // Client Error happened due to a possible error connection: Network not available, extended latency, timeout, etc.
                    // Algo ocurrió en el lado del Cliente, tal vez un error en la red que evita que la petición se 
                    // complete de forma exitosa, o tal vez se generó una excepción lanzada por un Operador RxJS.
                    // Cualquiera de estos errores genera un objeto de tipo ErrorEvent.
                    
                    console.error(`Client Side error (possible error connection): ${ JSON.stringify(error,null, 4) }`);
                    
                } else {
                    // Common (Known) Errors.

                    // Server Error happened due to a request rejection. Possible causes: 
                    // Unauthorized (401)
                    // Forbidden (403)
                    // Not Found (404)
                    // Internal Server Error (500), etc.
                    const httpErrorCode:number = error.status;

                    switch( httpErrorCode ) {
                        
                        case 0:
                        // Posibles causas: 
                        // 1. El error con código 0, status: Unkown Error, ocurre cuando el Servidor esta fuera de linea.
                            ngZone.run( () => router.navigateByUrl('/500') );
                        break;

                        case 403:
                            // Posibles causas:
                            // 1. No tenemos acceso a un recurso que hemos pedido.
                            ngZone.run( () => router.navigateByUrl('/403') );
                        break;

                        case 404:
                            // Posibles causas:
                            // 1. La url del endpoint esta mal escrita.
                            // 2. El recurso de nuestro interes no se encuentra, por lo tanto, es posible que el 
                            //    Servidor nos responda con un código 404.
                            ngZone.run( () => router.navigateByUrl('/404') );
                        break;

                        case 500:
                            // Posibles causas:
                            // 1. El servidor respondió con un código 500.
                            ngZone.run( () => router.navigateByUrl('/500') );
                        break;

                        default:
                            // El Servidor retornó un código de error que no esperabamos.
                            
                            // TODO: Envia el error al Servidor de Logs para verificar que sucedió.
                            
                            // Redirecciona al usuario a la página de errores.
                            ngZone.run( () => router.navigateByUrl('/error') );
                        break;
                    }
                    
                    console.error(`Backend Side error: statusCode ${error.status}, ` + `body: ${ JSON.stringify(error,null, 4) }`);
                }
            }

        } else {
            // Unkown Errors.

            // Client Error happened due to a possible Bug.
            // Dada su naturaleza, la respuesta producida por los los errores generados en el Cliente debe ser diferente.
            // Lo mejor es redireccionar al usuario a la Página de Errores.
            // Este error producido deriva de potencial Bug que rompe nuestra aplicación.

            // Cuando intentamos autentificarnos por redes sociales y el usuario cierra el popup sin seguir el 
            // proceso de autentificación, se lanzan las siguientes excepciones con los códigos de error siguiente:
            // Atrapa el error generado por el login de redes sociales cuando el usuario cierra el popup.
            if ( error.code && (error.code === 'auth/popup-closed-by-user' || error.code === 'auth/cancelled-popup-request') ) {

                ngZone.run( () => router.navigateByUrl('/login') );    

            } else  {
             
                // TODO: Envia el error al Servidor de Logs para verificar que sucedió.
                // Redirecciona al usuario a la página de errores.
                ngZone.run( () => router.navigateByUrl('/error') );
            }

            console.error(`Client Side error (possible bug error): ${ JSON.stringify(error,null, 4) }`);
        }

        // Always log the error.
        console.error('Shit always happens: ', error);
    }
}
