import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { MenuModule } from 'primeng/menu';
import { MenubarModule } from 'primeng/menubar';
import { EditorModule } from 'primeng/editor';
import { SplitButtonModule } from 'primeng/splitbutton';

// UI Module.
import { LinkPreviewModule } from '@app/ui/link-preview/link-preview.module';
import { TopicModule } from '@app/ui/topic/topic.module';
import { PostModule } from '@app/ui/post/post.module';
import { BannerCommunityModule } from '@app/ui/banner-community/banner-community.module';

import { ComunidadRoutingModule } from './comunidad-routing.module';
import { ScrollableToBeginningDirective } from './directives/scrollable-to-beginning.directive';

// Pages.
import { ComunidadLandingComponent } from './pages/comunidad-landing/comunidad-landing.component';
import { ComunidadPostDetailComponent } from './pages/comunidad-post-detail/comunidad-post-detail.component';
import { ComunidadTopicComponent } from './pages/comunidad-topic/comunidad-topic.component';

// Panels.
import { CommunityHomePanelComponent } from './components/community-home-panel/community-home-panel.component';
import { CommunityFavoriteTeamPanelComponent } from './components/community-favorite-team-panel/community-favorite-team-panel.component';
import { CommunityFollowingPanelComponent } from './components/community-following-panel/community-following-panel.component';

import {ToastModule} from 'primeng/toast';
import {TopsCommunityModule} from '@app/ui/tops-community/tops-community.module';
import {TextEditorModule} from "@app/ui/text-editor/text-editor.module";
import {AdvertisementModule} from "@app/ui/advertisement/advertisement.module";

@NgModule({
  declarations: [
    ComunidadLandingComponent,
    ComunidadPostDetailComponent,
    ComunidadTopicComponent,
    CommunityHomePanelComponent,
    CommunityFavoriteTeamPanelComponent,
    CommunityFollowingPanelComponent,
    ScrollableToBeginningDirective,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    InfiniteScrollModule,
    MenuModule,
    MenubarModule,
    EditorModule,
    SplitButtonModule,
    LinkPreviewModule,
    TopicModule,
    PostModule,
    BannerCommunityModule,
    ComunidadRoutingModule,
    ToastModule,
    BannerCommunityModule,
    TopsCommunityModule,
    TextEditorModule,
    AdvertisementModule,
  ]
})
export class ComunidadModule { }
