import { Component, OnInit, Output, EventEmitter, Input} from '@angular/core';
import { FormGroup, FormBuilder, FormArray, Validators, FormControl } from '@angular/forms';

import { PostType } from '../../../shared/models/post-type';
import { PostPolling } from '../../../shared/models/post-polling';

interface PollingDuration {
    labelDurationDays: string;
    durationInDays: number;
}

@Component({
    selector: 'app-create-polling-post-form',
    templateUrl: './create-polling-post-form.component.html',
    styleUrls: ['./create-polling-post-form.component.scss']
})
export class CreatePollingPostFormComponent implements OnInit {
    
    @Input() public createPollingPostLoading: boolean = false;
    @Output() onCreate: EventEmitter<Partial<PostPolling>> = new EventEmitter<Partial<PostPolling>>();
    @Output() onCancel: EventEmitter<void> = new EventEmitter<void>();

    public createPollingPostForm!: FormGroup;
    public pollingDurations!: PollingDuration [];
    private maxNumberOfFieldsAllowed: number = 4;

    constructor(private fb: FormBuilder) { }

    ngOnInit(): void {

        this.pollingDurations = [
            { labelDurationDays: '1 Día', durationInDays: 1 },
            { labelDurationDays: '2 Días', durationInDays: 2 },
            { labelDurationDays: '3 Días', durationInDays: 3 },
        ];

        this.loadCreatePollingPostForm();
    }

    private loadCreatePollingPostForm(): void {

        this.createPollingPostForm = this.fb.group({
            description:    [ '', [ Validators.required, Validators.maxLength(400)] ],
            pollingOptions:     this.fb.array([ this.buildPollingOption(), this.buildPollingOption()  ]),
            duration:           [1]
        });
    }

    public onCreatePollingPost(): void {

        // En caso que el usuario de click al botón iniciar sin haber ingresado los datos que se le piden.
        this.createPollingPostForm.markAllAsTouched();

        // Una vez los datos del formulario sean correctos
        if ( this.createPollingPostForm.valid  ) {

            const postType: PostType = PostType.POLLING;
            const { description, pollingOptions, duration } = this.createPollingPostForm.value;

            this.onCreate.emit({ description, pollingOptions, duration, postType } as Partial<PostPolling>);
        }
    }

    public onCreatePollingPostCancellation(): void {

        this.onCancel.emit();
    }

    public addPollingOption(): void {
        
        // Siempre y cuando estemos dentro del máximo de campos requerido, podemos agregar nuevos.
        if ( this.pollingOptions.length < this.maxNumberOfFieldsAllowed ) {
        
            // Agrega más opciones al FormArray pollingOptions
            this.pollingOptions.push( this.buildPollingOption() );
        }
    }
    
    private buildPollingOption(): FormControl {

        // Crear los Validadores tanto del Control.
        return new FormControl('', [ Validators.required, Validators.maxLength(30) ]);        
    }

    get description() {

        return this.createPollingPostForm.get('description');
    }

    get duration() {

        return this.createPollingPostForm.get('duration');
    }

    get pollingOptions() {

        return this.createPollingPostForm.get('pollingOptions') as FormArray;
    }
}
