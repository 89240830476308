import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-new-min-slide',
  templateUrl: './new-min-slide.component.html',
  styleUrls: ['./new-min-slide.component.scss']
})
export class NewMinSlideComponent implements OnInit {

  @Input() id!: number;
  @Input() title: string = '';
  @Input() urlImage: string = '';
  @Input() url: string = '';
  @Input() avatarDomain: string = '';
  @Input() hostDomain: string = '';
  @Input() date!: Date;

  constructor() { }

  ngOnInit(): void {
  }
}
