import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, NavigationExtras, UrlTree } from '@angular/router';

import { AuthService } from '@app/auth/shared/services/auth.service';

@Injectable({
  providedIn: 'root'
})
export class RedirectWhenSharingPostGuard implements CanActivate {

    constructor(
        private router: Router,
        private authService: AuthService
    ) { }

    canActivate(route: ActivatedRouteSnapshot, routerState: RouterStateSnapshot): true | UrlTree {
        
        const intendedUrl: string = routerState.url;

        if ( !(this.authService.isAuthenticated()) ) {
            
            // Podemos activar la página donde se despliega el Preview de la Publicación.
            return true;
        }

        // En este punto, la aplicación nos reconoce como usuarios autentificados, por lo tanto, podemos 
        // ir al detalle del Post de nuestro interes.

        // Obtenemos el id del Post a partir de la Url de retorno.
        const postId: number = this.getPostId(intendedUrl);
        
        // Redireccionamos al Login con una url de retorno.
        return this.router.createUrlTree([`/community/post/${ postId }`]);
    }

    // Nos va a permitir trae obtener el id del Post a partir de la url de retorno.
    // Url de prueba: /post/share/264
    private getPostId(url: string): number {

        const urlParts: string[] = url.split('/');
        // En la tercera posición se encuentra el id del post, esto es por que cuando hacemo split obtenemos lo siguiente.
        // ["", "post", "share", "264"]
        return +(urlParts[3] || 0);
    }
}
