import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, ActivatedRouteSnapshot, NavigationExtras, Router} from '@angular/router';
import {SubSink} from 'subsink';
import {Meta, Title} from '@angular/platform-browser';
import {LayoutConfigService} from '@app/layout-config/shared/services/layout-config.service';
import {PostService} from '../../shared/services/post.service';
import {PostMedia} from '@app/ui/post/shared/models/post-media';
import {PostPolling} from '@app/ui/post/shared/models/post-polling';

@Component({
  selector: 'app-post-preview-page',
  templateUrl: './post-preview-page.component.html',
  styleUrls: ['./post-preview-page.component.scss']
})
export class PostPreviewPageComponent implements OnInit, OnDestroy {

  private postId: number = 0;
  private subscriptions = new SubSink();
  public postLoaded: boolean = false;

  public postType: number = 0;
  public postTitle = '';
  public postUser = '';
  public postMedia?: PostMedia;
  public postPolling?: PostPolling;

  constructor(
    private readonly router: Router,
    private readonly currentRoute: ActivatedRoute,
    private readonly layoutConfig: LayoutConfigService,
    private postService: PostService,
    private readonly metaTagService: Meta,
    private readonly titleService: Title,

  ) {

    layoutConfig.setLayoutConfig({
      layout: {
        navbar: {visible: false},
        footer: {visible: false}
      }
    });
  }

  ngOnInit(): void {
    // Obtenemos el id de la Publicación a partir de la Url.
    const routeSnapshot: ActivatedRouteSnapshot = this.currentRoute.snapshot;
    this.postId = +(routeSnapshot.paramMap.get('id') || 0);
    this.loadPostDetail(this.postId);

  }

  public loadPostDetail(postId: number): void {
    this.subscriptions.sink = this.postService.getPostById(postId).subscribe({
      next: (post) => {
        this.postType = post.type;
        if (post.type !== 5) {
          this.postMedia = post as PostMedia;
        } else {
          this.postPolling = post as PostPolling;
        }
      },
      complete: () => {
        this.postLoaded = true;
        if (this.postType !== 5) {
          this.metaTagService.updateTag(
            {'og:image': this.postMedia?.media.content},
          );
        }
      }
    });
  }

  public gotoLogin(): void {

    // Definimos manualmente la url de retorno, en este caso será la url del detalle del Post que se encuentra en Comunidad.
    const intendedUrl: string = `/community/post/${this.postId}`;
    // Construimos la navegación con la url de retorno codificada en base64
    const navigationExtras: NavigationExtras = {
      // La funcion btoa() nos permite codificar un string a base64
      queryParams: {intendedUrl: btoa(intendedUrl)}
    };

    // Redireccionamos al login con laa url de retorno.
    this.router.navigate(['/login'], navigationExtras);
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
