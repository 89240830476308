<div class="single-post-grid main-grid">


  <div class="left grid-left">

    <!-- breadcrumb -->
    <div class="back">
      <a [routerLink]="['/community']">Comunidad</a><span class="separator">/</span><a [routerLink]="['/community/topic', topicId]">{{ topicTitle }}</a>
    </div>

    <div class="post-container">
      <!--Post-->

      <div class="post" *ngIf="postLoaded; else loadingPost">
        <ng-container [ngSwitch]="postType">
          <!-- Post de Votación -->
          <ng-container *ngSwitchCase="5"
                        [ngTemplateOutlet]="postPollingTemplate"
                        [ngTemplateOutletContext]="{ $implicit: postPolling }"></ng-container>

          <!-- Post de Contenido Media -->
          <ng-container *ngSwitchDefault
                        [ngTemplateOutlet]="postMediaTemplate"
                        [ngTemplateOutletContext]="{ $implicit: postMedia}"></ng-container>
        </ng-container>

        <ng-template #postMediaTemplate>
          <app-post-media-expanded [post]="postMedia!" [showTopicAsPostHeader]="true"
                                   (onBookmark)="onBookmarkPost($event)">
            <!-- Aqui incluimos el contenido Media dependiendo del tipo de Post: texto, link, imagen o video -->

            <!-- Es un Post de Link? -->
            <!-- Link Preview content goes here -->
            <app-link-preview
              *ngIf="postMedia!.type === 2"
              [originalLinkSource]="postMedia!.media.content.originalLinkSource"
              [metaTags]="postMedia!.media.content.metaTags"></app-link-preview>

            <!-- Es un Post de Imagen? -->
            <!-- Image content goes here -->
            <img
              *ngIf="postMedia!.type === 3"
              [src]="postMedia!.media.content"
              [alt]="postMedia!.description">

            <!-- Es un Post de Video? -->
            <!-- Video content goes here -->
            <!-- Todavia no tenemos post de video para renderizar -->
            <video
              *ngIf="postMedia!.type === 4"
              width="100%"
              height="360px"
              controls>
              <source type="video/mp4" [src]="postMedia!.media.content">
            </video>
            <!-- Es un Post de Texto? -->
            <!-- Un Post de Texto no posee ningún tipo de contenido media -->
          </app-post-media-expanded>
        </ng-template>

        <ng-template #postPollingTemplate>
          <!-- Es un Post de Votaciones? -->
          <!-- Todavia no tenemos post de votaciones para renderizar -->
          <app-post-polling [post]="postPolling!"></app-post-polling>
        </ng-template>
      </div>


      <ng-template #loadingPost>
        <!-- Este spinner se muestra cuando se cargan inicialmente las noticias en el scroller. -->
        <div class="loading">
          <i class="pi pi-spin pi-spinner spinner spinner-lg"></i>
        </div>
      </ng-template>

      <!--Comentarios-->

      <ng-template [ngIf]="postType !== 5">

        <div class="comments-container" id="comments" *ngIf="commentsLoaded; else loadingComments">

          <form [formGroup]="commentForm" class="make-comment" (ngSubmit)="makeComment()">
            <p>Comentar como <span>{{userName}}</span></p>
            <input type="hidden" formControlName="id">
            <app-text-editor
              placeholder="Comentar..."
              [parent]="commentForm"
              controlName="message"
              [model]="commentText">
            </app-text-editor>
            <button type="submit">Comentar ></button>
          </form>

          <div class="comments" *ngIf="comments.length > 0; else noComments">
            <h3>Comentarios</h3>
            <div *ngFor="let comment of comments" class="comment">
              <div class="parent">
                <div class="content">
                  <div class="user">
                    <div class="img">
                      <img src="{{comment.user.picture}}" alt="Usuario">
                      <img src="{{comment.user.fanLevel.icon}}" alt="{{comment.user.fanLevel.name}}">
                    </div>
                    <p>{{comment.user.name}} <span>•</span> {{comment.dateDifference}}</p>
                    <div class="edit-delete" *ngIf="userId === comment.user.id">
                      <p-menu [popup]="true" #menu [model]="commentMenu"
                              (onShow)="getMenuComment(comment.id, comment.message)"></p-menu>
                      <button type="button" pButton class="open-menu" label="..."
                              (click)="menu.toggle($event)"></button>
                    </div>
                  </div>
                  <div class="main" *ngIf="idToEdit !== comment.id" [innerHTML]="comment.message"></div>
                  <div class="main" *ngIf="idToEdit === comment.id">
                    <form [formGroup]="commentEditForm" class="edit-comment" (ngSubmit)="editComment()">
                      <input type="hidden" formControlName="id" [value]="comment.id">
                      <app-text-editor
                        [parent]="commentEditForm"
                        controlName="message"
                        [model]="commentEditText">
                      </app-text-editor>
                      <div class="buttons">
                        <button class="cancel" (click)="idToEdit = 0" type="button">Cancelar</button>
                        <button type="submit">Editar</button>
                      </div>
                    </form>
                  </div>
                  <div class="controls">
                    <button (click)="replyComment(comment.id)"><img src="assets/iconos/icono_responder.svg"
                                                                    alt="Responder">Responder
                    </button>
                    <button (click)="vote('like', comment.id)">{{comment.totalVotesUp}} <img
                      src="assets/iconos/icono_upvote.svg" alt="Positivos"></button>
                    <button (click)="vote('dislike', comment.id)">{{comment.totalVotesDown}} <img
                      src="assets/iconos/icono_downvote.svg" alt="Negativos"></button>
                  </div>
                </div>
                <div class="reply-comment" *ngIf="comment.id == idToReply">
                  <form [formGroup]="commentReplyForm" class="make-comment" (ngSubmit)="makeReplyComment()">
                    <input type="hidden" formControlName="id" [value]="comment.id">
                    <app-text-editor
                      placeholder="Contestar..."
                      [parent]="commentReplyForm"
                      controlName="message"
                      [model]="commentReplyText">
                    </app-text-editor>
                    <button type="submit">Comentar</button>
                  </form>
                </div>
                <div *ngFor="let childComment of comment.inner_comments" class="child">
                  <div class="content">
                    <div class="user">
                      <div class="img">
                        <img src="{{childComment.user.picture}}" alt="Usuario">
                        <img src="{{childComment.user.fanLevel.icon}}" alt="{{childComment.user.fanLevel.name}}">
                      </div>
                      <!-- TODO No se como meter la diferencia en las respuestas -->
                      <p>{{childComment.user.name}} <span>•</span> {{childComment.dateDifference}}</p>
                      <div class="edit-delete" *ngIf="userId === childComment.user.id">
                        <p-menu [popup]="true" #menu [model]="commentMenu"
                                (onShow)="getMenuComment(childComment.id, childComment.message)"></p-menu>
                        <button type="button" pButton class="open-menu" label="..."
                                (click)="menu.toggle($event)"></button>
                      </div>
                    </div>
                    <div class="main" *ngIf="idToEdit !== childComment.id" [innerHTML]="childComment.message"></div>
                    <div class="main" *ngIf="idToEdit === childComment.id">
                      <form [formGroup]="commentEditForm" class="edit-comment" (ngSubmit)="editComment()">
                        <input type="hidden" formControlName="id" [value]="childComment.id">
                        <app-text-editor
                          [parent]="commentEditForm"
                          controlName="message"
                          [model]="commentEditText">
                        </app-text-editor>
                        <div class="buttons">
                          <button class="cancel" (click)="idToEdit = 0" type="button">Cancelar</button>
                          <button type="submit">Editar</button>
                        </div>
                      </form>
                    </div>
                    <div class="controls">
                      <button (click)="vote('like', childComment.id)">{{childComment.totalVotesUp}} <img
                        src="assets/iconos/icono_upvote.svg" alt="Positivos"></button>
                      <button (click)="vote('dislike', childComment.id)">{{childComment.totalVotesDown}} <img
                        src="assets/iconos/icono_downvote.svg" alt="Negativos"></button>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>

          <ng-template #noComments>
            <p class="empty">Sé el primero en comentar</p>
          </ng-template>

        </div>

      </ng-template>


      <ng-template #loadingComments>
        <!-- Este spinner se muestra cuando se cargan inicialmente las noticias en el scroller. -->
        <div class="loading" *ngIf="postLoaded">
          <i class="pi pi-spin pi-spinner spinner spinner-lg"></i>
        </div>
      </ng-template>

    </div>

  </div>

  <div class="grid-right">
    <app-banner-community></app-banner-community>
    <app-recommended-topic-list></app-recommended-topic-list>
    <app-advertisement-type-d *ngIf="showAdTypeD" (loaded)="showAdTypeD = $event"></app-advertisement-type-d>
  </div>

</div>


<p-toast position="top-left"></p-toast>
