import { AuthCredentials } from './auth-credentials';

export enum Provider {
    Facebook = 'facebook',
    Google = 'google',
    Apple = 'apple',
    Basic = 'basic'
};

export interface AuthProvider {
    provider:       Provider;
    credentials?:   AuthCredentials
}