import { Injectable } from '@angular/core';
import { Router, CanActivate } from '@angular/router';

import { AuthService } from '../services/auth.service';

@Injectable({
    providedIn: 'root'
})
export class RedirectIfAuthenticatedService implements CanActivate {

    constructor(
        private authService:AuthService,
        private router:Router
    ) { }
    
    // No tiene caso mostrar al usuario la sección de Inicio de Sesión una vez autenticado.
    canActivate():boolean {

        if ( this.authService.isAuthenticated() ) {
            
            this.router.navigateByUrl('/');
            return false;
        }

        return true;
    }
}
