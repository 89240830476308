import { Injectable } from '@angular/core';

import { PaginatorLandingAdvertisementIntercalationService } from '../paginator/paginator-landing-advertisement-intercalation.service';

import { PaginatorItem } from '../../models/paginator-item';
import { PaginatorItemType } from '../../models/paginator-item-type';
import { PaginatorItemNew } from '../../models/paginator-item-new';

import { NewResponse } from '../../models/responses/new-response';
import { New } from '../../models/new';

@Injectable({
    providedIn: 'root'
})
export class PaginatorLandingNewsMappingService {

    constructor(
        private readonly paginatorLandingAdvertisementIntercalation: PaginatorLandingAdvertisementIntercalationService
    ) { }

    /*
     * Mapeador para obtener las Noticias con Anuncios.
     */
    public getPaginatorItemNews(newsResponse: NewResponse[]): PaginatorItem {

        const newsItems = newsResponse
            .map((newResponse) => this.mapNew(newResponse))
            // Intercalamos los Anuncios.
            .reduce(this.paginatorLandingAdvertisementIntercalation.insertAdsInNews, [] as PaginatorItemNew[]);

        return {
            type: PaginatorItemType.News,
            item: newsItems
        } as PaginatorItem;
    }

    // This is the Entry Point to format a New.
    private mapNew(newResponse: NewResponse): New {

        const { id, url, title, image_local:urlImage, date, crawler } = newResponse;

        return {
            id,
            title,
            url,
            urlImage,
            date:       new Date(date),
            hostDomain: crawler.domain,
            favicon: crawler.favicon
        };
    }
}
