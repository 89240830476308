import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable, of, EMPTY } from 'rxjs';
import { map, exhaustMap, switchMap, catchError } from 'rxjs/operators';

import { AdvertisementResponse } from '../models/advertisement-response';
import { AdvertisementPayload } from '../models/advertisement-payload';
import { Advertisement } from '../models/advertisement';
import { AdvertisementType } from '../models/advertisement-type';

import { environment } from '@env/environment';

@Injectable()
export class AdvertisementService {

    constructor(
        private readonly httpClient: HttpClient
    ) { }

    // Al desplegar un anuncio, se espera que también haya una Costo por Impresión.
    public displayAd(adType: AdvertisementType): Observable<Advertisement | null> {

        return this.fetchAdvertisement(adType)
                .pipe(
                    exhaustMap((advertisement) => {
                        // Si el anuncio es null, resulve null, de lo contrario tenemos un anuncio que mostrar.
                        return advertisement ? this.addCMPActivity(advertisement) : of(null);
                    }),
                    catchError(() => {
                        // En este punto ocurren 2 cosas:
                        // 1. Probablemente no pudimos extraer el Anuncio.
                        // 2. Probablemente fallamos en agregar la Actividad CMP.
                        // En cualquier caso fallamos de forma silenciosa
                        return of(null);
                    })
                );
    }

    public clickOnAd(advertisement: Advertisement): Observable<never> {

        return this.addCPCActivity(advertisement)
                .pipe(
                    catchError(() => {
                        // En este punto solo puede ocurrir 1 cosa:
                        // 1. Probablemente no se pudo agregar la Actividad CPC.
                        // No nos interesa evaluar el error, en su lugar, solo lo atrapamos y fallamos de forma silenciosa.
                        return EMPTY;
                    })
                );
    }

    // Registra el Costo por Impresión (CPM)
    private addCMPActivity(advertisement: Advertisement): Observable<Advertisement> {

        const payload: AdvertisementPayload = {
            advertising_id: advertisement.id,
            action:         'CPM',
            'user-agent':   this.getUserAgent()
        };

        return this.addAdvertisementActivity(payload)
                .pipe(
                    switchMap(() => of(advertisement))
                );
    }

    // Registra el Costo por Click (CPC)
    private addCPCActivity(advertisement: Advertisement): Observable<never> {
        
        const payload: AdvertisementPayload = {
            advertising_id: advertisement.id,
            action:         'CPC',
            'user-agent':   this.getUserAgent()
        };

        return this.addAdvertisementActivity(payload);
    }

    private addAdvertisementActivity(payload: AdvertisementPayload): Observable<never> {

        return this.httpClient.post<never>(`${ environment.host }/api/advertising/addActivity/WEB`, { ...payload });
    }

    private fetchAdvertisement(adType: AdvertisementType): Observable<Advertisement | null> {
        
        return this.httpClient.get<AdvertisementResponse>(`${ environment.host }/api/advertising/list/filtered/WEB/${ adType }`)
            .pipe(
                map((advertisementResponse) => {
                    
                    const { content } = advertisementResponse;

                    if ( !content ) {

                        return null;
                    }

                    const { id, client_id, title, description, image, url, status } = content[ adType ];

                    return {
                        id,
                        title,
                        description,
                        isStillActive:      !!status,             
                        sponsorId:          client_id,
                        sponsorAdImage:     image,
                        sponsorHomePage:    url
                    };
                })
                // En caso de que haya un error al Extraer el Anuncio, este será atrapado y manejado directamente en el Suscriptor.
            ); 
    }

    private getUserAgent(): string {

        return (window && window.navigator && window.navigator.userAgent) || '';
    }
}