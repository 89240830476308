import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';

import { DynamicDialogModule } from 'primeng/dynamicdialog';
import { DialogService } from 'primeng/dynamicdialog';

import { BoardingTeamsModule } from '@app/ui/boarding-teams/boarding-teams.module';
import { ProgressBarModule } from 'primeng/progressbar';

import { PickATeamDialogComponent } from './components/pick-a-team-dialog/pick-a-team-dialog.component';
import { ChangeTeamPreferenceDialogComponent } from './components/change-team-preference-dialog/change-team-preference-dialog.component';
import { PasswordRecoveryComponent } from './components/password-recovery/password-recovery.component';
import { PasswordRecoveryFormComponent } from './components/password-recovery/password-recovery-form/password-recovery-form.component';
import { VideoProgressBarComponent } from './components/video-progress-bar/video-progress-bar.component';
import { AccountActivationDialogComponent } from './components/account-activation-dialog/account-activation-dialog.component';
import { AccountActivationFormComponent } from './components/account-activation-dialog/account-activation-form/account-activation-form.component';

@NgModule({
    declarations: [ 
        PickATeamDialogComponent, 
        ChangeTeamPreferenceDialogComponent, 
        PasswordRecoveryComponent, 
        PasswordRecoveryFormComponent, 
        VideoProgressBarComponent, 
        AccountActivationDialogComponent, 
        AccountActivationFormComponent 
    ],
    imports: [
        CommonModule,
        ReactiveFormsModule,
        DynamicDialogModule,
        ProgressBarModule,
        BoardingTeamsModule
    ],
    providers: [         
        DialogService,
    ]
})
export class ModalsModule { }
