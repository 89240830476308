// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  // Este es el de Test.
  // host: 'https://ligamxws.pekxel.com',
  // Este es el de Producción.
  host: 'https://serouno.api.kybel.mx',
  // Firebase configurations.
  firebase: {
    projectId:          'ligamx-77faf',
    appId:              '1:262611892847:web:32f7aff831e372eee3ab9a',
    apiKey:             'AIzaSyB44QOAnofNSWYoXb01Y39D-H9jokIaRkw',
    authDomain:         'ligamx-77faf.firebaseapp.com',
    storageBucket:      'ligamx-77faf.appspot.com',
    messagingSenderId:  '262611892847',
    measurementId:      'G-660WY4KYXS'
  },
  facebookAppId: '352602222733014'
};




/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
