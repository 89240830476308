<!-- Lista de reacciones seleccionadas -->
<div class="reactions-selected">
    <app-reactions-selected
        [reactions]="reactionsSelected"
        (onSelectedReaction)="onSelectReaction($event)"></app-reactions-selected>
</div>

<!-- Lista de reacciones disponibles a elegir -->
<div class="reactions-available">
    <button type="button" (click)="menu.toggle($event)">+</button>
    <p-menu #menu styleClass="reactions-menu" [popup]="true" appendTo="body" [model]="reactionsAvailableItems"></p-menu>
</div>
