import { Component, OnInit, Input, Output, EventEmitter, ChangeDetectionStrategy } from '@angular/core';

import { Reaction } from '../../../shared/models/post-media';

@Component({
  selector: 'app-reactions-selected',
  templateUrl: './reactions-selected.component.html',
  styleUrls: ['./reactions-selected.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ReactionsSelectedComponent implements OnInit {

    @Input('reactions') reactionsSelected!: Reaction[];
    @Output() onSelectedReaction: EventEmitter<Reaction> = new EventEmitter<Reaction>();

    constructor() { }

    ngOnInit(): void {
    }

    public onSelectReaction(reaction: Reaction): void {

        this.onSelectedReaction.emit(reaction);
    }
}
