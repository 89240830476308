import {Component, OnInit, OnDestroy} from '@angular/core';

import {SubSink} from 'subsink';
import {MessageService} from 'primeng/api';

import {switchMap, distinctUntilChanged} from 'rxjs/operators';
import {UserService} from '../../shared/services/user.service';
import {LayoutConfigService} from '@app/layout-config/shared/services/layout-config.service';
import {PaginatorItemPost} from '../../shared/models/paginator-item-post';
import {CommunityPaginatorService} from '../../shared/services/community-paginator.service';


@Component({
  selector: 'app-community-favorite-team-panel',
  templateUrl: './community-favorite-team-panel.component.html',
  styleUrls: ['./community-favorite-team-panel.component.scss']
})
export class CommunityFavoriteTeamPanelComponent implements OnInit, OnDestroy {

  constructor(
    private readonly toastMessage: MessageService,
    private readonly userService: UserService,
    private readonly communityPaginatorService: CommunityPaginatorService,
    private readonly layoutConfigService: LayoutConfigService
  ) {
    layoutConfigService.setLayoutConfig({
      layout: {
        navbar: {visible: true},
        footer: {visible: false}
      }
    });
  }

  private subscriptions = new SubSink();
  public paginatorItemPosts: PaginatorItemPost[] = [];
  public paginatorItemPostsPreviouslyLoaded: PaginatorItemPost[] = [];
  public loadingMorePosts: boolean = false;
  public initialLoadingDone: boolean = false;
  public userImg = '';

  // Flag para mostrar/ocultar los Anuncios.
  public showAdTypeB: boolean = true;
  public showAdTypeC: boolean = true;

  ngOnInit(): void {
    this.userImg = this.userService.getUserAvatar();
    this.initialLoadingDone = false;

    this.subscriptions.sink = this.communityPaginatorService.listenPagination$
      .pipe(
        distinctUntilChanged(),
        switchMap((page) => this.communityPaginatorService.getPosts(page, 'team'))
      )
      .subscribe((posts) => {

        const isFirstPage: boolean = ((this.communityPaginatorService.nextPage - 1) === 1);

        if (isFirstPage) {

          this.initialLoadingDone = true;
        }

        this.loadingMorePosts = false;
        this.paginatePosts(posts);
      });

    // Fire first Pagination Page.
    this.communityPaginatorService.paginate(1);
  }

  ngOnDestroy(): void {

    this.subscriptions.unsubscribe();
  }

  public onScroll(): void {

    if (this.communityPaginatorService.hasNotReachedPaginationEnd()) {

      const nextPage: number = this.communityPaginatorService.nextPage;

      this.loadingMorePosts = true;
      this.communityPaginatorService.paginate(nextPage);
    }
  }

  public onBookmarkPost(isFollowingPost: boolean): void {

    this.toastMessage.add({
        severity: isFollowingPost ? 'success' : 'info', 
        summary: 'Éxito', 
        detail: isFollowingPost ? 'Has guardado esta noticia.' : 'Has eliminado de tus guardados esta noticia.'
    });
  }
  
  private paginatePosts(posts: PaginatorItemPost[]): void {

    // Fusionamos los posts previamente cargadados con los posts que provengan del Servidor.
    this.paginatorItemPostsPreviouslyLoaded = [...this.paginatorItemPostsPreviouslyLoaded, ...posts];

    // Actualizamos la vista con la nueva información cargada en el Property Binding correspondiente.
    this.paginatorItemPosts = this.paginatorItemPostsPreviouslyLoaded;
  }

}
