<div class="main-grid">
  <div class="grid-left">
    <section class="section privacy">
      <h4 class="subtitle"><b>Términos y condiciones</b></h4>
      <p>
        <img src="assets/iconos/icono_politica.svg" alt="Politica de Privacidad">
        <!--<b></b>-->
      </p>
      <p><b>INFORMACIÓN RELEVANTE</b></p>
      <p>Es requisito necesario para la adquisición de los servicios que se ofrecen en este sitio, que lea y acepte los
        siguientes Términos y Condiciones que a continuación se redactan. El uso de nuestros servicios implicará que
        usted ha leído y aceptado los Términos y Condiciones de Uso en el presente documento. Todas los servicios que
        son ofrecidos por nuestro sitio web pudieran ser creadas, cobradas, enviadas o presentadas por una página web
        tercera y en tal caso estarían sujetas a sus propios Términos y Condiciones. En algunos casos, para adquirir un
        servicio, será necesario el registro por parte del usuario, con ingreso de datos personales fidedignos y
        definición de una contraseña.</p>
      <p>El usuario puede elegir y cambiar la clave para su acceso de administración de la cuenta en cualquier momento,
        en caso de que se haya registrado y que sea necesario para la solicitud de alguno de nuestros servicios. CANTON
        MERCHANDISING S.A. de C.V. no asume la responsabilidad en caso de que entregue dicha clave a terceros.</p>
      <p>Todas las interacciones y transacciones que se lleven a cabo por medio de este sitio web son libremente
        autorizadas por el usuario y serán almacenadas en nuestros registros, siendo así propiedad de CANTON
        MERCHANDISING S.A. DE C.V.</p>
      <p><b>USO NO AUTORIZADO</b></p>
      <p>Usted no puede colocar uno de nuestros productos, modificado o sin modificar, en un CD, sitio web o ningún otro
        medio y ofrecerlos para la redistribución o la reventa de ningún tipo.</p>
      <p><b>PROPIEDAD</b></p>
      <p>Usted no puede declarar propiedad intelectual o exclusiva a ninguno de nuestros productos, modificado o sin
        modificar. Todos los servicios son propiedad de los proveedores del contenido. En caso de que no se especifique
        lo contrario, nuestros servicios se proporcionan sin ningún tipo de garantía, expresa o implícita. En ningún
        momento esta compañía será responsable de ningún daño incluyendo, pero no limitado a, daños directos,
        indirectos, especiales, fortuitos o consecuentes u otras pérdidas resultantes del uso o de la imposibilidad de
        utilizar nuestros servicios.</p>
      <p><b>REEMBOLSO Y GARANTÍA</b></p>
      <p>Una vez concluido el servicio solicitado, no realizamos reembolsos después de que se envíen los resultados,
        usted tiene la responsabilidad de entender antes de solicitarlo. Le pedimos que lea cuidadosamente antes de
        solicitarlo. Hacemos solamente excepciones con esta regla cuando la descripción no se ajusta al servicio
        prestado. Hay algunos servicios que pudieran tener garantía y posibilidad de reembolso pero este será
        especificado al comprar el producto. En tales casos la garantía sólo se hará efectiva cuando el software se haya
        usado correctamente. La garantía no cubre averías o daños ocasionados por uso indebido. Los términos de la
        garantía están asociados a fallas de origen y funcionamiento en condiciones normales de los servicios y sólo se
        harán efectivos estos términos si el software ha sido usado correctamente.</p>
      <p><b>PRIVACIDAD</b></p>
      <p>CANTON MERCHANDISING S.A. DE C.V. garantiza que la información personal que usted envía cuenta con la seguridad
        necesaria. Puede revisar más a detalle en nuestra POLÍTICA DE PRIVACIDAD</p>
      <p>CANTON MERCHANDISING S.A. DE C.V. reserva los derechos de cambiar o de modificar estos términos sin previo
        aviso.</p>
    </section>
  </div>
</div>


