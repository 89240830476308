import { Injectable } from '@angular/core';

import { PostPollingPayload, OptionsPayload } from '../models/post-polling-payload';
import { PostPolling } from '../models/post-polling';

@Injectable({
	providedIn: 'root'
})
export class PostPollingMappingService {

	constructor() { }

	public mapPostPollingPayload(pollingPost: Partial<PostPolling>) {

        const payload: Partial<PostPollingPayload> = {};
        const { id, description, pollingOptions, duration, postType, topicId } = pollingPost;

        // Cuando estamos editando una Publicación de Encuesta es común que venga un identificador del mismo.
        // En caso de que haya un id ya fijado para este Post, esto es común cuando estamos editando un Post.
        if ( id ) {

            payload.id = id;
        }
        
		// Una Publicación de Encuesta puede tener o no tener un Topic asociado. Por lo común, si se esta creando 
        // una publicación sin un Topic asociado, ésta se considerará una Publicación asociada a un Topic General.
        if ( topicId ) {

            payload.topic_id = topicId;
        }
        
		// Cuando estamos editando una Publicación de Encuesta, NO debe venir el tipo de Publicación, este ya lo conoce 
        // la API de antemano a través del identificador del mismo.
        if ( postType ) {

            payload.type_post_id = postType;
        }
         
        // Mapeo de las opciones de la Encuesta.
        payload.option = pollingOptions?.reduce((state, polling, idx) => {
            
            return { ...state, [idx + 1]: polling };
       
        }, {} as OptionsPayload);

		// Mapeo del campo end_date de la Encuesta.
        if ( duration ) {
            
            payload.end_date = this.mapExpirationDate(duration);
        }

		payload.description = description?.trim();

        return payload;
    }

    private mapExpirationDate(duration: number): string {

        const today: Date = new Date();
        const expirationDate: Date = new Date(today.setDate(today.getDate() + duration));
        const normalizeDateFragment = (fragment: string) => (fragment.length === 1) ? ('0' +  fragment) : fragment;

        let year: string = expirationDate.getFullYear().toString();
        let month: string = (expirationDate.getMonth() + 1).toString();
        let day: string = expirationDate.getDate().toString();
        let hours: string = expirationDate.getHours().toString();
        let minutes: string = expirationDate.getMinutes().toString();
        
        month = normalizeDateFragment(month);
        day = normalizeDateFragment(day);
        hours = normalizeDateFragment(hours);
        minutes = normalizeDateFragment(minutes);

        return `${ year }-${ month }-${ day } ${ hours }:${ minutes }:00`;
    }
}
