export class DateDifference {
  static getDifferenceBetweenDates(afterDate: Date, beforeDate: Date): string {

    const after = new Date(afterDate);
    const before = new Date(beforeDate);

    const diff = Math.floor(after.getTime() - before.getTime());

    const second = 1000;
    let message: string = '';
    const seconds = Math.floor(diff / second);
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);
    const months = Math.floor(days / 31);
    const years = Math.floor(months / 12);

    if (years > 0) {
      message += years + ' años';
      message += (years > 1) ? 's' : '';
    } else if (months > 0) {
      message += months + ' mes';
      message += (months > 1) ? 'es' : '';
    } else if (days > 0) {
      message += days + ' día';
      message += (days > 1) ? 's' : '';
    } else if (hours > 0) {
      message += hours + ' hora';
      message += (hours > 1) ? 's' : '';
    } else if (minutes > 0) {
      message += minutes + ' minuto';
      message += (minutes > 1) ? 's' : '';
    } else if (second > 0) {
      message += second + ' segundo';
      message += (second > 1) ? 's' : '';
    }

    return message;
  }
}
