import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { ErrorServerPageComponent } from './pages/error-server-page/error-server-page.component';
import { ErrorForbiddenPageComponent } from './pages/error-forbidden-page/error-forbidden-page.component';
import { ErrorNotFoundPageComponent } from './pages/error-not-found-page/error-not-found-page.component';
import { ErrorPageComponent } from './pages/error-page/error-page.component';
import { ErrorOfflinePageComponent } from './pages/error-offline-page/error-offline-page.component';

const routes: Routes = [
  { path: '403', component: ErrorForbiddenPageComponent },
  { path: '404', component: ErrorNotFoundPageComponent },
  { path: '500', component: ErrorServerPageComponent },
  { path: 'offline', component: ErrorOfflinePageComponent},
  { path: 'error', component: ErrorPageComponent },
  { path: '**', component: ErrorNotFoundPageComponent }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ErrorHandlingRoutingRoutingModule { }
