<div
  *ngIf="initialLoadingDone; else showLoadingIndicator"
  class="scrolling-area"
  infiniteScroll
  [infiniteScrollDistance]="3"
  [infiniteScrollThrottle]="100"
  (scrolled)="onScroll()"
  [scrollWindow]="false">
  <div class="main-grid">
    <section class="user-posts grid-left">

      <div class="back mb-40">
        <a [routerLink]="['/account']"><img src="assets/iconos/icono_arrowdown.svg" alt="Regresar">
          Regresar
        </a>
      </div>

      <div class="select-info banner">
        <h3>Mis <b>Posts</b></h3>
        <p>Ve todo lo que has publicado, likeado, comentado y compartido en la comunidad de <b><i>Fans LigaMX</i></b>
        </p>
        <img src="assets/mis-post-banner.png" alt="Mis posts">
      </div>

      <section class="controls">

        <div class="buttons">
          <button (click)="changePostType('created')" class="{{postType == 'created' ? 'active' : ''}}">Publicaciones
          </button>
          <button (click)="changePostType('liked')" class="{{postType == 'liked' ? 'active' : ''}}">Likes</button>
          <button (click)="changePostType('commented')" class="{{postType == 'commented' ? 'active' : ''}}">
            Comentarios
          </button>
        </div>

        <div class="filters-dropdown">
          <img src="assets/iconos/icon_ordenar.svg" alt="Ordenar">
          <span>Ordenar:</span>
          <p-dropdown
            [options]="dropDownFilters"
            optionLabel="dropDownFilterLabel"
            optionValue="dropDownFilterDirection"
            [dropdownIcon]="selectedFilter === 'DESC' ? 'pi pi-arrow-up' : 'pi pi-arrow-down'"
            [(ngModel)]="selectedFilter"
            (ngModelChange)="onChangeFilter($event)"></p-dropdown>
        </div>
      </section>

      <section class="posts grid-left">
        <div class="items">
          <ng-container *ngIf="paginatorItemPosts.length > 0 || loadingMorePosts; else empty">
            <ng-container *ngFor="let paginatorItemPost of paginatorItemPosts">
              <ng-container
                [ngTemplateOutlet]="postTemplate"
                [ngTemplateOutletContext]="{ $implicit: paginatorItemPost }"></ng-container>
            </ng-container>
            <ng-template #postTemplate let-post>
              <ng-container [ngSwitch]="true">
                <!-- Post de Contenido Media -->
                <ng-container *ngSwitchCase="post.type === 1 || post.type === 2 || post.type === 3 || post.type === 4"
                              [ngTemplateOutlet]="postMediaTemplate"
                              [ngTemplateOutletContext]="{ $implicit: post }"></ng-container>
                <!-- Post de Votación -->
                <ng-container *ngSwitchCase="post.type === 5"
                              [ngTemplateOutlet]="postPollingTemplate"
                              [ngTemplateOutletContext]="{ $implicit: post }"></ng-container>
              </ng-container>

              <!-- Template para la Publicación de Contenido Media. -->
              <ng-template #postMediaTemplate let-postMedia>
                <div class="item">
                  <app-post-media-expanded [post]="postMedia" [showTopicAsPostHeader]="true"
                                           (onBookmark)="onBookmarkPost($event)">
                    <!-- Aqui incluimos el contenido Media dependiendo del tipo de Post: texto, link, imagen o video -->

                    <!-- Es un Post de Link? -->
                    <!-- Link Preview content goes here -->
                    <app-link-preview
                      *ngIf="postMedia.type === 2"
                      [originalLinkSource]="postMedia.media.content.originalLinkSource"
                      [metaTags]="postMedia.media.content.metaTags"></app-link-preview>

                    <!-- Es un Post de Imagen? -->
                    <!-- Image content goes here -->
                    <img
                      *ngIf="postMedia.type === 3"
                      [src]="postMedia.media.content"
                      [alt]="postMedia.description">

                    <!-- Es un Post de Video? -->
                    <!-- Video content goes here -->
                    <video
                      *ngIf="postMedia.type === 4"
                      width="100%"
                      height="360px"
                      controls>
                      <source type="video/mp4" [src]="postMedia.media.content">
                    </video>

                    <!-- Es un Post de Texto? -->
                    <!-- Un Post de Texto no posee ningún tipo de contenido media -->
                  </app-post-media-expanded>
                </div>
              </ng-template>

              <!-- Template para la Publicación de Votación. -->
              <ng-template #postPollingTemplate let-postPolling>
                <div class="item">
                  <!-- Es un Post de Votaciones? -->
                  <app-post-polling [post]="postPolling"></app-post-polling>
                </div>
              </ng-template>

            </ng-template>
          </ng-container>
          <ng-template #empty>
            <div class="no-results">
              <img src="assets/fan.png" alt="Sin resultados">
              <p><b>¡Ups! Aún no guardas nada...</b></p>
              <p>Te invitamos a guardar los Posts que te gusten usando este icono</p>
              <img src="assets/iconos/icono_guardar.svg" alt="Guardar">
            </div>
          </ng-template>
          <div style="text-align: center; margin: 40px auto;" *ngIf="loadingMorePosts">
            <i class="pi pi-spin pi-spinner spinner spinner-md"></i>
          </div>

        </div>
      </section>

    </section>

    <div class="grid-right pt-100">
      <app-advertisement-type-c *ngIf="showAdTypeC" (loaded)="showAdTypeC = $event"></app-advertisement-type-c>
      <app-banner-community></app-banner-community>
    </div>

  </div>

</div>

<ng-template #showLoadingIndicator>
  <!-- Este spinner es cuando se cargan inicialmente los Posts por vez primera. -->
  <div class="loading" style="text-align: center;">
    <i class="pi pi-spin pi-spinner spinner spinner-lg"></i>
  </div>
</ng-template>

<p-toast position="top-left"></p-toast>
