import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { RedirectWhenSharingNewGuard } from './shared/guards/redirect-when-sharing-new.guard';

import { NewPreviewPageComponent } from './pages/new-preview-page/new-preview-page.component';

const routes: Routes = [
  { path: 'new/preview/:id', component: NewPreviewPageComponent, canActivate: [ RedirectWhenSharingNewGuard ]}
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class NewSharingRoutingModule { }
