import { Component, OnInit, OnDestroy } from '@angular/core';

import { SubSink } from 'subsink';

import { NavbarActionListenerService } from '@app/core/services/navbar-action-listener.service';
import { ModalService } from '@app/ui/modals/shared/services/modal.service';
import { ProfileService } from '../../shared/services/profile.service';
import { Profile } from '../../shared/models/profile';

@Component({
    selector: 'app-edit-profile-panel',
    templateUrl: './edit-profile-panel.component.html',
    styleUrls: ['./edit-profile-panel.component.scss']
})
export class EditProfilePanelComponent implements OnInit, OnDestroy {

    private subscriptions = new SubSink();

    public userProfile!: Profile;
    public userProfileLoaded: boolean = false;

    public loadingChangeUserProfileAction: boolean = false;

    constructor(
        private readonly navbarActionListener: NavbarActionListenerService,
        private readonly modalService: ModalService,
        private readonly profileService: ProfileService
    ) { }

    ngOnInit(): void {

        this.userProfileLoaded = false;

        this.profileService.getProfile().subscribe((profile) => {

            this.userProfileLoaded = true;
            this.userProfile = profile;
        });
    }

    ngOnDestroy(): void {

        this.subscriptions.unsubscribe();
    }

    public onChangeUserProfile(profile: Profile): void {

        this.loadingChangeUserProfileAction = true;

        this.subscriptions.sink = this.profileService.updateProfile(profile).subscribe({
            next: (response) => {

                const {
                    usrn, // nombre de usuario.
                    usid, // id Usuario
                    avat, // url de la imagen del usuario.
                    ftid, // id de equipo favorito.
                    ftnm, // nombre del equipo favorito.
                    ftlg // logo de equipo favorito.
                } = response;

                this.loadingChangeUserProfileAction = false;

                // Vuelva la información en el local storage.
                this.profileService.updateProfileInLocalStorage({ usrn, usid, avat, ftid, ftnm, ftlg });
                // Comunica a la barrá de navegación que actualice su información.
                this.navbarActionListener.dispatchAction({ type: 'refresh' });
                // Muestra un mensaje de éxito al usuario.
                this.modalService.successDialog('Sus cambios han sido guardados.');
            },
            complete: () => {

                this.loadingChangeUserProfileAction = false;
            }
        });
    }
}
