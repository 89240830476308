import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule, LOCALE_ID } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

import localeEs from '@angular/common/locales/es';
import { registerLocaleData } from '@angular/common';

import { AngularFireModule } from "@angular/fire";
import { AngularFireAuthModule } from "@angular/fire/auth";

import { MessageService } from 'primeng/api';

// UI Modules.
import { NavbarModule } from './ui/navbar/navbar.module';
import { FooterModule } from './ui/footer/footer.module';
import { ModalsModule } from './ui/modals/modals.module';

// Feature Modules.
import { ComingSoonLandingModule } from './coming-soon-landing/coming-soon-landing.module';
import { AuthModule } from './auth/auth.module';
import { LandingModule } from './landing/landing.module';
import { NewsModule } from './news/news.module';
import { NewSharingModule } from './new-sharing/new-sharing.module';
import { TeamsModule } from './teams/teams.module';
import { ComunidadModule} from './comunidad/comunidad.module';
import { TopicManagerModule } from './topic-manager/topic-manager.module';
import { TopicSharingModule } from './topic-sharing/topic-sharing.module';
import { PostManagerModule } from './post-manager/post-manager.module';
import { PostSharingModule } from './post-sharing/post-sharing.module';
import { AccountSettingsModule } from './account-settings/account-settings.module';
import { WebviewsModule } from './webviews/webviews.module';
import { ComplianceAndTransparencyModule } from './compliance-and-transparency/compliance-and-transparency.module';
import { SearchResultsModule } from './search-results/search-results.module';
import { ErrorHandlingModule } from './error-handling/error-handling.module';
import { LayoutConfigModule } from './layout-config/layout-config.module';

// App Routing Module.
import { AppRoutingModule } from './app-routing.module';

// Root Component.
import { AppComponent } from './app.component';

// Global Interceptors.
import { TokenInterceptorService } from './auth/shared/interceptors/token-interceptor.service';

// Layout Configuration.
import { LayoutConfig } from './layout-config/shared/models/layout-config';

import { environment } from '@env/environment';


const layoutConfigStart: LayoutConfig = {
    layout: {
        navbar: { visible: false },
        footer: { visible: false }
    }
};
// Local Global Date Configuration.
registerLocaleData(localeEs, 'es');

@NgModule({
    declarations: [
        AppComponent,
    ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        HttpClientModule,
        AngularFireModule.initializeApp(environment.firebase),
        AngularFireAuthModule,
        NavbarModule,
        FooterModule,
        ModalsModule,
        AuthModule,
        LandingModule,
        NewsModule,
        NewSharingModule,
        TeamsModule,
        ComunidadModule,
        TopicManagerModule,
        TopicSharingModule,
        PostManagerModule,
        PostSharingModule,
        AccountSettingsModule,
        WebviewsModule,
        ComplianceAndTransparencyModule,
        SearchResultsModule,
        ComingSoonLandingModule,
        AppRoutingModule,
        ErrorHandlingModule,
        LayoutConfigModule.forRoot(layoutConfigStart)
    ],
    providers: [
        MessageService,
        { provide: LOCALE_ID, useValue: 'es' },
        { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptorService, multi: true }
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
