import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'extractHostname'
})
export class ExtractHostnamePipe implements PipeTransform {

    transform(url: string): string {
        
        let hostname: string = '';
        
        try {
            // Que sucede cuando no se puede construir una URL a partir de una url inválida?
            hostname = (new URL(url)).hostname;
        
        } catch (e) {

            // Atrapamos la excepción y dejamos la url que el usuario ingreso.
            hostname = url;
        }

        return hostname;
    }
}
