<form [formGroup]="editTopicForm" (ngSubmit)="onUpdateTopic()" novalidate>
    <div style="margin-bottom: 15px;">
        <div class="input-box input-box-block">
            <span class="input-box-prepend-icon">
                <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g opacity="0.4">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M0.761719 0.846221L1.14261 0.465332H11.0457L11.4266 0.846221L11.4266 9.52264L11.0457 9.90353H1.14261L0.761719 9.52264L0.761719 0.846221ZM1.5235 1.63466V9.14175H10.6648L10.6648 1.63542L10.2561 1.22711H1.93225L1.5235 1.63466Z" fill="white"/>
                        <line x1="2.37842" y1="3.12146" x2="9.81039" y2="3.12146" stroke="white" stroke-width="0.891837"/>
                    </g>
                </svg>
            </span>

            <input type="text" class="input-box-face input-box-white-text" placeholder="Título del Topic" formControlName="title" pInputText required>
        </div>

        <ng-container *ngIf="title?.invalid && (title?.dirty || title?.touched)">
            <span *ngIf="title?.hasError('required')" class="invalid-feedback-message"><small>Ingrese un título a su Topic</small></span>
        </ng-container>
    </div>

    <div style="margin-bottom: 15px;">
        <div class="input-box input-box-block">
            <span class="input-box-prepend-icon">
                <svg width="12" height="7" viewBox="0 0 12 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path opacity="0.3" fill-rule="evenodd" clip-rule="evenodd" d="M0.594727 0.441717C0.594727 0.195512 0.795272 0 1.03761 0H11.1512C11.3935 0 11.5941 0.195511 11.5941 0.441717C11.5941 0.687921 11.3935 0.883433 11.1512 0.883433H1.03761C0.795272 0.883433 0.594727 0.68792 0.594727 0.441717ZM0.594727 3.29896C0.594727 3.05275 0.795273 2.85724 1.03761 2.85724H11.1512C11.3935 2.85724 11.5941 3.05275 11.5941 3.29896C11.5941 3.54516 11.3935 3.74067 11.1512 3.74067H1.03761C0.795272 3.74067 0.594727 3.54516 0.594727 3.29896ZM0.594727 6.1562C0.594727 5.90999 0.795273 5.71448 1.03761 5.71448H5.92692C6.16926 5.71448 6.3698 5.90999 6.3698 6.1562C6.3698 6.4024 6.16926 6.59791 5.92692 6.59791H1.03761C0.795271 6.59791 0.594727 6.4024 0.594727 6.1562Z" fill="white"/>
                </svg>
            </span>

            <input type="text" class="input-box-face input-box-white-text" placeholder="Descripción del Topic" formControlName="description" pInputText required>
        </div>

        <ng-container *ngIf="description?.invalid && (description?.dirty || description?.touched)">
            <span *ngIf="description?.hasError('required')" class="invalid-feedback-message"><small>Ingrese una descripción a su Topic</small></span>
        </ng-container>
    </div>

    <div style="margin-bottom: 15px;">
        <app-select-team-field
            placeholder="Club de fútbol"
            formControlName="teamPreference"></app-select-team-field>

        <ng-container *ngIf="teamPreference?.invalid && (teamPreference?.dirty || teamPreference?.touched)">
            <span *ngIf="teamPreference?.hasError('required')" class="invalid-feedback-message">
                <small>Seleccione un club de su preferencia.</small>
            </span>
        </ng-container>
    </div>

    <div style="margin-bottom: 15px;">
        <app-single-base64image-uploader-field
            accept="image/png,image/jpeg"
            [maxFileSize]="1024"
            formControlName="cover"></app-single-base64image-uploader-field>

        <ng-container *ngIf="cover?.invalid && (cover?.dirty || cover?.touched)">
            <span *ngIf="cover?.hasError('fileRequired')" class="invalid-feedback-message"><small>Eliga una imagen como portada para su Topic</small></span>
            <span *ngIf="cover?.hasError('invalidFileSize')" class="invalid-feedback-message"><small>No pudimos cargar su imagen, el tamaño máximo permitido es de hasta 1MB.</small></span>
            <span *ngIf="cover?.hasError('invalidMimeType')" class="invalid-feedback-message"><small>No pudimos cargar su imagen, solo se aceptan formatos jpg, jpeg y png.</small></span>
            <span *ngIf="cover?.hasError('unsuccessfulEncoding')" class="invalid-feedback-message"><small>No pudimos cargar su imagen, inténtelo nuevamente.</small></span>
            <span *ngIf="cover?.hasError('unSelectedFile')" class="invalid-feedback-message"><small>No se seleccionó ninguna imagen.</small></span>
        </ng-container>
    </div>

    <div class="controls">
        <p-menu #menu [popup]="true" [model]="topicMenuOptions"></p-menu>
        <button class="more" type="button" (click)="menu.toggle($event)"></button>

        <span *ngIf="removeCurrentTopicLoading">
            <i class="pi pi-spin pi-spinner spinner spinner-sm"></i>
            <span style="color: white;">Eliminando Topic...</span>
        </span>

        <button type="button" (click)="onUpdateTopicCancellation()">Cancelar <img src="assets/iconos/x_cancelar.svg" alt="Cancelar"></button>
        <button type="submit" class="submit icon-right" [disabled]="changeCurrentTopicLoading">
            <ng-container *ngIf="!changeCurrentTopicLoading; else loading">
                Publicar <img src="assets/iconos/arrow_publica.svg" alt="Publicar">
            </ng-container>

            <ng-template #loading>
                <i class="pi pi-spin pi-spinner button-spinner"></i>
            </ng-template>
        </button>
    </div>
</form>

