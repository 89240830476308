import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';

import { Observable, forkJoin } from 'rxjs';
import { map } from 'rxjs/operators';

import { AuthProfileService } from '@app/auth/shared/services/auth-profile.service';
import { TopicsResponse } from '../models/topics-response';
import { Topic } from '../models/topic';
import { Paginator } from '../models/paginator';

import { environment } from '@env/environment';

@Injectable({
  providedIn: 'root'
})
export class TopicsService {

    constructor(
        private readonly httpClient: HttpClient,
        private readonly authProfile: AuthProfileService
    ) { }

    public paginate(page: number, teamId: number): Observable<Paginator> {

        const currentPage: string = (page && page > 0) ? String(page) : '1';
        const params = new HttpParams().set('page', currentPage);
        
        return forkJoin({
            userId:         this.authProfile.getAuthId(),
            topicsResponse: this.httpClient.get<TopicsResponse>(`${ environment.host }/api/topics/team/${ teamId }`, { params })
        }).pipe(
            map(({ userId, topicsResponse }) => {

                const { content } = topicsResponse;
                const { 
                    data, 
                    current_page:currentPage, 
                    last_page:lastPage, 
                    per_page:perPage, 
                    total:totalResults, 
                    first_page_url:firstPageUrl, 
                    last_page_url:lastPageUrl, 
                    next_page_url:nextPageUrl }  = content;

                const topics: Topic[] = data.map((topicResponse) => {

                    const { 
                        id, 
                        user_id:ownerId, 
                        name:title, 
                        is_official, 
                        cover, 
                        currentUser, 
                        totals } = topicResponse;

                    return {
                        id,
                        title,
                        cover:                  (cover ? cover : ''),
                        isOfficial:             !!is_official,
                        following:              currentUser.isFollower,
                        ownership:              (ownerId === userId),
                        totalFollowers:         totals.followers,
                        totalPublishedPosts:    totals.posts                        
                    } as Topic;
                }); 

                return {
                    topics,
                    currentPage,
                    lastPage,
                    perPage,
                    totalResults,
                    firstPageUrl,
                    lastPageUrl,
                    nextPageUrl
                };
            })
        );
    }
}
