<div class="item post community-min">
  <img src="{{cover}}" alt="Community">
    <p class="title">
        <img
            [src]="isOfficial ? 'assets/iconos/icono_topicofcial.svg': 'assets/iconos/icono_fanmade.svg'"
            [alt]="isOfficial ? 'Official' : 'Fan'">{{ title }}
    </p>
    <p>
        <span>{{ totalFollowers | rateTotalizer }} <img src="assets/iconos/icono_joinedfans.svg" alt="Fans"></span>
        <span><img src="assets/iconos/icono_postsintopic.svg" alt="Posts">{{ totalPublishedPosts | rateTotalizer }}</span>
    </p>
</div>
