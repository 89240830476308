import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { PostMediaService } from './post-media.service';
import { PostActionStatus } from '../models/post-action';
import { PostImageMappingService } from './post-image-mapping.service';

import { PostImage } from '../models/post-image';
import { PostImagePayload } from '../models/post-image-payload';

@Injectable({
    providedIn: 'root'
})
export class PostImageService {

    constructor(
        private readonly postMedia: PostMediaService,
        private readonly postImageMapping: PostImageMappingService
    ) { }

    public create(postImage: Partial<PostImage>): Observable<PostActionStatus> {

        // Mapeamos nuestro post para poder enviarlo al Servidor.
        const postImagePayload: Partial<PostImagePayload> = this.postImageMapping.mapPostImagePayload(postImage);

        return this.postMedia.createPostMedia(postImagePayload);
    }

    public update(postImage: Partial<PostImage>) {

        // Mapeamos nuestro post para poder enviarlo al Servidor.
        const postImagePayload: Partial<PostImagePayload> = this.postImageMapping.mapPostImagePayload(postImage);

        return this.postMedia.editPostMedia(postImagePayload);
    }
}
