export interface TeamsByLeague {
    femaleLeagueTeams:Team[],
    maleLeagueTeams:Team[],
    expansionLeagueTeams:Team[]
}

export interface Team {
    id:number;
    name:string;
    logo:string;
    league:string;
    leagueId:number;
}

export enum League {
    MALE = 1,
    FEMALE = 2,
    EXPANSION = 3
}