import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable, of } from 'rxjs';
import { catchError, map, mapTo } from 'rxjs/operators';

import { PostTextMappingService } from '../services/post-text-mapping.service';
import { PostImageMappingService } from '../services/post-image-mapping.service';
import { PostLinkMappingService } from '../services/post-link-mapping.service';
import { PostVideoMappingService } from '../services/post-video-mapping.service';

import { PostMediaResponse } from '../models/post-response';
import { PostMediaDetailsResponse } from '../models/post-response';
import { PostLinkPayload } from '../models/post-link-payload'; 
import { PostTextPayload } from '../models/post-text-payload';
import { PostImagePayload } from '../models/post-image-payload';

import { PostActionStatus } from '../models/post-action';
import { PostText } from '../../shared/models/post-text';
import { PostImage } from '../../shared/models/post-image';
import { PostLink } from '../../shared/models/post-link';
import { PostVideo } from '../../shared/models/post-video';
import { PostType } from '../../shared/models/post-type';

import { environment } from '@env/environment';

@Injectable({
    providedIn: 'root'
})
export class PostMediaService {

    constructor(
        private readonly httpClient: HttpClient,
        private readonly postTextMapping: PostTextMappingService,
        private readonly postImageMapping: PostImageMappingService,
        private readonly postLinkMapping: PostLinkMappingService,
        private readonly postVideoMapping: PostVideoMappingService
    ) { }

    public createPostMedia(payload: Partial<PostLinkPayload | PostTextPayload | PostImagePayload>): Observable<PostActionStatus> {
        
        return this.httpClient.post<PostMediaResponse>(`${ environment.host }/api/posts/store`, { ...payload })
            .pipe(
                map((response) => {

                    const { content } = response;

                    return {
                        state: 'Published',
                        payload: { postId: content.id }
                    } as PostActionStatus;
                }),
                catchError(_ => {
                    
                    return of({
                            state: 'NotPublished',
                            payload: null
                    } as PostActionStatus);
                })
            );
    }

    public editPostMedia(payload: Partial<PostLinkPayload | PostTextPayload | PostImagePayload>): Observable<PostActionStatus>  {

        return this.httpClient.put<PostMediaResponse>(`${ environment.host }/api/posts/${ payload.id }`, { ...payload })
            .pipe(
                map((response) => {

                    const { content } = response;

                    return {
                        state: 'Published',
                        payload: { postId: content.id }
                    } as PostActionStatus;
                }),
                catchError(_ => {
                    
                    return of({
                            state: 'NotPublished',
                            payload: null
                    } as PostActionStatus);
                })
            );
    }

    public removePostMedia(postId: number): Observable<boolean> {

        return this.httpClient.delete(`${ environment.host }/api/posts/${ postId }`)
        .pipe(
            mapTo(true),
            catchError(_ => of(false))
        );
    }

    public getPostMediaDetails(postId: number): Observable<PostText | PostImage | PostLink | PostVideo> {

        return this.httpClient.get<PostMediaDetailsResponse>(`${ environment.host }/api/posts/${ postId }`)
            .pipe(
                map((postMediaDetailsResponse) => {

                    const { content } = postMediaDetailsResponse;
                    const { type_post_id } = content;

                    if ( type_post_id === PostType.IMAGE ) {

                        return this.postImageMapping.mapPostImageDetails(postMediaDetailsResponse);
                    }

                    if ( type_post_id === PostType.LINK ) {

                        return this.postLinkMapping.mapPostLinkDetails(postMediaDetailsResponse);
                    }

                    if ( type_post_id === PostType.VIDEO ) {

                        return this.postVideoMapping.mapPostVideoDetails(postMediaDetailsResponse);
                    }

                    return this.postTextMapping.mapPostTextDetails(postMediaDetailsResponse);
                })
            );
    }
}
