import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { BannerCommunityComponent } from './banner-community/banner-community.component';

@NgModule({
  declarations: [BannerCommunityComponent],
  imports: [
    CommonModule,
    RouterModule
  ],
  exports: [ BannerCommunityComponent ]
})
export class BannerCommunityModule { }
