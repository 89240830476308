import { Component, OnInit, OnDestroy, Output, Input, EventEmitter } from '@angular/core';

import { SubSink } from 'subsink';

import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import { JoinedTopicsService } from '../../shared/services/joined-topics.service';
import { JoinedTopic } from '../../shared/models/joined-topic';

@Component({
  selector: 'app-topic-joined-type-ahead-list',
  templateUrl: './topic-joined-type-ahead-list.component.html',
  styleUrls: ['./topic-joined-type-ahead-list.component.scss'],
  providers: [ JoinedTopicsService ],
})
export class TopicJoinedTypeAheadListComponent implements OnInit, OnDestroy {

    @Input() topicId: number | undefined = undefined;
    @Output() onSelect: EventEmitter<number | undefined> = new EventEmitter<number | undefined>();
 
    private subscriptions = new SubSink();
 
    public topicSelectedId: number | undefined = undefined;
    public topics: JoinedTopic[] = [];
    public topicsLoaded: boolean = false;
    public topics$: Observable<JoinedTopic[]> = this.joinedTopics.topics$;

    constructor(private readonly joinedTopics: JoinedTopicsService) { }

    ngOnInit(): void {

        this.topicsLoaded = false;

        this.subscriptions.sink = this.topics$
            .pipe(
                tap((joinedTopics) => {

                    // Si NO recibimos un id de Topic proveniente del exterior le indicamos al dropdown que no seleccione ningún elemento, esto se logra asignandole undefined como valor.
                    // De lo contrario, suceden 2 cosas:
                    // 1. Si el id del Topic del exterior se encuentra dentro de los Topics a los que estamos suscritos, le indicamos al dropdown que seleccione ese topic.
                    // 2. Si por el contrario, el id del Topic del Exterior NO se encuentra dentro de los Topics a los que estamos suscritos, le indicamos al dropdown 
                    //    que no seleccione ningún elemento, esto se logra asignandole undefined como valor.
                    this.topicSelectedId = this.topicId ? joinedTopics.map(t => t.id).find(id => this.topicId === id) : undefined;
                    this.topicsLoaded = true;
                })
            )
            .subscribe((joinedTopics) => {

                this.topics = joinedTopics;
            });
    }

    ngOnDestroy(): void {

        this.subscriptions.unsubscribe();
    }

    public onChange(topicId: number | null ): void {

        this.onSelect.emit(topicId ? topicId : undefined);
    }
}
