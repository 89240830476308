import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import { PollingOption } from '../../shared/models/post-polling';

import { environment } from '@env/environment';

@Injectable()
export class PostPollingService {

    constructor(private readonly httpClient: HttpClient) { }

    public vote(pollingId: number, pollOption: PollingOption): Observable<unknown> {
    
        return this.httpClient.post<unknown>(`${ environment.host }/api/posts/vote/${ pollingId }/option/selected`,  { option: pollOption })
            .pipe(
                tap( (res) => console.log(res) )
            );
    }
}
