import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';

import { CommonValidators } from '@app/core/validators/common-validators';

@Component({
    selector: 'app-account-activation-form',
    templateUrl: './account-activation-form.component.html',
    styleUrls: ['./account-activation-form.component.scss']
})
export class AccountActivationFormComponent implements OnInit {

    @Input() public accountActivationLoading: boolean = false;
    @Input() public accountActivationCodeMessage: string = '';
    @Output() private activateAccount: EventEmitter<string> = new EventEmitter<string>();
    @Output() private resendActivationCode: EventEmitter<void> = new EventEmitter<void>();

    public accountActivationForm!: FormGroup;
    
    constructor(private fb: FormBuilder) { }

    ngOnInit(): void {

        this.loadAccountActivationForm();
    }

    private loadAccountActivationForm(): void {

        this.accountActivationForm = this.fb.group({
            activationCode: ['', {
                validators: [
                    Validators.required,
                    CommonValidators.mustNotBeBlank
                ]}
            ]
        });
    }

    public onActivateAccount(): void {

        this.accountActivationForm.markAllAsTouched();

        if ( this.accountActivationForm.valid ) {
            
            const { activationCode } = this.accountActivationForm.value;

            this.activateAccount.emit(activationCode);
        }
    }

    public onResendActivationCode(): void {

        this.resendActivationCode.emit();
    }

    get activationCode() {

        return this.accountActivationForm.get('activationCode');
    }

    // Desde aqui vamos a tratar los posibles errores generados en el campo activationCode.
    get errorOnActivationCodeField() {

        // Nos interesa solo mostrar el primer error generado. En esta caso si el campo 'activationCode' genera más 
        // de 1 error, no queremos que se empalmen mostrando 2 mensajes de errores al mismo tiempo.
        return Object.keys(this.accountActivationForm.get('activationCode')?.errors || {})[0];
    }
}
