import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges, ChangeDetectionStrategy } from '@angular/core';

import { MenuItem } from 'primeng/api';

import { Reaction } from '../../shared/models/post-media';

@Component({
    selector: 'app-reactions',
    templateUrl: './reactions.component.html',
    styleUrls: ['./reactions.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ReactionsComponent implements OnInit, OnChanges {

    @Input() available: Reaction[] = [];
    @Input() selected: Reaction[] = [];
    @Output() onReaction: EventEmitter<Reaction> = new EventEmitter<Reaction>();

    // Para el Menú de reacciones disponibles.
    public reactionsAvailableItems: MenuItem[] = [];

    // Para el Componente que muestra las reacciones seleccionadas por el usuario actual u otros usuarios.
    // Esta lista de reacciones se actualiza dependiendo de los cambios de selección que haga el usuario.
    // El cálculo de la nueva vista se hará a través del hook OnChanges()
    public reactionsSelected: Reaction[] = [];

    constructor() { }

    // Este hook solo se va a ejecutar 1 sola vez al iniciar el Componente.
    ngOnInit(): void {
        
        this.reactionsAvailableItems = this.mapReactionsAvailable();
    }

    // Este hook solo se va a ejecutar cada vez que cambiemos la referencia del @Input reactions, esto se debe 
    // a que hemos adoptado una estrategia OnPush para este Componente.
    ngOnChanges(changes: SimpleChanges): void {

        this.reactionsSelected = [ ...this.selected ];
    }

    // Este método se va a encargar de cargar las reacciones disponibles en nuestro Menú.
    private mapReactionsAvailable(): MenuItem[] {

        // Totamos las reacciones disponibles y las cargamos a nuestro Menú de reacciones.
        return this.available.map((reaction) => {

            const { id, name, htmlCode } = reaction;

            return {
                label: htmlCode,
                escape: false,
                state: {
                    id, name, htmlCode
                },
                command: (event) => {

                    const { item } = event;
                    const reactionSelected: Reaction = (item.state) as Reaction;

                    this.onSelectReaction(reactionSelected);
                }
            };
        });
    }

    public onSelectReaction(reaction: Reaction): void {

        this.onReaction.emit(reaction);
    }
}
