import { Component, OnInit, OnDestroy,Output, Input, EventEmitter } from '@angular/core';

import { TeamsService } from '../shared/services/teams.service';
import { Team, League } from '../shared/models/teams';

@Component({
    selector: 'app-boarding-teams',
    templateUrl: './boarding-teams.component.html',
    styleUrls: ['./boarding-teams.component.scss'],
    providers: [ TeamsService ]
})
export class BoardingTeamsComponent implements OnInit, OnDestroy {

    @Input() currentTeamId: number | undefined = undefined;
    @Input() showInfoBanner: boolean = true; 
    @Output() teamSelected: EventEmitter<Team | undefined> = new EventEmitter();

    public loading:boolean = false;
    public teamsReady:boolean = false;
    public errorMessage:string = '';

    public femaleLeagueTeams:any[] = [];
    public maleLeagueTeams:any[] = [];
    public expansionLeagueTeams:any[] = [];

    public femaleLeagueSelected:boolean = false;
    public maleLeagueSelected:boolean = false;
    public expansionLeagueSelected:boolean = false;

    private currentTeamSelected:Team | null = null;

    constructor( private teamsService:TeamsService ) { }

    ngOnInit(): void {

        this.loading = true;

        this.teamsService.getAllTeamsByLeague().subscribe( 
            
            (teamsByLeagueResponse) => {

                this.loading = false;
                this.teamsReady = true;
                this.errorMessage = '';

                if ( teamsByLeagueResponse ) {

                    const { femaleLeagueTeams, maleLeagueTeams, expansionLeagueTeams } = teamsByLeagueResponse;

                    // Buscamos activar el TeamGroup correspondiente.
                    this.setActiveTeamGroup([ ...femaleLeagueTeams, ...maleLeagueTeams, ...expansionLeagueTeams ]);
    
                    this.femaleLeagueTeams = femaleLeagueTeams;
                    this.maleLeagueTeams = maleLeagueTeams;
                    this.expansionLeagueTeams = expansionLeagueTeams;
                
                } else {

                    this.errorMessage = 'Los equipos de fútbol no pudierón ser cargados. Intentelo más tarde nuevamente.';
                }
            });
    }

    ngOnDestroy(): void {

        console.log('BoardingTeamsComponent destroyed.');
    }

    public onTeamSelection(team:Team): void {

        this.currentTeamId = team.id;
        this.currentTeamSelected = team;
    }

    public onTeamSelected(): void {

        this.teamSelected.emit( this.currentTeamSelected || undefined );
    }

    private setActiveTeamGroup(teams:Team[]): void {

        // Si no viene ningún id de un equipo, podemos dejar por defecto el TabGroup de la selección varoníl.
        if ( !(this.currentTeamId) ) {

            return this.selectMaleLeague(); 
        } 
            
        // Debemos buscar la liga a la que pertenece el equipo para saber que TeamGroup debemos activar.
        const currentTeam:Team | undefined = teams.find( (team) => team.id === this.currentTeamId );

        // En caso que no hayamos encontrado el equipo asociado al equipo currentTeamId , 
        // abrimos un TabGroup por defecto.
        if ( !currentTeam ) {

            return this.selectMaleLeague(); 
        }
        
        // Antes de abrir el Tab correspondiente, selecciona el equipo actual.
        this.currentTeamSelected = currentTeam;

        // Abre en TabGroup correspondiente.
        this.openTabGroup( currentTeam.leagueId );
    }

    private openTabGroup(leagueId:number): void {

        switch (leagueId) {

            case League.FEMALE:
                this.selectFemaleLeague();
            break;

            case League.MALE:
                this.selectMaleLeague();
            break;

            case League.EXPANSION:
                this.selectExpansionLeague();
            break;
        }
    }

    private selectFemaleLeague(): void {

        this.femaleLeagueSelected = true;
        this.maleLeagueSelected = false;
        this.expansionLeagueSelected = false;
    }

    private selectMaleLeague(): void {

        this.femaleLeagueSelected = false;
        this.maleLeagueSelected = true;
        this.expansionLeagueSelected = false;
    }

    private selectExpansionLeague(): void {

        this.femaleLeagueSelected = false;
        this.maleLeagueSelected = false;
        this.expansionLeagueSelected = true;
    }
}
