import { Component, OnInit, Input } from '@angular/core';

import { PostPollingMetricsService } from '../../shared/services/post-polling-metrics.service';
import { Polling, PollingMetrics } from '../../shared/models/post-polling';

@Component({
  selector: 'app-post-polling-metrics',
  templateUrl: './post-polling-metrics.component.html',
  styleUrls: ['./post-polling-metrics.component.scss'],
  providers: [ PostPollingMetricsService ]
})
export class PostPollingMetricsComponent implements OnInit {

    // ¿Como se deben ser mostradas las métricas cuando un Votación se encuentra ABIERTA o CERRADA?
    // Ten en cuenta que el Panel de Métricas solo es visible cuando:
    //  a) Un Usuario vota.
    //  b) Una Publicación de Votación ya se encuentra cerrada.
    @Input() pollIsOpen: boolean = false;

    // Total de votaciones realizdas en la Publicación actual.
    @Input() pollTotalVotesCounted: number = 0;

    // La lista de opciones de votación a partir de la cual calcularemos las Métricas de la Votación.
    @Input() polls: Polling[] = [];

    // Estas son las métricas a mostrar en el Template.
    public pollingMetrics: PollingMetrics[] = [];

    constructor(private readonly postPollingMetrics: PostPollingMetricsService) { }

    ngOnInit(): void {

        // La forma en que se muestran las Métricas dependerá de si el estado de la Votación se encuentra CERRADA o ABIERTA.
        this.pollingMetrics = this.postPollingMetrics.getPollingMetrics(this.pollIsOpen, this.pollTotalVotesCounted, this.polls);
    }
}
