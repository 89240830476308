<div>
    <ng-container *ngIf="firstPageFromPaginatorIsLoaded; else showLoadingIndicator">
        <!-- Este es el nuevo Scroller -->
        <div
            class="scrolling-area"
            appScrollableToBeginning
            infiniteScroll
            [infiniteScrollDistance]="3"
            [infiniteScrollThrottle]="150"
            (scrolled)="lazyLoadMoreNews()"
            [scrollWindow]="false">
            <!-- Estas son las noticias que se van cargando -->
            <div *ngFor="let item of news">
                <ng-container
                [ngTemplateOutlet]="item.type === 'new' ? showNew : showAd"
                [ngTemplateOutletContext]="{ $implicit: item }"></ng-container>
            </div>

            <!-- Este es el botón que nos va a llevar al inicio una vez hemos alcanzado el final del Scroller -->
            <div style="text-align: center; background-color: green;">
                <button type="button" (click)="gotoFirstPage()">Ir al inicio</button>
            </div>
        </div>

        <ng-template #showNew let-new>
            <!-- Es la noticia en si -->
            <app-new
            [id]="new.id"
            [title]="new.title"
            [urlImage]="new.urlImage"
            [url]="new.url"
            avatarDomain="{{new.favicon}}"
            [hostDomain]="new.hostDomain"
            [date]="new.date"></app-new>
        </ng-template>

        <ng-template #showAd let-ad>
            <!-- Es el anunción, esto luego se va a extraer a su propio Módulo -->
            <div style="background-color: gray; height: 30px;">Aqu va un Anuncio</div>
        </ng-template>
    </ng-container>

    <ng-template #showLoadingIndicator>
        <!-- Este spinner es cuando se cargan inicialmente las noticias. -->
        <div style="text-align: center;">
            <i class="pi pi-spin pi-spinner spinner spinner-lg"></i>
        </div>
    </ng-template>
</div>

<!-- Este spinner es visible a medida que vamos haciendo scroll y se esta cargando el nuevo chunk de noticias -->
<div *ngIf="loadingMoreNews">
    <div style="text-align: center;">
        <i class="pi pi-spin pi-spinner spinner spinner-md"></i>
    </div>
</div>
