import { HttpEvent, HttpResponse, HttpEventType } from '@angular/common/http';
import { pipe } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { PostVideoResponse } from '../../models/post-video/post-video-response';
import { PostActionStatus } from '../../models/post-video/post-action-status';

export function toResponseBody<T>() {

    return pipe(
        filter((event: HttpEvent<T>) => (event.type === HttpEventType.Response)),
        map((res: HttpEvent<T>) => {

            // Forzamos la conversión. No se por que despues del proceso de filtro, el siguiente operador
            // toma la respuesta como un HttpEvent en lugar de un HttpResponse.
            const response: unknown = res as unknown;

            return (response as HttpResponse<T>).body;
        }),
        map((res) => {

            // Forzamos nuevamente la conversión. Para que la respuesta sea de tipo PostVideoResponse.
            const response: unknown = res as unknown;

            return response as PostVideoResponse;
        }),
        map((response) => {

            const { content } = response;

            return {
                state: 'Published',
                payload: { postId: content.id }
            } as PostActionStatus;
        })
    ); 
}