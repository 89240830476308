import { Injectable } from '@angular/core';
import { HttpClient, HttpEvent } from '@angular/common/http';
import { Observable } from 'rxjs';

import { environment } from '@env/environment';

@Injectable()
export class PostVideoService {

    constructor(private readonly httpClient: HttpClient) { }

    public create(payload: FormData): Observable<HttpEvent<Object>> {

        // Crea o Modifica el Post. Tanto al crear como modificar, el Webservices nos pide que lo hagamos 
        // a través de un método HTTP POST.
        return this.httpClient.post(`${ environment.host }/api/posts/store`, payload, {
            observe: 'events',
            reportProgress: true
        });
    }

    public update(payload: FormData): Observable<HttpEvent<Object>>  {


        // Actualiza la Publicación de Video.
        return this.httpClient.post(`${ environment.host }/api/posts/${ payload.get('id') }`, payload, {
            observe: 'events',
            reportProgress: true
        });
    }
}
