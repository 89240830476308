import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { JoinedTopicsResponse } from '../models/joined-topics-response';
import { JoinedTopic } from '../models/joined-topic';

import { environment } from '@env/environment';

@Injectable()
export class JoinedTopicsService {

    public topics$: Observable<JoinedTopic[]> = this.httpClient.get<JoinedTopicsResponse>(`${ environment.host }/api/user/topics/all/i/follow`)
        .pipe(
            map((joinedTopicsResponse) => {

                const { content } = joinedTopicsResponse;

                return content.map((topic) => {

                    const { id, name:title, is_official, cover, totals } = topic;

                    return {
                        id,
                        title,
                        cover,
                        isOfficial: !!is_official,
                        totalFollowers: totals.followers
                    };
                });
            })
        );

    constructor( private httpClient: HttpClient ) { }
}
