import { Injectable } from '@angular/core';
import { Router, CanActivate, UrlTree, ActivatedRouteSnapshot, RouterStateSnapshot, NavigationExtras } from '@angular/router';

import { AuthService } from '../services/auth.service';

@Injectable({
    providedIn: 'root'
})
export class WelcomeFirstTimeUserService implements CanActivate {

    constructor(
        private readonly router: Router,
        private readonly auth: AuthService
    ) {}

    canActivate(route: ActivatedRouteSnapshot, routerState: RouterStateSnapshot): boolean | UrlTree {

        // Si es la primera vez que el usuario se ha autentificado a través de login/password, lo llevamos
        // a la página de Bienvenida.
        if ( (this.auth.isFirstTimeLoginAuthentication ) ) {
            
            const intendedUrl: string = routerState.url;
            const navigationExtras: NavigationExtras = {
                // Si la url a la que se pretende ir es la raíz, no tiene caso crear una url de retorno.
                // La funcion btoa() nos permite codificar un string a base64
                queryParams: (intendedUrl === '/') ? {} : { intendedUrl: btoa(intendedUrl) }
            };

            // Vete a la página de welcome con todos los extras de navigación correspondientes.
            return this.router.createUrlTree(['/welcome'], navigationExtras);
        }

        return true;
    }
}
