<button *ngIf="topicOwned" (click)="onEditTopic()" type="button">
    Editar
</button>

<button *ngIf="!topicOwned" (click)="onSubscribeTopic()" [disabled]="suscriptionLoading" type="button" class="{{topicJoined ? 'btn-call-to-action joined' : 'btn-call-to-action'}}">
    <ng-container *ngIf="!suscriptionLoading; else loading">
        <span *ngIf="!topicJoined">Unirme
          <span>+</span>
        </span>
        <span *ngIf="topicJoined">Unido
        <svg width="11" height="9" viewBox="0 0 11 9" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M1 4.66667L4.73846 8L10 1" stroke="white"/>
          </svg>
        </span>
    </ng-container>

    <ng-template #loading>
        <i class="pi pi-spin pi-spinner button-spinner spinner-sm"></i>
      </ng-template>
</button>
