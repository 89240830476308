import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';

import { Observable, forkJoin, Subject } from 'rxjs';
import { map, filter, tap } from 'rxjs/operators';

import { AuthProfileService } from '@app/auth/shared/services/auth-profile.service';
import { LandingNewsService } from '../landing-news.service';
import { PaginatorLandingPostMappingsService } from '../paginator/paginator-landing-post-mappings.service';
import { PaginatorLandingTopicsMappingService } from '../paginator/paginator-landing-topics-mapping.service';
import { PaginatorLandingNewsMappingService } from '../paginator/paginator-landing-news-mapping.service';

import { LandingResponse } from '../../models/responses/landing-response';
import { PaginatorItem } from '../../models/paginator-item';

import { environment } from '@env/environment';

@Injectable({
    providedIn: 'root'
})
export class PaginatorLandingService {

    constructor(
        private readonly httpClient: HttpClient,
        private readonly authProfile: AuthProfileService, 
        private readonly landingNews: LandingNewsService,
        private readonly paginatorLandingPostMappings: PaginatorLandingPostMappingsService,
        private readonly paginatorLandingTopicsMapping: PaginatorLandingTopicsMappingService,
        private readonly paginatorLandingNewsMapping: PaginatorLandingNewsMappingService
    ) { }

    // Elementos para poder llevar a cabo la Paginación.
    private nextPage: number = 1;
    private hasDonePaginating: boolean = false;

    private paginationSubject: Subject<number> = new Subject<number>();
    public listenPagination$: Observable<number> = this.paginationSubject.asObservable();

    public loadInitialPaginationContent() {

        return forkJoin({
            missedNews:     this.landingNews.getNewsBy('missed'),
            latestNews:     this.landingNews.getNewsBy('latest'),
            featuredNews:   this.landingNews.getNewsBy('featured'),
            paginatorItems: this.getNextPage(),
        });
    }

    public getNextPage(): Observable<PaginatorItem[]> { 

        const currentPage: string = (this.nextPage > 0) ? String(this.nextPage) : '1';
        const params = new HttpParams().set('page', currentPage);

        return forkJoin({
            userId:             this.authProfile.getAuthId(),
            landingResponse:    this.httpClient.get<LandingResponse>(`${ environment.host }/api/landing`, { params })
        })
        .pipe(
            tap(({ landingResponse }) => {
    
                const { content } = landingResponse;
                const { data, current_page } = content;
                const { posts, topics, news } = data;
                const totalItemsResponse: number = (posts.length + topics.length + news.length);

                // Si las 3 entidades: post, topics y news ya no poseen elementos, podemos considerar que 
                // hemos llegado al final de la Paginación.
                if ( totalItemsResponse <= 0 ) {

                    this.hasDonePaginating = true;

                    return;
                }

                // No hemos llegado a la última Página, podemos seguir paginando.
                // Guardamos una referencia a la siguiente Página.
                this.nextPage = current_page + 1;
            }),
            filter(() => {
                
                // Si ya no hay elementos, no dejes que estos pasen al siguiente operador.
                return !this.hasDonePaginating;
            }),
            map(({ userId, landingResponse }) => {

                const { content } = landingResponse;
                const { data } = content;
                const { posts, topics, news } = data;
                
                return [
                    ...this.paginatorLandingPostMappings.getPaginatorItemsPost(posts, userId),
                    this.paginatorLandingTopicsMapping.getPaginatorItemTopics(topics, userId),
                    this.paginatorLandingNewsMapping.getPaginatorItemNews(news)
                ];
            })
        );
    }

    public paginateNextResult(): void {

        this.paginationSubject.next(this.nextPage);
    }

    public hasNotReachedPaginationEnd(): boolean {

        return !this.hasDonePaginating;
    }
}
