<div class="coming-soon-page">
  <p>Coming Soon</p>
  <img src="assets/logo_full.svg" alt="SEROUNO">
  <p-carousel class="carousel"
              [value]="sliderImages"
              [numVisible]="1" [numScroll]="1"
              [autoplayInterval]="5000"
  >
    <ng-template let-image pTemplate="item">
      <img src="{{image}}" alt="Coming soon">
    </ng-template>
  </p-carousel>
</div>

