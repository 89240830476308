import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { concatMap, tap, filter} from 'rxjs/operators';

import { SubSink } from 'subsink';
import { MenuItem } from 'primeng/api';
import { MessageService } from 'primeng/api';

import { PostCommunicatorService } from '../../shared/services/post-communicator.service';
import { PostMediaService } from '../../shared/services/post-media.service';
import { PostImageService } from '../../shared/services/post-image.service';
import { PostTextService } from '../../shared/services/post-text.service';
import { PostLinkService } from '../../shared/services/post-link.service';
import { ModalService } from '@app/ui/modals/shared/services/modal.service';
import { LayoutConfigService } from '@app/layout-config/shared/services/layout-config.service';

import { PostAction, PostActionType, PostActionStatus } from '../../shared/models/post-action';
import { PostType } from '../../shared/models/post-type';
import { PostLink } from '../../shared/models/post-link';
import { PostText } from '../../shared/models/post-text';
import { PostImage } from '../../shared/models/post-image';
import { PostVideo } from '../../shared/models/post-video';

@Component({
    selector: 'app-edit-post-page',
    templateUrl: './edit-post-page.component.html',
    styleUrls: ['./edit-post-page.component.scss']
})
export class EditPostPageComponent implements OnInit, OnDestroy {

    private subscriptions = new SubSink();

    public selectedTopicId: number | undefined = undefined;
    public postId: number = 0;
    public postType!: PostType;

    public postMenuOptions!: MenuItem[];

    constructor(
        private readonly router: Router,
        private readonly currentRoute: ActivatedRoute,
        private readonly message: MessageService,
        private readonly postCommunicator: PostCommunicatorService,
        private readonly postMedia: PostMediaService,
        private readonly postImage: PostImageService,
        private readonly postText: PostTextService,
        private readonly postLink: PostLinkService,
        private readonly modal: ModalService,
        private readonly layoutConfig: LayoutConfigService
    ) {

        // Establecemos la configuración inicial de la barra de navegación y del footer.
        layoutConfig.setLayoutConfig({
            layout: {
                navbar: { visible: true },
                footer: { visible: false }
            }
        });
    }

    ngOnInit(): void {        

        // Al cargarse este Componente suceden 2 cosas:

        // 1. Obtenemos los detalles de la Publicación que deseamos editar.
        // Leemos el segmento de url para obtener el identificador de la Publicación (DATO CURIOSO: si haces +'' obtienes 0)
        const postId: string = this.currentRoute.snapshot.paramMap.get('id') || '';

        // Obtenemos los detalles de la Publicación ya sea de Texto, Video, Imagen o Link según corresponda.
        this.subscriptions.sink = this.postMedia.getPostMediaDetails(+postId).subscribe((postMedia) => { 
            
            // Guardamos una referencia del Topic asociado a esta Publicación.
            this.selectedTopicId = postMedia.topicId ?? undefined;

            // Guardamos una referencia del Identificador de esta Publicación.
            this.postId = postMedia.id;

            // Guardamos una referencia del Tipo de Publicación obtenido.
            this.postType = postMedia.postType;

            // Comunicamos al Panel de Edición de Publicación la publicación que será editada.
            this.postCommunicator.sendBackPublication(postMedia);

            // Cargamos el ménu con la opción para poder eliminar la Publicación.
            this.loadPostMenuOptions();
        });

        // 2. Cargamos el listener que se encarga de escuchar que Publicación desea el usuario publicar.
        this.subscriptions.sink = this.postCommunicator.incomingAction$.subscribe((post) => {

            if ( this.isValidPublication() ) {

                // Ejecuta la acción correspondiente.
                this.updatePost(post);
            }
        });
    }

    ngOnDestroy(): void {

        this.subscriptions.unsubscribe();
    }

    public onSelectTopic(topicId: number | undefined): void {

        this.selectedTopicId = topicId;
    }

    private updatePost(postAction: PostAction): void {
        
        switch (postAction.action) {

            case PostActionType.UpdateLinkPost:               

                this.editLinkPost(postAction.payload);
            break;

            case PostActionType.UpdateTextPost: 
                
                this.editTextPost(postAction.payload);
            break;

            case PostActionType.UpdateImagePost: 

                this.editImagePost(postAction.payload);
            break;

            case PostActionType.UpdateVideoPost: 

                this.editVideoPost(postAction.payload);
            break;
        }
    }

    private editLinkPost(post: Partial<PostLink>): void {
        
        let postId: number | undefined = undefined;

        // Agregamos a la publicación el topicId seleccionado.
        const postLink: Partial<PostLink> = { ...post, topicId: this.selectedTopicId };

        // Actualizamos el post.
        this.subscriptions.sink = this.postLink.update(postLink)
        .pipe(
            tap((post) => {

                postId = post.payload?.postId;

                // Notifica al panel de que hay una respuesta sobre un post publicado.       
                this.postCommunicator.notifyBackActionStatus(post);
            }),
            concatMap((post) => {

                return (post.state === 'Published') ? 
                    this.modal.successDialog('Tu cambios han sido guardados.').onClose : 
                    this.modal.failureDialog('Ocurrió un error, intentelo de nuevo').onClose;
            })
        ).subscribe(() => {

            this.handleSuccesfulPublishing(postId);
        });
    }
    
    private editTextPost(post: Partial<PostText>): void {

        let postId: number | undefined = undefined;

        // Agregamos a la publicación el topicId seleccionado.
        const postText: Partial<PostText> = { ...post, topicId: this.selectedTopicId };

        // Actualizamos el post.
        this.subscriptions.sink = this.postText.update(postText)
        .pipe(
            tap((post) => {

                postId = post.payload?.postId;

                // Notifica al panel de que hay una respuesta sobre un post publicado.       
                this.postCommunicator.notifyBackActionStatus(post);
            }),
            concatMap((post) => {

                return (post.state === 'Published') ? 
                    this.modal.successDialog('Tu cambios han sido guardados.').onClose : 
                    this.modal.failureDialog('Ocurrió un error, intentelo de nuevo').onClose;
            })
        ).subscribe(() => {

            this.handleSuccesfulPublishing(postId);
        });
    }

    private editImagePost(post: Partial<PostImage>): void {

        let postId: number | undefined = undefined;

        // Agregamos a la publicación el topicId seleccionado.
        const postImage: Partial<PostImage> = { ...post, topicId: this.selectedTopicId };

        // Actualizamos el post.
        this.subscriptions.sink = this.postImage.update(postImage)
        .pipe(
            tap((post) => {

                postId = post.payload?.postId;

                // Notifica al panel de que hay una respuesta sobre un post publicado.       
                this.postCommunicator.notifyBackActionStatus(post);
            }),
            concatMap((post) => {

                return (post.state === 'Published') ? 
                    this.modal.successDialog('Tu cambios han sido guardados.').onClose : 
                    this.modal.failureDialog('Ocurrió un error, intentelo de nuevo').onClose;
            })
        ).subscribe(() => {

            this.handleSuccesfulPublishing(postId);
        });
    }

    private editVideoPost(post: Partial<PostVideo>): void {
        
        let postId: number = 0;

        // Antes de enviar el post al Servidor, agregamos los campos topicId y postType
        const videoPost: Partial<PostVideo> = { ...post, topicId: this.selectedTopicId };

        // Enviamos la Publicación de Video de nuestro interés al responsable correspondiente.
        this.subscriptions.sink = this.modal.publishPostVideoDialog('EditionMode',videoPost).onClose
        .pipe(
            tap((post) => {

                postId = post?.payload?.postId;

                // NOTA: En una Publicación de Video realmente no es necesario notificar el estado de la publicación a su panel 
                // y formulario asociado. Lo mejor es omitir la comunicación del lado del panel no hay nadie escuchando.
            })
        )
        .subscribe(() => {

            if ( postId ) {
                
                this.router.navigate(['community/post', postId ]);
            }
        });
    }

    private removePost(postId: number): void {

        this.subscriptions.sink = this.modal.warningConfirmationDialog('¿Estás seguro de que quieres eliminar esta Publicación?').onClose
        .pipe(
            filter((confirmation) => confirmation === 'agreed'),
            concatMap(_ => {

                return this.postMedia.removePostMedia(postId)
            }),
            concatMap((postIsRemoved) => {

                return postIsRemoved 
                                ? this.modal.successDialog('Tu Publicación fue eliminada').onClose
                                : this.modal.failureDialog('Por el momento no se pudo eliminar esta Publicación, intentelo nuevamente').onClose;
            })
        )
        .subscribe(() => {
            
            this.router.navigateByUrl('/community');
        });
    }

    private isValidPublication(): boolean {

        if ( !this.selectedTopicId ) {
            
            // Muestra un mensaje al usuario en caso que no haya seleccionado un Topic.
            this.message.add({
                key:        'postManagerToastNotifications',
                severity:   'error',
                summary:    'Editar Publicación',
                detail:     'Elige un topic'
            });

            // Notifica al panel de que hay una respuesta sobre un post publicado.       
            this.postCommunicator.notifyBackActionStatus({
                state: 'NotPublished',
                payload: null
            } as PostActionStatus);

            return false;
        }

        return true;
    }

    private loadPostMenuOptions(): void {
        
        this.postMenuOptions = [{
            items: [
                { 
                    label: 'Eliminar Publicación', 
                    icon: 'pi pi-trash',
                    command: () => {

                        this.removePost(this.postId);
                    }
                }
            ]
        }];
    }

    private handleSuccesfulPublishing(postId: number | undefined): void {

        this.router.navigate(['community/post', postId ]);
    }
}
