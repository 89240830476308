<div class="team-page">
    <div class="team-topics-content">
        <div class="main-grid">
            <div class="grid-left">
                <button type="button" class="back" (click)="back()">
                    <img src="assets/iconos/icono_arrowdown.svg" alt="Regresar">Regresar
                </button>
                <div class="leagues-container">
                    <button *ngFor="let league of teamsByLeague; index as i"
                            [ngClass]="{'active': activeLeagueInt === i}"
                            (click)="showTeamsFromLeague(i)">
                        <img src="{{league.icon}}" alt="{{league.name}}">
                    </button>
                </div>

                <div class="teams-container">
                    <div *ngFor="let team of activeLeague;" class="team">
                        <a [routerLink]="['/team', team.id]">
                            <img src="{{team.logo}}" alt="{{team.name}}">
                        </a>
                </div>
                </div>
            </div>

            <div class="grid-right pt-50">
                <app-advertisement-type-c *ngIf="showAdTypeC" (loaded)="showAdTypeC = $event"></app-advertisement-type-c>
                <app-banner-community></app-banner-community>
            </div>
        </div>
    </div>
</div>
