import { NgModule, ErrorHandler } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ErrorHandlingRoutingRoutingModule } from './error-handling-routing.module';

import { LigamxErrorHandlerService } from './shared/services/ligamx-error-handler.service';
import { ErrorPageComponent } from './pages/error-page/error-page.component';
import { ErrorNotFoundPageComponent } from './pages/error-not-found-page/error-not-found-page.component';
import { ErrorForbiddenPageComponent } from './pages/error-forbidden-page/error-forbidden-page.component';
import { ErrorServerPageComponent } from './pages/error-server-page/error-server-page.component';
import { ErrorOfflinePageComponent } from './pages/error-offline-page/error-offline-page.component'

@NgModule({
    declarations: [
        ErrorPageComponent, 
        ErrorNotFoundPageComponent, 
        ErrorForbiddenPageComponent, 
        ErrorServerPageComponent, 
        ErrorOfflinePageComponent],
    imports: [
        CommonModule,
        ErrorHandlingRoutingRoutingModule
    ],
    exports: [],
    providers: [{ provide: ErrorHandler, useClass: LigamxErrorHandlerService }]
})
export class ErrorHandlingModule { }
