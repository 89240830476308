import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {NewDetail} from '../models/new-detail';
import {NewDetailResponse} from '../models/new-detail-response';
import {environment} from '@env/environment';
import {map} from 'rxjs/operators';
import {HttpClient} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class NewsService {

  constructor(
    private httpClient: HttpClient
  ) {
  }

  public getNewPublicDetail(id: number): Observable<NewDetail> {

    return this.httpClient.get<NewDetailResponse>(`${environment.host}/api/news/${id}/nosession`)
      .pipe(
        map((newDetailResponse) => {

          const {content} = newDetailResponse;
          const {id, url, title, description, image_local: urlImage, date, category, league_id: leagueId, team_id: teamId, currentUser, crawler: {domain: hostDomain, favicon}} = content;

          return {
            id,
            url,
            title,
            description,
            urlImage,
            date,
            hostDomain,
            category,
            leagueId,
            teamId,
            currentUser,
            favicon: favicon
          };
        })
      );
  }

}
