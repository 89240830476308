<header>

  <button class="burger" (click)="showGeneralMenu()">
    <span></span><span></span><span></span>
  </button>

  <div class="general-menu menu {{generalButtonActive ? 'active' : ''}}">
    <div class="content">
      <button class="close" (click)="showGeneralMenu()">
        <span></span><span></span>
      </button>
      <div class="menu-button user">
      <span class="img">
              <img class="user" [src]="avatar" [alt]="username">
      </span>
        <span class="info">
        <span>{{ username }}</span>
        <span><img *ngIf="fanLevelPlate" [src]="fanLevelPlate" [alt]="fanLevel">
          {{ fanLevel }}</span>
      </span>
      </div>
      <p-menu [model]="generalMenu"></p-menu>
    </div>
  </div>

  <a [routerLink]="['/']">
    <img class="logo" src="assets/logo_full.svg" alt="Serouno">
  </a>

  <div class="page-menu menu">
    <div class="menu-button page">
      <span class="img">
              <img *ngIf="favoriteTeamLogo"
                   class="team"
                   [src]="favoriteTeamLogo"
                   alt="Imagen de tu Equipo">
      </span>
      <p-splitButton label="Inicio" [model]="pagesMenu"></p-splitButton>
    </div>
    <ul class="links">
      <li><a [routerLink]="['/account']">Mi perfil</a></li>
    </ul>
  </div>

  <!-- Búsqueda -->
  <div class="search">
    <div class="icon-input">
      <input #search type="text" placeholder="Buscar" (keyup.enter)="onSearch(search.value); search.value=''">
      <img src="assets/iconos/icono_buscar.svg" alt="Buscar">
    </div>
  </div>

  <div class="buttons">
    <a title="Noticias" [routerLink]="['/news']"><img src="assets/iconos/icono_noticias.svg" alt="Noticias"></a>
    <a title="Comunidad" [routerLink]="['/community']"><img src="assets/iconos/icono_comunidad.svg" alt="Comunidad"></a>
    <a title="Equipos" [routerLink]="['/team-topics']"><img src="assets/iconos/icono_equipos.svg" alt="Equipos"></a>
  </div>

  <a class="new-post" [href]="['/#/post/create/text-post']" title="Crear Post">
    <svg width="21" height="23" viewBox="0 0 21 23" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M10.2438 5.85352C10.417 5.85352 10.5832 5.92235 10.7057 6.04486C10.8282 6.16738 10.8971 6.33355 10.8971 6.50682V10.4267H14.8169C14.9902 10.4267 15.1563 10.4955 15.2789 10.618C15.4014 10.7405 15.4702 10.9067 15.4702 11.08C15.4702 11.2532 15.4014 11.4194 15.2789 11.5419C15.1563 11.6644 14.9902 11.7333 14.8169 11.7333H10.8971V15.6531C10.8971 15.8264 10.8282 15.9925 10.7057 16.115C10.5832 16.2376 10.417 16.3064 10.2438 16.3064C10.0705 16.3064 9.90434 16.2376 9.78182 16.115C9.6593 15.9925 9.59047 15.8264 9.59047 15.6531V11.7333H5.67064C5.49737 11.7333 5.3312 11.6644 5.20868 11.5419C5.08616 11.4194 5.01733 11.2532 5.01733 11.08C5.01733 10.9067 5.08616 10.7405 5.20868 10.618C5.3312 10.4955 5.49737 10.4267 5.67064 10.4267H9.59047V6.50682C9.59047 6.33355 9.6593 6.16738 9.78182 6.04486C9.90434 5.92235 10.0705 5.85352 10.2438 5.85352V5.85352Z"
            fill="#29EB4F"/>
      <mask id="path-2-inside-1" fill="white">
        <rect width="20.9058" height="22.1601" rx="0.418115"/>
      </mask>
      <rect width="20.9058" height="22.1601" rx="0.418115" stroke="#29EB4F" stroke-width="2.50869"
            mask="url(#path-2-inside-1)"/>
    </svg>

  </a>

  <div class="user-menu menu">

    <div class="menu-button user">
      <span class="img">
              <img class="user" [src]="avatar" [alt]="username">
      </span>
      <span class="info">
        <span>{{ username }}</span>
        <span><img *ngIf="fanLevelPlate" [src]="fanLevelPlate" [alt]="fanLevel">
          {{ fanLevel }}</span>
      </span>
    </div>
    <p-splitButton label="Inicio" [model]="userMenu"></p-splitButton>

  </div>

  <div class="change-team">
    <button><img src="assets/iconos/icon_cambiarequipoi.svg" alt="Cambiar Equipo" (click)="changeTeamPreference()">
    </button>
  </div>
</header>
