import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';

import { PasswordSettings } from '../../shared/models/password-settings';

import { CommonValidators } from '@app/core/validators/common-validators';

@Component({
    selector: 'app-change-password-form',
    templateUrl: './change-password-form.component.html',
    styleUrls: ['./change-password-form.component.scss']
})
export class ChangePasswordFormComponent implements OnInit {
    
    @Input() public changePasswordLoading: boolean = false;
    @Output() private changePassword: EventEmitter<PasswordSettings> = new EventEmitter<PasswordSettings>();

    public changePasswordForm!: FormGroup;

    constructor(private fb: FormBuilder) { }

    ngOnInit(): void {
        
        this.loadChangePasswordForm();
    }

    public onChangePassword(): void {

        this.changePasswordForm.markAllAsTouched();

        if ( this.changePasswordForm.valid ) {

            const { currentPassword, newPassword, confirmPassword } = this.changePasswordForm.value;

            this.changePassword.emit({ currentPassword, newPassword, confirmPassword } as PasswordSettings);
        }
    }

    private loadChangePasswordForm(): void {

        this.changePasswordForm = this.fb.group({
            currentPassword:    ['', [ Validators.required, CommonValidators.mustNotBeBlank ]],
            newPassword:        ['', [ Validators.required, Validators.minLength(6), CommonValidators.mustNotBeBlank ]],
            confirmPassword:    ['', [ Validators.required, CommonValidators.mustNotBeBlank ]]
        });
    }

    // Getters.
    get currentPassword() {

        return this.changePasswordForm.get('currentPassword');
    }

    get newPassword() {

        return this.changePasswordForm.get('newPassword');
    }

    get confirmPassword() {

        return this.changePasswordForm.get('confirmPassword');
    }

    // Errores generados en el campo currentPassword.
    get errorOnCurrentPasswordField() {

        // Nos interesa solo mostrar el primer error generado. En esta caso si el campo 'currentPassword' genera más 
        // de 1 error, no queremos que se empalmen mostrando 2 mensajes de errores al mismo tiempo.
        return Object.keys(this.changePasswordForm.get('currentPassword')?.errors || {})[0];
    }

    // Errores generados en el campo newPassword.
    get errorOnNewPasswordField() {

        // Nos interesa solo mostrar el primer error generado. En esta caso si el campo 'newPassword' genera más 
        // de 1 error, no queremos que se empalmen mostrando 2 mensajes de errores al mismo tiempo.
        return Object.keys(this.changePasswordForm.get('newPassword')?.errors || {})[0];
    }

    // Errores generados en el campo confirmPassword.
    get errorOnConfirmPasswordField() {

        // Nos interesa solo mostrar el primer error generado. En esta caso si el campo 'confirmPassword' genera más 
        // de 1 error, no queremos que se empalmen mostrando 2 mensajes de errores al mismo tiempo.
        return Object.keys(this.changePasswordForm.get('confirmPassword')?.errors || {})[0];
    }
}
