<div class="single-post-grid main-grid">
    <div class="left grid-left">
      <button type="button" (click)="back()" class="back">
        <img src="assets/iconos/icono_arrowdown.svg" alt="Regresar">Regresar
      </button>
  
      <div class="title">
        <h3>Editar Topic</h3>
      </div>

      <ng-container *ngIf="currentTopicLoaded; else loadingCurrentTopic">
        <app-edit-topic-form
          [currentTopic]="currentTopic"
          [changeCurrentTopicLoading]="loadingChangeCurrentTopicAction"
          [removeCurrentTopicLoading]="loadingRemoveCurrentTopicAction"
          (update)="onUpdateTopic($event)"
          (remove)="onRemoveTopic($event)"
          (cancel)="back()"></app-edit-topic-form>
      </ng-container>

      <ng-template #loadingCurrentTopic>
        <div style="text-align: center;">
            <i class="pi pi-spin pi-spinner spinner spinner-lg"></i>
        </div>    
    </ng-template>
    </div>
</div>
