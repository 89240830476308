import { Injectable } from '@angular/core';
import { Router, CanActivate, CanActivateChild, ActivatedRouteSnapshot, RouterStateSnapshot, NavigationExtras, UrlTree } from '@angular/router';

import { AuthService } from '../services/auth.service';

@Injectable({
    providedIn: 'root'
})
export class AuthenticateService implements CanActivate, CanActivateChild {

    constructor(
        private router: Router,
        private authService: AuthService
    ) { }

    // Este guardia tiene el comportamiento inverso al guardia RedirectIfAuthenticatedService.
    // En caso de no estar autenticado, me redirecciona al /login, en constraste, al estar autenticado
    // seguimos en potencial activación de la siguiente ruta en el stack.
    // Url de prueba, copy & paste in the webbrowser: https://localhost:4200/#/community/post/225
    canActivate(route: ActivatedRouteSnapshot, routerState: RouterStateSnapshot): boolean | UrlTree {

        if ( !(this.authService.isAuthenticated()) ) {
            
            const intendedUrl: string = routerState.url;
            const navigationExtras: NavigationExtras = {
                // Si la url a la que se pretende ir es la raíz, no tiene caso crear una url de retorno.
                // La funcion btoa() nos permite codificar un string a base64
                queryParams: (intendedUrl === '/') ? {} : { intendedUrl: btoa(intendedUrl) }
            };
            
            return this.router.createUrlTree(['/login'], navigationExtras);
        }

        return true;
    }

    canActivateChild(route: ActivatedRouteSnapshot, routerState: RouterStateSnapshot): boolean | UrlTree {

        return this.canActivate(route, routerState);
    }
}
