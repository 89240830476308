import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { SubSink } from 'subsink';

import { PaginatorSearchResultsPostsService } from '../../shared/services/paginator-search-results-posts.service';
import { PaginatorSearchResultsPostMappingsService } from '../../shared/services/paginator-search-results-post-mappings.service';

import { PaginatorItemPost } from '../../shared/models/paginator-item-post';
import { SearchCriteria } from '../../shared/models/search-criteria';
import { DropDownFilterOption, DropDownFilterDirection } from '../../shared/models/dropdown-filter';

@Component({
  selector: 'app-search-results-posts-panel',
  templateUrl: './search-results-posts-panel.component.html',
  styleUrls: ['./search-results-posts-panel.component.scss'],
  providers: [ 
    PaginatorSearchResultsPostsService,
    PaginatorSearchResultsPostMappingsService 
  ]
})
export class SearchResultsPostsPanelComponent implements OnInit, OnDestroy {

  private subscriptions = new SubSink();

  public initialLoadingDone: boolean = false;
  public loadingMorePosts: boolean = false;
  public noMoreResultsWereFound = false;
  public noResultsWereFound = false;

  public searchExpression: string | null = '';
  public selectedFilter: DropDownFilterDirection  = 'DESC';

  public paginatorItemPosts: PaginatorItemPost[] = [];
  public paginatorItemPostsPreviouslyLoaded: PaginatorItemPost[] = [];

  public dropDownFilters: DropDownFilterOption[] = [
    { dropDownFilterLabel: 'Más recientes', dropDownFilterDirection: 'DESC' },
    { dropDownFilterLabel: 'Menos recientes', dropDownFilterDirection: 'ASC' }
  ];

  constructor(
    private readonly router: Router,
    private readonly currentRoute: ActivatedRoute,
    private readonly paginatorSearchResultsPosts: PaginatorSearchResultsPostsService
  ) {
    
    this.subscriptions.sink = this.paginatorSearchResultsPosts.listenPagination$.subscribe((posts) => {     
        
        this.initialLoadingDone = true;
        this.loadingMorePosts = false;

        // Si no hay resultados disponibles, terminamos.
        if ( posts.length <= 0 ) {

          this.noResultsWereFound = true;

          return;
        }

        // Solo en caso que haya resultados disponibles, pasamos a las acciones de Paginación.
        this.paginateResults(posts);

        if ( this.paginatorSearchResultsPosts.hasReachedPaginationEnding() ) {

          this.noMoreResultsWereFound = true;
        } 
    });
  }

  ngOnInit(): void {

    this.subscriptions.sink = this.currentRoute.queryParamMap.subscribe((queryParamMap) => {
      
      this.searchExpression = queryParamMap.get('q');
      this.selectedFilter = 'DESC';

      if ( !this.searchExpression ) {

        this.router.navigateByUrl('/404');
        
        return;
      }

      this.initialLoadingDone = false;

      // Reiniciamos el Paginador y limpiamos los resultados de búsqueda.
      this.clearResultSet();

      // En este caso avanza a la primer Página comunicando el término de búsqueda y el orden de los datos
      this.paginatorSearchResultsPosts.moveToNextPage(
        { searchFor: this.searchExpression, sortDirection: this.selectedFilter } as SearchCriteria
      );
    });
  }

  ngOnDestroy(): void {

    this.subscriptions.unsubscribe();
  }

  public onScroll(): void {

    if ( this.paginatorSearchResultsPosts.hasReachedPaginationEnding() ) {

      this.noMoreResultsWereFound = true;

      return;
    }

    // Avanzar a la siguiente Página comunicando el término de búsqueda y el orden de los datos.
    this.loadingMorePosts = true;

    this.paginatorSearchResultsPosts.moveToNextPage(
      { searchFor: this.searchExpression, sortDirection: this.selectedFilter } as SearchCriteria
    );
  }

  public onChangeFilter(selectedFilter: DropDownFilterDirection): void {

    this.initialLoadingDone = false;

    // Reiniciamos el Paginador y limpiamos los resultados de búsqueda.
    this.clearResultSet();

    // Al reiniciar debido a la selección del nuevo filtro, avanzamos a la primera Página comunicando 
    // el término de búsqueda y el orden de los datos.
    this.paginatorSearchResultsPosts.moveToNextPage(
      { searchFor: this.searchExpression, sortDirection: selectedFilter } as SearchCriteria
    );
  }

  private clearResultSet(): void {

    // Reiniciamos el Servicio de Paginación.
    this.paginatorSearchResultsPosts.resetPaginator();
    // Limpiamos cualquier valor almacenado en memoria.
    this.paginatorItemPosts = this.paginatorItemPostsPreviouslyLoaded = [];
    // Reinicia los valores de los Flags de Mensajes. 
    this.noMoreResultsWereFound = false;
    this.noResultsWereFound = false;
  }

  private paginateResults(posts: PaginatorItemPost[]): void {

    // Fusionamos los posts previamente cargadados con los posts que provengan del Servidor.
    this.paginatorItemPostsPreviouslyLoaded = [ ...this.paginatorItemPostsPreviouslyLoaded, ...posts ];

    // Actualizamos la vista con la nueva información cargada en el Property Binding correspondiente.
    this.paginatorItemPosts = this.paginatorItemPostsPreviouslyLoaded;
  }
}
