<div
    *ngIf="initialLoadingDone; else showLoadingIndicator"
    class="landing-posts"
    infiniteScroll
    [infiniteScrollDistance]="3"
    [infiniteScrollThrottle]="100"
    (scrolled)="onScroll()"
    [scrollWindow]="false">

    <ng-container *ngFor="let paginatorItemPost of paginatorItemPosts">
        <ng-container
            [ngTemplateOutlet]="postTemplate"
            [ngTemplateOutletContext]="{ $implicit: paginatorItemPost }"></ng-container>
    </ng-container>
</div>

<!-- Este spinner es visible al final de la pantalla cuando se dispara el evento (scroll) -->
<div style="text-align: center;" *ngIf="loadingMorePosts">
    <i class="pi pi-spin pi-spinner spinner spinner-md"></i>
</div>

<!-- Template de la Publicación para mostrar en el Paginador. -->
<ng-template #postTemplate let-post>
    <ng-container [ngSwitch]="true">
        <!-- Post de Contenido Media -->
        <ng-container *ngSwitchCase="post.type === 1 || post.type === 2 || post.type === 3 || post.type === 4"
            [ngTemplateOutlet]="postMediaTemplate"
            [ngTemplateOutletContext]="{ $implicit: post }"></ng-container>
        <!-- Post de Votación -->
        <ng-container *ngSwitchCase="post.type === 5"
            [ngTemplateOutlet]="postPollingTemplate"
            [ngTemplateOutletContext]="{ $implicit: post }"></ng-container>
        <!-- Anuncio de tipo B -->
        <ng-container *ngSwitchDefault
            [ngTemplateOutlet]="advertisementTypeBTemplate"></ng-container>
    </ng-container>

    <!-- Template para la Publicación de Contenido Media. -->
    <ng-template #postMediaTemplate let-postMedia>
        <app-post-media-expanded [post]="postMedia" [showTopicAsPostHeader]="true">
            <!-- Aqui incluimos el contenido Media dependiendo del tipo de Post: texto, link, imagen o video -->
    
            <!-- Es un Post de Link? -->
            <!-- Link Preview content goes here -->
            <app-link-preview
                *ngIf="postMedia.type === 2"
                [originalLinkSource]="postMedia.media.content.originalLinkSource"
                [metaTags]="postMedia.media.content.metaTags"></app-link-preview>
    
            <!-- Es un Post de Imagen? -->
            <!-- Image content goes here -->
            <img
                *ngIf="postMedia.type === 3"
                [src]="postMedia.media.content"
                [alt]="postMedia.description">
    
            <!-- Es un Post de Video? -->
            <!-- Video content goes here -->
            <video
                *ngIf="postMedia.type === 4"
                width="100%"
                height="360px"
                controls>
                <source type="video/mp4" [src]="postMedia.media.content">
            </video>
    
            <!-- Es un Post de Texto? -->
            <!-- Un Post de Texto no posee ningún tipo de contenido media -->
        </app-post-media-expanded>
    </ng-template>

    <!-- Template para la Publicación de Votación. -->   
    <ng-template #postPollingTemplate let-postPolling>
        <!-- Es un Post de Votaciones? -->
        <app-post-polling [post]="postPolling"></app-post-polling>
    </ng-template>

    <!-- Template para el Anuncio de tipo B que se va a insertar. -->
    <ng-template #advertisementTypeBTemplate>
        <app-advertisement-type-b></app-advertisement-type-b>
    </ng-template>
</ng-template>

<ng-template #showLoadingIndicator>
    <!-- Este spinner es cuando se cargan inicialmente los Posts por vez primera. -->
    <div style="text-align: center;">
        <i class="pi pi-spin pi-spinner spinner spinner-lg"></i>
    </div>
</ng-template>
