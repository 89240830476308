<div class="team-page" *ngIf="topicsLoaded; else showLoadingIndicator">
  <!-- Pagina de equipo -->

  <div class="team-list">
    <div class="community-topics"
         infiniteScroll
         [infiniteScrollDistance]="2"
         [infiniteScrollThrottle]="50"
         [scrollWindow]="false"
         (scrolled)="onScroll()">

      <div class="main-grid">


        <div class="grid-left">
          <button type="button" class="back" (click)="back()">
            <img src="assets/iconos/icono_arrowdown.svg" alt="Regresar">Regresar
          </button>

          <div class="team-header">
            <div class="left">
              <img [src]="teamFlagIcon" [alt]="teamName">
            </div>

            <div class="right">
              <h4>{{ teamName }}</h4>
              <p *ngIf="teamHomeUrl" class="url">
                <a [href]="teamHomeUrl" target="_blank">{{ teamHomeUrl }}</a>
              </p>

              <div class="social">
                <a *ngIf="teamFB && teamFB.length > 0" href="{{teamFB}}" target="_blank">
                  <img src="assets/iconos/icono_facebook_team.svg"
                       alt="Facebook">{{teamFBClean}}</a>
                <a *ngIf="teamTW && teamTW.length > 0" href="{{teamTW}}" target="_blank">
                  <img src="assets/iconos/icono_twitter_team.svg"
                       alt="Twitter">{{teamTWClean}}</a>
                <a *ngIf="teamIG && teamIG.length > 0" href="{{teamIG}}" target="_blank">
                  <img src="assets/iconos/icono_instagram_team.svg"
                       alt="instagram">{{teamIGClean}}</a>
              </div>
            </div>
          </div>

          <h3><img src="assets/iconos/icono_comunidad.svg" alt="Comunidad">Topics Comunidad</h3>

          <div class="item" *ngFor="let topic of topicsByTeam">
            <app-topic-call-to-action
              [id]="topic.id"
              [title]="topic.title"
              [cover]="topic.cover"
              [isOfficial]="topic.isOfficial"
              [following]="topic.following"
              [ownership]="topic.ownership"
              [totalFollowers]="topic.totalFollowers"
              [totalPublishedPosts]="topic.totalPublishedPosts">
            </app-topic-call-to-action>

          </div>

          <!-- Este es el botón que nos va a llevar al inicio una vez hemos alcanzado el final del Scroller -->
          <div style="text-align: center;" *ngIf="showGotoFirstButton">
            <p><b>.</b></p>
          </div>

          <!-- Este spinner es visible cuando se dispara el evento (scrolled) -->
          <div style="text-align: center;" *ngIf="loadingMoreTopics">
            <i class="pi pi-spin pi-spinner spinner spinner-md"></i>
          </div>

        </div>
        <div class="grid-right pt-50">
          <app-advertisement-type-c *ngIf="showAdTypeC" (loaded)="showAdTypeC = $event"></app-advertisement-type-c>
          <app-banner-community></app-banner-community>
        </div>
      </div>

    </div>


  </div>
</div>
<!-- Termina pagina de equipos -->


<ng-template #showLoadingIndicator>
  <!-- Este spinner es cuando se cargan inicialmente las noticias. -->
  <div class="loading" style="text-align: center;">
    <i class="pi pi-spin pi-spinner spinner spinner-lg"></i>
  </div>
</ng-template>
