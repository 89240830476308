import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AdvertisementTypeAComponent } from './components/advertisement-type-a/advertisement-type-a.component';
import { AdvertisementTypeBComponent } from './components/advertisement-type-b/advertisement-type-b.component';
import { AdvertisementTypeCComponent } from './components/advertisement-type-c/advertisement-type-c.component';
import { AdvertisementTypeDComponent } from './components/advertisement-type-d/advertisement-type-d.component';

@NgModule({
  declarations: [
    AdvertisementTypeAComponent, 
    AdvertisementTypeBComponent, 
    AdvertisementTypeCComponent, 
    AdvertisementTypeDComponent
  ],
  imports: [
    CommonModule
  ],
  exports: [
    AdvertisementTypeAComponent, 
    AdvertisementTypeBComponent, 
    AdvertisementTypeCComponent, 
    AdvertisementTypeDComponent
  ]
})
export class AdvertisementModule { }
