import { PostText } from './post-text';
import { PostLink } from './post-link';
import { PostImage } from './post-image';
import { PostVideo } from './post-video';
import { PostPolling } from './post-polling';

export interface PostAction {
    action:     PostActionType;
    payload:    Partial<PostText | PostLink | PostPolling | PostImage | PostVideo>; 
}

export enum PostActionType {
    CreateLinkPost =      'create-link-post',
    CreateImagePost =     'create-image-post',
    CreateVideoPost =     'create-video-post',
    CreateTextPost =      'create-text-post',
    CreatePolllingPost =  'create-polling-post',
    UpdateLinkPost =      'update-link-post',
    UpdateImagePost =     'update-image-post',
    UpdateVideoPost =     'update-video-post',
    UpdateTextPost =      'update-text-post',
};

export interface PostActionStatus {
    state: 'NotPublished' | 'Published';
    payload: { postId: number } | null;
}