<div class="password-reset">
  <button type="button" class="close" (click)="close()"><img src="assets/iconos/x_cancelar.svg" alt="Cancelar"></button>
  <p>¿Olvidaste tu contraseña?</p>
  <p>Por favor, escribe tu correo electrónico. Recibiras una contraseña temporal que podrás cambiar más
    adelante.</p>

  <!-- Formulario de recuperación de contraseña -->
  <div *ngIf="!emailSent && !errorMessage">
    <app-password-recovery-form
      [recoverPasswordLoading]="sendingEmailAction"
      (recoverPassword)="onPasswordRecovery($event)"></app-password-recovery-form>
  </div>

  <!-- Mensaje de éxito cuando la contraseña fue enviado al destinatario -->
  <div *ngIf="emailSent">
    <p>
      <span class="success-feedback-message">
      Su contraseña temporal fue enviada a {{ recipient }}
    </span>
    </p>
  </div>

  <!-- Mensaje de error cuando la cuenta de correo no existe o se encuentra vinculada a una red social-->
  <div *ngIf="errorMessage">
      <p>
        <span class="invalid-feedback-message">
        {{ errorMessage }}
      </span>
      </p>
  </div>
</div>

