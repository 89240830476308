import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { WebviewsRoutingModule } from './webviews-routing.module';
import { WebviewsPageComponent } from './pages/webviews-page/webviews-page.component';
import {BannerCommunityModule} from "@app/ui/banner-community/banner-community.module";
import {TopicModule} from "@app/ui/topic/topic.module";
import {AdvertisementModule} from "@app/ui/advertisement/advertisement.module";


@NgModule({
  declarations: [WebviewsPageComponent],
  imports: [
    CommonModule,
    WebviewsRoutingModule,
    BannerCommunityModule,
    TopicModule,
    AdvertisementModule
  ]
})
export class WebviewsModule { }
