import { FormGroup, ValidationErrors } from '@angular/forms';

import { Video } from '@app/ui/single-video-uploader-field/shared/models/video';

export class PostVideoValidators {

    static mustBeValidVideo(coverControl: FormGroup):  ValidationErrors | null {

        const video: Video | null = coverControl.value;

        if ( !video ) {

            return {
                'fileRequired': true
            };
        }

        const errorMessages: { [name: string]: string } = {
            'unselected_file':      'unSelectedFile',
            'invalid_mime_type':    'invalidMimeType',
            'invalid_file_size':    'invalidFileSize'
        };

        const { errorCode } = video;

        if ( errorCode ) {
            
            return { [ errorMessages[errorCode] ]: true } as ValidationErrors;    
        }

        return null;
    }
}