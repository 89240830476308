<form [formGroup]="signInForm" (ngSubmit)="onSignIn()" novalidate>

  <button class="close" type="button" (click)="back()"><img src="assets/iconos/icono_arrowdown.svg" alt="Regresar">
    Regresar
  </button>

  <div class="inputs">
    <div style="margin-bottom: 25px;">
      <div class="icon-input">
        <input
            type="email"
            class="input-box-face input-box-green-text"
            name="email"
            placeholder="Correo Electrónico"
            formControlName="email"
            pInputText>
        <img src="assets/iconos/icono_correo_verde.svg" alt="Correo">
      </div>

      <ng-container *ngIf="email?.invalid && (email?.dirty || email?.touched)">
        <span *ngIf="email?.hasError('required')" class="invalid-feedback-message">
          <small>Ingrese su correo electrónico</small>
        </span>
        <span *ngIf="email?.hasError('email')" class="invalid-feedback-message">
          <small>Ingrese un correo electrónico válido</small>
        </span>
      </ng-container>
    </div>

    <div style="margin-bottom: 15px;">
      <app-password-field
            placeholder="Contraseña"
            formControlName="password"></app-password-field>

      <ng-container *ngIf="password?.invalid && (password?.dirty || password?.touched)">
        <span *ngIf="password?.hasError('required')" class="invalid-feedback-message">
          <small>Ingrese su contraseña</small>
        </span>
      </ng-container>
    </div>
  </div>

  <!--
      TODO: actualizar la url a donde se enviará al usuario para llevar a cabo el proceso de reseteo
      de su contraseña.
  -->
  <div class="controls">
    <button class="password-reset" type="button" (click)="onPasswordRecovery()">¿Olvidaste tu contraseña?</button>
    <button type="submit" [disabled]="signInLoading">

      <ng-container *ngIf="!signInLoading; else loading">
        Iniciar <img src="assets/iconos/icono_arrow.svg" alt="Iniciar">
      </ng-container>

      <ng-template #loading>
        <i class="pi pi-spin pi-spinner button-spinner"></i>
      </ng-template>
    </button>
  </div>
</form>

