import { Component, OnInit } from '@angular/core';

import { LayoutConfigService } from '@app/layout-config/shared/services/layout-config.service';

@Component({
  selector: 'app-privacy-policy-page',
  templateUrl: './privacy-policy-page.component.html',
  styleUrls: ['./privacy-policy-page.component.scss']
})
export class PrivacyPolicyPageComponent implements OnInit {

  constructor(private readonly layoutConfig: LayoutConfigService) {

    // Establecemos la configuración inicial de la barra de navegación y del footer.
    layoutConfig.setLayoutConfig({
        layout: {
            navbar: { visible: false },
            footer: { visible: true }
        }
    });
  }

  ngOnInit(): void {
  }

}
