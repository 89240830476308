<form [formGroup]="accountActivationForm" (ngSubmit)="onActivateAccount()" novalidate autocomplete="off">
  <div class="input">
    <input type="text" placeholder="Ingresa el código de verificación" formControlName="activationCode">
    <!-- Posibles Mensajes de error para el campo activationCode -->
    <ng-container *ngIf="activationCode?.invalid && (activationCode?.dirty || activationCode?.touched)">
      <ng-container [ngSwitch]="errorOnActivationCodeField">
                <span *ngSwitchCase="'required'" class="invalid-feedback-message">
                    <small>Ingrese el Código de Verificación.</small>
                </span>
        <span *ngSwitchCase="'blank'" class="invalid-feedback-message">
                    <small>Ingrese un Código de Verificación válido.</small>
                </span>
      </ng-container>

    </ng-container>
      <!-- Hay que darle estilo al mensaje que recibamos derivado de la intereacción con la Activación de la Cuenta. -->
    <span class="invalid-feedback-message"
          *ngIf="accountActivationCodeMessage"><small>{{ accountActivationCodeMessage }}</small></span>

  </div>

  <p class="code-error"><span>¿No te llego el código?</span>
    <button class="inline-button" type="button" (click)="onResendActivationCode()">Reenviar Código</button>
  </p>


  <button type="submit" [disabled]="accountActivationLoading">
    <ng-container *ngIf="!accountActivationLoading; else loading">Aceptar</ng-container>

    <ng-template #loading>
      <i class="pi pi-spin pi-spinner button-spinner"></i>
    </ng-template>
  </button>
</form>
