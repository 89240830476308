import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Urls} from '@app/core/utilities/urls';
import {environment} from '@env/environment';
import {Observable} from 'rxjs';
import {Topic, TopicsResponse} from '../../shared/models/community';
import {New, NewsResponse} from '../../shared/models/news';
import {map} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class CommunityService {

  constructor(
    private readonly httpClient: HttpClient,
  ) {
  }

  public getMyTopics(page: number = 1, order = 'DESC'): Observable<Topic[]> {
    const params = new HttpParams()
      .set('page', `${page}`)
      .set('order', order)
      .set('order_by', 'DATE');
    return this.httpClient.get<TopicsResponse>(`${environment.host}/api/user/topics`, {params})
      .pipe(
        map((topicResponse) => {
          console.log(topicResponse);
          const {data} = topicResponse.content;
          return data.map((topic) => {
            return {
              ...topic
            };
          });
        })
      );
  }

  public getMyNews(page: number = 1, order = 'DESC'): Observable<New[]> {
    const params = new HttpParams()
      .set('page', `${page}`)
      .set('order', order)
      .set('order_by', 'DATE');
    return this.httpClient.get<NewsResponse>(`${environment.host}/api/user/news/i/follow`, {params})
      .pipe(
        map((newResponse) => {
          const {data} = newResponse.content;
          return data.map((item) => {
            return {
              ...item,
              url: Urls.getHostName(item.url)
            };
          });
        })
      );
  }

}
