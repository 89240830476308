import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';

import { Observable, throwError, EMPTY } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { AuthService } from '../services/auth.service';

// No tiene sentido enviar el Token a estas endpoints o enviar 
// una cabecera Authorization vacia dentro de la petición.
const endpointsExcluded: string [] = [
    '/api/auth/login',
    '/api/auth/register',
    '/api/teams/league',
    '/api/auth/password-recovery',
];

@Injectable({
    providedIn: 'root'
})
export class TokenInterceptorService implements HttpInterceptor {

    constructor( 
        private router: Router,
        private authService: AuthService
    ) { }

    intercept( request: HttpRequest<any>, next: HttpHandler ): Observable<HttpEvent<any>> {

        let req!: HttpRequest<any>;

        // Si la endpoint NO es elegible para adjuntarle un Token
        // o si la petición ya viene con un Token, en ambos casos, dejala pasar.
        if ( this.endpointIsNotTokenAttachable(request) || this.endpointHasTokenAttached(request) ) {

            req = request;

        } else {

            // En este caso, a la petición saliente le debemos adjuntar un Token.
            // Catch, Modify & add the token to the outgoing request.
            req = request.clone({ setHeaders: { Authorization: `Bearer ${ this.authService.getToken() }` } });
        }
        
        return next.handle(req)
                .pipe(
                    catchError((error: HttpErrorResponse) => {

                        const httpError: any = error.error;
                        const httpErrorCode: number = error.status;
                        
                        if ( httpErrorCode === 400 ) {
                            
                            // Determina el estado de la respuesta con respecto al estado del Token:
                            // token_expired
                            // token_invalid
                            // token_absent                          
                            if ( 
                                httpError.message && 
                                (httpError.message === 'token_expired' || httpError.message === 'token_invalid' || httpError.message === 'token_absent') ) { 

                                // Removemos lo que hay en el localStorage.
                                this.authService.removeAuthProfileFromLocalStorage();
                                // Redireccionamos a la ruta /login.
                                this.router.navigate(['/login'], { queryParams: { session: 'expired'} });

                                return EMPTY;
                            }
                        }

                        return throwError(error); 
                    })
                );
    }

    private endpointIsNotTokenAttachable(request: HttpRequest<any>): boolean {

        const endpointUrl: string = request.url;

        return endpointsExcluded.some((endpoint) => endpointUrl.includes(endpoint) );
    }

    private endpointHasTokenAttached(request: HttpRequest<any>): boolean {

        return request.headers.has('Authorization');
    }
}
