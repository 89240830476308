import { Component, OnInit } from '@angular/core';

import { DynamicDialogRef } from 'primeng/dynamicdialog';

import { PasswordRecoveryService } from '../../shared/services/password-recovery.service';

@Component({
    selector: 'app-password-recovery',
    templateUrl: './password-recovery.component.html',
    styleUrls: ['./password-recovery.component.scss'],
    providers: [ PasswordRecoveryService ]
})
export class PasswordRecoveryComponent implements OnInit {

    public recipient: string = '';
    public sendingEmailAction: boolean = false;
    public emailSent: boolean = false;
    public errorMessage: string = '';

    constructor(
        private dialogRef: DynamicDialogRef,
        private passwordRecoveryService: PasswordRecoveryService
    ) { }

    ngOnInit(): void {
    }

    public onPasswordRecovery($event: string): void {

        const recipient: string = $event;

        this.sendingEmailAction = true;

            this.passwordRecoveryService.sendEmail(recipient).subscribe(
                (recipient) => {

                    this.sendingEmailAction = false;
                    this.emailSent = true;
                    this.recipient = recipient;
                },
                (err) => {

                    this.sendingEmailAction = false;

                    if ( err === 'account_dont_exist' ) {

                        this.errorMessage = 'La cuenta de correo destino no existe';

                        return;
                    }

                    if ( err === 'account_link_to_social' ) {

                        this.errorMessage = 'La cuenta de correo destino esta vinculada a una red social';
                        
                        return;
                    }

                    this.dialogRef.close();
                    throw err;
                }
            );
    }

    public close(): void {

        this.dialogRef.close();
    }
}
