import { Injectable } from '@angular/core';

import { PostMediaDetailsResponse } from '../models/post-response';
import { PostVideo } from '../models/post-video';
import { PostType } from '../models/post-type';

import { Video } from '@app/ui/single-video-uploader-field/shared/models/video';

@Injectable({
    providedIn: 'root'
})
export class PostVideoMappingService {

    constructor() {}

    public mapPostVideoDetails(postMediaDetailsResponse: PostMediaDetailsResponse): PostVideo {

        const { content } = postMediaDetailsResponse;
        const { id, description, video, topic } = content;

        const videoContent: Video = {
            name:               null,
            content:            video,
            type:               null,
            size:               null,
            isLoadedLocally:    false
        };

        return {
            id, 
            description,
            video:          videoContent,
            postType:       PostType.VIDEO,
            topicId:        topic.id
        } as PostVideo;
    }
}
