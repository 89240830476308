import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';

import { MenuModule } from 'primeng/menu';
import { InputTextModule } from 'primeng/inputtext';

import { SelectTeamFieldModule } from '../ui/select-team-field/select-team-field.module';
import { SingleBase64imageUploaderFieldModule } from '../ui/single-base64image-uploader-field/single-base64image-uploader-field.module';

import { TopicManagerRoutingModule } from './topic-manager-routing.module';

import { CreateTopicPageComponent } from './pages/create-topic-page/create-topic-page.component';
import { EditTopicPageComponent } from './pages/edit-topic-page/edit-topic-page.component';
import { CreateTopicFormComponent } from './components/create-topic-form/create-topic-form.component';
import { EditTopicFormComponent } from './components/edit-topic-form/edit-topic-form.component';
import {TopicModule} from "@app/ui/topic/topic.module";
import {AdvertisementModule} from "@app/ui/advertisement/advertisement.module";

@NgModule({
  declarations: [
    CreateTopicPageComponent,
    EditTopicPageComponent,
    CreateTopicFormComponent,
    EditTopicFormComponent,
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MenuModule,
    InputTextModule,
    SelectTeamFieldModule,
    SingleBase64imageUploaderFieldModule,
    TopicManagerRoutingModule,
    TopicModule,
    AdvertisementModule
  ],
})
export class TopicManagerModule { }
