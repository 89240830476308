<div class="input-box input-box-block icon-input {{inputClass}}">

  <ng-container
    [ngTemplateOutlet]="showPassword ? passwordShown : passwordHidden"
    [ngTemplateOutletContext]="{$implicit: password}"></ng-container>

  <span class="icon-show-password" (click)="handlePasswordInputMask()">
        <svg *ngIf="showPassword" width="13" height="10" viewBox="0 0 13 10" fill="none"
             xmlns="http://www.w3.org/2000/svg">
            <path
              d="M12.8716 4.60607C11.4675 1.5563 9.34502 0.0214844 6.49979 0.0214844C3.65307 0.0214844 1.53211 1.5563 0.128008 4.6076C0.0716891 4.73056 0.0424805 4.86486 0.0424805 5.00085C0.0424805 5.13683 0.0716891 5.27113 0.128008 5.39409C1.53211 8.44387 3.65455 9.97868 6.49979 9.97868C9.3465 9.97868 11.4675 8.44387 12.8716 5.39257C12.9856 5.14516 12.9856 4.85806 12.8716 4.60607V4.60607ZM6.49979 8.87911C4.11074 8.87911 2.36154 7.62988 1.12776 5.00008C2.36154 2.37028 4.11074 1.12105 6.49979 1.12105C8.88883 1.12105 10.638 2.37028 11.8718 5.00008C10.6395 7.62988 8.89031 8.87911 6.49979 8.87911ZM6.44054 2.31225C5.00089 2.31225 3.83377 3.51567 3.83377 5.00008C3.83377 6.4845 5.00089 7.68792 6.44054 7.68792C7.88019 7.68792 9.04731 6.4845 9.04731 5.00008C9.04731 3.51567 7.88019 2.31225 6.44054 2.31225ZM6.44054 6.71052C5.52373 6.71052 4.78169 5.94541 4.78169 5.00008C4.78169 4.05476 5.52373 3.28964 6.44054 3.28964C7.35736 3.28964 8.0994 4.05476 8.0994 5.00008C8.0994 5.94541 7.35736 6.71052 6.44054 6.71052Z"
              fill="#545456"/>
        </svg>
        <svg *ngIf="!showPassword" width="16" height="16" viewBox="0 0 16 16" fill="none"
             xmlns="http://www.w3.org/2000/svg">
            <g opacity="0.3">
            <path
              d="M11.1953 9.06673C11.3229 8.72564 11.3877 8.36424 11.3864 8.00006C11.3864 7.19144 11.0652 6.41595 10.4934 5.84417C9.92163 5.27239 9.14613 4.95117 8.33751 4.95117C7.97782 4.95159 7.6211 5.01631 7.28418 5.14228L7.99974 5.88006C8.10851 5.86266 8.21847 5.85374 8.32862 5.85339C8.90037 5.8522 9.44937 6.07728 9.85573 6.47948C10.2621 6.88168 10.4928 7.42833 10.4975 8.00006C10.4971 8.11021 10.4882 8.22017 10.4708 8.32895L11.1953 9.06673Z"
              fill="white"/>
            <path
              d="M15.2399 7.79112C13.7421 5.02224 11.1154 3.34668 8.20877 3.34668C7.41737 3.34854 6.63129 3.47605 5.87988 3.72446L6.59544 4.44446C7.12248 4.30825 7.66442 4.23808 8.20877 4.23557C10.711 4.23557 12.9865 5.63112 14.3421 7.98223C13.8448 8.8545 13.1856 9.62383 12.3999 10.2489L13.031 10.88C13.9404 10.1464 14.6956 9.2401 15.2532 8.21335L15.3688 8.00001L15.2399 7.79112Z"
              fill="white"/>
            <path
              d="M2.16428 2.56896L4.14651 4.55118C2.89358 5.3579 1.86957 6.47375 1.17317 7.79118L1.05762 8.00007L1.17317 8.21341C2.67095 10.9823 5.29762 12.6578 8.20428 12.6578C9.3388 12.6576 10.4586 12.4008 11.4798 11.9067L13.7021 14.129L14.4798 13.4623L2.92428 1.90674L2.16428 2.56896ZM6.49762 6.90229L9.45317 9.85785C9.11923 10.0646 8.73479 10.1753 8.34206 10.1779C8.0567 10.1779 7.77415 10.1214 7.51067 10.0118C7.2472 9.90222 7.008 9.74159 6.80683 9.53919C6.60567 9.33679 6.44652 9.09661 6.33853 8.83246C6.23055 8.56832 6.17586 8.28543 6.17762 8.00007C6.18262 7.61188 6.29325 7.23238 6.49762 6.90229ZM5.85317 6.25785C5.43737 6.84425 5.24247 7.55887 5.303 8.27518C5.36353 8.99149 5.67558 9.66327 6.18389 10.1716C6.6922 10.6799 7.36398 10.9919 8.08029 11.0525C8.7966 11.113 9.51122 10.9181 10.0976 10.5023L10.8087 11.2134C9.98552 11.5653 9.09954 11.7467 8.20428 11.7467C5.70206 11.7467 3.42651 10.3512 2.07095 8.00007C2.72149 6.84817 3.65838 5.88372 4.79095 5.20007L5.85317 6.25785Z"
              fill="white"/>
            </g>
        </svg>
    </span>


  <ng-template #passwordShown let-password>
    <input class="input-box-face input-box-green-text" type="text" [placeholder]="placeholder" [value]="password"
           (input)="persistPasswordState($event)" (blur)="onBlur($event)" pInputText>
  </ng-template>

  <ng-template #passwordHidden let-password>
    <input class="input-box-face input-box-green-text" type="password" [placeholder]="placeholder" [value]="password"
           (input)="persistPasswordState($event)" (blur)="onBlur($event)" pInputText>
  </ng-template>

  <ng-container *ngIf="icon === '';else firstc">
    <img src="assets/iconos/icono_contrasena.svg" alt="Contraseña">
  </ng-container>
  <ng-template #firstc>
    <ng-container *ngIf="icon === 'nueva';else secondc">
      <img src="assets/iconos/icono_nueva.svg" alt="Contraseña">
    </ng-container>
  </ng-template>
  <ng-template #secondc>
    <ng-container *ngIf="icon === 'confirmar'">
      <img src="assets/iconos/icono_confirmar.svg" alt="Contraseña">
    </ng-container>
  </ng-template>



</div>

