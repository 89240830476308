import {Component, OnInit} from '@angular/core';
import {LayoutConfigService} from '@app/layout-config/shared/services/layout-config.service';

@Component({
  selector: 'app-error-not-found-page',
  templateUrl: './error-not-found-page.component.html',
  styleUrls: ['./error-not-found-page.component.scss']
})
export class ErrorNotFoundPageComponent implements OnInit {

  constructor(private layoutConfigService: LayoutConfigService
  ) {
    layoutConfigService.setLayoutConfig({
      layout: {
        navbar: {visible: true},
        footer: {visible: true}
      }
    });
  }

  ngOnInit(): void {
  }

}
