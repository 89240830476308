<div class="signup-form main-padding">
  <button class="facebook" type="button" (click)="onAuthProviderSelected(provider.Facebook)">
    <img src="assets/iconos/icono_facebook.svg" alt="Facebook"><span>Ingresa con Facebook</span></button>
  <button class="google" type="button" (click)="onAuthProviderSelected(provider.Google)">
    <img src="assets/iconos/icono_google.svg" alt="Google"><span>Ingresa con Google</span></button>
  <button class="apple" type="button" (click)="onAuthProviderSelected(provider.Apple)">
    <img src="assets/iconos/apple_icon.svg" alt="AppleID"><span>Ingresa con Apple ID</span></button>
  <button class="mail" type="button" (click)="onAuthProviderSelected(provider.Basic)">
    <img src="assets/iconos/icono_mail.svg" alt="Email"><span>Ingresa con tu Correo</span></button>
</div>
