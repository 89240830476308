import {Component, OnInit, OnDestroy} from '@angular/core';
import {Router, ActivatedRoute, ActivatedRouteSnapshot, NavigationExtras} from '@angular/router';
import {SubSink} from 'subsink';
import {LayoutConfigService} from '@app/layout-config/shared/services/layout-config.service';
import {NewsService} from '../../shared/services/news.service';
import {MessageService} from 'primeng/api';

@Component({
  selector: 'app-new-preview-page',
  templateUrl: './new-preview-page.component.html',
  styleUrls: ['./new-preview-page.component.scss']
})
export class NewPreviewPageComponent implements OnInit, OnDestroy {

  private newId: number = 0;
  private subscriptions = new SubSink();
  public newTitle: string = '';
  public newDescription: string = '';
  public newUrlImage: string = '';
  public newDate!: Date;
  public newHostDomain: string = '';
  public newUrl: string = '';
  public newLoaded: boolean = false;
  public avatarDomain = '';


  constructor(
    private readonly router: Router,
    private readonly currentRoute: ActivatedRoute,
    private readonly newsService: NewsService,
    private readonly messageService: MessageService,
    private readonly layoutConfig: LayoutConfigService
  ) {

    layoutConfig.setLayoutConfig({
      layout: {
        navbar: {visible: false},
        footer: {visible: false}
      }
    });
  }

  ngOnInit(): void {

    // Obtenemos el id de la Noticia a partir de la Url.
    const routeSnapshot: ActivatedRouteSnapshot = this.currentRoute.snapshot;
    this.newId = +(routeSnapshot.paramMap.get('id') || 0);
  
    this.loadNewDetail(this.newId);
  }

  private loadNewDetail(id: number): void {

    if (isNaN(+id)) {

      this.router.navigateByUrl('/404');

      return;
    }

    this.subscriptions.sink = this.newsService.getNewPublicDetail(+id).subscribe((newDetail) => {

      this.newId = newDetail.id;
      this.newTitle = newDetail.title;
      this.newUrlImage = newDetail.urlImage;
      this.newDate = newDetail.date;
      this.newHostDomain = newDetail.hostDomain;
      this.newUrl = newDetail.url;
      this.newDescription = newDetail.description;
      this.newLoaded = true;
      this.avatarDomain = newDetail.favicon;
    });
  }


  public gotoLogin(): void {

    // Definimos manualmente la url de retorno, en este caso será la url del detalle de la Noticia.
    const intendedUrl: string = `/news/${this.newId}`;
    // Construimos la navegación con la url de retorno codificada en base64
    const navigationExtras: NavigationExtras = {
      // La funcion btoa() nos permite codificar un string a base64
      queryParams: {intendedUrl: btoa(intendedUrl)}
    };

    // Redireccionamos al login con laa url de retorno.
    this.router.navigate(['/login'], navigationExtras);
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
