import { Injectable } from '@angular/core';

@Injectable()
export class LinkPreviewService {

    constructor() { }

    public retrieveTitle(tags: string | null): string {

        const fallbackTitle: string = '¡Echa un vistazo al link compartido haciendo click en el enlace!';

        if ( !tags ) {

            return fallbackTitle;
        }

        const parsedTags: any = this.parseTags(tags);

        // Para obtener el Titulo:
        // 1. title.
        // 2. twitter:title
        // 3. dcterms:title
        // 4. og:title
        const titleTagField: string | undefined = Object.keys(parsedTags).find((k) => k.includes('title')); 

        if ( !titleTagField ) {

            return fallbackTitle;
        }

        return parsedTags[ titleTagField ];
    }

    public retrieveImage(tags: string | null): string{

        const fallbackImage: string = 'assets/iconos/link_placeholder.png';

        if ( !tags ) {

            return fallbackImage;
        }

        const parsedTags: any = this.parseTags(tags);

        // Para obtener la Imagen: 
        // 1. image.
        // 2. twitter:image 
        // 3. og:imag
        const imageTagField: string | undefined = Object.keys(parsedTags).find((k) => k.includes('image'));

        if ( !imageTagField ) {

            return fallbackImage;
        }

        return parsedTags[ imageTagField ];
    }

    private parseTags(tags: string): any {

        return JSON.parse(tags);
    }
}
