import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse  } from '@angular/common/http';

import { Observable, throwError, EMPTY } from 'rxjs';
import { map, catchError } from 'rxjs/operators';

import { AuthSuccessfulResponse } from '../models/auth-successful-response';
import { AuthNewCredentials } from '../models/auth-new-credentials';
import { PasswordSettingsPayload } from '../models/password-settings-payload';
import { PasswordSettings } from '../models/password-settings';

import { ModalService } from '@app/ui/modals/shared/services/modal.service';

import { environment } from '@env/environment';

@Injectable({
    providedIn: 'root'
})
export class PasswordSettingsService {

    constructor( 
        private httpClient: HttpClient,
        private modalService: ModalService
    ) { }

    public changePassword(passwordSettings: PasswordSettings): Observable<AuthNewCredentials> {

        const passwordSettingsPayload: PasswordSettingsPayload = this.mapPasswordSettingsPayload(passwordSettings);

        return this.httpClient.put<AuthSuccessfulResponse>(`${ environment.host }/api/auth/profile/password/update`, { ...passwordSettingsPayload })
                .pipe(
                    map( (authSuccessfulResponse) => {

                        const { access_token, expires_at, token_type } = authSuccessfulResponse.content;

                        return {
                            token:              access_token,
                            tokenType:          token_type,
                            tokenExpiration:    expires_at
                        };
                    }),
                    catchError((error: HttpErrorResponse) => {
                        
                        // Inspecciona la propiedad error de HttpErrorResponse para determinar el tipo de error
                        // y manejarlo acorde.
                        const httpError: any = error.error;
                        const httpErrorCode: number = error.status;
                        
                        switch( httpErrorCode ) {
                            
                            case 400: 
                                // Inspecciona el error a nivel de nuestro servidor.
                                if ( 
                                    httpError.message && 
                                    httpError.message === 'La contraseña no fue actualizada.' 
                                ) {

                                    this.modalService.failureDialog('La contraseña no pudo ser actualizada, intentelo nuevamente');
    
                                    return EMPTY;
                                }
                            break;

                            case 401:
                
                                // Inspecciona el error a nivel de nuestro servidor.
                                if ( 
                                    httpError.message && 
                                    httpError.message === 'No fue posible reiniciar la contraseña, tu contraseña actual no es correcta.' 
                                ) {
                                    
                                    this.modalService.failureDialog('Su contraseña actual es incorrecta, intentelo nuevamente');
    
                                    return EMPTY;
                                }

                                if ( 
                                    httpError.message && 
                                    httpError.message === 'No fue posible reiniciar la contraseña, la cuenta está vinculada a una red social.' 
                                ) {
                                    
                                    this.modalService.failureDialog('La contraseña no pudo ser actualizada, la cuenta esta vinculada a una red social');
    
                                    return EMPTY;
                                }
                            break;
                        } 

                        return throwError(error);
                    })
                );
    }

    public refreshAuthCredentialsInLocalStorage(token: string, tokenExpiration: number): void {

        localStorage.setItem('tkn', token);
        localStorage.setItem('tkne', JSON.stringify(tokenExpiration * 1000));
    }

    private mapPasswordSettingsPayload(passwordSettings: PasswordSettings): PasswordSettingsPayload {

        const { 
            currentPassword:old_password, 
            newPassword:new_password, 
            confirmPassword:new_password_confirmation } = passwordSettings;
        
        return {
            old_password: old_password.trim(),
            new_password: new_password.trim(),
            new_password_confirmation: new_password_confirmation.trim()
        }
    }
}
