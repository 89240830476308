import { Component, OnInit, Input, forwardRef } from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';

@Component({
    selector: 'app-password-field',
    templateUrl: './password-field.component.html',
    styleUrls: ['./password-field.component.scss'],
    providers: [{
        provide: NG_VALUE_ACCESSOR,
        useExisting: forwardRef(() => PasswordFieldComponent),
        multi: true
    }]
})
export class PasswordFieldComponent implements OnInit, ControlValueAccessor {

    @Input() placeholder:string = 'Contraseña';
    @Input() inputClass:string = '';
    @Input() icon:string = '';
    public showPassword:boolean = false;
    public password:string = '';
    private onChanged:any = () => {};
    private onTouched:any = () => {};

    constructor() { }

    ngOnInit(): void {
    }

    // Implementa los métodos de la interfaz ControlValueAccessor para que el componente PasswordFieldComponent se comporte como un elemento
    // de un formulario reactivo.
    public writeValue(val:any):void { this.password = val; }
    public registerOnChange(fn:any):void { this.onChanged = fn; }
    public registerOnTouched(fn:any):void { this.onTouched = fn; }

    // Toggle: Muestra la mascara con el texto plano o con los asteriscos.
    public handlePasswordInputMask():void {

        this.showPassword = !this.showPassword;
    }

    // Guarda la información ingresada derivada de la acción de cualquier input.
    public persistPasswordState($event:any):void {

        this.password = $event.target.value;
        this.onTouched();
        this.onChanged( this.password );
    }

    // Cuando alguno de nuestros inputs pierda el foco, transita a un estado touched.
    // En caso que haya un valor, comunicalo al Formulario padre.
   public onBlur($event:any):void {

        this.onTouched();

        if ( !this.password ) {

            this.onChanged('');
        }
   }
}
