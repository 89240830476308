import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';

import { DropdownModule } from 'primeng/dropdown';
import { InputTextModule } from 'primeng/inputtext';
import { CalendarModule } from 'primeng/calendar';
import { ToastModule } from 'primeng/toast';

import { AuthRoutingModule } from './auth-routing.module';
import { BoardingTeamsModule } from '../ui/boarding-teams/boarding-teams.module';
import { BannerCommunityModule } from '../ui/banner-community/banner-community.module';
import { SelectTeamFieldModule } from '../ui/select-team-field/select-team-field.module';
import { PasswordFieldModule } from '../ui/password-field/password-field.module';
import { AdvertisementModule } from '../ui/advertisement/advertisement.module';

import { SignupFormComponent } from './components/signup-form/signup-form.component';
import { SigninFormComponent } from './components/signin-form/signin-form.component';
import { LoginComponent } from './pages/login/login.component';
import { SigninProviderOptionsComponent } from './components/signin-provider-options/signin-provider-options.component';
import { NotAccountYetComponent } from './components/not-account-yet/not-account-yet.component';
import { RecentSigninActivityComponent } from './components/recent-signin-activity/recent-signin-activity.component';
import { TeamPreferenceComponent } from './pages/team-preference/team-preference.component';
import { OneTimeWelcomeScreenComponent } from './pages/one-time-welcome-screen/one-time-welcome-screen.component';

@NgModule({
  declarations: [
    SignupFormComponent,
    SigninFormComponent,
    LoginComponent,
    SigninProviderOptionsComponent,
    NotAccountYetComponent,
    RecentSigninActivityComponent,
    TeamPreferenceComponent,
    OneTimeWelcomeScreenComponent,
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    DropdownModule,
    InputTextModule,
    CalendarModule,
    ToastModule,
    BoardingTeamsModule,
    BannerCommunityModule,
    SelectTeamFieldModule,
    PasswordFieldModule,
    AdvertisementModule,
    AuthRoutingModule
  ]
})
export class AuthModule { }
