import { Component, OnInit } from '@angular/core';

import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

@Component({
    selector: 'app-warning-confirmation-dialog',
    templateUrl: './warning-confirmation-dialog.component.html',
    styleUrls: ['./warning-confirmation-dialog.component.scss']
})
export class WarningConfirmationDialogComponent implements OnInit {

    public message:string = 'No message available';

    constructor( 
        private dialogRef:DynamicDialogRef,
        private dialogConfig:DynamicDialogConfig 
    ) { }


    ngOnInit(): void {

        this.message = this.dialogConfig.data.dialogMessage;
    }

    public accept(): void {

        this.dialogRef.close('agreed');
    }

    public cancel(): void {

        this.dialogRef.close('rejected');
    }

    public close(): void {

        this.dialogRef.close('noopconfirmation');
    }
}
