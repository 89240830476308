import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { AuthService } from '../../shared/services/auth.service';
import { AuthSocialService } from '@app/auth/shared/services/auth-social.service';
import { TeamPreferenceService } from '../../shared/services/team-preference.service';
import { Team } from '../../shared/models/team';

import { LayoutConfigService } from '@app/layout-config/shared/services/layout-config.service';
import { ModalService } from '@app/ui/modals/shared/services/modal.service';

@Component({
    selector: 'app-team-preference',
    templateUrl: './team-preference.component.html',
    styleUrls: ['./team-preference.component.scss']
})
export class TeamPreferenceComponent implements OnInit {

    // Para manejar la url de retorno.
    public intendedUrl: string = '/';

    // Flag para mostrar/ocultar los Anuncios.
    public showAdTypeC: boolean = true;

    constructor(
        private readonly router: Router,
        private readonly route: ActivatedRoute,
        private readonly authService: AuthService,
        private readonly authSocial: AuthSocialService,
        private readonly teamPreference: TeamPreferenceService,
        private readonly modalService: ModalService,
        private readonly layoutConfigService: LayoutConfigService
    ) {
        // Establecemos la configuración inicial de la barra de navegación y del footer.
        layoutConfigService.setLayoutConfig({
            layout: { 
                navbar: { visible: false },
                footer: { visible: true }
            }
        });
    }

    ngOnInit(): void {
        
        // Intenteamos obtener una query param con el nombre intendedUrl.
        const intendedUrl: string | null = this.route.snapshot.queryParamMap.get('intendedUrl');

        // Vamos a guardar una referencia la url a la que se pretende acceder.
        // En caso que no haya una intendedUrl, por defecto demamos la url raíz de la aplicación.
        this.intendedUrl = intendedUrl ? atob(intendedUrl) : '/';
    }

    public onTeamSelected(team: Team | undefined): void {

        if ( !team ) {

            this.modalService.failureDialog('Seleccione un equipo');

            return;
        }

        const { id:teamId, name:teamName, logo:teamLogo } = team;

        this.teamPreference.updatePreference(teamId, teamName, teamLogo).subscribe({
            next: (teamPrefence) => {
                
                this.teamPreference.updatePreferenceInLocalStorage(teamPrefence);
                this.router.navigateByUrl(this.intendedUrl);
            },
            error: (err) => {  

                // En caso de algún error de cualquier índole: 
                // 1. Cerramos la sesión activa y limpiamos lo que haya en localStorage.
                this.authService.logout();
                // 2. En caso de que haya habido un acceso por redes sociales, cerramos dicha sesión.
                this.authSocial.signOut();
                // 3. Reenviamos el error para que el manejador global se encargue de él.
                throw err;
            }
        });
    }
    
    public cancel(): void {
        
        // 1. Cerramos la sesión activa y limpiamos lo que haya en localStorage.
        this.authService.logout();
        // 2. En caso de que haya habido un acceso por redes sociales, cerramos dicha sesión.
        this.authSocial.signOut();
        // 3. Enviamos al usuario a la sección de autentificación.
        this.router.navigateByUrl('/login');
    }
}
