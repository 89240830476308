import { Component, OnInit, OnDestroy, Input } from '@angular/core';

@Component({
    selector: 'app-team-group',
    templateUrl: './team-group.component.html',
    styleUrls: ['./team-group.component.scss']
})
export class TeamGroupComponent implements OnInit, OnDestroy {

    @Input() leagueImageSrc:string | undefined;
    @Input() leagueName:string | undefined;
    @Input() isSelected:boolean = false;
     
    constructor() { }

    ngOnInit(): void {

        console.log('TeamGroupComponent created.')
    }

    ngOnDestroy(): void {

        console.log('TeamGroupComponent destroyed.');
    }
}
