import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'extractHostDomain'
})
export class ExtractHostDomainPipe implements PipeTransform {

  transform(fqdn: string): string {

    return this.extractHostName(fqdn);
  }

  private extractHostName(url:string): string {

    return url.slice( url.indexOf('.') + 1 );
  }
}
