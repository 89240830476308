import { Injectable } from '@angular/core';

import { PaginatorItemPost } from '../models/paginator-item-post';
import { PostMedia } from '@app/ui/post/shared/models/post-media';
import { PostPolling } from '@app/ui/post/shared/models/post-polling';

import { AdvertisementType } from '@app/ui/advertisement/shared/models/advertisement-type';
import { AdvertisementTypeB } from '@app/ui/advertisement/shared/models/advertisement-type-B';

@Injectable({
    providedIn: 'root'
})
export class PaginatorLandingAdvertisementIntercalationTestService {

    constructor() { }

    public insertAdsInPosts(state: PaginatorItemPost[], currentPost: (PostMedia | PostPolling), idx: number): PaginatorItemPost[] {

        // El intercalado en este caso se realizará en conjuntos de 10 elementos, por lo que, debemos 
        // insertar 1 anuncio al inicio y 1 anuncio al medio de la Página.
        // Por cada 10 Publicaciones insertamos 1 Anuncio al Inicio y 1 Anuncio al medio de la Página.
        const isBeggining: boolean = (idx === 0);
        const isMiddle: boolean = (idx === 5);

        // Agrega un anuncio de Tipo B al inicio de los elementos Post o a la mitad del total de los elementos Post.
        if ( isBeggining || isMiddle ) {
            
            // Crea la marca de anuncio de Tipo B.
            const ad: AdvertisementTypeB = { classification: AdvertisementType.B };

            // Empuja un Anuncio de Tipo B.
            state.push(ad);

            // Empuja el Post Original.
            state.push(currentPost);

        } else {

            // Solo empuja el Post Original.
            state.push(currentPost);
        }

        return state;
    }
}
