<form [formGroup]="changePasswordForm" (ngSubmit)="onChangePassword()" novalidate autocomplete="off">
    <div class="input-group">
      <app-password-field
        inputClass="bg-gray"
        placeholder="Contraseña actual"
        formControlName="currentPassword"></app-password-field>

      <!-- Posibles Mensajes de error para el campo currentPassword -->
      <ng-container *ngIf="currentPassword?.invalid && (currentPassword?.dirty || currentPassword?.touched)">
        <ng-container [ngSwitch]="errorOnCurrentPasswordField">
            <span *ngSwitchCase="'required'" class="invalid-feedback-message">
                <small>Ingrese su contraseña actual</small>
            </span>
          <span *ngSwitchCase="'blank'" class="invalid-feedback-message">
                <small>Ingrese una contraseña válida</small>
            </span>
        </ng-container>
      </ng-container>
    </div>

    <div class="input-group">
      <app-password-field
        inputClass="bg-gray"
        icon="nueva"
        placeholder="Contraseña nueva"
        formControlName="newPassword"></app-password-field>

      <!-- Posibles Mensajes de error para el campo newPassword -->
      <ng-container *ngIf="newPassword?.invalid && (newPassword?.dirty || newPassword?.touched)">
        <ng-container [ngSwitch]="errorOnNewPasswordField">
            <span *ngSwitchCase="'required'" class="invalid-feedback-message">
                <small>Ingrese su nueva Contraseña</small>
            </span>
          <span *ngSwitchCase="'minlength'" class="invalid-feedback-message">
                <small>Su contraseña como mínimo debe tener 6 caracteres</small>
            </span>
          <span *ngSwitchCase="'blank'" class="invalid-feedback-message">
                <small>Ingrese una contraseña válida</small>
            </span>
        </ng-container>
      </ng-container>
    </div>

    <div class="input-group">
      <app-password-field
        inputClass="bg-gray"
        icon="confirmar"
        placeholder="Confirmar contraseña nueva"
        formControlName="confirmPassword"></app-password-field>

      <!-- Posibles Mensajes de error para el campo confirmPassword -->
      <ng-container *ngIf="confirmPassword?.invalid && (confirmPassword?.dirty || confirmPassword?.touched)">
        <ng-container [ngSwitch]="errorOnConfirmPasswordField">
            <span *ngSwitchCase="'required'" class="invalid-feedback-message">
                <small>Ingrese su nueva Contraseña</small>
            </span>
          <span *ngSwitchCase="'blank'" class="invalid-feedback-message">
                <small>Ingrese una contraseña válida</small>
            </span>
        </ng-container>
      </ng-container>
    </div>

    <button type="submit" class="icon-right">
        <ng-container *ngIf="!changePasswordLoading; else loading">
            Actualizar <img src="assets/iconos/icono_arrow.svg" alt="Actualizar Contraseña">
        </ng-container>

        <ng-template #loading>
          <i class="pi pi-spin pi-spinner button-spinner"></i>
        </ng-template>
    </button>
</form>
