import { Injectable } from '@angular/core';

import { UserAuthenticatedByActivationCode } from '@app/ui/modals/shared/models/account-activation/user-authenticated-by-activation-code';
import { AuthUserAuthenticatedResponse } from '../models/auth-user-authenticated-response';
import { AuthUserAuthenticated, Fan, TeamPreference} from '../models/auth-user-authenticated';

@Injectable({
    providedIn: 'root'
})
export class AuthUserAuthenticatedMappingService {

    constructor() { }

    public mapAuthProfile(userCandidate: UserAuthenticatedByActivationCode | AuthUserAuthenticatedResponse): AuthUserAuthenticated {

        if ( this.isUserAuthenticatedByActivationCode(userCandidate) ) {

            // Mapeamos un Usuario que esta activando su cuenta.
            return this.mapUserAuthenticatedByActivationCode(userCandidate);
        }
        
        // Mapeamos un Usuario que se ha autentificado por otro medio que no es el código de activación. 
        return this.mapUserAuthenticated(userCandidate);
    }

    private isUserAuthenticatedByActivationCode(userCandidate: UserAuthenticatedByActivationCode | AuthUserAuthenticatedResponse): userCandidate is UserAuthenticatedByActivationCode {

        // Debemos forzar primero la conversión de userCandidate a un tipo unknown
        // Si el párametro que recibimos como userCandidate posee la propiedad accountDetail sabremos que 
        // lo que se esta pasando por argumento es un Usuario Autentificado por Código de Activación. 
        return !!((<UserAuthenticatedByActivationCode>userCandidate).accountDetail);
    }

    private mapUserAuthenticated(user: AuthUserAuthenticatedResponse): AuthUserAuthenticated {

        const { content } = user;
        const { access_token, expires_at } = content;
        const { id, name, picture, fanLevel, favoriteTeamDetail } = content.user;

        // Construimos el objeto fan.
        const fan: Fan = { level: fanLevel.name, plate: fanLevel.icon };

        // Construimos el objeto teamPreference.
        const teamPreference: TeamPreference | null = !favoriteTeamDetail ? null : { teamId: favoriteTeamDetail.id, teamName: favoriteTeamDetail.name, teamLogo: favoriteTeamDetail.logo };
                
        return {
            id,
            name,
            avatar: picture,
            isFirstTimeLoginAuthentication: false,
            fan,
            teamPreference,
            auth: {
                token:      access_token,
                expiresAt:  expires_at
            }
        };
    }

    private mapUserAuthenticatedByActivationCode(user: UserAuthenticatedByActivationCode): AuthUserAuthenticated {

        const { accountDetail } = user;
        const { id, name, picture, fanLevel, favoriteTeamDetail, access_token, expires_at } = accountDetail;

        // Construimos el objeto fan.
        const fan: Fan = { level: fanLevel.name, plate: fanLevel.icon };

        // Construimos el objeto teamPreference.
        const teamPreference: TeamPreference | null = !favoriteTeamDetail ? null : { teamId: favoriteTeamDetail.id, teamName: favoriteTeamDetail.name, teamLogo: favoriteTeamDetail.logo };

        return {
            id,
            name,
            avatar: picture,
            isFirstTimeLoginAuthentication: true,
            fan,
            teamPreference,
            auth: {
                token:      access_token,
                expiresAt:  expires_at
            }
        };
    }
}
