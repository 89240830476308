import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpClient, HttpParams} from '@angular/common/http';
import {environment} from '@env/environment';

import {Comment} from '../models/comments';
import {CommentBody} from '../models/comment-body';
import {
  CommentsResponse,
  CommentResponse,
  DeleteCommentResponse,
  MakeCommentResponse,
  VoteResponse
} from '../../shared/models/comments-responses';
import {map} from 'rxjs/operators';
import {DateDifference} from '@app/core/utilities/date-difference';

@Injectable({
  providedIn: 'root'
})
export class CommentsService {

  constructor(
    private httpClient: HttpClient
  ) {
  }

  public getCommentsByPost(postId: number): Observable<Comment[]> {
    return this.httpClient.get<CommentsResponse>(`${environment.host}/api/posts/${postId}/comments`)
      .pipe(
        map((commentsResponse => {
          const {content} = commentsResponse;
          return content.map((commentResponse) => {
            const {id, message, user, inner_comments, totalVotesDown, totalVotesUp, created_at} = commentResponse;
            return {
              id,
              totalVotesDown,
              totalVotesUp,
              date: created_at,
              dateDifference: DateDifference.getDifferenceBetweenDates(new Date(), created_at),
              message,
              user,
              inner_comments
            };
          });
        }))
      );
  }

  public makeComment(comment: CommentBody, id: number): Observable<MakeCommentResponse> {
    return this.httpClient.post<MakeCommentResponse>(`${environment.host}/api/posts/${id}/comments/create`, {
      parent_id: comment.parent,
      message: comment.message
    })
      .pipe(
        map((commentResponse) => {
          const {code, success, message} = commentResponse;
          return {
            code, success, message
          };
        })
      );
  }

  public editComment(comment: string, idPost: number, idComment: number): Observable<MakeCommentResponse> {
    return this.httpClient.put<MakeCommentResponse>(`${environment.host}/api/posts/${idPost}/comments/${idComment}/edit`, {
      message: comment
    })
      .pipe(
        map((commentResponse) => {
          const {code, success, message} = commentResponse;
          return {
            code, success, message
          };
        })
      );
  }

  public vote(action: string, postId: number): Observable<VoteResponse> {
    const api: string = `${environment.host}/api/comments/`;

    const endpoints: { [name: string]: string } = {
      like: `${api}${postId}/like`,
      unlike: `${api}${postId}/unlike`,
      dislike: `${api}${postId}/dislike`,
      undislike: `${api}${postId}/undislike`
    };
    const endpointSelected: string = endpoints[action];


    return this.httpClient.post<VoteResponse>(`${endpointSelected}`, {})
      .pipe(
        map((response) => {
          const {code, success, message} = response;
          return {
            code, success, message
          };
        })
      );
  }

  public deleteComment(postId: number, commentId: number): Observable<DeleteCommentResponse> {
    return this.httpClient.delete<DeleteCommentResponse>(`${environment.host}/api/posts/${postId}/comments/${commentId}`)
      .pipe(
        map((response) => {
          return {...response};
        })
      );
  }
}

