<div class="photo mb-20">
    <div class="img">
        <img class="picture" [src]="avatar" alt="Foto de Usuario">
    </div>

    <div class="info">
        <span class="green">
            Cambiar foto de perfil
        </span>
    </div>
  <input type="file" [accept]="accept" (change)="onFileSelected($event)">

</div>
