<div *ngIf="newLoaded; else showLoadingIndicator" class="single-post-grid main-grid">
  <div class="left grid-left">
    <div class="back">
      <button class="close" type="button" (click)="back()">
        <img src="assets/iconos/icono_arrowdown.svg" alt="Regresar">Regresar
      </button>
    </div>

    <div class="post-container">
      <!--Post-->
      <div class="post">
        <div class="row picture">
          <img [alt]="newTitle" [appMediaSource]="newUrlImage" [appFallbackAssetMedia]="'assets/place_big.jpg'">
        </div>

        <div class="row content">
          <h4>{{ newTitle }}</h4>
          <p class="detail"><img class="picture" src="{{avatarDomain}}" alt="Imagen o video">
            <a [href]="newHostDomain" target="_blank">{{ newHostDomain | extractHostDomain }}</a>
            <span>|</span>{{ newDate | date }}</p>
          <div class="share">
            <button (click)="shareNew(urlInput)"><img src="assets/iconos/icono_compartir.svg" alt="Compartir"></button>
            <a href="http://www.facebook.com/sharer.php?u={{newUrl}}&t=Vía LigaMX" target="_blank">
              <img src="assets/iconos/i_face.svg" alt="Facebook">
            </a>
            <a href="https://twitter.com/intent/tweet?text={{newUrl}} - Vía LigaMX" target="_blank">
              <img src="assets/iconos/icon_twitter.svg" alt="Twitter">
            </a>
            <a href="https://t.me/share/url?url={{newUrl}}&text=Vía%20LigaMX" target="_blank">
              <img src="assets/iconos/icon_telegram.svg" alt="Telegram">
            </a>
            <a target="_blank" href="https://wa.me/?text={{newUrl}}">
              <img src="assets/iconos/icon_whatsapp.svg" alt="Whatsapp">
            </a>
            <button class="last" [class.active]="isUserFollowingThisNew" (click)="bookmarkThisNew()">
              <svg width="16" height="25" viewBox="0 0 16 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M8.26596 19.6287L8 19.4617L7.73404 19.6287L1.00302 23.8568C0.908532 23.9114 0.771169 23.9107 0.677745 23.8548L0.421053 24.2839L0.677743 23.8548C0.588556 23.8014 0.5 23.6697 0.5 23.498V3.12519C0.5 1.8545 1.43316 0.904785 2.52632 0.904785H13.4737C14.5388 0.904785 15.5 1.88473 15.5 3.12519V23.498C15.5 23.6697 15.4114 23.8014 15.3223 23.8548L15.3149 23.8592L15.3076 23.8639C15.2812 23.881 15.2587 23.8901 15.2384 23.8955C15.2175 23.9012 15.1918 23.9048 15.1579 23.9048C15.124 23.9048 15.0983 23.9012 15.0773 23.8955C15.0571 23.8901 15.0346 23.881 15.0082 23.8639L15.0082 23.8639L15.0028 23.8605L8.26596 19.6287Z"
                  stroke="#29EB4F"/>
              </svg>

              <!-- Utiliza este flag para cambiar el color del icono de guardar según corresponda
                  y después remueve esta expresión del Template -->
            </button>
            <input type="text" value="{{newUrl}}" #urlInput>
          </div>
          <!-- <div class="main" [innerHTML]="newDescription"></div> -->
          <div class="main">
            <a target="_blank" href="{{originalUrl}}">Ir a noticia completa</a>
          </div>

          <div class="nav">
            <button (click)="goToNew(prevNew!.id)"><img src="assets/iconos/icono_arrow.svg" alt="Noticia anterior">{{ prevNew!.title }}</button>
            <button (click)="goToNew(nextNew!.id)">{{ nextNew!.title }}<img src="assets/iconos/icono_arrow.svg" alt="Noticia Siguiente"></button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="grid-right pt-50">
    <app-banner-community></app-banner-community>
    <app-recommended-topic-list></app-recommended-topic-list>
    <app-advertisement-type-d *ngIf="showAdTypeD" (loaded)="showAdTypeD = $event"></app-advertisement-type-d>
  </div>
</div>

<ng-template #showLoadingIndicator>
  <!-- Este spinner se muestra cuando se carga el detalle de la noticia. -->
  <div class="loading" style="text-align: center;">
    <i class="pi pi-spin pi-spinner spinner spinner-lg"></i>
  </div>
</ng-template>

<p-toast position="top-left"></p-toast>
