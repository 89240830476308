import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {TopPostsService} from '../shared/services/top-posts.service';
import {Topic} from '../shared/models/top-topics';
import {SubSink} from 'subsink';

@Component({
  selector: 'app-tops-community',
  templateUrl: './tops-community.component.html',
  styleUrls: ['./tops-community.component.scss']
})
export class TopsCommunityComponent implements OnInit, OnDestroy {

  constructor(
    private topPostsService: TopPostsService
  ) {
  }

  @Input() section: string = 'home';
  private subscriptions = new SubSink();
  public topTopics: Topic[] = [];
  public loading = true;
  public activeTop: string = 'top';

  public setTopicsBy(type: string = 'top'): void {
    this.loading = true;
    this.activeTop = type;
    this.getTopics();
  }

  ngOnInit(): void {
    this.getTopics();
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  getTopics(): void {
    this.subscriptions.sink = this.topPostsService.getTopBy(this.section, this.activeTop).subscribe({
        next: (topics) => {
          this.topTopics = topics.slice(0, 4);
        },
        complete: () => {
          this.loading = false;
        }
      }
    );
  }

}
