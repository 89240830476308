import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { PostPollingMappingService } from './post-polling-mapping.service';

import { PostActionStatus } from '../models/post-action';
import { PostPollingResponse } from '../models/post-response';
import { PostPollingPayload } from '../models/post-polling-payload';
import { PostPolling } from '../models/post-polling';

import { environment } from '@env/environment';

@Injectable({
    providedIn: 'root'
})
export class PostPollingService {

    constructor(
        private readonly httpClient: HttpClient,
        private readonly postPollingMapping: PostPollingMappingService
    ) { }

    public create(postPolling: Partial<PostPolling>): Observable<PostActionStatus>  {

        // Mapeamos nuestro post para poder enviarlo al Servidor.
        const postPollingPayload: Partial<PostPollingPayload> = this.postPollingMapping.mapPostPollingPayload(postPolling);

        return this.createPostPolling(postPollingPayload);
    }


    private createPostPolling(payload: Partial<PostPolling>): Observable<PostActionStatus> {

        return this.httpClient.post<PostPollingResponse>(`${ environment.host }/api/posts/vote/store`, { ...payload })
            .pipe(
                map((response) => {

                    const { content } = response;
                    const { post } = content;

                    return {
                        state: 'Published',
                        payload: { postId: post.id }
                    };
                })
            );
    }
}
