import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { LayoutConfigService } from '@app/layout-config/shared/services/layout-config.service';

@Component({
    selector: 'app-one-time-welcome-screen',
    templateUrl: './one-time-welcome-screen.component.html',
    styleUrls: ['./one-time-welcome-screen.component.scss']
})
export class OneTimeWelcomeScreenComponent implements OnInit {
    
    // Para manejar la url de retorno.
    public intendedUrl: string = '/';

    constructor(
        private readonly router: Router,
        private readonly route: ActivatedRoute,
        private readonly layoutConfigService: LayoutConfigService
    ) { 

        // Establecemos la configuración inicial de la barra de navegación y del footer.
        layoutConfigService.setLayoutConfig({
            layout: {
                navbar: { visible: false },
                footer: { visible: false }
                }
        });
    }

    ngOnInit(): void {

        // Intenteamos obtener una query param con el nombre intendedUrl.
        const intendedUrl: string | null = this.route.snapshot.queryParamMap.get('intendedUrl');

        // Vamos a guardar una referencia la url a la que se pretende acceder.
        // En caso que no haya una intendedUrl, por defecto demamos la url raíz de la aplicación.
        this.intendedUrl = intendedUrl ? atob(intendedUrl) : '/';
    }

    public onContinue(): void {

        this.router.navigateByUrl( this.intendedUrl );
    }
}
