import { Injectable } from '@angular/core';

import { PaginatorItem } from '../../models/paginator-item';
import { PaginatorItemType } from '../../models/paginator-item-type';
import { PaginatorItemTopic } from '../../models/paginator-item-topic';

import { TopicResponse } from '../../models/responses/topic-response';

@Injectable({
    providedIn: 'root'
})
export class PaginatorLandingTopicsMappingService {

    constructor() { }
    /*
    * Mapeador para obtener los Topics (en este caso, no aplica incluir anuncios).
    */
    public getPaginatorItemTopics(topicsResponse: TopicResponse[], userId: number): PaginatorItem {

        const topicsItems: PaginatorItemTopic[] = topicsResponse.map((topicResponse) => this.mapTopic(topicResponse, userId) );

        return {
            type: PaginatorItemType.Topics,
            item: topicsItems
        } as PaginatorItem;
    }

    // This is the Entry Point to format a Topic.
    private mapTopic(topicResponse: TopicResponse, userId: number): PaginatorItemTopic {

        const { 
            id, 
            user_id:ownerId, 
            name:title, 
            is_official, 
            cover, 
            currentUser, 
            totals } = topicResponse;

        return {
            id,
            title,
            cover:                  (cover ? cover : ''),
            isOfficial:             !!is_official,
            following:              currentUser.isFollower,
            ownership:              (ownerId === userId),
            totalFollowers:         totals.followers,
            totalPublishedPosts:    totals.posts                        
        } as PaginatorItemTopic;
    }
}
