<!-- TODO Esto hasta el final, solo lo comento

<div *ngIf="showRecentSignInActivityBar">
    <app-recent-signin-activity></app-recent-signin-activity>
</div> -->

<div class="page-login">
    <div class="left">
      <img src="assets/logo_full.svg" alt="Logo Serouno">

      <ul>
        <li>
          <img src="assets/iconos/icono_shield.svg" alt="Sitio web Oficial">
          <p> El sitio web <b>oficial para fans</b> de tu deporte favorito</p>
        </li>
        <li>
          <img src="assets/iconos/icono_comunidad.svg" alt="Conversación">
          <p>Se parte de la <b>conversación</b> entre <b>fanáticos</b></p></li>
        <li>
          <img src="assets/iconos/icono_noticias.svg" alt="Noticias">
          <p> Lee y comparte todas las <b>noticias</b> de tu <b>equipo favorito</b></p>
        </li>
      </ul>

    </div>
    <div class="right">

      <h2><img src="assets/logo_min.png" alt="Serouno">¡Exclusivo para <b><i>FANS</i></b>!</h2>
      <p>Postea, comenta y comparte limpio... <br>
      ¡Siente tu liga!</p>

      <!-- Opciones para Iniciar Sesión -->
      <ng-container *ngIf="showAuthProviderOptionsBar">
        <app-signin-provider-options
          (authProviderSelected)="onAuthProviderSelected($event)"></app-signin-provider-options>
      </ng-container>

      <!-- Formulario para Iniciar Sesión -->
      <ng-container *ngIf="openSignInForm">
        <app-signin-form
          [signInLoading]="loadingSignInAction"
          (signIn)="onSignInWithBasicProvider($event)"
          (passwordRecovery)="onPasswordRecovery()"
          (cancel)="showAuthProviderOptions()"></app-signin-form>
      </ng-container>

      <!-- Formulario para Registro -->
      <ng-container *ngIf="openSignUpForm">
        <app-signup-form
          [signUpLoading]="loadingSignUpAction"
          (signUp)="onSignUp($event)"
          (cancel)="showAuthProviderOptions()"></app-signup-form>
      </ng-container>

      <!-- Aun no posee una cuenta con nosotros?-->
      <ng-container *ngIf="showNotAccountYetBar">
        <app-not-account-yet (getAnAccount)="showSignUpForm()"></app-not-account-yet>
      </ng-container>
    </div>
</div>

<p-toast position="top-center" key="authToastNotifications"></p-toast>
