<div class="not-logged-page">
  <div class="not-logged">
    <div class="content">
      <img src="assets/logo_full.svg" alt="SEROUNO">
      <button type="button" (click)="gotoLogin()">Ingresa a Fan Zone SEROUNO</button>
    </div>
  </div>

  <div *ngIf="newLoaded; else showLoadingIndicator" class="single-post-grid main-grid">
    <div class="left grid-left">

      <div class="post-container">
        <!--Post-->
        <div class="post">
          <div class="row picture">
            <img [alt]="newTitle" [appMediaSource]="newUrlImage" [appFallbackAssetMedia]="'assets/place_big.jpg'">
          </div>

          <div class="row content">
            <h4>{{ newTitle }}</h4>
            <p class="detail"><img class="picture" src="{{avatarDomain}}" alt="Imagen o video">
              <a [href]="newHostDomain" target="_blank">{{ newHostDomain | extractHostDomain }}</a> <span>|</span>{{ newDate | date }}</p>
            <div class="share">
              <a href="http://www.facebook.com/sharer.php?u={{newUrl}}&t=Vía LigaMX" target="_blank"><img src="assets/iconos/icono_face.svg" alt="Facebook"></a>
              <a href="https://twitter.com/intent/tweet?text={{newUrl}} - Vía LigaMX" target="_blank"><img src="assets/iconos/icono_twitter.svg" alt="Twitter"></a>
              <input type="text" value="{{newUrl}}" #urlInput>
            </div>
            <!-- <div class="main" [innerHTML]="newDescription"></div> -->
            <div class="main">
              <a target="_blank" href="{{newUrl}}">Ir a noticia completa</a>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>

</div>



<ng-template #showLoadingIndicator>
  <!-- Este spinner se muestra cuando se carga el detalle de la noticia. -->
  <div class="loading" style="text-align: center;">
    <i class="pi pi-spin pi-spinner spinner spinner-lg"></i>
  </div>
</ng-template>

