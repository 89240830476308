import {Component, OnDestroy, OnInit} from '@angular/core';
import {WebviewService} from '../../shared/services/webview.service';
import {Webview, WebviewMenu} from '../../shared/modules/webview';
import {LayoutConfigService} from '@app/layout-config/shared/services/layout-config.service';
import {SubSink} from 'subsink';
import {ActivatedRoute, ActivatedRouteSnapshot, Router} from '@angular/router';

@Component({
    selector: 'app-webviews-page',
    templateUrl: './webviews-page.component.html',
    styleUrls: ['./webviews-page.component.scss']
})
export class WebviewsPageComponent implements OnInit, OnDestroy {
    constructor(
        private readonly router: Router,
        private currentRoute: ActivatedRoute,
        private readonly layoutConfig: LayoutConfigService,
        private webviewService: WebviewService
    ) {

        // Establecemos la configuración inicial de la barra de navegación y del footer.
        layoutConfig.setLayoutConfig({
            layout: {
                navbar: {visible: true},
                footer: {visible: true}
            }
        });
    }

    public webviewMenuId: string = '';
    public webviewMenu: WebviewMenu = {
        id: 0, name: '', description: '', icon: ''
    };
    public webviewsSize: string = 'large';
    public webviewsLoaded: boolean = false;
    private subscriptions = new SubSink();
    public webViews: Webview[] = [];

    ngOnInit(): void {

        const routeSnapshot: ActivatedRouteSnapshot = this.currentRoute.snapshot;
        this.webviewMenuId = routeSnapshot.paramMap.get('id') || '';
        this.getWebviewMenu();
        this.getWebviews();
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

    private getWebviewMenu(): void {
        this.webviewsLoaded = false;
        this.subscriptions.sink = this.webviewService.getWebviewMenuById(+this.webviewMenuId).subscribe({
            next: (webviewsMenu) => {
                this.webviewMenu = webviewsMenu;
            },
            complete: () => {
                this.webviewsLoaded = true;
            }
        });
    }

    private getWebviews(): void {
        this.webviewsLoaded = false;
        this.subscriptions.sink = this.webviewService.getWebviewsByMenuId(+this.webviewMenuId).subscribe({
                next: (webViews) => {
                    this.webViews = webViews;
                    if (webViews.length > 0 && webViews.length < 7) {
                        // 1 a 6
                        this.webviewsSize = 'large';
                    } else if (webViews.length >= 7 && webViews.length < 14) {
                        this.webviewsSize = 'medium';
                    } else {
                        this.webviewsSize = 'small';
                    }
                },
                complete: () => {
                    this.webviewsLoaded = true;
                }
            }
        );
    }

    public back(): void {

        this.router.navigateByUrl('/');
    }

}
