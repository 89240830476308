<div class="topic">
    <img [src]="cover ? cover : 'assets/iconos/placeholder_portada_topic.jpg'" width="50" height="50">
    <p class="title">
        <img
            [src]="isOfficial ? 'assets/iconos/icono_topicofcial.svg': 'assets/iconos/icono_fanmade.svg'"
            [alt]="isOfficial ? 'Official' : 'Fan'">{{ title }}
    </p>

    <p>
        <span>{{ totalFollowers | rateTotalizer }} <img src="assets/iconos/icono_joinedfans.svg" alt="Fans"></span>
    </p>
</div>
