import { Component, OnInit, OnDestroy } from '@angular/core';

import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { SubSink } from 'subsink';

import { AccountActivationService } from '../../shared/services/account-activation/account-activation.service';

@Component({
    selector: 'app-account-activation-dialog',
    templateUrl: './account-activation-dialog.component.html',
    styleUrls: ['./account-activation-dialog.component.scss'],
    providers: [ AccountActivationService ]
})
export class AccountActivationDialogComponent implements OnInit, OnDestroy {

    private subscriptions: SubSink = new SubSink();
    
    private accessToken: string = '';
    private tokenExpiration: number = 0;

    public activationCodeMessage: string = '';
    public loadingAccountActivationAction: boolean = false;

    constructor(
        private readonly dialogRef: DynamicDialogRef,
        private readonly dialogConfig: DynamicDialogConfig,
        private readonly accountActivation: AccountActivationService
    ) { }

    ngOnInit(): void {

        // Recibimos el Token y su Fecha de Expiración.
        this.accessToken = this.dialogConfig.data?.accessToken;
        this.tokenExpiration = this.dialogConfig.data?.tokenExpiration;
    }

    ngOnDestroy(): void {

        this.subscriptions.unsubscribe();
    }

    public onActivateAccount(activationCode: string): void {

        // Para activar la cuenta debemos enviar un Token y el Código de Verificación.
        // El Token se envia por cabecera HTTP y el Código de Verificación se envia a través del Cuerpo de la petición. 
        this.subscriptions.sink = this.accountActivation.verify(activationCode, this.accessToken, this.tokenExpiration).subscribe(
            (userAuthenticatedByActivationCode) => {

                // La cuenta ha sido activada con éxito.
                // Enviamos los detalles a quien invoco este Modal.
                this.dialogRef.close(userAuthenticatedByActivationCode);
            },
            (err) => {

                // Ocurrió un error al intentar Activar la Cuenta.
                this.displayActivationCodeMessage(err);
            }
        );
    }

    public onResendActivationCode(): void {
        
        // Para poder reenviar el código de activación al correo del Usuario debemos adjuntar el Token 
        // que previamente le fue entregado al intentar registrase o autentificarse por login básico.
        this.subscriptions.sink = this.accountActivation.resendActivationCode(this.accessToken).subscribe(
            () => {

                // El Código de Verificación fue enviado exitosamente.
                this.displayActivationCodeMessage('Código de Activaciòn enviado a su cuenta de Correo.')
            },
            (err) => {

                // Ocurrió un error al intentar Reenviar el Código de Verificación.
                this.displayActivationCodeMessage(err);
            }
        );
    }

    private displayActivationCodeMessage(message: string): void {

        this.activationCodeMessage = message;

        // Despues de x segundos borramos limpiamos el Mensaje.
        setTimeout(() => this.activationCodeMessage = '', 2500);
    }
}
