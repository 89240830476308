import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';

@Component({
    selector: 'app-password-recovery-form',
    templateUrl: './password-recovery-form.component.html',
    styleUrls: ['./password-recovery-form.component.scss']
})
export class PasswordRecoveryFormComponent implements OnInit {

    @Input() public recoverPasswordLoading: boolean = false;
    @Output() private recoverPassword: EventEmitter<string> = new EventEmitter<string>();

    public passwordRecoveryForm!: FormGroup;
    
    constructor(private fb: FormBuilder) { }

    ngOnInit(): void {

        this.loadPasswordRecoveryForm();
    }

    private loadPasswordRecoveryForm(): void {

        this.passwordRecoveryForm = this.fb.group({
            email: ['', {
                validators: [
                    Validators.required,
                    Validators.email,
                    Validators.maxLength(255)
                ]}
            ]
        });
    }

    public onPasswordRecovery(): void {

        this.passwordRecoveryForm.markAllAsTouched();

        if ( this.passwordRecoveryForm.valid ) {
            
            const { email:recipient } = this.passwordRecoveryForm.value;

            this.recoverPassword.emit(recipient);
        }
    }

    get email() {

        return this.passwordRecoveryForm.get('email');
    }
}
