import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';

import { PostType } from '../../../shared/models/post-type';
import { PostVideo } from '../../../shared/models/post-video';

import { PostVideoValidators } from '../../../shared/validators/post-video-validators';

@Component({
    selector: 'app-create-video-post-form',
    templateUrl: './create-video-post-form.component.html',
    styleUrls: ['./create-video-post-form.component.scss']
})
export class CreateVideoPostFormComponent implements OnInit {

    @Output() onCreate: EventEmitter<Partial<PostVideo>> = new EventEmitter<Partial<PostVideo>>();
    @Output() onCancel: EventEmitter<void> = new EventEmitter<void>();

    public createVideoPostForm!: FormGroup;

    constructor(private fb: FormBuilder) { }
    
    ngOnInit(): void {

        this.loadCreateVideoPostForm();
    }
    
    private loadCreateVideoPostForm(): void {
        
        this.createVideoPostForm = this.fb.group({
            description:    [ '', [ Validators.required ] ],
            video:          [ null, [ PostVideoValidators.mustBeValidVideo ] ]
        });
    }   
    
    public onCreateVideoPost(): void {

        // En caso que el usuario de click al botón iniciar sin haber ingresado los datos que se le piden.
        this.createVideoPostForm.markAllAsTouched();

        // Una vez los datos del formulario sean correctos y tengamos una imagen seleccionada como cover
        // realizamos el envio.
        if ( this.createVideoPostForm.valid ) {

            const postType: PostType = PostType.VIDEO
            const { description, video } = this.createVideoPostForm.value;

            this.onCreate.emit({ description, video, postType } as Partial<PostVideo>);
        }
    }

    public onCreateVideoPostCancellation(): void {

        this.onCancel.emit();
    }

    get description() {

        return this.createVideoPostForm.get('description');
    }

    get video() {

        return this.createVideoPostForm.get('video');
    }

}
