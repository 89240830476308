import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'timeToLiveFormat'
})
export class TimeToLiveFormatPipe implements PipeTransform {

    transform(ttlInMS: number): string {

        if ( ttlInMS <= 0 ) {

            return 'TTL reached (esto no se deberia mostrar)';
        }

        return this.TTLFormat(ttlInMS);
    }

    private TTLFormat(ms: number): string {

        // El formato en Días, Horas y Minutos o Minutos son excluyentes entre sí.

        // Mostrar formato en Días.
        const durationInDays: number = this.calculateDaysLeft(ms);
            
        if ( durationInDays >= 1 ) {
        
            const days = Math.ceil(durationInDays);

            return (days === 1) ? (days + ' día restante') : (days + ' días restantes');
        }
        
        // Mostrar formato en Horas y Minutos. 
        const durationInHours: number = this.calculateHoursLeft(ms);

        if ( durationInHours >= 1 ) {

            const hours: number = Math.floor(durationInHours);
            const minutes: number = Math.round(Number( (durationInHours % 1).toFixed(2) ) * 60);
            
            const hoursLabel: string = (hours >= 2) ? 'horas' : 'hora'
            const minutesLabel: string = (minutes >= 2) ? 'minutos' : 'minuto';

            return hours + ' ' + hoursLabel + ' ' + minutes + ' ' + minutesLabel + ' restantes';
        }

        
        // Mostrar formato en Minutos.
        const durationInMinutes: number = this.calculateMinutesLeft(ms);
        const minutes: number = Math.floor(durationInMinutes);

        return (minutes >= 2) ? (minutes + ' minutos restantes') : (minutes + ' minuto restante');
    }

    private calculateDaysLeft(ms: number): number {

        return ms / (1000 * 3600 * 24);
    }

    private calculateHoursLeft(ms: number): number {

        return ms / (1000 * 3600);
    }

    private calculateMinutesLeft(ms: number): number {

        return ms / (1000 * 60);
    }
}