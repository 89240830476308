import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AuthenticateService } from '@app/auth/shared/guards/authenticate.service';
import { VerifyTeamPreferenceService } from '@app/auth/shared/guards/verify-team-preference.service';

import { NewsPageComponent } from './pages/news-page/news-page.component';
import { NewDetailPageComponent } from './pages/new-detail-page/new-detail-page.component';
import { NewsScrollerTestPageComponent } from './pages/news-scroller-test-page/news-scroller-test-page.component';

const routes: Routes = [
  { path: 'news', component: NewsPageComponent, canActivate: [ AuthenticateService, VerifyTeamPreferenceService ] },
  { path: 'news/:id', component: NewDetailPageComponent, canActivate: [ AuthenticateService, VerifyTeamPreferenceService ] },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class NewsRoutingModule { }
