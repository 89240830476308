import { Injectable } from '@angular/core';

import { NewResponse } from '../models/responses/new-response';
import { PaginatorItemNew } from '../models/paginator-item-new';

@Injectable({
    providedIn: 'root'
})
export class PaginatorSearchResultsNewMappingsService {

    constructor() { }
    /*
    * Mapeador para obtener los Topics (en este caso, no aplica incluir anuncios).
    */
    public getPaginatorItemsNew(newsResponse: NewResponse[]): PaginatorItemNew[] {
    
        return newsResponse.map((newResponse) => this.mapNew(newResponse) );
    }

    // This is the Entry Point to format a New.
    private mapNew(newResponse: NewResponse): PaginatorItemNew {

        const { id, url, title, image_local:urlImage, date, crawler } = newResponse;

        return {
            id,
            title,
            url,
            urlImage,
            date:       new Date(date),
            hostDomain: crawler.domain,
            favicon:    crawler.favicon
        } as PaginatorItemNew;
    }
}
