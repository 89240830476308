<div class="not-logged-page">
  <div class="not-logged">
    <div class="content">
      <img src="assets/logo_full.svg" alt="SEROUNO">
      <button type="button" (click)="gotoLogin()">Ingresa a Fan Zone SEROUNO</button>
    </div>
  </div>
  <div class="single-post-grid main-grid">


    <div class="left grid-left">
      <!--<div class="back">
        <img src="assets/iconos/icono_arrowdown.svg" alt="Regresar">Regresar
      </div>-->

      <div class="post-container">
        <!--Post-->

        <div class="post" *ngIf="postLoaded; else loadingPost">
          <ng-container [ngSwitch]="postType">
            <!-- Post de Votación -->
            <ng-container *ngSwitchCase="5"
                          [ngTemplateOutlet]="postPollingTemplate"
                          [ngTemplateOutletContext]="{ $implicit: postPolling }"></ng-container>

            <!-- Post de Contenido Media -->
            <ng-container *ngSwitchDefault
                          [ngTemplateOutlet]="postMediaTemplate"
                          [ngTemplateOutletContext]="{ $implicit: postMedia}"></ng-container>
          </ng-container>

          <ng-template #postMediaTemplate>
            <app-post-media-expanded [showTopicAsPostHeader]="false" [post]="postMedia!" [logged]="false">
              <!-- Aqui incluimos el contenido Media dependiendo del tipo de Post: texto, link, imagen o video -->

              <!-- Es un Post de Link? -->
              <!-- Link Preview content goes here -->
              <app-link-preview
                *ngIf="postMedia!.type === 2"
                [originalLinkSource]="postMedia!.media.content.originalLinkSource"
                [metaTags]="postMedia!.media.content.metaTags"></app-link-preview>

              <!-- Es un Post de Imagen? -->
              <!-- Image content goes here -->
              <img
                *ngIf="postMedia!.type === 3"
                [src]="postMedia!.media.content"
                [alt]="postMedia!.description">

              <!-- Es un Post de Video? -->
              <!-- Video content goes here -->
              <video
                *ngIf="postMedia!.type === 4"
                width="100%"
                height="360px"
                controls>
                <source type="video/mp4" [src]="postMedia!.media.content">
              </video>

              <!-- Es un Post de Texto? -->
              <!-- Un Post de Texto no posee ningún tipo de contenido media -->
            </app-post-media-expanded>
          </ng-template>

          <ng-template #postPollingTemplate>
            <app-post-polling [post]="postPolling!" [logged]="false"></app-post-polling>
          </ng-template>
        </div>


        <ng-template #loadingPost>
          <!-- Este spinner se muestra cuando se cargan inicialmente las noticias en el scroller. -->
          <div class="loading" style="text-align: center">
            <i class="pi pi-spin pi-spinner spinner spinner-lg"></i>
          </div>
        </ng-template>

      </div>
    </div>


  </div>
</div>
