<article class="post-media-expanded post community">
  <div class="topic-img" *ngIf="topicIsOfficial && backgroundCover">
    <img src="{{backgroundCover}}" alt="{{topicTitle}}">
  </div>
  <div class="post-media-expanded-header header">
    <!-- Aquí va ir el Componente de Topic relacionado al Post actual. -->
    <app-topic-call-to-action *ngIf="showTopicAsPostHeader"
                              [id]="topicId"
                              [title]="topicTitle"
                              [isOfficial]="topicIsOfficial"
                              [following]="topicFollowing"
                              [ownership]="topicOwnership"></app-topic-call-to-action>

    <!-- Datos de quien publicó -->
    <div class="row">
      <div class="info">
        <p>Publicado por {{ publishedBy }} - {{ publishedDate | date }}</p>
      </div>

      <!-- Opción solamente visible si soy propietario del Post -->
      <ng-container *ngIf="ownedPost">
        <div class="more-button">
          <p-menu styleClass="clean-menu" #menu [popup]="true" appendTo="body" [model]="postMenuOptions"></p-menu>
          <button class="label" type="button" (click)="menu.toggle($event)">. . .</button>
        </div>
      </ng-container>
    </div>
  </div>

  <div class="post-media-expanded-content media-content row">
    <!-- Aqui vamos generar la Inclusión de Contenido. -->
    <ng-content></ng-content>
    <!-- El texto del Post -->

  </div>

  <div class="row">
    <a [routerLink]="['/community/post', postId]">
      <div class="content" [innerHTML]="description"></div>
    </a>
  </div>

  <div class="row actions">
    <div class="controls">
      <!-- Componente de Likes. -->
      <app-likes
        [status]="likeStatus"
        [likes]="totalLikes"
        [dislikes]="totalDislikes"
        (onClickLike)="onClickLike($event)"
        (onClickDislike)="onClickDislike($event)"></app-likes>

      <!-- Comentarios Totales realizados en este post.-->
      <button (click)="onClickComments()">
        <img src="assets/iconos/icono_comentarios.svg" alt="Comments">{{ totalComments }}
      </button>
    </div>
    <!-- Componente de Seguir este Post. -->
    <div class="save">
      <app-follow
        [status]="followingPost"
        (onFollow)="onFollowPost()"
        (onUnfollow)="onUnfollowPost()"></app-follow>


    </div>
  </div>
  <div class="row media">
    <!-- Componente de Reacciones. -->
    <div class="reactions">
      <app-reactions
        [available]="reactionsAvailable"
        [selected]="reactionsSelected"
        (onReaction)="onClickReaction($event)"></app-reactions>

    </div>
    <!-- Componente de Compartir en Redes Sociales. -->
    <div class="share" *ngIf="logged">
      <app-social-sharing [id]="postId"></app-social-sharing>
    </div>
  </div>
</article>
