import {Component, OnDestroy, OnInit} from '@angular/core';

import {MessageService} from 'primeng/api';
import {SubSink} from 'subsink';

import {switchMap, distinctUntilChanged} from 'rxjs/operators';

import {CommunityService} from '../../shared/services/community.service';
import {LayoutConfigService} from '@app/layout-config/shared/services/layout-config.service';
import {PaginatorItemPost} from '../../shared/models/paginator-item-post';
import {ProfilePaginatorService} from '../../shared/services/profile-paginator.service';
import {DropDownFilterDirection, DropDownFilterOption} from '@app/search-results/shared/models/dropdown-filter';

@Component({
  selector: 'app-my-posts-page',
  templateUrl: './my-posts-page.component.html',
  styleUrls: ['./my-posts-page.component.scss']
})
export class MyPostsPageComponent implements OnInit, OnDestroy {

  constructor(
    private readonly toastMessage: MessageService,
    private readonly communityService: CommunityService,
    private readonly profilePaginatorService: ProfilePaginatorService,
    private readonly layoutConfigService: LayoutConfigService,
  ) {

    // Establecemos la configuración inicial de la barra de navegación y del footer.
    layoutConfigService.setLayoutConfig({
      layout: {
        navbar: {visible: true},
        footer: {visible: false}
      }
    });
  }

  private subscriptions = new SubSink();

  public postType = 'created';
  public paginatorItemPosts: PaginatorItemPost[] = [];
  public paginatorItemPostsPreviouslyLoaded: PaginatorItemPost[] = [];
  public loadingMorePosts = false;
  public initialLoadingDone = false;

  public dropDownFilters: DropDownFilterOption[] = [
    { dropDownFilterLabel: 'Más recientes', dropDownFilterDirection: 'DESC' },
    { dropDownFilterLabel: 'Menos recientes', dropDownFilterDirection: 'ASC' }
  ];
  public selectedFilter: DropDownFilterDirection  = 'DESC';

  // Flag para mostrar/ocultar los Anuncios.
  public showAdTypeC = true;

  ngOnInit(): void {
    this.initialLoadingDone = false;
    this.loadPosts();
  }
  public onChangeFilter(selectedFilter: DropDownFilterDirection): void {
    this.paginatorItemPosts = [];
    this.paginatorItemPostsPreviouslyLoaded = [];
    this.loadingMorePosts = true;
    this.loadPosts();
  }

  public loadPosts(): void {
    this.subscriptions.sink = this.profilePaginatorService.listenPagination$
      .pipe(
        distinctUntilChanged(),
        switchMap((page) => this.profilePaginatorService.getPosts(page, this.postType, this.selectedFilter))
      )
      .subscribe((posts) => {

        const isFirstPage: boolean = ((this.profilePaginatorService.nextPage - 1) === 1);

        if (isFirstPage) {

          this.initialLoadingDone = true;
        }

        this.loadingMorePosts = false;
        this.paginatePosts(posts);
      });

    // Fire first Pagination Page.
    this.profilePaginatorService.paginate(1);
  }

  public changePostType(type: string = 'created'): void {
    this.paginatorItemPosts = [];
    this.paginatorItemPostsPreviouslyLoaded = [];
    this.loadingMorePosts = true;
    this.postType = type;
    this.loadPosts();
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  public onScroll(): void {

    if (this.profilePaginatorService.hasNotReachedPaginationEnd()) {

      const nextPage: number = this.profilePaginatorService.nextPage;

      this.loadingMorePosts = true;
      this.profilePaginatorService.paginate(nextPage);
    }
  }

  public onBookmarkPost(isFollowingPost: boolean): void {

    this.toastMessage.add({
        severity: isFollowingPost ? 'success' : 'info',
        summary: 'Éxito',
        detail: isFollowingPost ? 'Has guardado esta noticia.' : 'Has eliminado de tus guardados esta noticia.'
    });
  }

  private paginatePosts(posts: PaginatorItemPost[]): void {

    // Fusionamos los posts previamente cargadados con los posts que provengan del Servidor.
    this.paginatorItemPostsPreviouslyLoaded = [...this.paginatorItemPostsPreviouslyLoaded, ...posts];

    // Actualizamos la vista con la nueva información cargada en el Property Binding correspondiente.
    this.paginatorItemPosts = this.paginatorItemPostsPreviouslyLoaded;
  }
}

