import { Component, OnInit, Input } from '@angular/core';

import { LinkPreviewService } from '../shared/services/link-preview.service';

@Component({
    selector: 'app-link-preview',
    templateUrl: './link-preview.component.html',
    styleUrls: ['./link-preview.component.scss'],
    providers: [ LinkPreviewService ]
})
export class LinkPreviewComponent implements OnInit {

    @Input() originalLinkSource: string = '';
    @Input() metaTags: string | null = null;

    public linkPreviewImage: string = '';
    public linkPreviewTitle: string = '';

    constructor(private readonly linkPreview: LinkPreviewService) { }

    ngOnInit(): void {

        this.linkPreviewImage = this.linkPreview.retrieveImage(this.metaTags);
        this.linkPreviewTitle = this.linkPreview.retrieveTitle(this.metaTags);
    }
}
