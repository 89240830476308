import { Component, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';

import { SubSink } from 'subsink';

import { PostCommunicatorService } from '../../../shared/services/post-communicator.service';

import { PostAction, PostActionType } from '../../../shared/models/post-action';
import { PostPolling } from '../../../shared/models/post-polling';

@Component({
    selector: 'app-create-polling-post-panel',
    templateUrl: './create-polling-post-panel.component.html',
    styleUrls: ['./create-polling-post-panel.component.scss']
})
export class CreatePollingPostPanelComponent implements OnDestroy {
    
    private subscriptions = new SubSink();

    public loadingPollingPostCreationAction: boolean = false;

    constructor(
        private readonly router: Router,
        private readonly postCommunicator: PostCommunicatorService
    ) {

        // Escuchamos cual es la respuesta producto de la intención del usuario de crear un Post de Votación.
        this.subscriptions.sink = postCommunicator.publishedStatus$.subscribe((postStatus) => {

            this.loadingPollingPostCreationAction = false;
        });
    }

    ngOnDestroy(): void {

        this.subscriptions.unsubscribe();
    }

    public onCreatePollingPost(post: Partial<PostPolling>): void {
 
        this.loadingPollingPostCreationAction = true;

        // Comunicamos nuestra intención de publicar el siguiente Post.
        this.postCommunicator.dispatchIntentionToPublish({
            action: PostActionType.CreatePolllingPost,
            payload: post
        } as PostAction);
    }

    public onCancelPostCreation(): void {

        this.router.navigateByUrl('/community');
    }
}
