import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class NotifierService {

    constructor() {}

    public showSuccess(message:string):void {

        alert(message);

    }

    public showError(message:string):void {
        
        alert(message);
    }
}
