import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';

import { Observable } from 'rxjs';
import { map, filter, tap } from 'rxjs/operators';

import { Paginator } from '../models/paginator';
import { NewsResponse } from '../models/news-response';
import { NewDetailResponse } from '../models/new-detail-response';
import { NewOrAdType} from '../models/new-or-ad';
import { NewDetail, MiniNew } from '../models/new-detail';
import { New } from '../models/new';
import { Ad } from '../models/ad';

import { environment } from '@env/environment';

@Injectable({
    providedIn: 'root'
})
export class NewsService {

    constructor( private httpClient: HttpClient ) {}

    public paginate(pageNumber:number, by: string = 'all'):Observable<[ Paginator, NewOrAdType[] ]> {

        const endpoints: { [name: string]: string } = {
            team: '/api/news/team/fav',
            all: '/api/news',
        };

        const endpointSelected  = endpoints[by];

        const currentPage:string = (pageNumber && pageNumber > 0) ? String(pageNumber) : "1";

        let params = new HttpParams()
                                .set('page', currentPage);

        return this.httpClient.get<NewsResponse>(`${ environment.host }${endpointSelected}`, { params })
                .pipe(
                    filter( (newsResponse) => {

                        const { content } = newsResponse;
                        const newsAvailable:boolean = (content.data && content.data.length > 0);

                        // Solamente si existen noticias disponibles en la respuesta, dejamos pasar la información al Consumidor.
                        return newsAvailable;
                    }),
                    map( (newsResponse) => {

                        const { content } = newsResponse;
                        const { current_page:currentPage, last_page:lastPage, per_page:perPage, total, first_page_url:firstPageUrl, last_page_url:lastPageUrl, next_page_url:nextPageUrl } = content;

                        // Obten la información con respecto al estado del Paginador.
                        const paginator:Paginator = {
                            currentPage,
                            lastPage,
                            perPage,
                            total,
                            firstPageUrl,
                            lastPageUrl,
                            nextPageUrl
                        };

                        // Da forma a las noticias.
                        const incomingNews:NewOrAdType[] = content.data
                            .map( (newResponse) => {

                                const { id, url, title, image_local:urlImage, date, crawler:{ domain:hostDomain, favicon } } = newResponse;

                                return {
                                    id,
                                    url,
                                    title,
                                    urlImage,
                                    date,
                                    hostDomain,
                                    type: 'new',
                                    favicon: favicon
                                };
                            });

                        // Inserta un anuncio al final de cada chunk de noticias.
                        incomingNews.push({ company: '', urlBannerImage: '', type: 'ad' } as Ad);

                        return [ paginator, incomingNews ];
                    })
                );
    }

    public getTopFiveNews():Observable<New[]> {

        return this.httpClient.get<NewsResponse>(`${ environment.host }/api/news/top5`)
                .pipe(
                    map( (newsResponse) => {

                        const { content } = newsResponse;

                        // Da forma a las noticias.
                        return content.data.map( (newResponse) => {

                            const { id, url, title, image_local:urlImage, date, crawler:{ domain:hostDomain, favicon } } = newResponse;

                            return {
                                id,
                                url,
                                title,
                                urlImage,
                                date,
                                hostDomain,
                                type: 'new',
                                favicon: favicon
                            };
                        });
                    })
                );
    }

    public getNewDetail(id:number): Observable<NewDetail> {

        return this.httpClient.get<NewDetailResponse>(`${ environment.host }/api/news/${ id }`)
                .pipe(
                    map( (newDetailResponse) => {

                        const { content } = newDetailResponse;
                        const { id, url, nextItem: nextNew, prevItem: prevNew, title, description, image_local: urlImage, date, category, league_id: leagueId, team_id: teamId, currentUser, crawler: { domain: hostDomain, favicon } } = content;

                        return {
                            nextNew,
                            prevNew,
                            id,
                            url,
                            title,
                            description,
                            urlImage,
                            date,
                            hostDomain,
                            category,
                            leagueId,
                            teamId,
                            currentUser,
                            favicon: favicon
                        };
                    })
                );
    }

    public followNew(id:number): Observable<{}> {

        return this.httpClient.post(`${ environment.host }/api/news/${ id }/follow`, {})
                .pipe(
                    tap( (res) => console.log(res) )
                );
    }

    public unfollowNew(id:number): Observable<{}> {

        return this.httpClient.post(`${ environment.host }/api/news/${ id }/unfollow`, {})
                .pipe(
                    tap( (res) => console.log(res) )
                );
    }
}
