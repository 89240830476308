<!--
    The Dropzone.
    Visible solamente cuando no hay una imagen seleccionada.
-->
<ng-container *ngIf="!imageSelected">
    <div class="uploader" (drop)="onFileSelected($event)" (dragover)="onDragOver($event)">
        <p class="top">
          <span>Arrastra y suelta o</span>
          <span class="btn">Añade <input type="file" [accept]="accept" (change)="onFileSelected($event)" required></span>
        </p>
        <p class="bottom">{{textDescription}}</p>
    </div>
</ng-container>

<!--
    The selected image Zone.
    Visible solamente cuando existe una imagen seleccionada.
 -->
<ng-container *ngIf="imageSelected">
    <app-selected-image-content-area
        [image]="image"
        (imageCleared)="onImageCleared()">
    </app-selected-image-content-area>
</ng-container>
