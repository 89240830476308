<button (click)="readAndGo()" class="item item-notification">
  <div class="top">
    <img src="{{imageFrom}}" alt="Foto">
    <p>{{date | date}} • {{dateDifference}}</p>
    <button></button>
  </div>
  <div class="content">
    <p><b>{{from}}</b> {{title}} :</p>
    <div class="message" [innerHTML]=body></div>
  </div>
</button>
