import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';

import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';

import {ProfileResponse} from '../models/profile-response';
import {ProfileChangeResponse} from '../models/profile-change-response';
import {ProfilePayload} from '../models/profile-payload';
import {Profile, Avatar, TeamPreference} from '../models/profile';
import {ProfileSummaryResponse} from '../models/profile-summary-response';
import {ProfileSummary, ProfileSummaryMetrics, TotalResponse} from '../models/profile-summary';
import {Notice, NoticesResponse, NoticeResponse, MarkAsReadResponse} from '../../shared/models/notice';
import {BirthDateFormatUtility} from '@app/core/utilities/birthdate-format-utility';
import {Urls} from '@app/core/utilities/urls';
import {FileUtility} from '@app/core/utilities/file-utility';
import {DateDifference} from '@app/core/utilities/date-difference';

import {environment} from '@env/environment';
import {ProfileChange} from '../models/profile-change';

@Injectable({
  providedIn: 'root'
})
export class ProfileService {

  constructor(private httpClient: HttpClient) {
  }

  public getProfile(): Observable<Profile> {

    return this.httpClient.get<ProfileResponse>(`${environment.host}/api/auth/profile`)
      .pipe(
        map((profileResponse) => {

          const {content} = profileResponse;
          const {
            name,
            email,
            phone,
            city,
            birthday,
            gender,
            picture,
            favorite_team,
            isSocialAccount
          } = content;

          return {
            name,
            email,
            phone,
            city,
            birthDate: BirthDateFormatUtility.fromStringFormatToDateObject(birthday),
            gender,
            avatar: {name: null, image: picture, isEncoded: false, type: null, size: null} as Avatar,
            teamPreference: favorite_team ? ({id: favorite_team.id, name: favorite_team.name}) as TeamPreference : null,
            wasAuthentificatedWithSocialNetworks: isSocialAccount
          };
        })
      );
  }

  public getFavoriteTeamLogo(): string {

    const favoriteTeamLogo: string = localStorage.getItem('ftlg') || '';

    return this.isValidField(favoriteTeamLogo) ? favoriteTeamLogo : '';
  }

  public updateProfile(profile: Profile): Observable<ProfileChange> {

    const profilePayload: ProfilePayload = this.mapProfilePayload(profile);

    return this.httpClient.put<ProfileChangeResponse>(`${environment.host}/api/auth/profile/edit`, {...profilePayload})
      .pipe(
        map((profileChangeResponse) => {

          const {
            name:usrn, 
            id:usid, 
            picture:avat, 
            favorite_team: { id:ftid, name:ftnm, logo:ftlg} 
          } = profileChangeResponse.content;

          return {
            usrn, // nombre de usuario.
            usid, // id Usuario
            avat, // url de la imagen del usuario.
            ftid, // id de equipo favorito.
            ftnm, // nombre de equipo favorito.
            ftlg  // logo de equipo favorito.
          };
        })
      );
  }

  public getProfileSummary(): Observable<ProfileSummary> {

    return this.httpClient.get<ProfileSummaryResponse>(`${environment.host}/api/auth/profile`)
      .pipe(
        map((profileSummaryResponse) => {

          const {
            name: username,
            picture,
            fanLevel,
            totalTopics: followedTopics,
            totalPosts: postsMade,
            totalComments: commentsMade,
            total_points: pointsReached
          } = profileSummaryResponse.content;

          return {
            username,
            avatar: picture ? picture : 'assets/iconos/navbar/default_avatar.png',
            fanLevel: fanLevel.name,
            fanLevelPlate: fanLevel.icon,
            summaryMetrics: {
              pointsReached,
              followedTopics,
              postsMade,
              commentsMade
            }
          };
        })
      );
  }

  public getTotalTopicsIFollow(): Observable<number> {
    return this.httpClient.get<TotalResponse>(`${environment.host}/api/user/topics/total/i/follow`)
      .pipe(
        map((response) => {
          return response.content.total;
        })
      );
  }

  public updateProfileInLocalStorage(profile: ProfileChange): void {

    const {usrn, usid, avat, ftid, ftnm, ftlg} = profile;

    localStorage.setItem('usrn', usrn);
    localStorage.setItem('usid', JSON.stringify(usid));
    localStorage.setItem('avat', avat);
    localStorage.setItem('ftid', JSON.stringify(ftid));
    localStorage.setItem('ftnm', ftnm);
    localStorage.setItem('ftlg', ftlg);
  }

  private mapProfilePayload(profile: Profile): ProfilePayload {

    const {name, phone, city, birthDate, gender, avatar, teamPreference} = profile;

    // Obten y mapea la imagen seleccionada.
    // Si viene una imagen codificada, incluye la información en la petición.
    const {name: imageName, image, isEncoded, type: imageType} = avatar;

    let profileAvatar: { [name: string]: any } = {};

    if (image && isEncoded) {

      profileAvatar = {
        image: {
          name: FileUtility.extractName(imageName || ''),
          ext: FileUtility.extractExtension(imageType || ''),
          encode: FileUtility.extractOnlyBase64Text(image)
        }
      };
    }

    return {
      name: name.trim(),
      phone: phone.trim(),
      city: city.trim(),
      birthday: BirthDateFormatUtility.fromDateObjectToStringFormat(birthDate),
      gender,
      favorite_team_id: teamPreference ? teamPreference.id : null,
      ...profileAvatar
    };
  }

  public getNotifications(page: number = 1, type: string = ''): Observable<Notice[]> {
    const params = new HttpParams()
      .set('page', `${page}`);
    return this.httpClient.get<NoticesResponse>(`${environment.host}/api/notifications/${type}`, {params})
      .pipe(
        map((response) => {
          const {data} = response.content;
          return data.map((notice) => {
            const noticeId = notice.topic_id ? notice.topic_id : notice.post_id;
            return {
              ...notice,
              from: (notice.user_from && notice.user_from.name) ? notice.user_from.name : 'desconocido',
              imageFrom: (notice.user_from && notice.user_from.picture) ? notice.user_from.picture : '/assets/iconos/navbar/default_avatar.png',
              urlApp: Urls.getUrlApp(notice.section, noticeId),
              date: notice.updated_at,
              dateDifference: DateDifference.getDifferenceBetweenDates(new Date(), notice.updated_at)
            };
          });
        })
      );
  }

  public markNotificationAsRead(id: number): Observable<boolean> {
    return this.httpClient.post<MarkAsReadResponse>(`${environment.host}/api/notifications/markas/readed`, {
      notification_id: id
    }).pipe(
      map((response) => {
        return response.success;
      })
    );
  }

  private isValidField(field: string): boolean {

    return (typeof field === 'string') && field.trim().length > 0 && field !== 'null';
  }
}

