import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';

import { Observable, throwError, of, EMPTY } from 'rxjs';
import { catchError, map, exhaustMap } from 'rxjs/operators';

import { MessageService } from 'primeng/api';

import { ModalService } from '@app/ui/modals/shared/services/modal.service';
import { AuthUserAuthenticatedMappingService } from '../services/auth-user-authenticated-mapping.service';

import { AuthRegistration } from '../models/auth-registration';
import { AuthRegistrationPayload } from '../models/auth-registration-payload';
import { AuthUnverifiedAccountResponse } from '../models/auth-unverified-account-response';
import { AuthUserAuthenticated } from '../models/auth-user-authenticated';
import { UserAuthenticatedByActivationCode } from '@app/ui/modals/shared/models/account-activation/user-authenticated-by-activation-code';

import { BirthDateFormatUtility } from '@app/core/utilities/birthdate-format-utility';
import { environment } from '@env/environment';

@Injectable({
    providedIn: 'root'
})
export class RegistrationService {

    constructor(
        private readonly httpClient: HttpClient,
        private readonly messageService: MessageService,
        private readonly modal: ModalService,
        private readonly AuthUserAuthenticatedMapping: AuthUserAuthenticatedMappingService
    ) { }

    public signUp(registration: AuthRegistration): Observable<AuthUserAuthenticated> {

        // Mapeamos la información que será enviada a la API.
        const registrationPayload: AuthRegistrationPayload = this.mapAuthRegistrationPayload(registration);
        
        return this.httpClient.post<AuthUnverifiedAccountResponse>(`${ environment.host }/api/auth/register`, { ...registrationPayload })
                .pipe(
                    exhaustMap((authUnverifiedAccountResponse) => {
                        
                        const { access_token, expires_at } = authUnverifiedAccountResponse.content;

                        return this.modal.activateAccountDialog(access_token, expires_at).onClose;
                    }),
                    map((userAuthenticatedByActivationCode: UserAuthenticatedByActivationCode) => {

                        return this.AuthUserAuthenticatedMapping.mapAuthProfile(userAuthenticatedByActivationCode)
                    }),
                    catchError((error: HttpErrorResponse) => {

                        // Inspecciona la propiedad error de HttpErrorResponse para determinar el tipo de error
                        // y manejarlo acorde.
                        const httpError: any = error.error;
                        const httpErrorCode: number = error.status;
                        
                        if ( httpErrorCode === 400) {
                            
                            if ( httpError && httpError.content ) {

                                const errorMessage: string = this.getRegistrationErrorMessage( httpError.content );
                                
                                this.messageService.add({ 
                                    key:        'authToastNotifications', 
                                    severity:   'error', 
                                    summary:    'LigaMX Registro', 
                                    detail:     errorMessage
                                });
                    
                                return EMPTY;
                            }
                        }

                        // Return an observable with an error that could not be handled.
                        return throwError(error);
                    })
                );
    }

    public emailHasBeenTaken(email: string): Observable<boolean> {

        return this.httpClient.post(`${ environment.host }/api/auth/verifyDuplicity`, { email })
                .pipe(
                    map( _ => false ),
                    catchError( (error: HttpErrorResponse ) => {

                        // Inspecciona la propiedad error de HttpErrorResponse para determinar el tipo de error
                        // y manejarlo acorde.
                        const httpError: any = error.error;
                        const httpErrorCode: number = error.status;
                            
                        if ( httpErrorCode === 400) {
                        
                            if( httpError.error === 'accountEmailExist') {

                                return of(true);
                            
                            } else {

                                return of(false);
                            }
                        }

                        // Return an observable with an error that could not be handled.
                        return throwError(error);
                    })
                );
    }

    // cambiar a privado y manejar desde aqui el servicio mismo.
    public mapAuthRegistrationPayload(registration: AuthRegistration): AuthRegistrationPayload {

        const { 
            name, 
            email, 
            password, 
            phone, 
            city, 
            birthDate, 
            teamPreference, 
            gender, 
            acceptTermsAndConditions } = registration;
        

        return {
            name:                   name.trim(),
            email:                  email.trim(),
            password:               password.trim(),
            password_confirmation:  password.trim(),
            phone:                  phone.trim(),
            city:                   city.trim(), 
            birthday:               BirthDateFormatUtility.fromDateObjectToStringFormat(birthDate),
            favorite_team_id:       teamPreference.id,
            gender,
            acept_terms:            +acceptTermsAndConditions,
            enable_verification:    1  // Flag temporal para habilitar el flujo de autenticación del lado del Web Service.
        };
    }

    private getRegistrationErrorMessage(errorFields: { [name:string]: any }): string {

        // Nos va a permitir recorrer cada campo con los errores obtenidos.
        const errorKeys: string[] = Object.keys(errorFields);
        // Dejamos un mensaje de error por defecto.
        let errorMessage: string = 'No pudimos procesar su solicitud, intentelo nuevamente!';

        // Si no existen mensajes de error (seria algo extraño)
        if ( !(errorKeys.length > 0) ) {

            return errorMessage;
        }
        
        // Recorremos cada campo de error e inspeccionamos su contenido.
        for( let key of errorKeys ) {

            if ( Array.isArray(errorFields[key]) && errorFields[key].length > 0 ) {

                // Retorna el primer mensaje de error que encuentres;
                errorMessage = errorFields[key][0];
                
                break;
            }
        }

        return errorMessage;
    }
}
