<div class="main-grid">
  <div class="grid-left">
    <div>
      <button class="cancel" type="button" (click)="cancel()">
        <img src="assets/iconos/icono_arrowdown.svg" alt="Regresar">
        cancelar
      </button>
      
      <app-boarding-teams (teamSelected)="onTeamSelected($event)"></app-boarding-teams>
    </div>
  </div>

  <div class="grid-right">
    <!-- Banner de Ir a Comunidad -->
    <app-banner-community></app-banner-community>
    <!-- Anuncio de Tipo C -->
    <app-advertisement-type-c *ngIf="showAdTypeC" (loaded)="showAdTypeC = $event"></app-advertisement-type-c>
  </div>
</div>


