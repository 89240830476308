import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {Topic, TopicsResponse} from '../models/top-topics';
import {environment} from '@env/environment';
import {map} from 'rxjs/operators';
import {HttpClient, HttpParams} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class TopPostsService {

  constructor(private httpClient: HttpClient) {
  }

  public getTopBy(section: string = 'home', by: string = 'top'): Observable<Topic[]> {
    const endpoints: { [key: string]: { [name: string]: string } } = {
      home: {
        top: '/api/topics/top5/hot/',
        new: '/api/topics/top5/new',
        hot: '/api/topics/top5' // cabiar cuando haya a hot/last
      },
      team: {
        top: '/api/topics/favteam/top5/hot/',
        new: '/api/topics/favteam/top5/new',
        hot: '/api/topics/favteam/top5' // cabiar cuando haya a hot/last
      },
      follow: {
        top: '/api/topics/ifollow/top5/hot/',
        new: '/api/topics/ifollow/top5/new',
        hot: '/api/topics/ifollow/top5' // cabiar cuando haya a hot/last
      }
    };

    const endpoint: string = endpoints[section][by];
    console.log(endpoint);
    return this.httpClient.get<TopicsResponse>(`${environment.host}${endpoint}`)
      .pipe(
        map((postsResponse) => {
          const {content} = postsResponse;
          return content.data.map((topic) => {
            const {id, name, is_official, cover, currentUser, user_id, totals, description} = topic;
            return {
              id,
              name,
              currentUser,
              user_id,
              is_official,
              cover,
              totals,
              description
            };
          });
        })
      );

  }

}

