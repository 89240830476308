<div class="loading" *ngIf="isLoadingVideo">
    <p-progressBar [value]="progress"></p-progressBar>
    <p>Guardando Publicación <span>{{ progress }}%</span></p>
</div>

<p *ngIf="successMessage">{{ successMessage }}</p>
<p *ngIf="errorMessage">{{ errorMessage }}</p>

<button type="button" (click)="close()" *ngIf="!isLoadingVideo">Aceptar</button>

