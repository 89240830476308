import {Component, OnInit, OnDestroy, Input, Output, EventEmitter} from '@angular/core';
import { Router } from '@angular/router';

import {SubSink} from 'subsink';
import {MenuItem} from 'primeng/api';

import {PostMediaService} from '../shared/services/post-media.service';
import {ReactionService} from '../shared/services/reaction.service';
import {Reaction, PostMedia, LikeStatus} from '../shared/models/post-media';

@Component({
  selector: 'app-post-media-expanded',
  templateUrl: './post-media-expanded.component.html',
  styleUrls: ['./post-media-expanded.component.scss'],
  providers: [PostMediaService, ReactionService]
})
export class PostMediaExpandedComponent implements OnInit, OnDestroy {

  @Input('post') currentPost!: PostMedia;
  @Input() showTopicAsPostHeader: boolean = true;
  @Input() logged = true;

  @Output() onBookmark: EventEmitter<boolean> = new EventEmitter<boolean>();

  private subscriptions = new SubSink();
  // Relativo al Post.
  public postId: number = 0;
  public description: string = '';
  public backgroundCover: string = '';
  public publishedBy: string = '';
  public publishedDate: Date = new Date();
  public reactionsAvailable: Reaction[] = [];
  public reactionsSelected: Reaction[] = [];
  public likeStatus: LikeStatus = LikeStatus.Unsettled;
  public totalLikes: number = 0;
  public totalDislikes: number = 0;
  public totalComments: number = 0;
  public isOfficialPost: boolean = false;
  public followingPost: boolean = false;
  public ownedPost: boolean = false;
  // Relativo al Topic.
  public topicId: number = 0;
  public topicTitle: string = '';
  public topicIsOfficial: boolean = false;
  public topicFollowing: boolean = false;
  public topicOwnership: boolean = false;

  public postMenuOptions!: MenuItem[];

  constructor(
    private readonly router: Router,
    private readonly postMedia: PostMediaService,
    private readonly reaction: ReactionService
  ) {
  }

  ngOnInit(): void {

    const {
      id: postId,
      description,
      backgroundCover,
      publishedBy,
      publishedDate,
      reactions,
      likeStatus,
      totalLikes,
      totalDislikes,
      totalComments,
      isOfficial,
      ownership,
      following,
      topic
    } = this.currentPost;
    // Obtenemos una referencia al identificador del Post actual.
    this.postId = postId;
    // Autor y fecha de Publicación.
    this.publishedBy = publishedBy;
    this.publishedDate = publishedDate;
    // Texto del Post.
    this.description = description;
    // El background del Post será la imagen que tenga el Topic asociado.
    this.backgroundCover = backgroundCover;
    // Todas la lista de reacciones disponibles para seleccionar.
    this.reactionsAvailable = [...reactions];
    // Solamente aceptamos las reacciones cuyo totalizador sea mayor 0
    this.reactionsSelected = [...reactions.filter((r) => r.totalReactions > 0)];
    // El estado de la acción de Like/Dislike
    this.likeStatus = likeStatus;
    // Total de Likes y Dislikes.
    this.totalLikes = totalLikes;
    this.totalDislikes = totalDislikes;
    // Total de Comentarios.
    this.totalComments = totalComments;
    // Este es un Post Oficial?
    this.isOfficialPost = isOfficial;
    // Soy Propietario de este Post?
    this.ownedPost = ownership;
    // Siguiendo este Post?
    this.followingPost = following;

    // Solo tiene sentido si el Topic es visible en el Post.
    if (this.showTopicAsPostHeader && topic) {

      // Información del Topic asociado a este Post.
      this.topicId = topic.id;
      this.topicTitle = topic.title;
      this.topicIsOfficial = topic.isOfficial;
      this.topicFollowing = topic.following;
      this.topicOwnership = topic.ownership;
    }

    // Cargamos las opciones del Menú 3 Dots (...) siempre y cuando el Post sea mio.
    if (this.ownedPost) {

      this.loadPostMenuOptions();
    }
  }

  ngOnDestroy(): void {

    this.subscriptions.unsubscribe();
  }

  public onClickLike(likeStatus: LikeStatus): void {
    if (this.logged) {
      if (likeStatus === LikeStatus.Liked) {

        this.subscriptions.sink = this.postMedia.addLike(this.postId).subscribe();

      } else {

        this.subscriptions.sink = this.postMedia.removeLike(this.postId).subscribe();
      }
    }
  }

  public onClickDislike(dislikeStatus: LikeStatus): void {
    if (this.logged) {
      if (dislikeStatus === LikeStatus.Disliked) {

        this.subscriptions.sink = this.postMedia.addDislike(this.postId).subscribe();

      } else {

        this.subscriptions.sink = this.postMedia.removeDislike(this.postId).subscribe();
      }
    }
  }

  public onClickReaction(reaction: Reaction): void {
    if (this.logged) {
      // En este punto tenemos la reacción elegida por el usuario, ya sea que esta provenga del Menú de Reacciones
      // o de la Lista de Reacciones seleccionadas por otro usuarios.

      // Verificamos dentro de la lista de reacciones seleccionadas, si es que hay una reacción previamente seleccionada por nosotros.
      const previousReaction: Reaction | undefined = this.reactionsSelected.find((r) => r.isSelected);
      // Verificamos si la reacción seleccionada por nosotros ya existe en la lista de reacciones seleccionadas (independientemente de si este seleccionada o no).
      const currentReaction: Reaction | undefined = this.reactionsSelected.find((r) => reaction.id === r.id);

      // Si la reacción seleccionada por nosotros NO existe dentro de la lista de reacciones seleccionadas.
      this.reactionsSelected = !currentReaction ?
        // Agregamos una nueva reacción a la lista de reacciones seleccionadas.
        this.reaction.addNewReaction(previousReaction, reaction, this.reactionsSelected) :
        // Por el contrario, si la reacción seleccionada por el nosotros YA existe en la lista de reacciones seleccionadas (independientemente de si este seleccionada o no).
        // Modificamos el estado de la reacción existente dentro de la lista de reacciones seleccionadas
        this.reaction.modifyExistingReaction(previousReaction, currentReaction, this.reactionsSelected)

      // En este punto, la lista de reacciones seleccionadas ha sido modificada.
      // Buscamos si la reacción seleccionada de nuestro intereses se encuentra en esta nueva lista de reacciones.
      if (this.reactionsSelected.some((r) => (reaction.id === r.id && r.isSelected))) {

        // Reescribimos la reacción.
        this.subscriptions.sink = this.postMedia.addReaction(this.postId, reaction.id).subscribe();

      } else {

        // Borramos la reacción.
        this.subscriptions.sink = this.postMedia.removeReaction(this.postId).subscribe();
      }
    }
  }

  public onFollowPost(): void {
    if (this.logged) {
      this.subscriptions.sink = this.postMedia.followPost(this.postId).subscribe();

      this.onBookmark.emit(true);
    }
  }

  public onUnfollowPost(): void {
    if (this.logged) {
      this.subscriptions.sink = this.postMedia.unfollowPost(this.postId).subscribe();

      this.onBookmark.emit(false);
    }
  }

  public onClickComments(): void {

    this.router.navigate(['/community/post', this.postId]);
  }

  private loadPostMenuOptions(): void {

    this.postMenuOptions = [{
      items: [
        {
          label: 'Editar',
          icon: 'pi pi-user-edit',
          command: () => {

            // Redireccionar a la sección donde se editará este Post.
            this.router.navigate(['/post/edit', this.postId]);

          }
        }
      ]
    }];
  }
}
