import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { TeamPreferenceResponse } from '../models/team-preference-response';
import { TeamPreference } from '../models/team-preference';

import { environment } from '@env/environment';

@Injectable({
    providedIn: 'root'
})
export class TeamPreferenceService {

    constructor(
        private httpClient: HttpClient
    ) { }

    public updatePreference(teamId: number, teamName: string, teamLogo: string): Observable<TeamPreference> {

        return this.httpClient.put<TeamPreferenceResponse>(`${ environment.host }/api/user/favorite-team/${ teamId }`, {})
            .pipe(
                map(() => {

                    return {
                        ftid:   teamId,
                        ftnm:   teamName,
                        ftlg:   teamLogo
                    };
                })
            );
    }

    public updatePreferenceInLocalStorage(teamPreference: TeamPreference): void {

        const { ftid, ftnm, ftlg } = teamPreference;

        localStorage.setItem('ftid', JSON.stringify(ftid));
        localStorage.setItem('ftnm', ftnm);
        localStorage.setItem('ftlg', ftlg);
    }

    public hasTeamAssociated(): boolean {

        const teamPreference: string | null = localStorage.getItem('ftid');

        if ( !teamPreference ) {

            return false;
        }

        if( isNaN(+teamPreference) || +teamPreference <= 0) { 
            
            return false; 
        }

        return true;
    }
}
