import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule} from '@angular/forms';
import {RouterModule} from '@angular/router';

import {DropdownModule} from 'primeng/dropdown';

import {TopicFlatComponent} from './components/topic-flat/topic-flat.component';
import {TopicCallToActionComponent} from './components/topic-call-to-action/topic-call-to-action.component';
import {TopicExpandedComponent} from './components/topic-expanded/topic-expanded.component';
import {RecommendedTopicListComponent} from './components/recommended-topic-list/recommended-topic-list.component';
import {RateTotalizerPipe} from './pipes/rate-totalizer.pipe';
import {ButtonCallToActionComponent} from './components/button-call-to-action/button-call-to-action.component';
import {TopicJoinedTypeAheadListComponent} from './components/topic-joined-type-ahead-list/topic-joined-type-ahead-list.component';
import {TopicJoinedItemComponent} from './components/topic-joined-type-ahead-list/topic-joined-item/topic-joined-item.component';
import {TopicJoinedSelectedItemComponent} from './components/topic-joined-type-ahead-list/topic-joined-selected-item/topic-joined-selected-item.component';
import {MenuModule} from 'primeng/menu';
import {ConfirmDialogModule} from 'primeng/confirmdialog';
import {ToastModule} from "primeng/toast";

@NgModule({
  declarations: [
    TopicFlatComponent,
    TopicCallToActionComponent,
    TopicExpandedComponent,
    RecommendedTopicListComponent,
    RateTotalizerPipe,
    ButtonCallToActionComponent,
    TopicJoinedTypeAheadListComponent,
    TopicJoinedItemComponent,
    TopicJoinedSelectedItemComponent,

  ],
  imports: [
    CommonModule,
    DropdownModule,
    FormsModule,
    RouterModule,
    ConfirmDialogModule,
    MenuModule,
    ToastModule,
  ],
  exports: [
    TopicFlatComponent,
    TopicCallToActionComponent,
    TopicExpandedComponent,
    RecommendedTopicListComponent,
    TopicJoinedTypeAheadListComponent
  ]
})
export class TopicModule {
}
