import { Injectable } from '@angular/core';

export interface FacebookUserCredential {
    socialId:       string;
    displayedName:  string;
    email:          string;
    photoUrl:       string
};

interface FacebookUserProfile {
    id:         string;
    name:       string;
    email:      string;
};

interface FacebookUserPicture {
    data:   {
        url: string;
    };
};

@Injectable({
    providedIn: 'root'
})
export class AuthFacebookSocialService {

    constructor() {}

    public async signIn(): Promise<FacebookUserCredential> {

        try {
            
            const authResponse: fb.AuthResponse = await this.loginIntoFacebook();
            const userProfile: FacebookUserProfile = await this.getProfile(authResponse.userID);
            const userPicture: FacebookUserPicture = await this.getPicture(authResponse.userID);

            return {
                socialId:       userProfile.id,
                displayedName:  userProfile.name,
                email:          userProfile.email || '',
                photoUrl:       userPicture.data.url || ''
            } as FacebookUserCredential;

        } catch(e) {

            throw e;
        }
    }

    public logout() {

        this.logoutFromFacebook();
    }

    private loginIntoFacebook(): Promise<fb.AuthResponse> {
 
        return new Promise<fb.AuthResponse>((resolve, reject) => {

            FB.login((response) => {
                
                const { status, authResponse } = response;
                
                if ( (status === 'unknown' || status === 'not_authorized') ) {

                    reject(new Error('User cancelled login or did not fully authorize.'));

                    return;
                }

                resolve(authResponse);
            
            }, { scope: 'email,public_profile' , return_scopes: true });
        });
    }

    private getProfile(userID: string): Promise<FacebookUserProfile> {

        const scopeFields: string = 'name,email';

        return new Promise((resolve) => {
            
            FB.api(`${ userID }/`, { fields: scopeFields }, (response: FacebookUserProfile) => {

                resolve(response);
            });
        });
    }

    private getPicture(userID: string): Promise<FacebookUserPicture>  {
        
        return new Promise((resolve) => {
            
            FB.api(`/${ userID }/picture?type=large&redirect=false`, {}, (response: FacebookUserPicture) => {

                resolve(response);
            });
        });
    }

    private logoutFromFacebook() {

        FB.getLoginStatus(function(response) {
            
            if (response.status === 'connected') {

                FB.logout();
            }
        });
    }
}
