import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { SplitButtonModule } from 'primeng/splitbutton';
import {MenuModule} from 'primeng/menu';
import { NavbarComponent } from './navbar/navbar.component';

@NgModule({
  declarations: [ NavbarComponent ],
  imports: [
    CommonModule,
    RouterModule,
    SplitButtonModule,
    MenuModule,

  ],
  exports: [ NavbarComponent ]
})
export class NavbarModule { }
