import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'totalVotesCountedFormat'
})
export class TotalVotesCountedFormatPipe implements PipeTransform {

    transform(value: number): string {

        if ( value <= 0 ) {

            return 'Sin votación'
        }

        if ( value === 1 ) {

            return value + ' voto'
        }

        return value + ' votos';
    }
}
