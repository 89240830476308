import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';

import { RegistrationService } from '../../shared/services/registration.service';
import { AuthRegistration } from '../../shared/models/auth-registration';

import { CommonValidators } from '@app/core/validators/common-validators';
import { RegistrationValidators } from '../../shared/validators/registration-validators';

@Component({
    selector: 'app-signup-form',
    templateUrl: './signup-form.component.html',
    styleUrls: ['./signup-form.component.scss']
})
export class SignupFormComponent implements OnInit {

    @Input() public signUpLoading: boolean = false;
    @Output() private signUp: EventEmitter<AuthRegistration> = new EventEmitter<AuthRegistration>();
    @Output() private cancel: EventEmitter<void> = new EventEmitter<void>();

    public signUpForm!: FormGroup;

    constructor( 
        private fb: FormBuilder,
        private registrationService: RegistrationService
    ) {}

    ngOnInit(): void {

        this.loadSignUpForm();
    }  

    private loadSignUpForm(): void {

        this.signUpForm = this.fb.group({
            name: ['', {
               validators: [
                    Validators.required,
                    Validators.minLength(3),
                    Validators.maxLength(255),
                    CommonValidators.mustNotBeBlank
               ]
            }],
            email: ['', {
                validators: [
                    Validators.required,
                    Validators.email,
                    Validators.maxLength(255)
                ],
                asyncValidators: [ RegistrationValidators.uniqueEmail( this.registrationService ) ],
                updateOn: 'blur'
            }],
            password: ['', {
                validators: [
                    Validators.required,
                    Validators.minLength(6)
                ]
            }],
            phone:                      ['', Validators.required ],
            city:                       ['', Validators.required ],
            birthDate:                  ['', Validators.required ],
            teamPreference:             [ null, Validators.required ],
            gender:                     [ 'M', Validators.required ],
            acceptTermsAndConditions:   [ false ]
        });
    }

    public onSignUp():void {

        this.signUpForm.markAllAsTouched();

        if ( this.signUpForm.valid && !this.signUpForm.pending ) {

            this.signUp.emit( this.signUpForm.value as AuthRegistration );
        }        
    }

    public back():void {

        this.cancel.emit();
    }

    // Getters.
    get name() {

        return this.signUpForm.get('name');
    }

    get email() {

        return this.signUpForm.get('email');
    }

    get password() {

        return this.signUpForm.get('password');
    }

    get phone() {

        return this.signUpForm.get('phone');
    }

    get city() {

        return this.signUpForm.get('city');
    }

    get birthDate() {

        return this.signUpForm.get('birthDate');
    }

    get teamPreference() {

        return this.signUpForm.get('teamPreference');
    }

    get gender() {

        return this.signUpForm.get('gender');
    }

    get acceptTermsAndConditions() {

        return this.signUpForm.get('acceptTermsAndConditions');
    }

    // Errores generados en el campo name.
    get errorOnNameField() {

        // Nos interesa solo mostrar el primer error generado. En esta caso si el campo 'name' genera más 
        // de 1 error, no queremos que se empalmen mostrando 2 mensajes de errores al mismo tiempo.
        return Object.keys(this.signUpForm.get('name')?.errors || {})[0];
    }
}
