import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { DropdownModule } from 'primeng/dropdown';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { LinkPreviewModule } from '@app/ui/link-preview/link-preview.module';
import { PostModule } from '@app/ui/post/post.module';
import { TopicModule } from '@app/ui/topic/topic.module';
import { MediaSourceModule } from "@app/ui/media-source/media-source.module";

import { SearchResultsRoutingModule } from './search-results-routing.module';
import { SearchResultsPagesComponent } from './pages/search-results-pages/search-results-pages.component';
import { SearchResultsTopicsPanelComponent } from './components/search-results-topics-panel/search-results-topics-panel.component';
import { SearchResultsNewsPanelComponent } from './components/search-results-news-panel/search-results-news-panel.component';
import { SearchResultsPostsPanelComponent } from './components/search-results-posts-panel/search-results-posts-panel.component';

import { NewComponent } from './components/new/new.component';
import { ExtractHostDomainPipe } from './pipes/extract-host-domain.pipe';
import {AdvertisementModule} from "@app/ui/advertisement/advertisement.module";

@NgModule({
  declarations: [
    SearchResultsPagesComponent,
    SearchResultsTopicsPanelComponent,
    SearchResultsNewsPanelComponent,
    SearchResultsPostsPanelComponent,
    NewComponent,
    ExtractHostDomainPipe
  ],
    imports: [
        CommonModule,
        FormsModule,
        InfiniteScrollModule,
        DropdownModule,
        LinkPreviewModule,
        PostModule,
        TopicModule,
        MediaSourceModule,
        SearchResultsRoutingModule,
        AdvertisementModule
    ]
})
export class SearchResultsModule { }
