import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AuthenticateService } from '@app/auth/shared/guards/authenticate.service';
import { VerifyTeamPreferenceService } from '@app/auth/shared/guards/verify-team-preference.service';

// Pages.
import { ProfilePageComponent } from './pages/profile-page/profile-page.component';
import { MyPostsPageComponent } from './pages/my-posts-page/my-posts-page.component';
import { SavedNewsPageComponent } from './pages/saved-news-page/saved-news-page.component';
import { TopicsCreatedPageComponent } from './pages/topics-created-page/topics-created-page.component';
import { ProfileSettingsPageComponent } from './pages/profile-settings-page/profile-settings-page.component';
import { DisableMyAccountPageComponent } from './pages/disable-my-account-page/disable-my-account-page.component';

// Panels.
import { EditProfilePanelComponent } from './components/edit-profile-panel/edit-profile-panel.component';
import { ChangePasswordPanelComponent } from './components/change-password-panel/change-password-panel.component';
import { NotificationsPanelComponent  } from './components/notifications-panel/notifications-panel.component';
import { PrivacyPolicyPanelComponent } from './components/privacy-policy-panel/privacy-policy-panel.component';
import { TermsAndConditionsPanelComponent } from './components/terms-and-conditions-panel/terms-and-conditions-panel.component';

const routes: Routes = [
    { path: 'account', component: ProfilePageComponent, canActivate: [ AuthenticateService, VerifyTeamPreferenceService ] },
    { path: 'account/my-posts', component: MyPostsPageComponent, canActivate: [ AuthenticateService, VerifyTeamPreferenceService] },
    { path: 'account/saved-news', component: SavedNewsPageComponent, canActivate: [ AuthenticateService, VerifyTeamPreferenceService ] },
    { path: 'account/topics-created', component: TopicsCreatedPageComponent, canActivate: [ AuthenticateService, VerifyTeamPreferenceService ] },
    { 
        path: 'account/profile-settings', 
        component: ProfileSettingsPageComponent, 
        canActivate: [ AuthenticateService, VerifyTeamPreferenceService ],
        children: [
            { path: '', redirectTo: 'edit-profile', pathMatch: 'full' },
            { path: 'edit-profile', component: EditProfilePanelComponent },
            { path: 'change-password', component: ChangePasswordPanelComponent },
            { path: 'notifications', component: NotificationsPanelComponent },
            { path: 'privacy-policy', component: PrivacyPolicyPanelComponent },
            { path: 'terms-and-conditions', component: TermsAndConditionsPanelComponent }
        ]
    },
    { path: 'account/disable-my-account', component: DisableMyAccountPageComponent }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class AccountSettingsRoutingModule { }
