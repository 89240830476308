<div class="teams-panel">
    <div
        *ngFor="let teamGroup of teamGroups"
        class="team-group"
        (click)="onSelectTeamGroup(teamGroup)"
        [class.team-group-active]="teamGroup.isSelected">

        <img [src]="teamGroup.leagueImageSrc" [alt]="teamGroup.leagueName">
    </div>
</div>

<ng-content select="app-team-group"></ng-content>

<ng-content></ng-content>
