<div class="main-grid">
  <div class="grid-left">

    <div class="back mb-40">
      <button type="button" (click)="back()"><img src="assets/iconos/icono_arrowdown.svg" alt="Regresar">
        Regresar
      </button>
    </div>

    <h2>{{searchExpression}}</h2>
    <p>Resultados de búsqueda</p>
    <div class="controls">
      <a [routerLink]="['./topics']" routerLinkActive="active" queryParamsHandling="preserve">Topics</a>
      <a [routerLink]="['./']" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }"
         queryParamsHandling="preserve">Posts</a>
      <a [routerLink]="['./news']" routerLinkActive="active" queryParamsHandling="preserve">Noticias</a>
    </div>

    <!-- Top TOPICS -->
    <router-outlet></router-outlet>

  </div>
  <div class="grid-right pt-50">
    <div class="item">
      <app-advertisement-type-d *ngIf="showAdTypeD" (loaded)="showAdTypeD = $event"></app-advertisement-type-d>
    </div>
    <div class="item">
      <app-advertisement-type-c *ngIf="showAdTypeC" (loaded)="showAdTypeC = $event"></app-advertisement-type-c>
    </div>
  </div>
</div>



