import {Component, Input} from '@angular/core';

@Component({
  selector: 'app-social-sharing',
  templateUrl: './social-sharing.component.html',
  styleUrls: ['./social-sharing.component.scss']
})
export class SocialSharingComponent {
  @Input() id = 0;
  public url = location.origin + '/#/post/share/';

  public sharePost(inputElement: HTMLInputElement): void {
    inputElement.select();
    document.execCommand('copy');
    inputElement.setSelectionRange(0, 0);
    alert('La liga se a guardado en tu portapapeles');
  }
}

