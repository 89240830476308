import { Component, OnInit, OnDestroy } from '@angular/core';

import { SubSink } from 'subsink';
import { switchMap, distinctUntilChanged } from 'rxjs/operators';

import { PaginatorLandingTestService } from '../../shared/services/paginator-landing-test.service';
import { PaginatorItemPost } from '../../shared/models/paginator-item-post';

@Component({
    selector: 'app-test-landing',
    templateUrl: './test-landing.component.html',
    styleUrls: ['./test-landing.component.scss']
})
export class TestLandingComponent implements OnInit, OnDestroy {

    private subscriptions = new SubSink();

    public paginatorItemPosts: PaginatorItemPost[] = [];
    public paginatorItemPostsPreviouslyLoaded: PaginatorItemPost[] = [];

    public loadingMorePosts: boolean = false;
    public initialLoadingDone: boolean = false;
    public postsLoaded: boolean = false;

    constructor(private readonly paginatorLandingTest: PaginatorLandingTestService) { }

    ngOnInit(): void {

        this.initialLoadingDone = false;

        this.subscriptions.sink = this.paginatorLandingTest.listenPagination$
            .pipe(
                distinctUntilChanged(),
                switchMap((page) => this.paginatorLandingTest.getPosts(page))
            )
            .subscribe((posts) => {

                const isFirstPage: boolean = ( (this.paginatorLandingTest.nextPage - 1) === 1);

                if ( isFirstPage ) {

                    this.initialLoadingDone = true;
                }

                this.loadingMorePosts = false;
                this.paginatePosts(posts);
                console.log(posts);
        });

        // Fire first Pagination Page.
        this.paginatorLandingTest.paginate(1);
    }

    ngOnDestroy(): void {

        this.subscriptions.unsubscribe();
    }

    public onScroll(): void {

        if ( this.paginatorLandingTest.hasNotReachedPaginationEnd() ) {

            const nextPage: number = this.paginatorLandingTest.nextPage;

            this.loadingMorePosts = true;
            this.paginatorLandingTest.paginate(nextPage);
        }
    }

    private paginatePosts(posts: PaginatorItemPost[]): void {

        // Fusionamos los posts previamente cargadados con los posts que provengan del Servidor.
        this.paginatorItemPostsPreviouslyLoaded = [ ...this.paginatorItemPostsPreviouslyLoaded, ...posts ];

        // Actualizamos la vista con la nueva información cargada en el Property Binding correspondiente.
        this.paginatorItemPosts = this.paginatorItemPostsPreviouslyLoaded;
      }
}
