import { Component, OnInit, Input } from '@angular/core';

import { PostPollingService } from '../shared/services/post-polling.service';
import { PostPolling, Polling } from '../shared/models/post-polling';

@Component({
  selector: 'app-post-polling',
  templateUrl: './post-polling.component.html',
  styleUrls: ['./post-polling.component.scss'],
  providers: [ PostPollingService ]
})
export class PostPollingComponent implements OnInit {

    @Input('post') currentPost!: PostPolling;
    @Input() logged = true;
    public openPollingPanel: boolean = false;
    public openPollingMetricsPanel: boolean = false;

    // Información relativa al Post de Votación.
    private pollingId: number = 0;
    public description: string = '';
    public isOpen: boolean = false;
    public totalVotesCounted: number = 0;
    public TTL: number = 0;
    public polls: Polling[] = [];

    constructor(private readonly postPolling: PostPollingService) { }

    ngOnInit(): void {

        this.pollingId = this.currentPost.pollingId;
        this.description = this.currentPost.description;
        this.isOpen = this.currentPost.isOpen;
        this.totalVotesCounted = this.currentPost.totalVotesCounted;
        this.TTL = this.currentPost.TTL;

        // Tanto el Panel de Votación como el Panel de Métricas se alimentan de la misma fuente de información. La única diferencia es que, cuando el usuario
        // ha generado un voto, dentro de la lista de opciones el valor isSelected puede ser true o false dependiendo la opción seleccionada.
        this.polls = this.currentPost.polls;

        // Al cargar este Componente debemos checar el estado de la Publicación de Votación.
        if ( this.isOpen ) {

            // Votación ABIERTA.
            // 1. Checamos el estado de la Votación para determinar si le enseñamos el Panel de Votación o el Panel de las Métricas.
            //    Esto dependerá de si el usuario ha votado o no.

            // ¿El usuario ya ha votado previamente?
            if( this.hasAlreadyVoted() ) {
                // Si.
                // Mostramos el Panel de Métricas.
                this.showPollingMetricsPanel();
                // En este caso, es seguro que una opción dentro de la lista de opciones, tenga en su propiedad isSelected el valor true.

            } else {
                // No.
                // Mostramos el Panel de Votación. Invitamos al usuario a votar por una opción.
                this.showPollingPanel();
            }

        } else {

            // Votación CERRADA.
            // 1. Mostramos el Panel de Métricas.
            this.showPollingMetricsPanel();
            // En este caso, la propiedad isSelected de la opción seleccionada por el usuario tenga como valor true o false.
        }

    }

    public onVote(pollSelected: Polling): void {

        if (this.logged){
          // Implementar la votación para ello toma los siguientes datos.
          // 1. id de la encuesta de votación (pollingId), OJO: No confundir con el id de la Publicación de Votación.
          // 2. opción seleccionada por el usuario (pollSelected.option)
          this.postPolling.vote(this.pollingId, pollSelected.option).subscribe(() => {

            // Al votar, sabemos con seguridad que:
            // 1. La publicación de votación se encuentra ABIERTA, y
            // 2. El usuario ha seleccionado una opción del Panel de Votación.

            // Producto de esta acción, incrementamos en 1 el total de Votaciones de este Post.
            this.totalVotesCounted += 1;

            // Mostramos el Panel de Métricas al usuario junto con la opción que acaba de seleccionar.
            this.showPollingMetricsPanel();

            // En este caso, creamos una fuente de información nueva en donde se incluya la selección hecha por el usuario. Simplemente agregamos a la opción
            // seleccionada por el usuario el flag isSelected y modificamos la propiedad voteCounting. El panel de métricas se alimentará de esta nueva fuente de datos.
            this.polls = this.currentPost.polls.map((poll) => {

              return ( poll.option == pollSelected.option ) ? ({ ...poll, isSelected: true, voteCounting: poll.voteCounting + 1 }) : poll;
            });
          });
        }
    }

    // Tanto el Panel de Votación como el Panel de Métricas son ambas mutuamente excluyentes.
    private showPollingPanel(): void {

        this.openPollingPanel = true;
        this.openPollingMetricsPanel = false;
    }

    // Tanto el Panel de Votación como el Panel de Métricas son ambas mutuamente excluyentes.
    private showPollingMetricsPanel(): void {

        this.openPollingPanel = false;
        this.openPollingMetricsPanel = true;
    }

    private hasAlreadyVoted(): boolean {

        return this.polls.some((poll) => poll.isSelected);
    }
}
