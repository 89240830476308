import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SelectedVideoContentAreaComponent } from './components/selected-video-content-area/selected-video-content-area.component';
import { SingleVideoUploaderFieldComponent } from './single-video-uploader-field/single-video-uploader-field.component';

@NgModule({
    declarations: [
        SelectedVideoContentAreaComponent,
        SingleVideoUploaderFieldComponent
    ],
    imports: [
        CommonModule
    ],
    exports: [
        SingleVideoUploaderFieldComponent
    ]
})
export class SingleVideoUploaderFieldModule { }
