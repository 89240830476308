import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';

import { PostMediaService } from '../../shared/services/post-media.service';

@Component({
    selector: 'app-topic-call-to-action',
    templateUrl: './topic-call-to-action.component.html',
    styleUrls: ['./topic-call-to-action.component.scss']
})
export class TopicCallToActionComponent implements OnInit {
    
    @Input() id: number = 0;
    @Input() title: string = '';
    @Input() isOfficial: boolean = false;
    @Input() following: boolean = false;
    @Input() ownership: boolean = false;

    public loadingTopicSubscriptionAction: boolean = false;
    public topicOwnership: boolean = false;
    public topicFollowing: boolean = false;

    constructor(
        private readonly router: Router,
        private readonly postMedia: PostMediaService,
    ) { }

    ngOnInit(): void {

        this.topicOwnership = this.ownership;
        this.topicFollowing = this.following;
    }

    public onEditTopic(): void {

        this.router.navigate(['/topic/edit', this.id]);
    }

    public onJoinTopic(): void {

        const topicId: number = this.id;

        this.loadingTopicSubscriptionAction = true;

        this.postMedia.joinTopic(topicId).subscribe(() => {

            this.topicFollowing = true;
            this.loadingTopicSubscriptionAction = false;
        });
    }

    public onUnJoinTopic(): void {

        const topicId: number = this.id;
        
        this.loadingTopicSubscriptionAction = true;

        this.postMedia.disjoinTopic(topicId).subscribe(() => {

            this.topicFollowing = false;
            this.loadingTopicSubscriptionAction = false;
        });
    }
}
