<div class="bg-profile">
  <div class="profile main-grid">
    <section class="user">

      <div class="back mb-40">
        <button type="button" (click)="back()"><img src="assets/iconos/icono_arrowdown.svg" alt="Regresar">
          Regresar
        </button>
      </div>

      <div class="row">
        <h3>Mi <b>Perfil</b></h3>
      </div>

      <ng-container *ngIf="profileMetricsLoaded; else loadingProfileMetrics">
        <div class="row">
          <div class="img">
            <img class="picture" [src]="avatar" [alt]="username">
            <img [src]="teamImg" class="team" alt="Favorite Team">
          </div>

          <div class="info">
            <h4><b>{{ username }}</b></h4>
            <p>
              <span>{{ pointsReached }} PTS</span>
              <span><img class="picture" [src]="fanLevelPlate" [alt]="username">{{ fanLevel }}</span>
            </p>
          </div>
        </div>

        <div class="row numbers">
          <p><span>{{ followedTopics }}</span> Topics Seguidos</p>
          <p><span>{{ postsMade }}</span> Publicaciones</p>
          <p><span>{{ commentsMade }}</span> Comentarios</p>
        </div>

        <ul class="row menu">
          <li>
            <img src="assets/iconos/icono_comunidad.svg" alt="posts"><a routerLink="my-posts">Mis Posts
            <img alt="Mis Posts" src="assets/iconos/arrow_publica.svg"></a>
          </li>
          <li>
            <img src="assets/iconos/icono_misttopicscreados.svg" alt="Topics Creados"><a routerLink="topics-created">Mis
            Topics Creados
            <img alt="Mis Topics Creados" src="assets/iconos/arrow_publica.svg"></a>
          </li>
          <li>
            <img src="assets/iconos/icono_misguardados.svg" alt="mis guardados"><a routerLink="saved-news">Mis Guardados
            <img alt="Mis Posts" src="assets/iconos/arrow_publica.svg"></a>
          </li>
          <li>
            <img src="assets/iconos/icon_ajustesapp.svg" alt="Ajustes"><a routerLink="profile-settings">Ajustes de
            Perfil
            <img alt="Mis Posts" src="assets/iconos/arrow_publica.svg"></a>
          </li>
        </ul>

        <div class="row last">
          <img src="assets/logo_serunowhite.png" alt="LigaMX">
          <p><small>Copyright 2020 SeroUno MX. Todos los derechos reservados</small></p>
        </div>
      </ng-container>
    </section>
  </div>


  <ng-template #loadingProfileMetrics>
    <div style="text-align: center;">
      <i class="pi pi-spin pi-spinner spinner spinner-lg"></i>
    </div>
  </ng-template>
</div>
