import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable, Subject } from 'rxjs';
import { map, tap, switchMap  } from 'rxjs/operators';

import { PaginatorSearchResultsNewMappingsService } from './paginator-search-results-new-mappings.service';

import { PaginatorNewsResponse } from '../../shared/models/responses/paginator-news-response';
import { PaginatorItemNew } from '../../shared/models/paginator-item-new';
import { SearchCriteria } from '../../shared/models/search-criteria';
import { SearchPayload } from '../models/search-payload';

import { environment } from '@env/environment';

@Injectable({
    providedIn: 'root'
})
export class PaginatorSearchResultsNewsService {

    private paginationSubject: Subject<SearchCriteria> = new Subject<SearchCriteria>();
    public listenPagination$: Observable<PaginatorItemNew[]> = this.paginationSubject
                                                                    .asObservable()
                                                                    .pipe(switchMap((searchCriteria) => this.search(searchCriteria)));

    // Elementos para poder llevar a cabo la Paginación.
    private nextPage: number = 1;
    private lastPage: number = 0;

    constructor(
        private readonly httpClient: HttpClient,
        private readonly paginatorSearchResultsNewMappings: PaginatorSearchResultsNewMappingsService
    ) { }

    public moveToNextPage(searchCriteria: SearchCriteria): void {

        this.paginationSubject.next(searchCriteria);
    }

    public hasReachedPaginationEnding(): boolean {

        return (this.nextPage > this.lastPage);
    }

    public resetPaginator(): void {

        this.nextPage = 1;
        this.lastPage = 0;
    }

    public search(searchCriteria: SearchCriteria): Observable<PaginatorItemNew[]> {

        const searchPayload: SearchPayload = {
            page:       this.nextPage,
            q:          searchCriteria.searchFor,
            order:      searchCriteria.sortDirection,
            orderBy:    'date'
        };
        return this.httpClient.post<PaginatorNewsResponse>(`${ environment.host }/api/search/news`, { ...searchPayload })
            .pipe(
                tap((newsResponse) => {

                    const { content } = newsResponse;
                    const { current_page, last_page } = content;

                    this.nextPage = current_page + 1;
                    this.lastPage = last_page;
                }),
                map((newsResponse) => {

                    const { content } = newsResponse;
                    const { data: news } = content;

                    return this.paginatorSearchResultsNewMappings.getPaginatorItemsNew(news);
                })
            );
    }
}
