import {Component, OnDestroy, OnInit} from '@angular/core';
import {AuthProfileService} from '@app/auth/shared/services/auth-profile.service';
import {LayoutConfigService} from '@app/layout-config/shared/services/layout-config.service';
import {SubSink} from 'subsink';

@Component({
  selector: 'app-comunidad-landing',
  templateUrl: './comunidad-landing.component.html',
  styleUrls: ['./comunidad-landing.component.scss']
})
export class ComunidadLandingComponent implements OnInit, OnDestroy {

  constructor(
    private layoutConfigService: LayoutConfigService,
    private authProfileService: AuthProfileService
  ) {

    // Establecemos la configuración inicial de la barra de navegación y del footer.
    layoutConfigService.setLayoutConfig({
      layout: {
        navbar: {visible: true},
        footer: {visible: false}
      }
    });
  }

  private subscriptions = new SubSink();
  public teamName: string = 'Favorito';

  ngOnInit(): void {
    this.getTeamName();
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  private getTeamName(): void {
    this.subscriptions.sink = this.authProfileService.getFavoriteTeamName().subscribe({
      next: (teamName) => {
        this.teamName = teamName;
      }
    });
  }

}
