import {Component} from '@angular/core';
import {LayoutConfigService} from '@app/layout-config/shared/services/layout-config.service';
import {Router, ActivatedRoute} from '@angular/router';

@Component({
  selector: 'app-search-results-pages',
  templateUrl: './search-results-pages.component.html',
  styleUrls: ['./search-results-pages.component.scss']
})
export class SearchResultsPagesComponent {

  public searchExpression: string | null = 'busqueda';

  // Flag para mostrar/ocultar los Anuncios.
  public showAdTypeA: boolean = true;
  public showAdTypeD: boolean = true;
  public showAdTypeC: boolean = true;

  constructor(
    private readonly router: Router,
    private readonly currentRoute: ActivatedRoute,
    private readonly layoutConfigService: LayoutConfigService,
  ) {

    layoutConfigService.setLayoutConfig({
      layout: {
        navbar: {visible: true},
        footer: {visible: false}
      }
    });

    currentRoute.queryParamMap.subscribe((queryParamMap) => {
      this.searchExpression = queryParamMap.get('q');
    });

  }
  public back(): void {

    this.router.navigateByUrl('/');
  }

}
