import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';

import { Observable, throwError, of, EMPTY } from 'rxjs';
import { catchError, map, exhaustMap } from 'rxjs/operators';

import { MessageService } from 'primeng/api';
import { ModalService } from '@app/ui/modals/shared/services/modal.service';

import { AuthProfileService } from '../services/auth-profile.service';
import { AuthUserAuthenticatedMappingService } from '../services/auth-user-authenticated-mapping.service';

import { AuthUserAuthenticatedResponse } from '../models/auth-user-authenticated-response';
import { AuthUnverifiedAccountResponse } from '../models/auth-unverified-account-response';
import { AuthUserAuthenticated } from '../models/auth-user-authenticated';
import { AuthCredentials } from '../models/auth-credentials';
import { UserAuthenticatedByActivationCode } from '@app/ui/modals/shared/models/account-activation/user-authenticated-by-activation-code';

import { environment } from '@env/environment';

@Injectable({
    providedIn: 'root'
})
export class AuthService {

    constructor(
        private readonly httpClient: HttpClient,
        private readonly messageService: MessageService,
        private readonly modal: ModalService,
        private readonly authProfile: AuthProfileService,
        private readonly authUserAuthenticatedMapping: AuthUserAuthenticatedMappingService
    ) {}

    public isFirstTimeLoginAuthentication: boolean = false;  


    public signIn(credentials: AuthCredentials): Observable<AuthUserAuthenticated> {

        return this.httpClient.post<AuthUnverifiedAccountResponse | AuthUserAuthenticatedResponse>(`${ environment.host }/api/auth/login`, { ...credentials, enable_verification: 1 })
            .pipe(
                exhaustMap((authResponse) => {

                    // Debemos de inspeccionar que tipo de respuesta nos esta dando el WebService.
                    // Si es de tipo AuthUserAuthenticatedResponse quiere decir que el usuario previamente ya ha 
                    // sido verificado y no es su primera vez entrando al Sistema.
                    // Por el contrario, si la respuesta es de tipo AuthUnverifiedAccountResponse quiere decir que 
                    // es su primera vez en el Sistema y debe de activar su cuenta con un código de Verificación.

                    if ( this.isAnAuthUserAuthenticatedResponse(authResponse) ) {

                        return of(authResponse);
                        
                    } else {
                        
                        const { access_token, expires_at } = authResponse.content;

                        return this.modal.activateAccountDialog(access_token, expires_at).onClose
                    }
                }),
                map((userCandidate: UserAuthenticatedByActivationCode | AuthUserAuthenticatedResponse) => this.authUserAuthenticatedMapping.mapAuthProfile(userCandidate)),
                catchError((error: HttpErrorResponse) => {

                    // Inspecciona la propiedad error de HttpErrorResponse para determinar el tipo de error
                    // y manejarlo acorde.
                    const httpError: any = error.error;
                    const httpErrorCode: number = error.status;

                    switch( httpErrorCode ) {

                        case 401:

                            // Inspecciona el error a nivel de nuestro servidor.
                            if ( httpError.message && httpError.message === 'invalid_credentials' ) {

                                this.messageService.add({
                                    key:        'authToastNotifications',
                                    severity:   'error',
                                    summary:    'LigaMX Inicio Sesión',
                                    detail:     'Usuario o contraseña incorrecta, intente nuevamente'
                                });

                                return EMPTY;
                            }

                            if ( httpError.message && httpError.message === 'user_blocked' ) {

                                this.messageService.add({
                                    key:        'authToastNotifications',
                                    severity:   'error',
                                    summary:    'LigaMX Inicio Sesión',
                                    detail:     'Esta cuenta se encuentra actualmente bloqueada'
                                });

                                return EMPTY;
                            }
                        break;

                        case 500:

                            // Inspecciona el error a nivel de nuestro servidor.
                            if ( httpError.message &&  httpError.message === 'could_not_create_token' ) {

                                this.messageService.add({
                                    key:        'authToastNotifications',
                                    severity:   'error',
                                    summary:    'LigaMX Inicio Sesión',
                                    detail:     'No pudimos crear su cuenta por el momento, intente nuevamente'
                                });

                                return EMPTY;
                            }
                        break;
                    }

                    // Return an observable with an error that could not be handled.
                    return throwError(error);
                })
            );
    }

    public logout(): void {

        // Limpiamos de la cache el id del usuario que se encuentra autenticado.
        this.authProfile.userId = undefined;

        // Notificamos al Webservice de nuestra intención de cerrar sesión.
        this.httpClient.post(`${ environment.host }/api/auth/logout`, {})
            .pipe( catchError( (err) => of(null) ) )
            .subscribe();

        // Limpiamos del LocalStorage la información del usuario que se encuentra autenticado.
        this.removeAuthProfileFromLocalStorage();
    }

    public isAuthenticated(): boolean {

        return this.tokenStillValid();
    }

    public tokenStillValid(): boolean {

        let token: string = localStorage.getItem('tkn') || '';
        let tokenExpirationTimeInMs: string = localStorage.getItem('tkne') || '';

        token = token.trim();
        tokenExpirationTimeInMs = tokenExpirationTimeInMs.trim();

        // Nuestra intención es que haya un token y una fecha de expiración para el token.
        if ( !token || !tokenExpirationTimeInMs ) {

            return false;
        }

        // Comprobamos si podemos obtener la expiración como un entero.
        if( isNaN(parseInt(tokenExpirationTimeInMs)) ) {

            return false;
        }

        // Llegados a este punto, tenemos un token y una fecha de expiración.
        // Comprobamos su validación:

        // Obtenemos la fecha actual en Milisegundos.
        const currentTimeInMs = new Date().getTime();
        // Si la fecha actual es menor a la de la expiración del Token, entonces, tenemos un Token aún válido.
        const isTokenStillValid = currentTimeInMs < parseInt(tokenExpirationTimeInMs);

        return isTokenStillValid;
    }

    public getToken():string | null {

        return localStorage.getItem('tkn');
    }

    public setAuthProfileInLocalStorage(name: string, id: number, avatar: string, fanLevel: string, fanPlate: string, teamId: number, teamName: string, teamLogo: string, token: string, tokenExpiration: number): void {

        // Volcamos la información en LocalStorage.
        localStorage.setItem('usrn', name);
        localStorage.setItem('usid', JSON.stringify(id));
        localStorage.setItem('avat', avatar);
        localStorage.setItem('ftid', JSON.stringify(teamId));
        localStorage.setItem('ftnm', teamName);
        localStorage.setItem('ftlg', teamLogo);
        localStorage.setItem('fnlv', fanLevel);
        localStorage.setItem('fnpl', fanPlate);
        localStorage.setItem('tkn', token);
        localStorage.setItem('tkne', JSON.stringify(tokenExpiration * 1000));
    }

    public removeAuthProfileFromLocalStorage(): void {

        // Removemos la información de LocalStorage
        localStorage.removeItem('usrn');
        localStorage.removeItem('usid');
        localStorage.removeItem('avat');
        localStorage.removeItem('ftid');
        localStorage.removeItem('ftnm');
        localStorage.removeItem('ftlg');
        localStorage.removeItem('fnlv');
        localStorage.removeItem('fnpl');
        localStorage.removeItem('tkn');
        localStorage.removeItem('tkne');
    }

    private isAnAuthUserAuthenticatedResponse(authResponse: AuthUnverifiedAccountResponse | AuthUserAuthenticatedResponse): authResponse is AuthUserAuthenticatedResponse {

        // Debemos forzar primero la conversión de authResponse a un tipo unknown
        // Si el párametro que recibimos como authResponse posee la propiedad content.user sabremos que 
        // lo que se esta pasando por argumento es una Respuesta del Servidor de un Usuario Autentificado en lugar de una 
        // respuesta de Cuenta No Verificada. 
        return !!((<AuthUserAuthenticatedResponse>authResponse).content.user);
    }
}
