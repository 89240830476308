<div class="post new">
    <div class="row content">
        <img class="picture" [alt]="title" [appMediaSource]="urlImage" [appFallbackAssetMedia]="'assets/place_small.jpg'">
        <div class="info">
        <h4><b><a [routerLink]="['/news', id]">{{ title }}</a></b></h4>
        <p class="source"><img class="picture" [src]="avatarDomain" [alt]="title">
            <a [href]="hostDomain" target="_blank">{{ hostDomain | extractHostDomain }}</a> <span>|</span> {{ date | date }}</p>
        </div>
    </div>
</div>
