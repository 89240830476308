import { HttpEvent, HttpEventType } from '@angular/common/http';
import { tap } from 'rxjs/operators';

export function uploadProgress<T>( cb: ( progress: number ) => void ) {

    return tap(( event: HttpEvent<T> ) => {
        
        if ( event.type === HttpEventType.UploadProgress ) {
            
            const { loaded, total } = event;

            const loadedSoFar: number = total ? ((100 * loaded) / total) : 100;
            
            cb( Math.round(loadedSoFar) );
        }
    });
  }