import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { PostSharingRoutingModule } from './post-sharing-routing.module';
import { PostPreviewPageComponent } from './pages/post-preview-page/post-preview-page.component';
import {AdvertisementModule} from '@app/ui/advertisement/advertisement.module';
import {PostModule} from '@app/ui/post/post.module';
import {LinkPreviewModule} from '@app/ui/link-preview/link-preview.module';


@NgModule({
  declarations: [
    PostPreviewPageComponent
  ],
    imports: [
        CommonModule,
        PostSharingRoutingModule,
        AdvertisementModule,
        PostModule,
        LinkPreviewModule
    ]
})
export class PostSharingModule { }
