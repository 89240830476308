import {Component, OnDestroy, OnInit, ChangeDetectorRef} from '@angular/core';
import {ActivatedRoute, ActivatedRouteSnapshot, Router} from '@angular/router';

import {SubSink} from 'subsink';
import {MenuItem} from 'primeng/api';
import {MessageService} from 'primeng/api';

import {LayoutConfigService} from '@app/layout-config/shared/services/layout-config.service';
import {PostsService} from '../../shared/services/posts.service';
import {UserService} from '../../shared/services/user.service';
import {CommentsService} from '../../shared/services/comments.service';
import {PostMedia} from '@app/ui/post/shared/models/post-media';
import {PostPolling} from '@app/ui/post/shared/models/post-polling';

import {Comment} from '../../shared/models/comments';
import {CommentBody} from '../../shared/models/comment-body';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';

@Component({
  selector: 'app-comunidad-post-detail',
  templateUrl: './comunidad-post-detail.component.html',
  styleUrls: ['./comunidad-post-detail.component.scss']
})

export class ComunidadPostDetailComponent implements OnInit, OnDestroy {

  constructor(
    private readonly changeDetectorRef: ChangeDetectorRef,
    private layoutConfigService: LayoutConfigService,
    private router: Router,
    private currentRoute: ActivatedRoute,
    private fb: FormBuilder,
    private messageService: MessageService,
    private userService: UserService,
    private postService: PostsService,
    private commentsService: CommentsService,
  ) {
    layoutConfigService.setLayoutConfig({
      layout: {
        navbar: {visible: true},
        footer: {visible: false}
      }
    });
  }

  private subscriptions = new SubSink();
  public postLoaded: boolean = false;
  public commentsLoaded: boolean = false;
  public comments: Comment[] = [];
  public idToReply: number = 0;
  public idToEdit: number = 0;
  public postType: number = 0;
  public postTitle = '';
  public postUser = '';

  public postMedia?: PostMedia;
  public postPolling?: PostPolling;

  public userName: string = '';
  public userId: number = 0;
  public commentText: string = '';
  public commentEditText: string = '';
  public commentReplyText: string = '';
  public commentForm!: FormGroup;
  public commentEditForm!: FormGroup;
  public commentReplyForm!: FormGroup;
  public commentMenu: MenuItem[] = [];
  public postId = 0;
  public topicId: number = 0;
  public topicTitle: string = '';
  public votesLoaded = true;
  public isSaving = false;

  // Flag para mostrar/ocultar los Anuncios.
  public showAdTypeD: boolean = true;

  ngOnInit(): void {

    const routeSnapshot: ActivatedRouteSnapshot = this.currentRoute.snapshot;
    const postId: string = routeSnapshot.paramMap.get('id') || '';
    
    this.postId = +postId;
    
    this.loadPostDetail(+postId);
    this.loadCommentForms();
    
    this.userName = this.userService.getUsername();
    this.userId = this.userService.getUserId();
  }

  public loadCommentForms(): void {
    this.commentForm = this.fb.group({
      message: ['', [Validators.required]],
      id: ['']
    });
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  // POST

  public loadPostDetail(postId: number, loadAll: boolean = true): void {
    this.subscriptions.sink = this.postService.getPostById(postId).subscribe({
      next: (post) => {

        // Para la construcción del Breadcrumb.
        this.topicId = post.topic?.id || 0;
        this.topicTitle = post.topic?.title || '';

        this.postType = post.type;
        
        if (post.type !== 5) {
          
          this.postMedia = post as PostMedia;
        
        } else {
          
          this.postPolling = post as PostPolling;
        }
      },
      complete: () => {
        
        if (loadAll) {
          
          this.loadPostComments(+postId);
        }
        
        this.postLoaded = true;
        this.votesLoaded = true;
        this.isSaving = false;
      }
    });
  }


  votePost(action: string): void {
    this.votesLoaded = false;
    this.subscriptions.sink = this.postService.votePost(action, this.postId).subscribe({
      complete: () => {
        this.loadPostDetail(+this.postId, false);
        // this.votesLoaded = true;
      }
    });
  }

  savePost(isFollower: boolean): void {
    this.isSaving = true;
    this.subscriptions.sink = this.postService.followPost(+this.postId, isFollower).subscribe({
      next: (result) => {
        console.log(result);
      },
      error: (err) => {
        console.log(err);
      },
      complete: () => {
        // console.log('complete');
        // this.isSaving = false;
        this.loadPostDetail(+this.postId, false);
        this.messageService.add({
          severity: 'success',
          summary: 'Éxito',
          detail: 'Guardados actualizados'
        });
      }
    });
  }


  // COMMENTS

  makeComment(): void {
    this.commentsLoaded = false;
    const {message, id} = this.commentForm.value;
    this.subscriptions.sink = this.commentsService.makeComment({
      message,
      parent: id
    } as CommentBody, this.postId).subscribe({
      next: (comment) => {
        console.log(comment);
      },
      complete: () => {
        this.commentForm.reset();
        this.commentText = '';
        this.loadPostComments(this.postId);
      }
    });
  }

  editComment(): void {
    this.commentsLoaded = false;
    const {message, id} = this.commentEditForm.value;
    this.subscriptions.sink = this.commentsService.editComment(
      message, this.postId, id
    ).subscribe({
      next: (comment) => {
        console.log(comment);
      },
      complete: () => {
        this.commentEditForm.reset();
        this.commentEditText = '';
        this.idToEdit = 0;
        this.loadPostComments(this.postId);
      }
    });
  }

  public replyComment(id: number): void {
    this.idToReply = id;
    this.commentReplyForm = this.fb.group({
      message: ['', [Validators.required]],
      id: [this.idToReply, [Validators.required]]
    });
  }

  makeReplyComment(): void {
    this.commentsLoaded = false;
    const {message, id} = this.commentReplyForm.value;
    this.subscriptions.sink = this.commentsService.makeComment({
      message,
      parent: id
    } as CommentBody, this.postId).subscribe({
      next: (comment) => {
        console.log(comment);
      },
      complete: () => {
        this.commentReplyForm.reset();
        this.commentReplyText = '';
        this.idToReply = 0;
        this.loadPostComments(this.postId);
      }
    });
  }

  public loadPostComments(postId: number): void {
    this.subscriptions.sink = this.commentsService.getCommentsByPost(postId).subscribe({
      next: (comment) => {
        this.comments = comment;
      },
      complete: () => {
        this.commentsLoaded = true;
      }
    });
  }

  public vote(action: string, postId: number): void {
    this.commentsLoaded = false;

    this.subscriptions.sink = this.commentsService.vote(action, postId).subscribe({
      next: (response) => {
        console.log(response);
      },
      complete: () => {
        this.loadPostComments(this.postId);
      }
    });
  }

  public getMenuComment(id: number, comment: string = ''): MenuItem[] {
    this.commentMenu = [{
      items: [
        {
          label: 'Borrar', icon: 'custom-icon delete-icon', command: event => {
            this.deleteComment(id);
          }
        },
        {
          label: 'Editar', icon: 'custom-icon edit-icon', command: event => {
            this.commentEditText = comment;
            this.showEdit(id);
          }
        }
      ]
    }];
    return this.commentMenu;
  }

  private deleteComment(id: number): void {
    this.commentsLoaded = false;
    this.subscriptions.sink = this.commentsService.deleteComment(this.postId, id).subscribe({
      next: (res) => {
        console.log(res);
      },
      complete: () => {
        this.loadPostComments(this.postId);
      }
    });
  }

  showEdit(id: number): void {
    this.commentEditForm = this.fb.group({
      message: ['', [Validators.required]],
      id: [id, '']
    });
    this.idToEdit = id;
    this.changeDetectorRef.detectChanges();
  }

  public onBookmarkPost(isFollowingPost: boolean): void {

    this.messageService.add({
        severity: isFollowingPost ? 'success' : 'info', 
        summary: 'Éxito', 
        detail: isFollowingPost ? 'Has guardado esta noticia.' : 'Has eliminado de tus guardados esta noticia.'
    });
}
}
