import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { MediaSourceModule } from '@app/ui/media-source/media-source.module';

import { TopsCommunityComponent } from './tops-community/tops-community.component';

@NgModule({
  declarations: [TopsCommunityComponent],
  imports: [
    CommonModule,
    MediaSourceModule,
    RouterModule
  ],
  exports: [ TopsCommunityComponent ]
})
export class TopsCommunityModule { }
