import { Injectable } from '@angular/core';

import { Reaction } from '../models/post-media';

@Injectable()
export class ReactionService {

    constructor() { }

    public addNewReaction(previousReaction: Reaction | undefined, reactionSelectedByUser: Reaction, reactionsSelected: Reaction[]): Reaction[] {

        const newReaction: Reaction = {
            ...reactionSelectedByUser,
            totalReactions: 1,
            isSelected: true
        };

        // Antes de agregar la nueva reacción a la lista, comprobamos si hay una reacción previamente seleccionada por nosotros.
        if ( previousReaction ) {

            // Modificamos la reacción previamente seleccionada.
            // Agregamos la nueva reacción.
            return [ ...this.updatePreviousReactionState(previousReaction, reactionsSelected), { ...newReaction } ];
        }

        // No hay estado previo que perseguir, solamente agregamos la nueva reacción a la lista de reacciones seleccionadas.
        return [ ...reactionsSelected, { ...newReaction } ];
    }

    public modifyExistingReaction(previousReaction: Reaction | undefined, existingReaction: Reaction, reactionsSelected: Reaction[]): Reaction[] {

        // Antes de modificar la reacción existente, comprobamos si hay una reacción previamente seleccionada por nosotros.
        if ( previousReaction ) {

            // En este punto debemos diferenciar 2 comportamientos de interacción:
            // 1. La reacción previamente seleccionada y la reacción de nuestro interés son la misma.
            if ( previousReaction.id === existingReaction.id ) {
                
                // Solo debemos modificar el estado de la reacción previamente seleccionada dentro de la lista de reacciones seleccionadas.
                return this.updatePreviousReactionState(previousReaction, reactionsSelected);
            }

            // 2. La reacción previamente seleccionada y la reacción de nuestro interés son diferentes.
            // Modificamos el estado de la reacción previamente seleccionada dentro de la lista de reacciones seleccionadas,
            return this.updatePreviousReactionState(previousReaction, reactionsSelected)
            // pero además, buscamos y modificamos el estado de la reacción existente a su nuevo estado (la que es de nuestro interés).
                        .map((r) => existingReaction.id === r.id ? ({ ...r, isSelected: true, totalReactions: r.totalReactions + 1}) : r);
        }

        // No hay ningún estado previo seleccionado por nostros dentro de la lista de reacciones seleccionadas,
        // Solo debemos modificar el estado de la reacción existente a su nuevo estado (la que es de nuestro interés).
        return reactionsSelected
                .map((r) => existingReaction.id === r.id ? ({ ...r, isSelected: true, totalReactions: r.totalReactions + 1}) : r);
    }

    private updatePreviousReactionState(previousReaction: Reaction, reactionsSelected: Reaction[]): Reaction[] {

        let { isSelected, totalReactions, id:previousReactionId } = previousReaction;

        // Cuando hay una reacción seleccionada previamente por nosotros, su propiedad isSelected será igual a true, mientras que
        // su propiedad totalReactions vendrá con el total de reacciones realizadas (tanto por nosotros como por otros usuarios).
        // Nuestra intencción es deseleccionar esta reacción y dismnuir en 1 su totalizador, para que el estado de la nueva reacción
        // pueda transitar. 
        isSelected = false;                     // deseleccionamos esta reacción.
        totalReactions = totalReactions - 1;    // descontamos 1 unidad al totalizador.

        // Producto de nuestra interacción, cuando el totalizador de una reacción en particular cae en 0 o por debajo de 0, debemos 
        // excluirla de la lista de reacciones seleccionadas.
        if ( totalReactions < 1 ) {

            // Una forma de eliminar esta reacción de un arreglo es simplemente excluyendola.
            return reactionsSelected.filter((r) => previousReactionId !== r.id);

        } else {
        // Por el contrario, cuando el totalizador de una reacción en particular se mantiene por arriba de 0, debemos mantenerla en la 
        // lista de reacciones seleccionadas y solomante actualizar su estado, es decir, sus propiedades isSelected y totalReactions.

            // Una forma de actualizar un elemento existente en un arreglo es simplemente buscar y mapear el elemento de nuestro interés.
            // En este caso, nos interesa buscar dentro de la lista de reacciones existentes, la reacción previamente utilizada y modificar su estado.
            return reactionsSelected.map((r) => previousReactionId === r.id ? ({ ...r, isSelected, totalReactions }) : r);
        }
    }
}
