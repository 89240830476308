import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import { environment } from '@env/environment';

@Injectable()
export class PostMediaService {

    constructor(private httpClient: HttpClient) { }

    public addReaction(postId: number, reactionId: number): Observable<unknown> {

        // Para crear una reacción, necesitamos indicar el id del post asociado y la reacción de nuestro interés.
        const reactionPayload = { 'reaction_id' : reactionId };

        return this.httpClient.post<unknown>(`${ environment.host }/api/posts/${ postId }/reactions/create`, { ...reactionPayload })
            .pipe(
                tap( (res) => console.log(res) )
            );
    }

    public removeReaction(postId: number): Observable<unknown> {
        
        // Para remover la reacción, no necesitamos más que indicar el id del post asociado.
        return this.httpClient.delete<unknown>(`${ environment.host }/api/posts/${ postId }/reactions/delete`,  {})
            .pipe(
                tap( (res) => console.log(res) )
            );
    }

    public addLike(postId: number): Observable<unknown> {

        return this.httpClient.post<unknown>(`${ environment.host }/api/posts/${ postId }/like`,  {})
            .pipe(
                tap( (res) => console.log(res) )
            );
    }

    public removeLike(postId: number): Observable<unknown> {

        return this.httpClient.post<unknown>(`${ environment.host }/api/posts/${ postId }/unlike`,  {})
            .pipe(
                tap( (res) => console.log(res) )
            );
    }

    public addDislike(postId: number): Observable<unknown> {

        return this.httpClient.post<unknown>(`${ environment.host }/api/posts/${ postId }/dislike`,  {})
            .pipe(
                tap( (res) => console.log(res) )
            );
    }

    public removeDislike(postId: number): Observable<unknown> {

        return this.httpClient.post<unknown>(`${ environment.host }/api/posts/${ postId }/undislike`,  {})
            .pipe(
                tap( (res) => console.log(res) )
            );
    }

    public followPost(postId: number): Observable<unknown> {

        return this.httpClient.post<unknown>(`${ environment.host }/api/posts/${ postId }/follow`,  {})
            .pipe(
                tap( (res) => console.log(res) )
            );
    }

    public unfollowPost(postId: number): Observable<unknown> {

        return this.httpClient.post<unknown>(`${ environment.host }/api/posts/${ postId }/unfollow`,  {})
            .pipe(
                tap( (res) => console.log(res) )
            );
    }

    public joinTopic(topicId: number): Observable<unknown> {

        return this.httpClient.post(`${ environment.host }/api/topics/${ topicId }/follow`, {})
            .pipe(
                tap( (res) => console.log(res) )
            );
    }

    public disjoinTopic(topicId: number): Observable<unknown> {

        return this.httpClient.post(`${ environment.host }/api/topics/${ topicId }/unfollow`, {})
            .pipe(
                tap( (res) => console.log(res) )
            );
    }
}
