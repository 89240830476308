import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';

import { Observable, forkJoin, Subject } from 'rxjs';
import { map, tap } from 'rxjs/operators';

import { AuthProfileService } from '@app/auth/shared/services/auth-profile.service';
import { PaginatorLandingPostMappingsTestService } from '../services/paginator-landing-post-mappings-test.service';

import { PostsResponse } from '../models/posts-response';
import { PaginatorItemPost } from '../models/paginator-item-post';

import { environment } from '@env/environment';

@Injectable({
    providedIn: 'root'
})
export class PaginatorLandingTestService {

    public nextPage: number = 1;
    private lastPage: number = 0;

    private paginationSubject: Subject<number> = new Subject<number>();
    public listenPagination$: Observable<number> = this.paginationSubject.asObservable();

    constructor(
        private readonly httpClient: HttpClient,
        private readonly authProfile: AuthProfileService,
        private readonly paginatorLandingPostMappingsTest: PaginatorLandingPostMappingsTestService
    ) { }

    public paginate(page: number): void {

        this.paginationSubject.next(page);
    }

    public hasNotReachedPaginationEnd(): boolean {

        return (this.nextPage <= this.lastPage);
    }

    public getPosts(page: number): Observable<PaginatorItemPost[]> {

        const currentPage: string = (page && page > 0) ? String(page) : '1';
        const params = new HttpParams().set('page', currentPage);

        return forkJoin({
            userId:        this.authProfile.getAuthId(),
            postsResponse: this.httpClient.get<PostsResponse>(`${ environment.host }/api/posts`, { params })
        }).pipe(
            tap(({ postsResponse }) => {

                const { content } = postsResponse;
                const { current_page, last_page } = content;

                this.nextPage = current_page + 1;
                this.lastPage = last_page;
            }),
            map(({ userId, postsResponse }) => {

                const { content } = postsResponse;
                const { data:posts } = content;


                return this.paginatorLandingPostMappingsTest.getPaginatorItemsPost(posts, userId);
            })
        );
    }
}
