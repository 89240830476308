<a [routerLink]="['/news', id]" class="post new-min">
  <div class="content">
    <p class="min">{{date | date}}</p>
    <p class="main">{{ (title.length > 28) ? (title | slice:0:28) + '...' : (title) }}</p>
    <p class="min"><a href="{{hostDomain}}">
      <img src="{{avatarDomain}}" [alt]="title">
      <small>{{ ((hostDomain | extractHostDomain).length > 10) ? (hostDomain | extractHostDomain | slice:0:10) + '...' : (hostDomain | extractHostDomain) }}</small></a>
    </p>
  </div>
  <div class="img">
    <img class="picture" [alt]="title" [appMediaSource]="urlImage" [appFallbackAssetMedia]="'assets/place_med.jpg'">
  </div>
</a>
