import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { TeamPreferenceResponse } from '../models/team-preference-response';
import { TeamPreference } from '../models/team-preference';
import { Team } from '../models/team';

import { environment } from '@env/environment';

@Injectable()
export class TeamPreferenceService {

    constructor(
        private httpClient: HttpClient
    ) { }

    public updatePreference(team: Team): Observable<TeamPreference> {

        const { id:teamId, name:teamName, logo:teamLogo } = team;

        return this.httpClient.put<TeamPreferenceResponse>(`${ environment.host }/api/user/favorite-team/${ teamId }`, {})
            .pipe(
                map(() => {

                    return {
                        ftid:   teamId,
                        ftnm:   teamName,
                        ftlg:   teamLogo
                    };
                })
            );
    }

    public updatePreferenceInLocalStorage(teamPreference: TeamPreference): void {

        const { ftid, ftnm, ftlg } = teamPreference;

        localStorage.setItem('ftid', JSON.stringify(ftid));
        localStorage.setItem('ftnm', ftnm);
        localStorage.setItem('ftlg', ftlg);
    }
}
