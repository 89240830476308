import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';

import { Observable, from, EMPTY } from 'rxjs';
import { catchError, map, exhaustMap } from 'rxjs/operators';

import firebase from 'firebase/app';
import { AngularFireAuth } from  "@angular/fire/auth";
import { MessageService } from 'primeng/api';

import { AuthFacebookSocialService } from '../services/auth-facebook-social.service';
import { AuthUserAuthenticatedMappingService } from '../services/auth-user-authenticated-mapping.service';

import { AuthUserAuthenticatedResponse } from '../models/auth-user-authenticated-response';
import { AuthUserAuthenticated } from '../models/auth-user-authenticated';
import { AuthSocialCredentials } from '../models/auth-social-credentials';
import { Provider } from '../models/auth-provider';

import { environment } from '@env/environment';

@Injectable({
    providedIn: 'root'
})
export class AuthSocialService {

    constructor(
        private readonly httpClient: HttpClient,
        private readonly afAuth: AngularFireAuth,
        private readonly authFacebookSocial: AuthFacebookSocialService,
        private readonly authUserAuthenticatedMapping: AuthUserAuthenticatedMappingService,
        private readonly messageService: MessageService,
    ) { }

    public signInWithFacebook(): Observable<any> {

        return from( this.authFacebookSocial.signIn() )
            .pipe(
                map((userCredential) => {

                    const { socialId, displayedName, email, photoUrl } = userCredential;

                    return {
                        socialId:   socialId,
                        email,
                        name:       displayedName,
                        avatar:     photoUrl,
                        provider:   Provider.Facebook

                    } as AuthSocialCredentials;
                }),
                exhaustMap((authSocialCredentials) => this.signIn(authSocialCredentials)),
                catchError((err) => EMPTY)
            );
    }

    public signInWithGoogle(): Observable<AuthUserAuthenticated> {

        return from( this.afAuth.signInWithPopup(new firebase.auth.GoogleAuthProvider()) )
            .pipe(
                map((userCredential) => {

                    const { user, additionalUserInfo } = userCredential;
                    const profile: any = (additionalUserInfo?.profile) as any;

                    return {
                        socialId:   profile.id,
                        email:      user?.email || '',
                        name:       user?.displayName,
                        avatar:     user?.photoURL,
                        provider:   Provider.Google
                    } as AuthSocialCredentials;
                }),
                exhaustMap((authSocialCredentials) => this.signIn(authSocialCredentials)),
                catchError((err) => EMPTY)
            ); 
    }

    public signInWithApple(): Observable<AuthUserAuthenticated>{

        const appleProvider = new firebase.auth.OAuthProvider('apple.com');

        appleProvider.addScope('email');
        appleProvider.addScope('name');

        return from ( this.afAuth.signInWithPopup(appleProvider) )
            .pipe(
                map((userCredential) =>{

                    const { user } = userCredential;

                    return {
                        socialId:   user?.uid,
                        email:      user?.email || '',
                        name:       user?.displayName,
                        avatar:     user?.photoURL,
                        provider:   Provider.Apple
                    } as AuthSocialCredentials;
                }),
                exhaustMap((authSocialCredentials) => this.signIn(authSocialCredentials)),
                catchError((err) => EMPTY)
            );
    }

    private signIn(socialCredentials: AuthSocialCredentials): Observable<AuthUserAuthenticated> {

        const loginOrRegisterWith: {[name:string]: string} = {
            'facebook': 'loginOrRegisterFb',
            'google':   'loginOrRegisterGoogle',
            'apple':    'loginOrRegisterApple'
        };

        console.log(socialCredentials);

        const { socialId:socialid, email, avatar:avatar_original, name, provider } = socialCredentials;
        const socialNetwork: string = loginOrRegisterWith[ provider ];

        return this.httpClient.post<AuthUserAuthenticatedResponse>(`${ environment.host }/api/auth/${ socialNetwork }`, { socialid, email, avatar_original, name })
                .pipe(
                    map((authUserAuthenticatedResponse) => this.authUserAuthenticatedMapping.mapAuthProfile(authUserAuthenticatedResponse)),
                    catchError((error: HttpErrorResponse) => {
                    
                        // En cuanto al login por redes sociales tenemos lo siguientes:
                        // 1. Errores de Validación: El WS necesita forsozamente los campos socialid y name, en caso de no obtener alguno de estos, se obtiene 
                        //    un código de error 400 y en el contenido un objeto con los campos que generaron el error. 
                        // 2. Errores de Funcionalidad: En caso de que se reciba bien la información pero por alguna razón falle el proceso, se obtienen un 
                        //    código de error 400 y en el campo message un cadena de texto con la constante bad_request
                        // Dado que cualquiera de estos errores no es un error feature, simplemente respondemos con un mensaje amigable ya que no podemos notificar
                        // de alguna manera al usuario de un error en específico.
                        this.messageService.add({
                            key:        'authToastNotifications',
                            severity:   'error',
                            summary:    'LigaMX Inicio Sesión',
                            detail:     'Ocurrió un error, intentelo nuevamente.'
                        });

                        return EMPTY;
                    })
                );
    } 

    public signOut(): void {

        this.afAuth.signOut();
    }

    public signOutFacebook(): void {

        this.authFacebookSocial.logout();
    }
}
