<div class="main-grid">
  <div class="grid-left">
    <section class="section edit">
      <div class="row first">
        <h4><b>Editar Mi cuenta</b></h4>
      </div>

      <ng-container *ngIf="userProfileLoaded; else loadingUserProfile">
        <app-edit-profile-form
          [profile]="userProfile"
          [changeProfileLoading]="loadingChangeUserProfileAction"
          (changeProfile)="onChangeUserProfile($event)"></app-edit-profile-form>
      </ng-container>

      <ng-template #loadingUserProfile>
        <div style="text-align: center;">
          <i class="pi pi-spin pi-spinner spinner spinner-lg"></i>
        </div>
      </ng-template>
    </section>
  </div>
</div>

