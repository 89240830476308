import { ModuleWithProviders, NgModule } from "@angular/core";

import { LayoutConfig } from './shared/models/layout-config';
import { LAYOUT_CONFIG_TOKEN } from './shared/services/layout-config.service';

@NgModule()
export class LayoutConfigModule {
    
    static forRoot( layoutConfig: LayoutConfig ): ModuleWithProviders<LayoutConfigModule> {
        
        return {
            ngModule: LayoutConfigModule,
            providers: [ { provide: LAYOUT_CONFIG_TOKEN, useValue: layoutConfig } ]
        };
    }
}
