<button class="close" type="button" (click)="close()">
  <img src="/assets/iconos/x_closepopup.svg" alt="Cerrar">
</button>

<div class="content">
  <img src="/assets/iconos/icono_exitopopup.svg" alt="Exito">
  <p class="message">{{ message }}</p>
<button class="" type="button" (click)="accept()">Aceptar
  <img src="/assets/iconos/icono_arrow.svg" alt="Aceptar"></button>
</div>


