<div class="row first">
  <h4><b>
    <a [routerLink]="['/community/topic/', id]">
      <img
        [src]="isOfficial ? 'assets/iconos/icono_topicofcial.svg': 'assets/iconos/icono_fanmade.svg'"
        [alt]="isOfficial ? 'Official' : 'Fan'">{{ title }}
    </a>
  </b></h4>


    <div class="labels">
        <app-topic-call-to-action-button
            [suscriptionLoading]="loadingTopicSubscriptionAction"
            [owned]="topicOwnership"
            [joined]="topicFollowing"
            (onEdit)="onEditTopic()"
            (onJoin)="onJoinTopic()"
            (onDisjoin)="onUnJoinTopic()"></app-topic-call-to-action-button>
    </div>
</div>
