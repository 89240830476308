import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {CarouselModule} from 'primeng/carousel';
import {InfiniteScrollModule} from 'ngx-infinite-scroll';

import { ToastModule } from 'primeng/toast';

import { BannerCommunityModule } from '../ui/banner-community/banner-community.module';
import { LinkPreviewModule } from '@app/ui/link-preview/link-preview.module';
import { PostModule } from '@app/ui/post/post.module';
import { TopicModule } from '@app/ui/topic/topic.module';
import { AdvertisementModule } from '@app/ui/advertisement/advertisement.module';
import { MediaSourceModule } from "@app/ui/media-source/media-source.module";
import { LandingRoutingModule } from './landing-routing.module';

import { MainLandingComponent } from './pages/main-landing/main-landing.component';
import { NewMinSlideComponent } from './components/new-min-slide/new-min-slide.component';
import { NewComponent } from './components/new/new.component';
import { ExtractHostDomainPipe } from './pipes/extract-host-domain.pipe';
import { ScrollableToBeginningDirective } from './directives/scrollable-to-beginning.directive';

import { TestLandingComponent } from './pages/test-landing/test-landing.component';

@NgModule({
  declarations: [
    MainLandingComponent,
    NewMinSlideComponent,
    NewComponent,
    ExtractHostDomainPipe,
    ScrollableToBeginningDirective,
    TestLandingComponent],
  imports: [
    CommonModule,
    CarouselModule,
    InfiniteScrollModule,
    ToastModule,
    BannerCommunityModule,
    LinkPreviewModule,
    PostModule,
    TopicModule,
    AdvertisementModule,
    MediaSourceModule,
    LandingRoutingModule,
  ]
})
export class LandingModule {
}
