import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, ActivatedRouteSnapshot } from '@angular/router';
import { Router } from '@angular/router';
import { Location } from '@angular/common';

import { concatMap, tap, filter } from 'rxjs/operators';

import { SubSink } from 'subsink';

import { LayoutConfigService } from '@app/layout-config/shared/services/layout-config.service';
import { ModalService } from '@app/ui/modals/shared/services/modal.service';

import { TopicService } from '../../shared/services/topic.service';
import { Topic } from '../../shared/models/topic';

@Component({
  selector: 'app-edit-topic-page',
  templateUrl: './edit-topic-page.component.html',
  styleUrls: ['./edit-topic-page.component.scss']
})
export class EditTopicPageComponent implements OnInit, OnDestroy {

    private subscriptions = new SubSink();
    
    public currentTopic!: Topic;
    public currentTopicLoaded: boolean = false;
    public loadingChangeCurrentTopicAction: boolean = false;
    public loadingRemoveCurrentTopicAction: boolean = false;

    constructor(
        private router: Router,
        private location: Location,
        private currentRoute: ActivatedRoute,
        private layoutConfigService: LayoutConfigService,
        private modalService: ModalService,
        private topicService: TopicService
    ) {

        // Establecemos la configuración inicial de la barra de navegación y del footer.
        layoutConfigService.setLayoutConfig({
            layout: { 
                navbar: { visible: true },
                footer: { visible: false }
            }
        });
    }

    ngOnInit(): void {

        const routeSnapshot: ActivatedRouteSnapshot = this.currentRoute.snapshot;
        const topicId: string = routeSnapshot.paramMap.get('id') || '';

        if ( !topicId || isNaN(+topicId) ) {

            this.router.navigateByUrl('/404');

            return;
        }

        this.currentTopicLoaded = false;

        this.subscriptions.sink = this.topicService.get(+topicId).subscribe((topic) =>{ 
            
            this.currentTopicLoaded = true;
            this.currentTopic = topic;
        });
    }

    ngOnDestroy(): void {

        this.subscriptions.unsubscribe();
    }

    public onUpdateTopic($event: Topic): void {

        const topic: Topic = $event;
        let topicId: number = 0;

        this.loadingChangeCurrentTopicAction = true;

        this.subscriptions.sink = this.topicService.update(topic)
        .pipe(
            tap((topicUpdated) => {
                
                this.loadingChangeCurrentTopicAction = false;

                topicId = topicUpdated.id;
            }),
            concatMap(() => {
                
                return this.modalService.successDialog('Tu Topic fue guardado').onClose;
            })
        )
        .subscribe({
            next: () => {
                
                this.loadingChangeCurrentTopicAction = false;

                this.router.navigate(['community/topic', topicId ]);                
            },
            complete: () => {

                this.loadingChangeCurrentTopicAction = false;
            }
        });
    }

    public onRemoveTopic($event: number): void {

        const topicId: number = $event;

        this.subscriptions.sink = this.modalService.warningConfirmationDialog('¿Estás seguro de que quieres eliminar este Topic?').onClose
            .pipe(
                filter((confirmation) => confirmation === 'agreed'),
                concatMap(() => {

                    this.loadingRemoveCurrentTopicAction = true;

                    return this.topicService.remove(topicId);
                }),
                concatMap((topicIsRemoved) => {

                    this.loadingRemoveCurrentTopicAction = false;

                    return topicIsRemoved 
                                    ? this.modalService.successDialog('Tu Topic fue eliminado').onClose
                                    : this.modalService.failureDialog('Por el momento no se pudo eliminar este Topic, intentelo nuevamente').onClose;
                })
            )
            .subscribe(() => {
                
                this.router.navigateByUrl('/account/topics-created');
            });
    }

    public back(): void {

        this.location.back();
    }
}
