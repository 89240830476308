import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { NewsResponse } from '../models/responses/news-response';
import { New } from '../models/new';
import {Urls} from '@app/core/utilities/urls';
import { environment } from '@env/environment';

const api: string = `${ environment.host }/api/news`;

const endpoints: { [name: string]: string } = {
  'missed':    `${ api }/top5/never`,         // Por si te lo perdiste.
  'latest':    `${ api }/byFavTeam/last`,     // Noticias Recientes.
  'featured':  `${ api }/byFavTeam/visited`   // Noticias Destacadas.
};

@Injectable({
    providedIn: 'root'
})
export class LandingNewsService {

    constructor(private httpClient: HttpClient) { }

    public getNewsBy(criteria: string): Observable<New[]> {

        const endpointUrl: string = endpoints[ criteria ];

        return this.httpClient.get<NewsResponse>(endpointUrl)
            .pipe(
                map((newsResponse) => {

                    const { content } = newsResponse;
                    const { data } = content;

                    return data.map((newResponse) => {

                        const { id, url, title, image_local:urlImage, date, crawler } = newResponse;

                        return {
                            id,
                            title,
                            url,
                            urlImage,
                            date:       new Date(date),
                            hostDomain: Urls.getHostName(crawler.domain),
                            favicon: crawler.favicon
                        };
                    });
                })
            );
    }
}
