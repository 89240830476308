import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';

import { Observable, EMPTY, throwError, of } from 'rxjs';
import { map, catchError } from 'rxjs/operators';

import { TopicSuccessfulOperationResponse } from '../models/topic-successful-operation-response';
import { TopicResponse } from '../models/topic-response';
import { TopicSuccessfulOperation } from '../models/topic-successful-operation';
import { TopicPayload } from '../models/topic-payload';
import { Topic } from '../models/topic';
import { TopicCover } from '../models/topic-cover';
import { TeamPreference } from '../models/team-preference';

import { FileUtility } from '@app/core/utilities/file-utility';
import { environment } from '@env/environment';

@Injectable({
    providedIn: 'root'
})
export class TopicService {

	constructor(
        private httpClient: HttpClient,
        private router: Router
    ) { }
    
    public create(topic: Topic): Observable<TopicSuccessfulOperation> {

        const topicPayload: TopicPayload = this.mapTopicPayload(topic);

        return this.httpClient.post<TopicSuccessfulOperationResponse>(`${ environment.host }/api/topics/store`, { ...topicPayload })
        .pipe(
            map((TopicSuccessfulOperationResponse) => {

                const { content:{ id, name } } = TopicSuccessfulOperationResponse;

                return {
                    id,
                    title: name

                } as TopicSuccessfulOperation;
            })
        );
    }

    public get(id: number): Observable<Topic> {

        return this.httpClient.get<TopicResponse>(`${ environment.host }/api/user/topic/${ id }`)
        .pipe(
            map((currentTopicResponse) => {

                const { content } = currentTopicResponse;
                const { 
                    id,
                    name:title, 
                    description,
                    cover,
                    team
                } = content;

                return {
                    id,
                    title,
                    description,
                    cover: { name: null, image: cover, isEncoded: false, type: null, size: null } as TopicCover,
                    teamPreference:  { id: team.id, name: team.name } as TeamPreference
                };
            }),
            catchError((error: HttpErrorResponse) => {

                // Inspecciona la propiedad error de HttpErrorResponse para determinar el tipo de error
                // y manejarlo acorde.
                const httpError: any = error.error;
                const httpErrorCode: number = error.status;

                switch( httpErrorCode ) {
    
                    case 401:
        
                        // Inspecciona el error a nivel de nuestro servidor.
                        if ( httpError.message && httpError.message === 'El topic que intentas editar no es tuyo.' ) {
                            
                            // Redirecciona al usuario a la página de acción no autorizada.
                            this.router.navigateByUrl('/403');

                            return EMPTY;
                        }
                    break;
                }

                return throwError(error);
            })
        );
    }

    public update(topic: Topic): Observable<TopicSuccessfulOperation>  {

        const topicPayload: TopicPayload = this.mapTopicPayload(topic);

        return this.httpClient.put<TopicSuccessfulOperationResponse>(`${ environment.host }/api/topics/${ topic.id }`, { ...topicPayload })
        .pipe(
            map((topicSuccessfulOperationResponse) => {

                const { content:{ id, name } } = topicSuccessfulOperationResponse;

                return {
                    id,
                    title: name

                } as TopicSuccessfulOperation;
            }),
            catchError((error: HttpErrorResponse) => {

                // Inspecciona la propiedad error de HttpErrorResponse para determinar el tipo de error
                // y manejarlo acorde.
                const httpError: any = error.error;
                const httpErrorCode: number = error.status;

                switch( httpErrorCode ) {
    
                    case 401:
        
                        // Inspecciona el error a nivel de nuestro servidor.
                        if ( httpError.message && httpError.message === 'El topic que intentas editar no es tuyo.' ) {
                            
                            // Redirecciona al usuario a la página de acción no autorizada.
                            this.router.navigateByUrl('/403');

                            return EMPTY;
                        }
                    break;
                }

                return throwError(error);
            })
        );
    }

    public remove(id: number): Observable<boolean> {

        return this.httpClient.delete(`${ environment.host }/api/topics/${ id }`)
        .pipe(
            map(() => true),
            catchError((error: HttpErrorResponse) => {

                // Inspecciona la propiedad error de HttpErrorResponse para determinar el tipo de error
                // y manejarlo acorde.
                const httpError: any = error.error;
                const httpErrorCode: number = error.status;

                switch( httpErrorCode ) {
    
                    case 400:
        
                        // Inspecciona el error a nivel de nuestro servidor.
                        if ( httpError.message && httpError.message === 'No fue posible realizar la petición' ) {

                            return of(false);
                        }
                    break;
                }

                return throwError(error);
            })
        );
    }

    public getCurrentTeamPreference(): TeamPreference | undefined {

        const rawFavoriteTeamId: string | null = localStorage.getItem('ftid');
        const rawFavoriteTeamName: string | null = localStorage.getItem('ftnm');

        if (!rawFavoriteTeamId || !rawFavoriteTeamName) {

            return undefined;
        }

        if ( isNaN(+rawFavoriteTeamId) ) {

            return undefined;
        }

        const favoriteTeamId: number = +rawFavoriteTeamId;
        const favoriteTeamName: string = rawFavoriteTeamName;

        return {
            id:     favoriteTeamId,
            name:   favoriteTeamName
        };
    }

    private mapTopicPayload(topic: Topic): TopicPayload {
        
        const { id, title, description, cover, teamPreference } = topic;

        // En caso de que venga un id, esto es común cuando vamos a editar el Topic.
        let topicId:{ [name:string]: any } = {};

        if ( id ) {

            topicId = { id };
        }

        // Obten y mapea la imagen seleccionada.
        // Si viene una imagen codificada, incluye la información en la petición.
        let topicCover:{ [name:string]: any } = {};

        const { name:imageName, image, isEncoded, type:imageType } = cover;

        if ( image && isEncoded ) {

            topicCover = {
                image: {
                    name:   FileUtility.extractName(imageName || ''),
                    ext:    FileUtility.extractExtension(imageType || ''),
                    encode: FileUtility.extractOnlyBase64Text(image)
                }
            }
        }

        return {
            ...topicId,
            name:           title.trim(),
            description:    description.trim(),
            team_id:        teamPreference.id,
            ...topicCover
        };
    }
}
