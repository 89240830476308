import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';

import {Observable, forkJoin, Subject} from 'rxjs';
import {map, tap} from 'rxjs/operators';

import {AuthProfileService} from '@app/auth/shared/services/auth-profile.service';
import {ProfilePaginatorMappingService} from '../services/profile-paginator-mapping.service';

import {PostsResponse} from '../models/post-response';

import {environment} from '@env/environment';
import {PaginatorItemPost} from '../../shared/models/paginator-item-post';

@Injectable({
  providedIn: 'root'
})
export class ProfilePaginatorService {

  public nextPage: number = 1;
  private lastPage: number = 0;

  private paginationSubject: Subject<number> = new Subject<number>();
  public listenPagination$: Observable<number> = this.paginationSubject.asObservable();

  constructor(
    private readonly httpClient: HttpClient,
    private readonly authProfile: AuthProfileService,
    private readonly profilePaginatorMappingService: ProfilePaginatorMappingService
  ) {
  }

  public paginate(page: number): void {

    this.paginationSubject.next(page);
  }

  public hasNotReachedPaginationEnd(): boolean {

    return (this.nextPage <= this.lastPage);
  }

  public getPosts(page: number, type = 'all', order = 'DESC'): Observable<PaginatorItemPost[]> {
    const endpoints: { [name: string]: string } = {
      created: '/api/user/posts',
      liked: '/api/user/posts/i/liked',
      commented: '/api/user/posts/i/commented',
      follow: '/api/user/posts/i/follow'
    };
    const selectedEndpoint = endpoints[type];
    const currentPage: string = (page && page > 0) ? String(page) : '1';
    const params = new HttpParams().set('page', currentPage);

    return forkJoin({
      userId: this.authProfile.getAuthId(),
      postsResponse: this.httpClient.get<PostsResponse>(`${environment.host}${selectedEndpoint}?order=${order}&order_by=date`, {params})
    }).pipe(
      tap(({postsResponse}) => {

        const {content} = postsResponse;
        const {current_page, last_page} = content;

        this.nextPage = current_page + 1;
        this.lastPage = last_page;
      }),
      map(({userId, postsResponse}) => {

        const {content} = postsResponse;
        const {data: posts} = content;


        return this.profilePaginatorMappingService.getPaginatorItemsPost(posts, userId);
      })
    );
  }

  public getPostsByTopic(page: number, topicId: number): Observable<PaginatorItemPost[]> {

    const currentPage: string = (page && page > 0) ? String(page) : '1';
    const params = new HttpParams().set('page', currentPage);

    return forkJoin({
      userId: this.authProfile.getAuthId(),
      postsResponse: this.httpClient.get<PostsResponse>(`${environment.host}/api/topics/${topicId}/posts`, {params})
    }).pipe(
      tap(({postsResponse}) => {

        const {content} = postsResponse;
        const {current_page, last_page} = content;

        this.nextPage = current_page + 1;
        this.lastPage = last_page;
      }),
      map(({userId, postsResponse}) => {

        const {content} = postsResponse;
        const {data: posts} = content;


        return this.profilePaginatorMappingService.getPaginatorItemsPost(posts, userId);
      })
    );
  }

}
