import { Injectable } from '@angular/core';

import { PostResponse } from '../models/responses/post-response';
import { PostMediaResponse, ReactionResponse, ReactionSelectedReponse } from '../models/responses/post-media-response';
import { PostPollingResponse, PostPollingVoteResponse } from '../models/responses/post-polling-response';

import { PaginatorItemPost } from '../models/paginator-item-post';
import { PostType } from '@app/ui/post/shared/models/post-type';
import { PostMedia, Topic, LikeStatus, Reaction } from '@app/ui/post/shared/models/post-media';
import { PostPolling, Polling, PollingOption,  } from '@app/ui/post/shared/models/post-polling';

@Injectable()
export class PaginatorSearchResultsPostMappingsService {

    constructor() { }

    /*
     * Mapeador para obtener los Posts con Anuncios.
     */
    public getPaginatorItemsPost(postsResponse: PostResponse[], userId: number): PaginatorItemPost[] {
    
        return postsResponse
            // Mapeamos nuestros posts para ser manipulados internamente.
            .map((postResponse) => this.mapPost(userId, postResponse));
    }

    // This is the Entry Point to format a Post.
    private mapPost(currentUserId: number, postResponse: PostResponse): PostMedia | PostPolling {

        // El elemento es un Post de Votación?
        if ( this.isPostPolling(postResponse) ) {

            return this.mapPollingPost(currentUserId, postResponse as PostPollingResponse);        
        
        } else {

        // Si el elemento NO es un Post de Votación , entonces determinamos que es un Post de Media.
            return this.mapMediaPost(currentUserId, postResponse as PostMediaResponse);
        }
    }

    private isPostPolling(postResponse: PostResponse): postResponse is PostPollingResponse {

        // Debemos forzar primero la conversión de postResponse a un tipo unknown
        const postPollingResponse: unknown = postResponse as unknown

        // para eventualmente pasarlo a un tipo de PostPollingResponse (de acuerdo a las exigencias de Typescript) 
        return (<PostPollingResponse>postResponse).type_post_id === PostType.PollingPost;
    }

    // Para mapear nuestra entidad de Negocio.
    private mapPollingPost(currentUserId: number, postPollingResponse: PostPollingResponse): PostPolling {

        // Raw data from PostPollingResponse.
        const { id, type_post_id, description, currentUser, vote } = postPollingResponse;

        // Convert Raw Response into our Entity Business Model.
        return {
            id,                  // id del Post de Votación.
            type:               this.getPostType(type_post_id),
            description,
            pollingId:          vote.id,
            polls:              this.getPolls(currentUser.hasLogVoteSelected, vote),
            isOpen:             !!vote.enabled,
            TTL:                this.getTTL(vote.end_date),
            totalVotesCounted:  this.getTotalVotesCounting(vote)   // Es la suma de votos de todas los opciones.
        };
    } 
    
    // Para mapear nuestra entidad de Negocio.
    private mapMediaPost(currentUserId: number, postMediaResponse: PostMediaResponse): PostMedia {

        // Raw data from PostMediaResponse.
        const { 
            id, 
            type_post_id,
            description,
            optional,
            is_official,
            url,
            image,
            video,
            totalVotesUp,
            totalVotesDown,
            totalComments,
            created_at, 
            user,
            currentUser, 
            topic,
            reactions } = postMediaResponse;    

        // Convert Raw Response into our Entity Business Model.
        return {
            id,
            type:                   this.getPostType(type_post_id),
            media:                  {
                    content:        this.getContentMedia(type_post_id, image, video, url, optional)
            },
            description,
            backgroundCover:        this.getPostCover(!!topic.is_official, topic.cover),
            publishedBy:            user.name,
            publishedDate:          new Date(created_at),
            totalLikes:             totalVotesUp,
            totalDislikes:          totalVotesDown,
            totalComments,
            isOfficial:             !!is_official,
            following:              currentUser.isFollower,
            ownership:              currentUser.isOwner,
            topic:                  {
                    id:         topic.id,
                    title:      topic.name,
                    ownership:  (currentUserId === topic.user_id),
                    isOfficial: !!topic.is_official,
                    following:  topic.currentUser.isFollower
            } as Topic,
            likeStatus:             this.mapLikeStatus(currentUser.hasLike),
            reactions:              this.mapReactions(currentUser.hasReaction, reactions)
        };
    }
    
    // Para obtener el tipo de Publicación.
    private getPostType(postIdType: number): PostType {

        let postType: PostType = PostType.TextPost;
        
        switch ( postIdType ) {

            case 1:
                postType = PostType.TextPost;
            break;

            case 2:
                postType = PostType.LinkPost;
            break;

            case 3:
                postType = PostType.ImagePost;
            break;

            case 4:
                postType = PostType.VideoPost;
            break;

            case 5:
                postType = PostType.PollingPost;
            break;
        }

        return postType;
    }
    
    /*
        * Mapeadores del Post de Votación.
        */
    // Para formatear cada una de las opciones de votación.
    private getPolls(pollingVoteSelected: number | null | false, vote: PostPollingVoteResponse): Polling[] {

        // Podriamos haber hecho una operación de reduce, pero como no hay tiempo, crearemos el arreglo al vuelo.
        const polls: Polling[] = [];
        const { option1, option2, option3, option4, total1, total2, total3, total4 } = vote;

        if ( option1 ) {

            polls.push({
                title:          option1,
                option:         PollingOption.A,
                voteCounting:   total1,
                isSelected:     false
            });
        }

        if ( option2 ) {
            
            polls.push({
                title:          option2,
                option:         PollingOption.B,
                voteCounting:   total2,
                isSelected:     false
            });
        }

        if ( option3 ) {
            
            polls.push({
                title:          option3,
                option:         PollingOption.C,
                voteCounting:   total3,
                isSelected:     false
            });
        }

        if ( option4 ) {
            
            polls.push({
                title:          option4,
                option:         PollingOption.D,
                voteCounting:   total4,
                isSelected:     false
            });
        }
        
        // Antes de retornar verificamos si hay una opción seleccionada.
        if ( pollingVoteSelected ) {

            // Dado que hay una opción seleccionada, le asignamos que ya viene seleccionada.
            return polls.map((poll) => (poll.option === pollingVoteSelected) ? ({ ...poll, isSelected: true }) : poll );
        }

        return polls;
    }

    // Para obtener el total de votaciónes de una Publicación de Votación.
    private getTotalVotesCounting(vote: PostPollingVoteResponse): number {
        // Podriamos haber hecho una operación de reduce, pero como no hay tiempo, crearemos la sumatoria al vuelo.

        let totalVotesCounting: number = 0;
        const { option1, option2, option3, option4, total1, total2, total3, total4 } = vote;

        if ( option1 ) {

            totalVotesCounting += total1;
        }
        
        if ( option2 ) {

            totalVotesCounting += total2;
        }
        
        if ( option3 ) {

            totalVotesCounting += total3;
        }
        
        if ( option4 ) {

            totalVotesCounting += total4;
        }
        
        return totalVotesCounting;
    }
    
    // Para obtener el tiempo de Expiración de la Publicación de Votación en ms.
    private getTTL(expirationDate: string): number {

        // el expirationDate no debe venir en null. Solo que como el campo end_date en el webservice se acaba de agregar, esta en null.
        const today: Date = new Date();
        const endDate: Date = expirationDate ? new Date(expirationDate) : today;

        return (endDate.getTime() - today.getTime());
    }

    /*
        * Mapeadores del Post de Media (incluye Post de Texto, Link, Imagen y Video).
        */
    // Para obtener las reacciones (Post Media)
    private mapReactions(userReaction: ReactionSelectedReponse | null ,reactionsResponse: ReactionResponse[]): Reaction[] {

        return reactionsResponse.map((reaction) => {

            const { id, title, code, totalReaction } = reaction;
            let hasReaction: boolean = false;  

            if (userReaction) {

                // Si la reacción actual es igual a la seleccionada por el usuario.
                hasReaction = (userReaction.reaction_id === id);
            } 

            return {
                id,
                name:           title,
                htmlCode:       code,
                totalReactions: totalReaction,
                isSelected:     hasReaction
            } as Reaction;
        });
    }

    // Para obtener el estado del Like (Post Media)
    private mapLikeStatus(hasLikeStatus: number | null): LikeStatus {

        let likeStatus: LikeStatus = LikeStatus.Unsettled;

        switch( hasLikeStatus ) {

            case 1: 
                likeStatus = LikeStatus.Liked;
            break;

            case -1:
                likeStatus = LikeStatus.Disliked
            break;
        }

        return likeStatus
    }

    // Para obtener el Contenido Media (Post Media)
    private getContentMedia(postIdType: number, imageSource: string | null, videoSource: string | null, linkSource: string | null,  tags: Object | null): any {

        const postType: PostType = this.getPostType(postIdType);

        if (postType === PostType.ImagePost) {

            return imageSource;
        }

        if (postType === PostType.VideoPost) {

            return videoSource;
        }

        if (postType === PostType.LinkPost) {

            return {
                originalLinkSource: linkSource,
                metaTags: tags
            };
        }

        return null;
    }
    
    // Para obtener el Cover de la Publicación (Post Media)
    private getPostCover(isTopicOfficial: boolean, topicCover: string | null): string {
        
        return (isTopicOfficial && topicCover)  ? topicCover : '';
    }
}
