import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BoardingTeamsComponent } from './boarding-teams/boarding-teams.component';
import { TeamPanelComponent } from './components/team-panel/team-panel.component';
import { TeamGroupComponent } from './components/team-group/team-group.component';

@NgModule({
    declarations: [BoardingTeamsComponent, TeamPanelComponent, TeamGroupComponent],
    imports: [
    CommonModule
    ],
    exports: [
        BoardingTeamsComponent
    ]
})
export class BoardingTeamsModule { }
