import { Component, OnInit, Output, EventEmitter, Input} from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
// @ts-ignore
import * as Emoji from 'quill-emoji';

import { PostType } from '../../../shared/models/post-type';
import { PostText } from '../../../shared/models/post-text';

declare var Quill: any;

@Component({
    selector: 'app-create-text-post-form',
    templateUrl: './create-text-post-form.component.html',
    styleUrls: ['./create-text-post-form.component.scss']
})
export class CreateTextPostFormComponent implements OnInit {

    @Input() public createTextPostLoading: boolean = false;

    @Output() onCreate: EventEmitter<Partial<PostText>> = new EventEmitter<Partial<PostText>>();
    @Output() onCancel: EventEmitter<void> = new EventEmitter<void>();

    public textTopic = '';
    public createTextPostForm!: FormGroup;

    constructor(private readonly fb: FormBuilder) {

        Quill.register('modules/emoji', Emoji);
    }

    ngOnInit(): void {

        this.loadCreateTextPostForm();
    }

    private loadCreateTextPostForm(): void {

        this.createTextPostForm = this.fb.group({
            description:    [ '', [ Validators.required, Validators.maxLength(1000) ] ]
        });
    }

    public onCreateTextPost(): void {

        // En caso que el usuario de click al botón iniciar sin haber ingresado los datos que se le piden.
        this.createTextPostForm.markAllAsTouched();

        // Una vez los datos del formulario sean correctos
        if ( this.createTextPostForm.valid ) {

            const postType: PostType = PostType.TEXT;
            const { description } = this.createTextPostForm.value;

            this.onCreate.emit({ description, postType} as Partial<PostText>);
        }
    }

    public onCreateTextPostCancellation(): void {

        this.onCancel.emit();
    }

    get description() {

        return this.createTextPostForm.get('description');
    }
}
