import {Component, OnDestroy, OnInit} from '@angular/core';

import {SubSink} from 'subsink';
import {MessageService} from 'primeng/api';

import {switchMap, distinctUntilChanged} from 'rxjs/operators';

import {CommunityService} from '../../shared/services/community.service';
import {ProfilePaginatorService} from '../../shared/services/profile-paginator.service';
import {LayoutConfigService} from '@app/layout-config/shared/services/layout-config.service';

import {PaginatorItemPost} from '../../shared/models/paginator-item-post';
import {PostMedia} from '@app/ui/post/shared/models/post-media';
import {New} from '../../shared/models/news';

import {DropDownFilterDirection, DropDownFilterOption} from '@app/search-results/shared/models/dropdown-filter';

@Component({
  selector: 'app-saved-news-page',
  templateUrl: './saved-news-page.component.html',
  styleUrls: ['./saved-news-page.component.scss']
})
export class SavedNewsPageComponent implements OnInit, OnDestroy {

  constructor(
    private readonly toastMessage: MessageService,
    private readonly communityService: CommunityService,
    private readonly profilePaginatorService: ProfilePaginatorService,
    private readonly layoutConfigService: LayoutConfigService
  ) {

    // Establecemos la configuración inicial de la barra de navegación y del footer.
    layoutConfigService.setLayoutConfig({
      layout: {
        navbar: {visible: true},
        footer: {visible: false}
      }
    });
  }

  private subscriptions = new SubSink();
  actualPage = 1;
  actualType = 'posts';
  loaded = false;
  isLoading = false;
  news: New[] = [];
  public paginatorItemPosts: PaginatorItemPost[] = [];
  public paginatorItemPostsPreviouslyLoaded: PaginatorItemPost[] = [];
  public loadingMorePosts: boolean = false;
  public initialLoadingDone: boolean = false;

  // Flag para mostrar/ocultar los Anuncios.
  public showAdTypeC: boolean = true;

  public dropDownFilters: DropDownFilterOption[] = [
    { dropDownFilterLabel: 'Más recientes', dropDownFilterDirection: 'DESC' },
    { dropDownFilterLabel: 'Menos recientes', dropDownFilterDirection: 'ASC' }
  ];
  public selectedFilter: DropDownFilterDirection  = 'DESC';

  ngOnInit(): void {
    this.getSavedNews();
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  public onChangeFilter(selectedFilter: DropDownFilterDirection): void {
    this.actualPage = 1;
    this.loaded = false;
    this.news = [];
    this.paginatorItemPosts = [];
    this.paginatorItemPostsPreviouslyLoaded = [];
    if (this.actualType === 'news') {
      this.getSavedNews();
    } else if (this.actualType === 'posts') {
      this.getSavedPost();
    }
  }

  setType(type: string): void {
    this.actualPage = 1;
    this.actualType = type;
    this.loaded = false;
    this.news = [];
    this.paginatorItemPosts = [];
    this.paginatorItemPostsPreviouslyLoaded = [];
    if (type === 'news') {
      this.getSavedNews();
    } else if (type === 'posts') {
      this.getSavedPost();
    }
  }

  getSavedPost(): void {
    this.subscriptions.sink = this.profilePaginatorService.listenPagination$
      .pipe(
        distinctUntilChanged(),
        switchMap((page) => this.profilePaginatorService.getPosts(page, 'follow', this.selectedFilter))
      )
      .subscribe((posts) => {

        const isFirstPage: boolean = ((this.profilePaginatorService.nextPage - 1) === 1);

        if (isFirstPage) {

          this.initialLoadingDone = true;
        }
        this.loaded = true;
        this.loadingMorePosts = false;
        this.paginatePosts(posts);
      });

    // Fire first Pagination Page.
    this.profilePaginatorService.paginate(1);
  }

  getSavedNews(): void {
    this.actualPage = 1;
    this.actualType = 'news';
    this.news = [];
    this.subscriptions.sink = this.communityService.getMyNews(this.actualPage, this.selectedFilter).subscribe({
      next: (items) => {
        this.news = items;
      },
      complete: () => {
        this.isLoading = false;
        this.loaded = true;
      }
    });
  }

  loadMoreItems(): void {
    if (!this.isLoading) {
      this.actualPage++;
      this.isLoading = true;
      if (this.actualType === 'news') {
        this.subscriptions.sink = this.communityService.getMyNews(this.actualPage, this.selectedFilter).subscribe({
          next: (items) => {
            items.map((item) => {
              this.news.push(item);
            });
          },
          complete: () => {
            this.isLoading = false;
          }
        });
      } else if (this.actualType === 'posts') {
        if (this.profilePaginatorService.hasNotReachedPaginationEnd()) {

          const nextPage: number = this.profilePaginatorService.nextPage;

          this.loadingMorePosts = true;
          this.profilePaginatorService.paginate(nextPage);
        }
      }
    }
  }

  public onBookmarkPost(isFollowingPost: boolean): void {

    this.toastMessage.add({
        severity: isFollowingPost ? 'success' : 'info',
        summary: 'Éxito',
        detail: isFollowingPost ? 'Has guardado esta noticia.' : 'Has eliminado de tus guardados esta noticia.'
    });
  }

  private paginatePosts(posts: PaginatorItemPost[]): void {

    // Fusionamos los posts previamente cargadados con los posts que provengan del Servidor.
    this.paginatorItemPostsPreviouslyLoaded = [...this.paginatorItemPostsPreviouslyLoaded, ...posts];

    // Actualizamos la vista con la nueva información cargada en el Property Binding correspondiente.
    this.paginatorItemPosts = this.paginatorItemPostsPreviouslyLoaded;
  }

}
