import { FormControl, ValidationErrors } from '@angular/forms';

export class CommonValidators {

    static mustNotContainNumbers(c: FormControl): ValidationErrors | null {

        const itHasNumbers: boolean = /\d/.test(c.value);
        const validationError: ValidationErrors = {
            'containsNumbers': true
        };
        
        return itHasNumbers ? validationError : null;
    }

    static mustContainOnlyNumbers(c: FormControl): ValidationErrors | null {
   
        const itOnlyHasNumbers: boolean = /^\d+$/.test(c.value);
        const validationError: ValidationErrors = {
            'containsMoreThanNumbers': true
        };

        return itOnlyHasNumbers ? null : validationError;
    }

    static mustNotBeBlank(c: FormControl): ValidationErrors | null {

        const isWhiteSpace: boolean = ( (c.value || '').trim().length === 0 );
        const isValid: boolean = !isWhiteSpace;

        const validationError: ValidationErrors = {
            'blank': true
        };

        return isValid ? null : validationError;
    }
}