<div class="video-container">
  <video
    width="100%"
    height="420px"
    controls>
    <source type="video/mp4" [src]="videoUrl">
  </video>

  <button type="button" (click)="onClearVideo()">X</button>
</div>
