import { Component, OnInit, ViewChild } from '@angular/core';

import { ScrollableToBeginningDirective } from '../../directives/scrollable-to-beginning.directive';

import { NewsService } from '../../shared/services/news.service';
import { Paginator } from '../../shared/models/paginator';
import { NewOrAdType } from '../../shared/models/new-or-ad';

@Component({
    selector: 'app-news-scroller-test-page',
    templateUrl: './news-scroller-test-page.component.html',
    styleUrls: ['./news-scroller-test-page.component.scss']
})
export class NewsScrollerTestPageComponent implements OnInit {

    @ViewChild(ScrollableToBeginningDirective) scroller!:ScrollableToBeginningDirective;

    public news: NewOrAdType[] = [];
    private previouslyLoadedNews: NewOrAdType[] = [];

    public firstPageFromPaginatorIsLoaded: boolean = false;
    public loadingMoreNews: boolean = false;

    private paginatorCurrentPage: number = 0;
    private paginatorNextPage: string | null = '';

    constructor(private newsService: NewsService) {
    }

    ngOnInit(): void {

        // Al cargar este componente, cargamos la información relativa a la primera página del páginador.
        this.loadFirstPageFromPaginator();
    }

    public lazyLoadMoreNews(): void {

        // Con la propiedad infiniteScrollDistance=3 de la directiva infiniteScroll hemos configurado para que un evento 
        // scroll sea disparado cuando se haya recorrido alrededor del 70% del area.  
        this.paginate();
    }

    private loadFirstPageFromPaginator(): void {
       
        this.firstPageFromPaginatorIsLoaded = false;
        this.paginatorCurrentPage = 1;

        this.newsService.paginate(this.paginatorCurrentPage).subscribe( (chunk) => {

            this.renderNewsOnTheScrollingArea(chunk);
            this.firstPageFromPaginatorIsLoaded = true;

            console.log( this.news );
        });
    }

    private paginate(): void {

        if ( this.paginatorHasNextPage() ) {

            this.loadingMoreNews = true;
            this.paginatorCurrentPage ++;

            this.newsService.paginate(this.paginatorCurrentPage).subscribe( (chunk) => {
                
                this.renderNewsOnTheScrollingArea(chunk);
                this.loadingMoreNews = false;

                console.log( this.news );
            });
        }
    }

    private paginatorHasNextPage(): boolean {
  
        return (this.paginatorNextPage !== null);
    }

    private renderNewsOnTheScrollingArea(chunk:[ Paginator, NewOrAdType[] ]): void {

        const [ paginatorInfo, incomingNews ] = chunk;

        // Actualizamos la información del Paginador.
        this.paginatorNextPage = paginatorInfo.nextPageUrl;
        // Fusionamos las noticias previamente cargadadas con las noticias que provengan del Servidor.
        this.previouslyLoadedNews = [ ...this.previouslyLoadedNews, ...incomingNews ];
        // Actualizamos la vista con la nueva información cargada en el Property Binding correspondiente.
        this.news = this.previouslyLoadedNews;
    }

    public gotoFirstPage(): void {
        
        this.scroller.gotoBeggining();
    }
}
