<form [formGroup]="createVideoPostForm" (ngSubmit)="onCreateVideoPost()" novalidate>
  <div class="input-group">
    <input
      type="text"
      class="description input-box-face input-box-white-text"
      placeholder="Escribe algo interesante..."
      formControlName="description"
      pInputText>

      <ng-container *ngIf="description?.invalid && (description?.dirty || description?.touched)">
        <span *ngIf="description?.hasError('required')" class="invalid-feedback-message"><small>Escribe algo interesante</small></span>
        <span *ngIf="description?.hasError('maxlength')" class="invalid-feedback-message"><small>El límite máximo permitido son 400 caracteres</small></span>
      </ng-container>
  </div>

  <div class="input-group">
    <app-single-video-uploader-field
      accept="video/mp4"
      [maxFileSize]="102400"
      formControlName="video"></app-single-video-uploader-field>

      <ng-container *ngIf="video?.invalid && (video?.dirty || video?.touched)">
        <span *ngIf="video?.hasError('fileRequired')" class="invalid-feedback-message"><small>Eliga un video para su Publicación</small></span>
        <span *ngIf="video?.hasError('invalidFileSize')" class="invalid-feedback-message"><small>No pudimos cargar su video, el tamaño máximo permitido es de hasta XMB.</small></span>
        <span *ngIf="video?.hasError('invalidMimeType')" class="invalid-feedback-message"><small>No pudimos cargar su video, solo se aceptan formatos mp4.</small></span>
        <span *ngIf="video?.hasError('unSelectedFile')" class="invalid-feedback-message"><small>No se seleccionó ningun video.</small></span>
      </ng-container>
  </div>

  <div class="controls">
    <button type="button" (click)="onCreateVideoPostCancellation()">
      Cancelar <img src="assets/iconos/x_cancelar.svg" alt="Cancelar">
    </button>

    <button type="submit">
      Publicar <img src="assets/iconos/arrow_publica.svg" alt="Publicar">
    </button>
  </div>
</form>
