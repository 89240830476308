import { Component, OnInit, Output, EventEmitter, ChangeDetectionStrategy } from '@angular/core';

import { Provider } from '../../shared/models/auth-provider';

@Component({
    selector: 'app-signin-provider-options',
    templateUrl: './signin-provider-options.component.html',
    styleUrls: ['./signin-provider-options.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class SigninProviderOptionsComponent implements OnInit {

    @Output() authProviderSelected:EventEmitter<Provider> = new EventEmitter<Provider>();

    public provider = Provider; 

    constructor() { }

    ngOnInit(): void {
    }

    public onAuthProviderSelected(provider:Provider):void {
        
        this.authProviderSelected.emit(provider);
    }
}
