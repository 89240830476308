<div class="main-grid">

  <div class="grid-left">

    <div class="back mb-40">
      <button type="button" (click)="back()"><img src="assets/iconos/icono_arrowdown.svg" alt="Regresar">
        Regresar
      </button>
    </div>

    <div class="webviews" *ngIf="webviewsLoaded; else loading">
      <h3>{{webviewMenu.name}}</h3>
      <a class="webview {{webviewsSize}}" *ngFor="let webview of webViews" href="{{webview.url}}" target="_blank">
        <img src="{{webview.cover}}" alt="webview">
        <div class="content">
          <ng-container *ngIf="webview.name">
            <h4>{{webview.name}}<img src="assets/iconos/icon_link.svg" alt="Link" class="link"></h4>
          </ng-container>
          <p>
            <ng-container *ngIf="webview.icon">
              <img src="{{webview.icon}}" alt="webview la-liga">
            </ng-container>
            <a href="{{webview.url}}">{{webview.url}}</a><a href="https://via.placeholder.com/100">
            <ng-container *ngIf="webview.description">
              | {{webview.description}}
            </ng-container>
          </a></p>
        </div>
      </a>
    </div>


    <ng-template #loading>
      <!-- Este spinner es cuando se cargan inicialmente las noticias. -->
      <div class="loading" style="text-align: center;">
        <i class="pi pi-spin pi-spinner spinner spinner-lg"></i>
      </div>
    </ng-template>
  </div>
  <div class="grid-right pt-50">
    <app-banner-community></app-banner-community>
    <app-recommended-topic-list></app-recommended-topic-list>
    <app-advertisement-type-d></app-advertisement-type-d>
  </div>
</div>
