import {Component, OnDestroy, OnInit} from '@angular/core';
import {Topic} from '../../shared/models/community';
import {CommunityService} from '../../shared/services/community.service';
import {LayoutConfigService} from '@app/layout-config/shared/services/layout-config.service';
import {SubSink} from 'subsink';
import {DropDownFilterDirection, DropDownFilterOption} from '@app/search-results/shared/models/dropdown-filter';

@Component({
  selector: 'app-topics-created-page',
  templateUrl: './topics-created-page.component.html',
  styleUrls: ['./topics-created-page.component.scss']
})
export class TopicsCreatedPageComponent implements OnInit, OnDestroy {

  constructor(
    private layoutConfigService: LayoutConfigService,
    public communityService: CommunityService
  ) {

    // Establecemos la configuración inicial de la barra de navegación y del footer.
    layoutConfigService.setLayoutConfig({
      layout: {
        navbar: {visible: true},
        footer: {visible: false}
      }
    });
  }

  private subscriptions = new SubSink();
  public currentPage = 1;
  public topics: Topic[] = [];
  public loadingTopics: boolean = false;
  public loaded: boolean = false;
  public dropDownFilters: DropDownFilterOption[] = [
    {dropDownFilterLabel: 'Más recientes', dropDownFilterDirection: 'DESC'},
    {dropDownFilterLabel: 'Menos recientes', dropDownFilterDirection: 'ASC'}
  ];
  public selectedFilter: DropDownFilterDirection = 'DESC';
  // Flag para mostrar/ocultar los Anuncios.
  public showAdTypeC: boolean = true;

  ngOnInit(): void {
    this.getMyTopics(this.currentPage);
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  public onChangeFilter(selectedFilter: DropDownFilterDirection): void {
    this.currentPage = 1;
    this.topics = [];
    this.getMyTopics(this.currentPage, selectedFilter);
  }

  getMyTopics(page: number, order = 'DESC'): void {
    this.subscriptions.sink = this.communityService.getMyTopics(page, order).subscribe({
      next: (topics) => {
        this.topics = topics;
      },
      complete: () => {
        this.loaded = true;
      }
    });
  }

  getMoreTopics(): void {
    if (!this.loadingTopics) {
      this.loadingTopics = true;
      this.currentPage++;
      this.subscriptions.sink = this.communityService.getMyTopics(this.currentPage).subscribe({
        next: (topics) => {
          topics.map((topic) => {
            this.topics.push(topic);
          });
        },
        complete: () => {
          this.loadingTopics = false;
        }
      });
    }

  }

}
