import { Component, OnInit, OnDestroy, AfterContentInit, ContentChildren, QueryList } from '@angular/core';

import { TeamGroupComponent } from '../team-group/team-group.component';

@Component({
    selector: 'app-team-panel',
    templateUrl: './team-panel.component.html',
    styleUrls: ['./team-panel.component.scss']
})
export class TeamPanelComponent implements OnInit, AfterContentInit, OnDestroy {

    @ContentChildren(TeamGroupComponent) teamGroups!:QueryList<TeamGroupComponent>;

    constructor() { }

    ngOnInit(): void {

        console.log('TeamPanelComponent created.')
    }

    ngOnDestroy(): void {

        console.log('TeamPanelComponent destroyed.');
    }

    ngAfterContentInit(): void {}

    public onSelectTeamGroup(teamGroup:TeamGroupComponent):void {
        
        // Resetea todos los teamGroups.
        this.teamGroups.forEach( (teamGroup) => teamGroup.isSelected = false );

        // Selecciona el actual
        teamGroup.isSelected = true;
    }
}
