import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AuthenticateService } from '@app/auth/shared/guards/authenticate.service';
import { WelcomeFirstTimeUserService } from '@app/auth/shared/guards/welcome-first-time-user.service';
import { VerifyTeamPreferenceService } from '@app/auth/shared/guards/verify-team-preference.service';

import { MainLandingComponent } from './pages/main-landing/main-landing.component';
import { TestLandingComponent } from './pages/test-landing/test-landing.component';

const routes: Routes = [
  { 
    path: 'landing', 
    component: MainLandingComponent, 
    canActivate: [ AuthenticateService, WelcomeFirstTimeUserService, VerifyTeamPreferenceService ] 
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class LandingRoutingModule { }
