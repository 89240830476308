import { Component, OnInit, OnDestroy } from '@angular/core';
import { Location } from '@angular/common';

import { SubSink } from "subsink";

import { LayoutConfigService } from '@app/layout-config/shared/services/layout-config.service';
import { TeamsService } from '../../shared/services/teams.service';

import { LeaguesResponse, TeamResponse } from '../../shared/models/teams-response';

@Component({
  selector: 'app-team-topics-page',
  templateUrl: './team-topics-page.component.html',
  styleUrls: ['./team-topics-page.component.scss']
})
export class TeamTopicsPageComponent implements OnInit, OnDestroy {

    private subscriptions = new SubSink();

    // Ligas de equipo.
    public teamsByLeague: LeaguesResponse[] = [];
    public activeLeague: TeamResponse[] = [];
    public activeLeagueInt: number = 1;
    
    // Flag para mostrar/ocultar los Anuncios.
    public showAdTypeC: boolean = true;

    constructor(
        private readonly location: Location,
        private readonly teams: TeamsService,
        private readonly layoutConfig: LayoutConfigService
    ) {

        // Establecemos la configuración inicial de la barra de navegación y del footer.
        layoutConfig.setLayoutConfig({
            layout: {
                navbar: { visible: true },
                footer: { visible: false }
            }
        });
    }

    ngOnInit(): void {

        this.subscriptions.sink = this.teams.getTeamsByLeague().subscribe({
            next: (league) => {
                
                this.teamsByLeague = league;
            },
            complete: () => {

                this.activeLeague = this.teamsByLeague[this.activeLeagueInt].teams;
            }
        });

    }

    ngOnDestroy(): void {

        this.subscriptions.unsubscribe();
    }

    public back(): void {

        this.location.back();
    }

    public showTeamsFromLeague(league: number): void {

        this.activeLeague = this.teamsByLeague[league].teams;
        this.activeLeagueInt = league;
    }
}
