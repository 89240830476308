import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import {InputTextModule} from 'primeng/inputtext';

import { SelectTeamFieldComponent } from './select-team-field/select-team-field.component';

@NgModule({
    declarations: [ SelectTeamFieldComponent ],
    imports: [
        CommonModule,
        InputTextModule
    ],
    exports: [
        SelectTeamFieldComponent
    ]
})
export class SelectTeamFieldModule { }
