<form [formGroup]="signUpForm" (ngSubmit)="onSignUp()" novalidate autocomplete="off" class="main-padding">

  <button class="close" type="button" (click)="back()">
    <img src="assets/iconos/icono_arrowdown.svg" alt="Regresar">
    Regresar
  </button>

  <div class="input-group">
    <!-- campo nombre -->
    <div class="icon-input">
      <input
        type="text"
        class="input-box-face input-box-green-text"
        name="name"
        placeholder="Nombre"
        formControlName="name"
        pInputText>
      <img src="assets/iconos/icono_nombre.svg" alt="Nombre">
    </div>

    <!-- Posibles Mensajes de error para el campo name -->
    <ng-container *ngIf="name?.invalid && (name?.dirty || name?.touched)">
      <ng-container [ngSwitch]="errorOnNameField">
        <span *ngSwitchCase="'required'" class="invalid-feedback-message">
          <small>Ingrese su nombre.</small>
        </span>
        <span *ngSwitchCase="'minlength'" class="invalid-feedback-message">
          <small>Ingrese un nombre más extenso.</small>
        </span>
        <span *ngSwitchCase="'maxlength'" class="invalid-feedback-message">
          <small>Ingrese un nombre más corto.</small>
        </span>
        <span *ngSwitchCase="'blank'" class="invalid-feedback-message">
          <small>Ingrese un nombre válido.</small>
        </span>
      </ng-container>
    </ng-container>
  </div>

  <div class="input-group">
    <!-- campo correo electrónico -->
    <div class="icon-input p-input-icon-right">
      <i class="pi green pi-spin pi-spinner" *ngIf="email?.pending"></i>
      <input
        type="email"
        class="input-box-face input-box-green-text"
        name="email"
        placeholder="Correo Electrónico"
        formControlName="email"
        pInputText>
      <img src="assets/iconos/icono_correo_verde.svg" alt="Mail">
    </div>

    <!-- Posibles Mensajes de error para el campo email -->
    <ng-container *ngIf="email?.invalid && (email?.dirty || email?.touched)">
    <span *ngIf="email?.hasError('required')" class="invalid-feedback-message">
      <small>Ingrese su Correo Electrónico.</small>
    </span>
      <span *ngIf="email?.hasError('email')" class="invalid-feedback-message">
      <small>Ingrese un Correo Electrónico válido.</small>
    </span>
      <span *ngIf="email?.hasError('maxlength')" class="invalid-feedback-message">
      <small>Pruebe con un correo electrónico más corto.</small>
    </span>
      <span *ngIf="email?.hasError('emailTaken')" class="invalid-feedback-message">
      <small>Esta dirección de correo ya encuentra ocupada, pruebe con otra.</small>
    </span>
    </ng-container>
  </div>

  <div class="input-group">
    <!-- campo password -->
    <app-password-field
      placeholder="Contraseña"
      formControlName="password"></app-password-field>

    <!-- Posibles Mensajes de error para el campo password -->
    <ng-container *ngIf="password?.invalid && (password?.dirty || password?.touched)">
    <span *ngIf="password?.hasError('required')" class="invalid-feedback-message">
      <small>Ingrese la contraseña deseada.</small>
    </span>
      <span *ngIf="password?.hasError('minlength')" class="invalid-feedback-message">
      <small>Ingrese una contraseña más extensa.</small>
    </span>
    </ng-container>
  </div>
  <div class="input-group">

    <!-- campo phone -->
    <div class="icon-input mt-20">
      <input
        type="text"
        class="input-box-face input-box-green-text"
        name="phone"
        placeholder="Teléfono"
        formControlName="phone"
        pInputText>
      <img src="assets/iconos/icono_telefono.svg" alt="Teléfono">
    </div>

    <!-- Posibles Mensajes de error para el campo phone -->
    <ng-container *ngIf="phone?.invalid && (phone?.dirty || phone?.touched)">
    <span *ngIf="phone?.hasError('required')" class="invalid-feedback-message">
      <small>Ingrese su número telefónico</small>
    </span>
    </ng-container>

  </div>
  <div class="input-group">
    <!-- campo city -->
    <div class="icon-input">
      <input
        type="text"
        class="input-box-face input-box-green-text"
        name="city"
        placeholder="Ciudad"
        formControlName="city"
        pInputText>
      <img src="assets/iconos/icono_ciudad.svg" alt="Teléfono">
    </div>

    <!-- Posibles Mensajes de error para el campo city -->
    <ng-container *ngIf="city?.invalid && (city?.dirty || city?.touched)">
    <span *ngIf="city?.hasError('required')" class="invalid-feedback-message">
      <small>Ingrese su ciudad</small>
    </span>
    </ng-container>
  </div>
  <div class="input-group">
    <!-- campo birthDate -->
    <!-- Hay un estilo en login.component.scss que esta afectando el despliegue del modal de la fecha de nacimiento -->
    <div class="icon-input calendar">
      <p-calendar
        class="input-box-face input-box-green-text"
        formControlName="birthDate"
        placeholder="Fecha de Nacimiento"
        dateFormat="dd/mm/yy"
        [yearNavigator]="true"
        yearRange="1900:2100"
        [monthNavigator]="true"
        [readonlyInput]="true"
        appendTo="body"
        [touchUI]="true"></p-calendar>
      <img src="assets/iconos/icono_cumpleanos.svg" alt="Nacimiento">
    </div>

    <!-- Posibles Mensajes de error para el campo birthDate -->
    <ng-container *ngIf="birthDate?.invalid && (birthDate?.dirty || birthDate?.touched)">
    <span *ngIf="birthDate?.hasError('required')" class="invalid-feedback-message">
      <small>Ingrese su fecha de nacimiento</small>
    </span>
    </ng-container>

  </div>
  <div class="input-group">
    <!-- campo teamPreference -->
    <app-select-team-field
      placeholder="Club de fútbol"
      formControlName="teamPreference"></app-select-team-field>

    <!-- Posibles Mensajes de error para el campo teamPreference -->
    <ng-container *ngIf="teamPreference?.invalid && (teamPreference?.dirty || teamPreference?.touched)">
    <span *ngIf="teamPreference?.hasError('required')" class="invalid-feedback-message">
      <small>Seleccione un club de su preferencia.</small>
    </span>
    </ng-container>
  </div>


  <div class="group-input">
    <div class="radio-input">
      <input type="radio" id="gender-m" name="gender" value="M" formControlName="gender">
      <label for="gender-m">Hombre</label>
    </div>

    <div class="radio-input">
      <input type="radio" id="gender-f" name="gender" value="F" formControlName="gender">
      <label for="gender-f">Mujer</label>
    </div>

    <div class="radio-input">
      <input type="radio" name="gender" id="gender-x" value="S" formControlName="gender">
      <label for="gender-x">Sin especificar</label>
    </div>
  </div>

  <!-- campo acceptTermsAndConditions -->
  <label class="terms-conditions">
    <!-- Hay un estilo que impide la selección del checkbox, por ahorita lo desactive. -->
    <!-- <div class="radio-input"> -->
    <input
      type="checkbox"
      name="acceptTermsAndConditions"
      formControlName="acceptTermsAndConditions">

    <!-- TODO: Actualizar los links con las rutas correspondientes. -->
    Al marcar esta casilla acepto los <a [routerLink]="['/login']">Términos y condiciones</a>, así como el <a
    [routerLink]="['/login']">Aviso de Privacidad</a>
    <!-- </div> -->
  </label>

  <div>
    <button class="submit" type="submit" [disabled]="signUpLoading">

      <ng-container *ngIf="!signUpLoading; else loading">Iniciar <img src="assets/iconos/icono_arrow.svg" alt="Iniciar"></ng-container>

      <ng-template #loading>
        <i class="pi pi-spin pi-spinner button-spinner"></i>
      </ng-template>
    </button>
  </div>
</form>
