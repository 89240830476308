import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { SubSink } from 'subsink';
import { MessageService } from 'primeng/api';

import { ModalService } from '@app/ui/modals/shared/services/modal.service';

import { AuthService } from '../../shared/services/auth.service';
import { AuthSocialService } from '../../shared/services/auth-social.service';
import { RegistrationService } from '../../shared/services/registration.service';
import { LayoutConfigService } from '@app/layout-config/shared/services/layout-config.service';

import { AuthRegistration } from '../../shared/models/auth-registration';
import { AuthUserAuthenticated } from '../../shared/models/auth-user-authenticated';
import { AuthCredentials } from '../../shared/models/auth-credentials';
import { Provider } from '../../shared/models/auth-provider';

@Component({
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit, OnDestroy {

    private subscriptions = new SubSink();

    // Flags para controlar el proceso de Inicio de Sesión.
    public loadingSignInAction: boolean = false;

    // Flags para controlar el proceso de Suscripción.
    public loadingSignUpAction: boolean = false;

    // Flags para orquestrar el despliegue de las diferentes secciones de este Componente Contenedor.
    public openSignInForm: boolean = false;
    public openSignUpForm: boolean = false;
    public showNotAccountYetBar: boolean = true;
    public showAuthProviderOptionsBar: boolean = true;
    public showRecentSignInActivityBar: boolean = true;

    // Para manejar la url de retorno.
    public intendedUrl: string = '/';

    constructor(
        private readonly router: Router,
        private readonly route: ActivatedRoute,
        private readonly messageService: MessageService,
        private readonly authSocial: AuthSocialService,
        private readonly authService: AuthService,
        private readonly modalService: ModalService,
        private readonly registrationService: RegistrationService,
        private readonly layoutConfigService: LayoutConfigService
    ) {

        // Establecemos la configuración inicial de la barra de navegación y del footer.
        layoutConfigService.setLayoutConfig({
            layout: {
                navbar: { visible: false },
                footer: { visible: true }
              }
        });
    }

    ngOnInit(): void {

        // Intentamos obtener una query param con el nombre session.
        const sessionStatus: string | null = this.route.snapshot.queryParamMap.get('session');
        // Intenteamos obtener una query param con el nombre intendedUrl.
        const intendedUrl: string | null = this.route.snapshot.queryParamMap.get('intendedUrl');

        // Mostramos un mensaje amigable al usuario de que su sesión ya expiró.
        if ( sessionStatus && sessionStatus === 'expired') {

            setTimeout( () => {
                this.messageService.add({
                    key:        'authToastNotifications',
                    severity:   'error',
                    summary:    'LigaMX Inicio Sesión',
                    detail:     'Su sesión ha expirado, ingrese sus credenciales nuevamente.'
                });
            }, 500);
        }

        // Vamos a guardar una referencia la url a la que se pretende acceder.
        // En caso que no haya una intendedUrl, por defecto demamos la url raíz de la aplicación.
        this.intendedUrl = intendedUrl ? atob(intendedUrl) : '/';        
    }

    ngOnDestroy(): void {

        this.subscriptions.unsubscribe();
    }

    public onAuthProviderSelected(provider:Provider): void {

        switch ( provider ) {

            case Provider.Facebook:

                this.subscriptions.sink = this.authSocial.signInWithFacebook().subscribe((authenticatedUser) => {

                    this.handleSuccessfulAuthentication(authenticatedUser);
                });
            break;

            case Provider.Google:

                this.subscriptions.sink = this.authSocial.signInWithGoogle().subscribe((authenticatedUser) => {

                    this.handleSuccessfulAuthentication(authenticatedUser);
                });
            break;

            case Provider.Apple:

                this.subscriptions.sink = this.authSocial.signInWithApple().subscribe((authenticatedUser) => {

                    this.handleSuccessfulAuthentication(authenticatedUser);
                });
            break;

            case Provider.Basic:

                this.showLoginForm();
            break;
        }
    }

    public onSignInWithBasicProvider(credentials: AuthCredentials): void {

        this.loadingSignInAction = true;

        this.subscriptions.sink = this.authService.signIn(credentials).subscribe({
            next: (authenticatedUser) => {

                this.loadingSignInAction = false;
                this.handleSuccessfulAuthentication(authenticatedUser);
            },
            complete: () => {

                this.loadingSignInAction = false;
            }
        });
    }

    public onSignUp(registration: AuthRegistration): void {

        const { acceptTermsAndConditions } = registration;

        if ( !acceptTermsAndConditions ) {

            this.messageService.add({
                key:        'authToastNotifications',
                severity:   'error',
                summary:    'LigaMX Registro',
                detail:     'Debe aceptar los términos y condiciones del sitio.'
            });

            return;
        }

        this.loadingSignUpAction = true;

        this.subscriptions.sink = this.registrationService.signUp(registration).subscribe({
            next: (registeredUser) => {

                this.loadingSignUpAction = false;
                this.handleSuccessfulAuthentication(registeredUser);
            },
            complete: () => {

                this.loadingSignUpAction = false;
            }
        });
    }

    public onPasswordRecovery(): void {

        this.modalService.passwordRecoveryDialog();
    }

    public showAuthProviderOptions(): void {

        this.openSignInForm = false;
        this.openSignUpForm = false;
        this.showRecentSignInActivityBar = true;
        this.showAuthProviderOptionsBar = true;
        this.showNotAccountYetBar = true;
    }

    public showLoginForm(): void {

        this.openSignInForm = true;
        this.openSignUpForm = false;
        this.showRecentSignInActivityBar = true;
        this.showAuthProviderOptionsBar = false;
        this.showNotAccountYetBar = true;
    }

    public showSignUpForm(): void {

        this.openSignInForm = false;
        this.openSignUpForm = true;
        this.showRecentSignInActivityBar = false;
        this.showAuthProviderOptionsBar = false;
        this.showNotAccountYetBar = false;
    }

    private handleSuccessfulAuthentication(userAuthenticated: AuthUserAuthenticated): void {

        const {
            id,
            name,
            avatar,
            fan: { level:fanLevel, plate:fanPlate },
            teamPreference,
            auth: { token, expiresAt:tokenExpiration },
            isFirstTimeLoginAuthentication
        } = userAuthenticated;

        // Guardamos el estado en memoria para saber si es la primera vez que el usuario se autentifica.
        // OJO: Saber si un usuario se autentifica por vez primera ante el sistema o no solo tiene sentido 
        // cuando el usuario lo hace a través de login/password. Cuando el usuario lo hace a través de redes sociales
        // este caso de uso no tiene sentido, por lo que en este caso la propiedad isFirstTimeLoginAuthentication será false.
        // Este flag nos va a servir dentro del Guardia WelcomeFirstTimeUser para determinar si enviamos al usuario a la 
        // pantalla de bienvenida o a la ubicación que el usuario pretender ir.
        this.authService.isFirstTimeLoginAuthentication = isFirstTimeLoginAuthentication;

        // Construimos la información del Equipo.
        const teamId: number = teamPreference ? teamPreference.teamId : 0;
        const teamName: string = teamPreference ? teamPreference.teamName : '';
        const teamLogo: string = teamPreference ? teamPreference.teamLogo : '';

        // Volcamos la información del usuario en LocalStorage.
        this.authService.setAuthProfileInLocalStorage(
            name,
            id,
            avatar,
            fanLevel,
            fanPlate,
            teamId,
            teamName,
            teamLogo,
            token,
            tokenExpiration
        );

        // Redireccionamos a la url que contenga intendedUrl.
        this.router.navigateByUrl(this.intendedUrl);
    }
}
