<form [formGroup]="editLinkPostForm" (ngSubmit)="onEditLinkPost()" novalidate>
    <div class="input-group">
        <input type="text" class="description input-box-face input-box-white-text" placeholder="Escribe algo interesante..." formControlName="description" pInputText>

        <ng-container *ngIf="description?.invalid && (description?.dirty || description?.touched)">
            <span *ngIf="description?.hasError('required')" class="invalid-feedback-message"><small>Escribe algo interesante</small></span>
            <span *ngIf="description?.hasError('maxlength')" class="invalid-feedback-message"><small>El límite máximo permitido son 400 caracteres</small></span>
        </ng-container>
    </div>

    <div class="input-group">
        <input type="text" class="input-box-face input-box-white-text" placeholder="http://" formControlName="homeUrl" pInputText>

        <ng-container *ngIf="homeUrl?.invalid && (homeUrl?.dirty || homeUrl?.touched)">
            <span *ngIf="homeUrl?.hasError('required')" class="invalid-feedback-message"><small>Ingresa una Url</small></span>
        </ng-container>
    </div>

    <div class="controls">
        <button type="button" (click)="onEditLinkPostCancellation()">Cancelar <img src="assets/iconos/x_cancelar.svg" alt="Cancelar"></button>
        <button type="submit" [disabled]="editLinkPostLoading">
            <ng-container *ngIf="!editLinkPostLoading; else loading">
                Publicar <img src="assets/iconos/arrow_publica.svg" alt="Publicar">
            </ng-container>

            <ng-template #loading>
                <i class="pi pi-spin pi-spinner button-spinner"></i>
            </ng-template>
        </button>
    </div>
</form>
