import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

// Guards.
import { AuthenticateService } from '@app/auth/shared/guards/authenticate.service';
import { VerifyTeamPreferenceService } from '@app/auth/shared/guards/verify-team-preference.service';
import { VerifyPostModificationPermissionGuard } from './shared/guards/verify-post-modification-permission.guard';

// Pages.
import { CreatePostPageComponent } from './pages/create-post-page/create-post-page.component';
import { EditPostPageComponent } from './pages/edit-post-page/edit-post-page.component';

// Panels.
import { CreateLinkPostPanelComponent } from './components/create-post/create-link-post-panel/create-link-post-panel.component';
import { CreateImagePostPanelComponent } from './components/create-post/create-image-post-panel/create-image-post-panel.component';
import { CreateVideoPostPanelComponent } from './components/create-post/create-video-post-panel/create-video-post-panel.component';
import { CreateTextPostPanelComponent } from './components/create-post/create-text-post-panel/create-text-post-panel.component';
import { CreatePollingPostPanelComponent } from './components/create-post/create-polling-post-panel/create-polling-post-panel.component';

import { EditPostPanelComponent } from './components/edit-post/edit-post-panel/edit-post-panel.component';

const routes: Routes = [
  { 
    path: 'post/create',
    component: CreatePostPageComponent, 
    canActivate: [ AuthenticateService, VerifyTeamPreferenceService ],
    children: [
      { path: '', redirectTo: 'link-post', pathMatch: 'full' },
      { 
        path: 'link-post', 
        component: CreateLinkPostPanelComponent,
        canActivate: [ AuthenticateService, VerifyTeamPreferenceService] 
      },
      { 
        path: 'image-post', 
        component: CreateImagePostPanelComponent,
        canActivate: [ AuthenticateService, VerifyTeamPreferenceService] 
      },
      { path: 'video-post', 
        component: CreateVideoPostPanelComponent,
        canActivate: [ AuthenticateService, VerifyTeamPreferenceService] 
      },
      { 
        path: 'text-post', 
        component: CreateTextPostPanelComponent,
        canActivate: [ AuthenticateService, VerifyTeamPreferenceService] 
      },
      { 
        path: 'polling-post', 
        component: CreatePollingPostPanelComponent,
        canActivate: [ AuthenticateService, VerifyTeamPreferenceService]
      }
    ]
  },
  { 
    path: 'post/edit/:id', 
    component: EditPostPageComponent, 
    canActivate: [ AuthenticateService, VerifyTeamPreferenceService, VerifyPostModificationPermissionGuard ],
    children: [
      { path: '', component: EditPostPanelComponent }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class PostManagerRoutingModule {}
