import { Injectable } from '@angular/core';

import { DialogService, DynamicDialogRef, DynamicDialogConfig } from 'primeng/dynamicdialog';

import { WarningConfirmationDialogComponent } from '../../components/warning-confirmation-dialog/warning-confirmation-dialog.component';
import { SuccessDialogComponent } from '../../components/success-dialog/success-dialog.component';
import { FailureDialogComponent } from '../../components/failure-dialog/failure-dialog.component';
import { PickATeamDialogComponent } from '../../components/pick-a-team-dialog/pick-a-team-dialog.component';
import { ChangeTeamPreferenceDialogComponent } from '../../components/change-team-preference-dialog/change-team-preference-dialog.component';
import { PasswordRecoveryComponent } from '../../components/password-recovery/password-recovery.component';
import { VideoProgressBarComponent } from '../../components/video-progress-bar/video-progress-bar.component';
import { AccountActivationDialogComponent } from '../../components/account-activation-dialog/account-activation-dialog.component';

import { PostVideo } from '../models/post-video/post-video';
import { PostVideoPublicationMode } from '../../shared/models/post-video/post-video-publication-mode';

const defaultDialogSettings:DynamicDialogConfig = {
    width: '40%',
    showHeader: false,
    closeOnEscape: false
}

@Injectable({
    providedIn: 'root'
})
export class ModalService {

    constructor(
        private dialogService: DialogService
    ) {}

    public successDialog(message: string): DynamicDialogRef {

        return this.dialogService.open(SuccessDialogComponent, {
            ...defaultDialogSettings,
            data: {
                dialogMessage: message
            },
            width: '300px'
        });
    }

    public failureDialog(message: string): DynamicDialogRef {

        return this.dialogService.open(FailureDialogComponent, {
            ...defaultDialogSettings,
            data: {
                dialogMessage: message
            },
            width: '300px'
        });
    }

    public warningConfirmationDialog(message: string): DynamicDialogRef {

        return this.dialogService.open(WarningConfirmationDialogComponent, {
             ...defaultDialogSettings,
             data: {
                 dialogMessage: message
             }
        });
    }

    public pickATeamDialog(currentTeamId?: number): DynamicDialogRef {

        return this.dialogService.open(PickATeamDialogComponent, {
            ...defaultDialogSettings,
            width: '70%',
            styleClass: 'team-select',
            data: {
                currentTeamId
            }
       });
    }

    public changeTeamPreferenceDialog(currentTeamId?: number): DynamicDialogRef {

        return this.dialogService.open(ChangeTeamPreferenceDialogComponent, {
            ...defaultDialogSettings,
            width: '50%',
            styleClass: 'team-select',
            data: {
                currentTeamId
            }
        });
    }

    public passwordRecoveryDialog(): void {

        this.dialogService.open(PasswordRecoveryComponent, {
            ...defaultDialogSettings,
            width: '450px',
            styleClass: 'password-reset'
        });
    }

    public publishPostVideoDialog(postPublicationMode: PostVideoPublicationMode, postVideo: Partial<PostVideo>): DynamicDialogRef {

        return this.dialogService.open(VideoProgressBarComponent, {
            ...defaultDialogSettings,
            width: '450px',
            data: {
                postPublicationMode,
                postVideo,
            }
       });
    }

    public activateAccountDialog(accessToken: string, tokenExpiration: number): DynamicDialogRef {
        
        return this.dialogService.open(AccountActivationDialogComponent, {
            ...defaultDialogSettings,
            width: '450px',
            data: {
                accessToken,
                tokenExpiration
            }
       });
    }
}
