<form [formGroup]="createImagePostForm" (ngSubmit)="onCreateImagePost()" novalidate>
    <div class="input-group">
        <input type="text" class="description input-box-face input-box-white-text" placeholder="Escribe algo interesante..." formControlName="description" pInputText>

        <ng-container *ngIf="description?.invalid && (description?.dirty || description?.touched)">
            <span *ngIf="description?.hasError('required')" class="invalid-feedback-message"><small>Escribe algo interesante</small></span>
            <span *ngIf="description?.hasError('maxlength')" class="invalid-feedback-message"><small>El límite máximo permitido son 400 caracteres</small></span>
        </ng-container>
    </div>

    <div class="input-group">
        <app-single-base64image-uploader-field
            accept="image/png,image/jpeg"
            [maxFileSize]="3072"
            formControlName="cover"></app-single-base64image-uploader-field>

        <ng-container *ngIf="cover?.invalid && (cover?.dirty || cover?.touched)">
            <span *ngIf="cover?.hasError('fileRequired')" class="invalid-feedback-message"><small>Eliga una imagen para su Publicación</small></span>
            <span *ngIf="cover?.hasError('invalidFileSize')" class="invalid-feedback-message"><small>No pudimos cargar su imagen, el tamaño máximo permitido es de hasta 3 MB.</small></span>
            <span *ngIf="cover?.hasError('invalidMimeType')" class="invalid-feedback-message"><small>No pudimos cargar su imagen, solo se aceptan formatos jpg, jpeg y png.</small></span>
            <span *ngIf="cover?.hasError('unsuccessfulEncoding')" class="invalid-feedback-message"><small>No pudimos cargar su imagen, inténtelo nuevamente.</small></span>
            <span *ngIf="cover?.hasError('unSelectedFile')" class="invalid-feedback-message"><small>No se seleccionó ninguna imagen.</small></span>
        </ng-container>
    </div>

    <div class="controls">
        <button type="button" (click)="onCreateImagePostCancellation()">Cancelar <img src="assets/iconos/x_cancelar.svg" alt="Cancelar"></button>
        <button type="submit" [disabled]="createImagePostLoading">
            <ng-container *ngIf="!createImagePostLoading; else loading">
                Publicar <img src="assets/iconos/arrow_publica.svg" alt="Publicar">
            </ng-container>

            <ng-template #loading>
                <i class="pi pi-spin pi-spinner button-spinner"></i>
            </ng-template>
        </button>
    </div>
</form>
