import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'extractHostDomain'
})
export class ExtractHostDomainPipe implements PipeTransform {

  transform(fqdn: string): string {

    return this.extractHostName(fqdn);
  }

  private extractHostName(url: string): string {
    const domain = (new URL(url));
    return decodeURI(domain.hostname);
  }
}
