import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AuthenticateService } from '@app/auth/shared/guards/authenticate.service';
import { VerifyTeamPreferenceService } from '@app/auth/shared/guards/verify-team-preference.service';

import { CreateTopicPageComponent } from './pages/create-topic-page/create-topic-page.component';
import { EditTopicPageComponent } from './pages/edit-topic-page/edit-topic-page.component';

const routes: Routes = [
  { path: 'topic/create', component: CreateTopicPageComponent, canActivate: [ AuthenticateService, VerifyTeamPreferenceService ] },
  { path: 'topic/edit/:id', component: EditTopicPageComponent, canActivate: [ AuthenticateService ] }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class TopicManagerRoutingModule { }
