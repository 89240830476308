<div class="topic post">
  <div class="img">
    <img src="{{cover}}" alt="Topic">
  </div>
  <div class="content">
    <div class="title">
      <a [routerLink]="['/community/topic', id]">
        <h3>
          <img
            [src]="isOfficial ? 'assets/iconos/icono_topicofcial.svg': 'assets/iconos/icono_fanmade.svg'"
            [alt]="isOfficial ? 'Official' : 'Fan'">
          {{name}}</h3>
      </a>

      <p-menu appendTo="body" #menu [popup]="true" (onShow)="setMenuTopic(id)" [model]="menuTopic"></p-menu>
      <button class="more" type="button" (click)="menu.toggle($event)">⋮</button>
    </div>
    <p class="gray">Creado: {{date | date}}</p>
    <p class="white">{{description}}</p>
  </div>
  <div class="bottom">
    <div class="icon-text">
      {{ totalFollowers | rateTotalizer }} <img src="assets/iconos/icono_usuarios.svg" alt="Usuarios">
    </div>
    <button class="icon-text share">
      <img src="assets/iconos/icono_compartir.svg" alt="Compartir">Compartir</button>
  </div>
</div>
<p-toast position="top-left"></p-toast>
