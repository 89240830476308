    import { Injectable } from '@angular/core';
    import { HttpClient } from '@angular/common/http';

    import { Observable, forkJoin, Subject } from 'rxjs';
    import { map, tap, switchMap  } from 'rxjs/operators';

    import { AuthProfileService } from '@app/auth/shared/services/auth-profile.service';
    import { PaginatorSearchResultsPostMappingsService } from './paginator-search-results-post-mappings.service';

    import { PaginatorPostsResponse } from '../../shared/models/responses/paginator-posts-response';
    import { PaginatorItemPost } from '../../shared/models/paginator-item-post';
    import { SearchCriteria } from '../../shared/models/search-criteria';
    import { SearchPayload } from '../models/search-payload';

    import { environment } from '@env/environment';

    @Injectable()
    export class PaginatorSearchResultsPostsService {
        
        private paginationSubject: Subject<SearchCriteria> = new Subject<SearchCriteria>();
        public listenPagination$: Observable<PaginatorItemPost[]> = this.paginationSubject
                                                                        .asObservable()
                                                                        .pipe(switchMap((searchCriteria) => this.search(searchCriteria)));

        // Elementos para poder llevar a cabo la Paginación.
        private nextPage: number = 1;
        private lastPage: number = 0;

        constructor(
            private readonly httpClient: HttpClient,
            private readonly authProfile: AuthProfileService,
            private readonly paginatorSearchResultsPostMappings: PaginatorSearchResultsPostMappingsService
        ) { }

        public moveToNextPage(searchCriteria: SearchCriteria): void {

            this.paginationSubject.next(searchCriteria);
        }

        public hasReachedPaginationEnding(): boolean {

            return (this.nextPage > this.lastPage);
        }

        public resetPaginator(): void {

            this.nextPage = 1;
            this.lastPage = 0;
        }

        public search(searchCriteria: SearchCriteria): Observable<PaginatorItemPost[]> {

            const searchPayload: SearchPayload = {
                page:       this.nextPage,
                q:          searchCriteria.searchFor,
                order:      searchCriteria.sortDirection,
                orderBy:    'date'
            };

            return forkJoin({
                userId:        this.authProfile.getAuthId(),
                postsResponse: this.httpClient.post<PaginatorPostsResponse>(`${ environment.host }/api/search/posts`, { ...searchPayload })
            }).pipe(
                tap(({ postsResponse }) => {

                    const { content } = postsResponse;
                    const { current_page, last_page } = content;

                    this.nextPage = current_page + 1;
                    this.lastPage = last_page;
                }),
                map(({ userId, postsResponse }) => {

                    const { content } = postsResponse;
                    const { data:posts } = content;

                    return this.paginatorSearchResultsPostMappings.getPaginatorItemsPost(posts, userId);
                })
            );
        }
    }
