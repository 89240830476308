import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable, forkJoin, Subject } from 'rxjs';
import { map, tap, switchMap  } from 'rxjs/operators';

import { AuthProfileService } from '@app/auth/shared/services/auth-profile.service';
import { PaginatorSearchResultsTopicMappingsService } from './paginator-search-results-topic-mappings.service';

import { PaginatorTopicsResponse } from '../../shared/models/responses/paginator-topics-response';
import { PaginatorItemTopic } from '../../shared/models/paginator-item-topic';
import { SearchCriteria } from '../../shared/models/search-criteria';
import { SearchPayload } from '../models/search-payload';

import { environment } from '@env/environment';

@Injectable({
    providedIn: 'root'
})
export class PaginatorSearchResultsTopicsService {

    private paginationSubject: Subject<SearchCriteria> = new Subject<SearchCriteria>();
    public listenPagination$: Observable<PaginatorItemTopic[]> = this.paginationSubject
                                                                    .asObservable()
                                                                    .pipe(switchMap((searchCriteria) => this.search(searchCriteria)));
    // Elementos para poder llevar a cabo la Paginación.
    private nextPage: number = 1;
    private lastPage: number = 0;
    
    constructor(
        private readonly httpClient: HttpClient,
        private readonly authProfile: AuthProfileService,
        private readonly paginatorSearchResultsTopicMappings: PaginatorSearchResultsTopicMappingsService
    ) { }

    public moveToNextPage(searchCriteria: SearchCriteria): void {

        this.paginationSubject.next(searchCriteria);
    }

    public hasReachedPaginationEnding(): boolean {

        return (this.nextPage > this.lastPage);
    }

    public resetPaginator(): void {

        this.nextPage = 1;
        this.lastPage = 0;
    }

    public search(searchCriteria: SearchCriteria): Observable<PaginatorItemTopic[]> {

        const searchPayload: SearchPayload = {
            page:       this.nextPage,
            q:          searchCriteria.searchFor,
            order:      searchCriteria.sortDirection,
            orderBy:    'members'
        };

        return forkJoin({
            userId:         this.authProfile.getAuthId(),
            topicsResponse: this.httpClient.post<PaginatorTopicsResponse>(`${ environment.host }/api/search/topics`, { ...searchPayload })
        }).pipe(
            tap(({ topicsResponse }) => {

                const { content } = topicsResponse;
                const { current_page, last_page } = content;

                this.nextPage = current_page + 1;
                this.lastPage = last_page;
            }),
            map(({ userId, topicsResponse }) => {

                const { content } = topicsResponse;
                const { data:topics } = content;

                return this.paginatorSearchResultsTopicMappings.getPaginatorItemsTopic(topics, userId);
            })
        );
    }
}
