import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { LinkPreviewComponent } from './link-preview/link-preview.component';
import { ExtractHostnamePipe } from './pipes/extract-hostname.pipe';
import {MediaSourceModule} from "@app/ui/media-source/media-source.module";

@NgModule({
  declarations: [LinkPreviewComponent, ExtractHostnamePipe],
    imports: [
        CommonModule,
        MediaSourceModule
    ],
  exports: [
    LinkPreviewComponent
  ]
})
export class LinkPreviewModule { }
