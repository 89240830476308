<div class="item post on-team community-min">
  <img src="{{cover}}" alt="Comunidad">
    <div class="left">
      <a [routerLink]="['/community/topic/', id]">
        <img
            [src]="isOfficial ? 'assets/iconos/icono_topicofcial.svg': 'assets/iconos/icono_fanmade.svg'"
            [alt]="isOfficial ? 'Official' : 'Fan'">{{ title }}
      </a>
        <p>
            <span>{{ topicTotalFollowers | rateTotalizer }} <img src="assets/iconos/icono_joinedfans.svg" alt="Fans"></span>
            <span><img src="assets/iconos/icono_postsintopic.svg" alt="Posts">{{ totalPublishedPosts | rateTotalizer }}</span>
        </p>
    </div>

    <div class="right">
        <app-button-call-to-action
            [suscriptionLoading]="loadingTopicSubscriptionAction"
            [topicOwned]="topicOwnership"
            [topicJoined]="followingTopic"
            (onEdit)="onEditTopic()"
            (onJoin)="onJoinTopic()"
            (onDisjoin)="onUnJoinTopic()"></app-button-call-to-action>
    </div>
</div>
