import { Injectable } from '@angular/core';

import { PostVideo } from '../../models/post-video/post-video';

@Injectable()
export class PostVideoMappingService {

    constructor() { }

    public mapVideoPostPayloadForCreation(postVideo: Partial<PostVideo>): FormData {

        const payload: FormData = new FormData();
        const { description, video, postType, topicId } = postVideo;

        // Al crear una Publicación de Video, puede haber o no un Topic asociado. Cuando no viene un Topic asociado, 
        // la publicación se asocia con el Topic General con identificador 24.
        if ( topicId ) {

            payload.append('topic_id', JSON.stringify(topicId));
        }

        // Tipo de Publicación.
        payload.append('type_post_id', JSON.stringify(postType));

        // Descripción de la Publicación.
        payload.append('description', (description ? description.trim() : ''));

        // El archivo de video.
        payload.append('video', video?.content as File);

        return payload;
    }

    public mapVideoPostPayloadForUpdate(postVideo: Partial<PostVideo>): FormData {

        const payload: FormData = new FormData();
        const { id, description, video, topicId } = postVideo;

        // Al actualizar una Publicación de Video, OBLIGATORIAMENTE debe existir id asociado para la Publicación.
        payload.append('id', JSON.stringify(id));

        // Al actualizar una Publicación de Video, OBLIGATORIAMENTE debe venir un Topic asociado.
        payload.append('topic_id', JSON.stringify(topicId));
        
        // Descripción de la Publicación.
        payload.append('description', (description ? description.trim() : ''));       
       
        // Con respecto al video como recurso, al actualizar una Publicación de Video: 
        // 1. Si el usuario no carga un nuevo video, el recurso que representa al video será una cadena de texto. 
        // 2. Si el usuario carga un nuevo video, el recurso que representa al video será una Objeto de tipo File.
        // En cualquier caso, también podemos deteminar esta estado a través de la propiedad isLoadedLocally del objeto video, 
        // que indica si el Video ha sido cargado de forma local o es una URL remota.
        if ( video && (video.content instanceof File) ) {
            
            payload.append('video', video.content);
        }

        // Cuando actualizamos una Publicación de Video el Servicio pide el siguiente parámetro.
        payload.append('_method', 'put');

        return payload;
    }
}
