import { Component, OnInit, Input, forwardRef } from '@angular/core';

import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';

import { ImageBase64ConverterService } from '../shared/services/image-base64-converter.service';
import { Image } from '../shared/models/image';

@Component({
    selector: 'app-single-base64image-uploader-field',
    templateUrl: './single-base64image-uploader-field.component.html',
    styleUrls: ['./single-base64image-uploader-field.component.scss'],
    providers: [
        ImageBase64ConverterService,
        {   provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef( () => SingleBase64imageUploaderFieldComponent ),
            multi: true
        }
    ]
})
export class SingleBase64imageUploaderFieldComponent implements OnInit, ControlValueAccessor {

    @Input() accept: string = '';
    @Input() maxFileSize: number = 0;
    @Input() textDescription = '';
    public image: string = '';
    public imageSelected: boolean = false;
    public encodingErrorMessage: string = '';

    private onChanged: any = () => {};
    private onTouched: any = () => {};

    constructor( private imageBase64ConverterService: ImageBase64ConverterService ) { }

    ngOnInit(): void {

        // Establece las restricciones con lass cuales el servicio va a trabajar.
        this.imageBase64ConverterService.validMaxFileSize = this.maxFileSize;
        this.imageBase64ConverterService.allowedMimeTypes = this.accept;
    }

    public writeValue(val: Image | null): void {

        if ( !val ) {

            return;
        }

        const { image } = val;

        this.image = image ? image : '';
        this.imageSelected = true;
    }

    public registerOnChange(fn: any): void {

        this.onChanged = fn;
    }

    public registerOnTouched(fn: any): void {

        this.onTouched = fn;
    }

    public onDragOver($event: DragEvent): void {

        $event.stopPropagation();
        $event.preventDefault();
    }

    public onFileSelected($event: any): void {

        $event.preventDefault();

        // Obten el archivo de imagen a partir del evento de arrastrar o si ha sido seleccionado a través del input file.
        const file: File | null  = this.imageBase64ConverterService.getImageInfoFrom($event.dataTransfer || $event.target);

        if ( !file ) {

            this.onTouched();
            this.onChanged({
                name:       null,
                image:      null,
                isEncoded:  false,
                type:       null,
                size:       null,
                errorCode:  'unselected_file'
            } as Image);

            return;
        }

        const { name, type, size } = file;

        // Codifica la imagen a base64.
        this.imageBase64ConverterService.encodeBase64(file)
            .then((encodedImage) => {

                this.image = encodedImage;
                this.imageSelected = true;

                this.onTouched();
                this.onChanged({
                    name,
                    image:      encodedImage,
                    isEncoded:  true,
                    type,
                    size
                } as Image);
            })
            .catch( (err) => {

                this.image = '';
                this.imageSelected = false;

                this.onTouched();
                this.onChanged({
                    name:       null,
                    image:      null,
                    isEncoded:  false,
                    type:       null,
                    size:       null,
                    errorCode:  err.code
                } as Image);
            });
    }

    public onImageCleared(): void {

        this.image = '';
        this.imageSelected = false;

        this.onTouched();
        this.onChanged(null);
    }
}
