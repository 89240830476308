<span *ngIf="parent" [formGroup]="parent">
<p-editor [placeholder]="placeholder"
          [(ngModel)]="model"
          [formControlName]="controlName"
          [style]="{'height':'250px'}" styleClass="liga-editor" [modules]="{ 'emoji-toolbar': true }">
  <p-header>
                  <span class="ql-formats">
                      <button class="ql-bold" aria-label="Bold"></button>
                      <button class="ql-italic" aria-label="Italic"></button>
                      <button class="ql-underline" aria-label="Underline"></button>
                      <button class="ql-emoji" aria-label="Emoji"></button>
                      <button class="ql-link" aria-label="Link"></button>
                  </span>
  </p-header>
</p-editor>
</span>
