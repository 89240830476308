import { Component, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';

import { SubSink } from 'subsink';

import { PostCommunicatorService } from '../../../shared/services/post-communicator.service';
import { PostAction, PostActionType } from '../../../shared/models/post-action';
import { PostImage } from '../../../shared/models/post-image';

@Component({
    selector: 'app-create-image-post-panel',
    templateUrl: './create-image-post-panel.component.html',
    styleUrls: ['./create-image-post-panel.component.scss']
})
export class CreateImagePostPanelComponent implements OnDestroy {
    
    private subscriptions = new SubSink();

    public loadingImagePostCreationAction: boolean = false;

    constructor(
        private readonly router: Router,
        private readonly postCommunicator: PostCommunicatorService
    ) {

        // Escuchamos cual es la respuesta producto de la intención del usuario de crear un Post de Imagen.
        this.subscriptions.sink = postCommunicator.publishedStatus$.subscribe((postStatus) => {

            this.loadingImagePostCreationAction = false;
        });
    }

    ngOnDestroy(): void {

        this.subscriptions.unsubscribe();
    }

    public onCreateImagePost(post: Partial<PostImage>): void {

        this.loadingImagePostCreationAction = true;
        
        // Comunicamos nuestra intención de publicar el siguiente Post.
        this.postCommunicator.dispatchIntentionToPublish({
            // Nuestra intencción es crear una Publicación de Imagen.
            action: PostActionType.CreateImagePost,
            payload: post
        } as PostAction);
    }

    public onCancelPostCreation(): void {

        this.router.navigateByUrl('/community');
    }
}
