import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {Webview, WebviewsResponse, WebviewMenuResponse, WebviewMenu} from '../modules/webview';
import {environment} from '@env/environment';
import {map} from 'rxjs/operators';
import {HttpClient, HttpParams} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class WebviewService {

  constructor(private httpClient: HttpClient) {
  }

  public getWebviewsByMenuId(id: number): Observable<Webview[]> {
    return this.httpClient.get<WebviewsResponse>(`${environment.host}/api/webviews/category/${id}`)
      .pipe(map((webviews) => {
        const {content} = webviews;
        return content.map((webview) => {
          const {id, name, cover, icon, url, description} = webview;
          return {
            id, name, cover, url, icon, description
          };
        });
      }));
  }

  public getWebviewMenuById(id: number): Observable<WebviewMenu> {
    return this.httpClient.get<WebviewMenuResponse>(`${environment.host}/api/webviews/categories/${id}`)
      .pipe(map((webviewMenuResponse) => {
        const {id, name, description, icon} = webviewMenuResponse.content;
        return {
          id, name, description, icon
        };
      }));
  }
}
