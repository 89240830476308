import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';

import { MenuItem } from 'primeng/api';

import { TopicCoverImageValidators } from '../../shared/validators/topic-cover-image-validators';
import { Topic } from '../../shared/models/topic';

@Component({
    selector: 'app-edit-topic-form',
    templateUrl: './edit-topic-form.component.html',
    styleUrls: ['./edit-topic-form.component.scss']
})
export class EditTopicFormComponent implements OnInit {

    @Input() currentTopic!: Topic;
    @Input() changeCurrentTopicLoading: boolean = false;
    @Input() removeCurrentTopicLoading: boolean = false;
    @Output() update: EventEmitter<Topic> = new EventEmitter<Topic>();
    @Output() remove: EventEmitter<number> = new EventEmitter<number>();
    @Output() cancel: EventEmitter<void> = new EventEmitter<void>();

    public editTopicForm!: FormGroup;
    public topicMenuOptions!: MenuItem[];

    private topicId: number | undefined = 0;

    constructor(private fb: FormBuilder) { }

    ngOnInit(): void {
        
        this.loadEditTopicForm();
        this.loadTopicMenuOptions();
    }

    private loadEditTopicForm(): void {

        const { 
            id, title, description, teamPreference: { id:teamId, name:teamName }, cover: { image:cover } 
        } = this.currentTopic;

        // Mantenemos una referencia del id del Topic actual.
        this.topicId = id;

        // Llenamos el formulario con los datos del Topic actual.
        this.editTopicForm = this.fb.group({
            title:          [ title, [ Validators.required ] ],
            description:    [ description, [ Validators.required ] ],
            teamPreference: [ 
                { 
                    id:     teamId, 
                    name:   teamName 
                }, [ Validators.required ] 
            ],
            cover: [ 
                {
                    name:       null,
                    image:      cover,
                    isEncoded:  false,
                    type:       null,
                    size:       null, 
                }, [ TopicCoverImageValidators.mustBeValidImage ] 
            ]
        });
    }

    private loadTopicMenuOptions(): void {
        
        this.topicMenuOptions = [{
            items: [
                { 
                    label: 'Eliminar Topic', 
                    icon: 'pi pi-trash',
                    command: () => {

                        // Comunicamos que deseamos eliminar el Topic con el id actual.
                        this.remove.emit(this.topicId);
                    }
                }
            ]
        }];
    }

    public onUpdateTopic(): void {

        // En caso que el usuario de click al botón iniciar sin haber ingresado los datos que se le piden.
        this.editTopicForm.markAllAsTouched();
        
        // Una vez los datos del formulario sean correctos y tengamos una imagen seleccionada como cover
        // realizamos el envio.
        if ( this.editTopicForm.valid ) {

            const { title, description, cover, teamPreference } = this.editTopicForm.value;

            this.update.emit({ 
                id: this.topicId, // Obten la referencia del id actual del Topic.
                title, 
                description, 
                cover, 
                teamPreference } as Topic);
        }
    }

    public onUpdateTopicCancellation(): void {

        this.cancel.emit();
    }

    get title() {

        return this.editTopicForm.get('title');
    }

    get description() {

        return this.editTopicForm.get('description');
    }

    get teamPreference() {

        return this.editTopicForm.get('teamPreference');
    }

    get cover() {

        return this.editTopicForm.get('cover');
    }

}
