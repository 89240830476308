<div class="topic-select">
  <ng-container *ngIf="topicsLoaded; else loadingJoinedTopics">
    <p-dropdown
      styleClass="topics-list"
      [options]="topics"
      optionLabel="title"
      optionValue="id"
      [filter]="true"
      filterBy="title"
      filterPlaceholder="Buscar"
      [showClear]="true"
      placeholder="Elige un Topic"
      [(ngModel)]="topicSelectedId"
      (ngModelChange)="onChange($event)">

      <ng-template let-topic pTemplate="selectedItem">
        <app-topic-joined-selected-item
          [title]="topic.title"
          [cover]="topic.cover"
          [isOfficial]="topic.isOfficial"></app-topic-joined-selected-item>
      </ng-template>

      <ng-template let-topic pTemplate="item">
        <app-topic-joined-item
          [title]="topic.title"
          [cover]="topic.cover"
          [isOfficial]="topic.isOfficial"
          [totalFollowers]="topic.totalFollowers"></app-topic-joined-item>
      </ng-template>
    </p-dropdown>
  </ng-container>

  <ng-template #loadingJoinedTopics>
    <div style="text-align: center;">
      <i class="pi pi-spin pi-spinner spinner spinner-sm" style="margin-right: 5px;"></i>
      <span style="color: white;"><small>Cargando mis Topics...</small></span>
    </div>
  </ng-template>
</div>
