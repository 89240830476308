import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';

import { PostType } from '../../../shared/models/post-type';
import { PostImage } from '../../../shared/models/post-image';
import { PostImageCoverValidators } from '../../../shared/validators/post-image-cover-validators';
@Component({
    selector: 'app-create-image-post-form',
    templateUrl: './create-image-post-form.component.html',
    styleUrls: ['./create-image-post-form.component.scss']
})
export class CreateImagePostFormComponent implements OnInit {
    
    @Input() public createImagePostLoading: boolean = false;
    @Output() onCreate: EventEmitter<Partial<PostImage>> = new EventEmitter<Partial<PostImage>>();
    @Output() onCancel: EventEmitter<void> = new EventEmitter<void>();

    public createImagePostForm!: FormGroup;

    constructor(private fb: FormBuilder) { }
    
    ngOnInit(): void {

        this.loadCreateImagePostForm();
    }
    
    private loadCreateImagePostForm(): void {
        
        this.createImagePostForm = this.fb.group({
            description:    [ '', [ Validators.required, Validators.maxLength(400)] ],
            cover:          [ null, [ PostImageCoverValidators.mustBeValidImage ] ]
        });
    }   
    
    public onCreateImagePost(): void {

        // En caso que el usuario de click al botón iniciar sin haber ingresado los datos que se le piden.
        this.createImagePostForm.markAllAsTouched();

        // Una vez los datos del formulario sean correctos y tengamos una imagen seleccionada como cover
        // realizamos el envio.
        if ( this.createImagePostForm.valid ) {
            
            const postType: PostType = PostType.IMAGE;
            const { description, cover } = this.createImagePostForm.value;
            
            this.onCreate.emit({ description, cover, postType } as Partial<PostImage>);
        }
    }

    public onCreateImagePostCancellation(): void {

        this.onCancel.emit();
    }

    get description() {

        return this.createImagePostForm.get('description');
    }

    get cover() {

        return this.createImagePostForm.get('cover');
    }
}
