import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AuthenticateService } from '@app/auth/shared/guards/authenticate.service';

import { WebviewsPageComponent } from './pages/webviews-page/webviews-page.component';

const routes: Routes = [
    { path: 'webviews/:id', component: WebviewsPageComponent, canActivate: [AuthenticateService ] }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class WebviewsRoutingModule { }
