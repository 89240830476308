import { Component, OnInit, Input, Output, EventEmitter, forwardRef } from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';

import { ImageBase64ConverterService } from '../shared/services/image-base64-converter.service';
import { ProfileAvatar } from '../shared/models/profile-avatar';

@Component({
    selector: 'app-profile-avatar-field',
    templateUrl: './profile-avatar-field.component.html',
    styleUrls: ['./profile-avatar-field.component.scss'],
    providers: [ 
        ImageBase64ConverterService,
        {   provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef( () => ProfileAvatarFieldComponent ),
            multi: true
        }
    ]
})
export class ProfileAvatarFieldComponent implements OnInit, ControlValueAccessor {
    
    @Input() accept: string = '';
    @Input() maxFileSize: number = 0;

    public avatar: string = '';
    public defaultAvatarImage: string = 'assets/iconos/profile-avatar/default_avatar.png';
    public encodingErrorMessage: string = '';

    private onChanged:any = () => {};
    private onTouched:any = () => {};

    constructor( private imageBase64ConverterService: ImageBase64ConverterService ) { }

    ngOnInit(): void {

        // Establece las restricciones con lass cuales el servicio va a trabajar.
        this.imageBase64ConverterService.validMaxFileSize = this.maxFileSize;
        this.imageBase64ConverterService.allowedMimeTypes = this.accept;
    }

    public writeValue(val: ProfileAvatar): void {
        
        if ( val ) {

            const { image } = val;

            this.avatar = image ? image : this.defaultAvatarImage;
        }
    }    

    public registerOnChange(fn: any): void { 
        
        this.onChanged = fn; 
    }
    
    public registerOnTouched(fn: any):void {
        
        this.onTouched = fn;
    }

    public onFileSelected($event: any): void {
 
        $event.preventDefault();

        const file: File | null  = this.imageBase64ConverterService.getImageInfoFrom($event.dataTransfer || $event.target);

        if ( !file ) {

            return;
        }

        const { name, type, size } = file;

        // Codifica la imagen a base64.
        this.imageBase64ConverterService.encodeBase64(file)
            .then((encodedImage) => {

                this.avatar = encodedImage;

                this.onTouched();
                this.onChanged({
                    name,
                    image:      encodedImage,
                    isEncoded:  true,
                    type,
                    size
                } as ProfileAvatar);
            })
            .catch( (err) => {
                
                this.avatar = this.defaultAvatarImage;

                this.onTouched();
                this.onChanged({
                    name:       null,
                    image:      null,
                    isEncoded:  false,
                    type:       null,
                    size:       null, 
                    errorCode:  err.code 
                } as ProfileAvatar);
            });
    }
}
