import { Inject, Injectable, InjectionToken } from "@angular/core";
import { Observable, BehaviorSubject } from "rxjs";
import { Meta } from '@angular/platform-browser';
import { LayoutConfig } from '../models/layout-config';

export const LAYOUT_CONFIG_TOKEN = new InjectionToken("layoutConfigToken");

@Injectable({
    providedIn: 'root'
})
export class LayoutConfigService {
    private layoutConfigSubject: BehaviorSubject<LayoutConfig>;
    public layoutConfig$: Observable<LayoutConfig>;

    constructor( @Inject(LAYOUT_CONFIG_TOKEN) layoutConfig: LayoutConfig ) {

        this.layoutConfigSubject = new BehaviorSubject<LayoutConfig>(layoutConfig);
        this.layoutConfig$ = this.layoutConfigSubject.asObservable();
    }

    public setLayoutConfig( newLayoutConfigState: LayoutConfig ): void {

        const lastLayoutConfigState: LayoutConfig = this.layoutConfigSubject.getValue();

        this.layoutConfigSubject.next({ ...lastLayoutConfigState, ...newLayoutConfigState });
    }

    public setMetaTags(): void {

    }
}
