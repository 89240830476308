import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { RedirectWhenSharingTopicGuard } from './shared/guards/redirect-when-sharing-topic.guard';

import { TopicPreviewPageComponent } from './pages/topic-preview-page/topic-preview-page.component';

const routes: Routes = [
  { path: 'topic/preview/:id', component: TopicPreviewPageComponent, canActivate: [ RedirectWhenSharingTopicGuard ]}
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class TopicSharingRoutingModule { }
