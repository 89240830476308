import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';

import { Observable, throwError, of, EMPTY } from 'rxjs';
import { map, catchError } from 'rxjs/operators';

import { AuthRecoveryPasswordResponse } from '../models/auth-recovery-password-response';

import { environment } from '@env/environment';

@Injectable()
export class PasswordRecoveryService {

    constructor(private httpClient: HttpClient) { }

    public sendEmail(recipient: string): Observable<string> {

        recipient = recipient.trim();

        return this.httpClient.post<AuthRecoveryPasswordResponse>(`${ environment.host }/api/auth/password-recovery`, { email: recipient })
                .pipe(
                    map((authRecoveryPasswordResponse) => {

                        const { email:recipient } = authRecoveryPasswordResponse.content;

                        return recipient.email;
                    }),
                    catchError((error: HttpErrorResponse) => {

                        // Inspecciona la propiedad error de HttpErrorResponse para determinar el tipo de error
                        // y manejarlo acorde.
                        const httpError: any = error.error;
                        const httpErrorCode: number = error.status;
                        
                        if ( httpErrorCode === 401 ) {

                            return throwError(httpError.error);
                        } 

                        return throwError(error);
                    })
                );
    }
}
