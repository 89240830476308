<div class="filters-dropdown">
  <img src="assets/iconos/icon_ordenar.svg" alt="Ordenar">
  <span>Ordenar:</span>
<p-dropdown *ngIf="!noResultsWereFound"
    [options]="dropDownFilters"
    optionLabel="dropDownFilterLabel"
    optionValue="dropDownFilterDirection"
    [dropdownIcon]="selectedFilter === 'DESC' ? 'pi pi-arrow-up' : 'pi pi-arrow-down'"
    [(ngModel)]="selectedFilter"
    (ngModelChange)="onChangeFilter($event)"></p-dropdown>
</div>
<div *ngIf="initialLoadingDone; else showLoadingIndicator"
    class="search-topics mt-30"
    infiniteScroll
    [infiniteScrollDistance]="3"
    [infiniteScrollThrottle]="100"
    (scrolled)="onScroll()"
     [scrollWindow]="true">

    <ng-container *ngFor="let paginatorItemTopic of paginatorItemTopics">
        <ng-container
            [ngTemplateOutlet]="topicTemplate"
            [ngTemplateOutletContext]="{ $implicit: paginatorItemTopic }"></ng-container>
    </ng-container>

    <!-- Mensaje de que hemos llegado al final de los resultados -->
    <p *ngIf="noMoreResultsWereFound" style="color: white; text-align: center;">No hay más resultados</p>

    <!-- Mensaje de que no se encontraron resultados -->
    <p *ngIf="noResultsWereFound" style="color: white; text-align: center;">No se encontraron resultados</p>

    <!-- Este spinner es visible al final de la pantalla cuando se dispara el evento (scroll) -->
    <div style="text-align: center;" *ngIf="loadingMoreTopics">
        <i class="pi pi-spin pi-spinner spinner spinner-md"></i>
    </div>
</div>

<ng-template #topicTemplate let-topic>
    <div class="item mb-20">
      <app-topic-call-to-action
        [id]="topic.id"
        [title]="topic.title"
        [cover]="topic.cover"
        [isOfficial]="topic.isOfficial"
        [following]="topic.following"
        [ownership]="topic.ownership"
        [totalFollowers]="topic.totalFollowers"
        [totalPublishedPosts]="topic.totalPublishedPosts">
      </app-topic-call-to-action>
    </div>
</ng-template>

<ng-template #showLoadingIndicator>
    <!-- Este spinner es cuando se cargan inicialmente los Topics por vez primera. -->
    <div style="text-align: center;">
        <i class="pi pi-spin pi-spinner spinner spinner-lg"></i>
    </div>
</ng-template>
