import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { RedirectWhenSharingPostGuard } from './shared/guards/redirect-when-sharing-post.guard';

import { PostPreviewPageComponent } from './pages/post-preview-page/post-preview-page.component';

const routes: Routes = [
  { path: 'post/preview/:id', component: PostPreviewPageComponent, canActivate: [ RedirectWhenSharingPostGuard ]}
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class PostSharingRoutingModule { }
