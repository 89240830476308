import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';

import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';

import { AccountActivatedResponse } from '../../models/account-activation/account-activated-response';
import { UserAuthenticatedByActivationCode } from '../../models/account-activation/user-authenticated-by-activation-code';

import { environment } from '@env/environment';

@Injectable()
export class AccountActivationService {

    constructor(private readonly httpClient: HttpClient) { }

    public verify(activationCode: string, token: string, tokenExpiration: number): Observable<UserAuthenticatedByActivationCode> {

        activationCode = activationCode.trim();

        const headers = new HttpHeaders({ Authorization: `Bearer ${ token }` });

        return this.httpClient.post<AccountActivatedResponse>(`${ environment.host }/api/auth/verify/email`, { code: activationCode }, { headers })
            .pipe(
                map((accountActivatedResponse) => {
                    
                    const { content } = accountActivatedResponse;
                    const { id, name, picture, fanLevel, favoriteTeamDetail } = content;

                    const userAuthenticatedByActivationCode: UserAuthenticatedByActivationCode = {
                        accountDetail: {
                            id,
                            name,
                            picture,
                            fanLevel,
                            favoriteTeamDetail,
                            access_token:   token,
                            expires_at:     tokenExpiration
                        }
                    };

                    return userAuthenticatedByActivationCode;
                }),
                catchError((err: HttpErrorResponse) => {

                    // Inspecciona la propiedad error de HttpErrorResponse para determinar el tipo de error
                    // y manejarlo acorde.
                    const httpError: any = err.error;
                    const httpErrorCode: number = err.status;

                    if ( httpErrorCode === 401 ) {
                            
                        // Inspecciona el error a nivel de nuestro servidor.
                        if ( httpError.message && httpError.message === 'invalid_code' ) {


                            return throwError('Código inválido, intentelo nuevamente.');
                        }
                    }

                    return throwError('Ocurrió un error al activar su cuenta, intentelo nuevamente.');
                })
            );
    }

    public resendActivationCode(token: string): Observable<unknown> {

        const headers = new HttpHeaders({ Authorization: `Bearer ${ token }` });

        return this.httpClient.post(`${ environment.host }/api/auth/resend/verify/email`, {}, { headers })
            .pipe(
                catchError((err: HttpErrorResponse) => {

                    return throwError('Ocurrió un error enviar el código, intentelo nuevamente.');
                })
            );

    }
}
