<div class="scrolling-area"
     infiniteScroll
     [infiniteScrollDistance]="3"
     [infiniteScrollThrottle]="150"
     (scrolled)="loadMoreItems()"
     [scrollWindow]="false">
  <div class="main-grid">
    <section class="user-posts grid-left">
      <div class="back mb-40">
        <a [routerLink]="['/account']"><img src="assets/iconos/icono_arrowdown.svg" alt="Regresar">
          Regresar
        </a>
      </div>


      <div class="grid-left">
        <div class="select-info banner">
          <h4>Mis <b>Guardados</b></h4>
          <p>Ve todas las noticias y post que has guardado en tu cuenta de <b><i>Fans LigaMX</i></b></p>
          <img src="assets/futbolista.png" alt="Tus Guardados">
        </div>

        <section class="controls">
          <div class="buttons">
            <button (click)="setType('news')" class="{{actualType === 'news' ? 'active' : ''}}">Noticias</button>
            <button (click)="setType('posts')" class="{{actualType === 'posts' ? 'active' : ''}}">Publicaciones</button>
          </div>

          <div class="filters-dropdown">
            <img src="assets/iconos/icon_ordenar.svg" alt="Ordenar">
            <span>Ordenar:</span>
            <p-dropdown
              [options]="dropDownFilters"
              optionLabel="dropDownFilterLabel"
              optionValue="dropDownFilterDirection"
              [dropdownIcon]="selectedFilter === 'DESC' ? 'pi pi-arrow-up' : 'pi pi-arrow-down'"
              [(ngModel)]="selectedFilter"
              (ngModelChange)="onChangeFilter($event)"></p-dropdown>
          </div>

        </section>

        <div class="items" *ngIf="loaded; else loading">
          <ng-container *ngIf="actualType === 'posts'">

            <ng-container *ngIf="paginatorItemPosts.length > 0 && !loadingMorePosts; else empty">
              <ng-container *ngFor="let paginatorItemPost of paginatorItemPosts">
                <div class="item">
                  <ng-container
                    [ngTemplateOutlet]="postTemplate"
                    [ngTemplateOutletContext]="{ $implicit: paginatorItemPost }"></ng-container>
                </div>
              </ng-container>
              <ng-template #postTemplate let-post>
                <ng-container [ngSwitch]="true">
                  <!-- Post de Contenido Media -->
                  <ng-container *ngSwitchCase="post.type === 1 || post.type === 2 || post.type === 3 || post.type === 4"
                                [ngTemplateOutlet]="postMediaTemplate"
                                [ngTemplateOutletContext]="{ $implicit: post }"></ng-container>
                  <!-- Post de Votación -->
                  <ng-container *ngSwitchCase="post.type === 5"
                                [ngTemplateOutlet]="postPollingTemplate"
                                [ngTemplateOutletContext]="{ $implicit: post }"></ng-container>
                </ng-container>

                <!-- Template para la Publicación de Contenido Media. -->
                <ng-template #postMediaTemplate let-postMedia>
                  <app-post-media-expanded [post]="postMedia" [showTopicAsPostHeader]="true"
                                           (onBookmark)="onBookmarkPost($event)">
                    <!-- Aqui incluimos el contenido Media dependiendo del tipo de Post: texto, link, imagen o video -->

                    <!-- Es un Post de Link? -->
                    <!-- Link Preview content goes here -->
                    <app-link-preview
                      *ngIf="postMedia.type === 2"
                      [originalLinkSource]="postMedia.media.content.originalLinkSource"
                      [metaTags]="postMedia.media.content.metaTags"></app-link-preview>

                    <!-- Es un Post de Imagen? -->
                    <!-- Image content goes here -->
                    <img
                      *ngIf="postMedia.type === 3"
                      [src]="postMedia.media.content"
                      [alt]="postMedia.description">

                    <!-- Es un Post de Video? -->
                    <!-- Video content goes here -->
                    <video
                      *ngIf="postMedia.type === 4"
                      width="100%"
                      height="360px"
                      controls>
                      <source type="video/mp4" [src]="postMedia.media.content">
                    </video>

                    <!-- Es un Post de Texto? -->
                    <!-- Un Post de Texto no posee ningún tipo de contenido media -->
                  </app-post-media-expanded>
                </ng-template>

                <!-- Template para la Publicación de Votación. -->
                <ng-template #postPollingTemplate let-postPolling>
                  <!-- Es un Post de Votaciones? -->
                  <app-post-polling [post]="postPolling"></app-post-polling>
                </ng-template>

                <!-- Template para el Anuncio de tipo B que se va a insertar. -->
              </ng-template>
            </ng-container>

            <ng-template #empty>
              <div class="no-results">
                <img src="assets/fan.png" alt="Sin resultados">
                <p><b>¡Ups! Aún no guardas nada...</b></p>
                <p>Te invitamos a guardar los Posts que te gusten usando este icono</p>
                <img src="assets/iconos/icono_guardar.svg" alt="Guardar">
              </div>
            </ng-template>

            <div style="text-align: center; margin: 40px auto;" *ngIf="loadingMorePosts">
              <i class="pi pi-spin pi-spinner spinner spinner-md"></i>
            </div>


          </ng-container>

          <ng-container *ngIf="actualType === 'news'">
            <ng-container *ngIf="news.length > 0; else noNews">
              <app-followed-new class="item" *ngFor="let item of news"
                                [id]="item.id"
                                [title]="item.title"
                                [urlImage]="item.image"
                                [url]="item.url"
                                avatarDomain="{{item.crawler.favicon}}"
                                [hostDomain]="item.url"
                                [date]="item.date">
                >
              </app-followed-new>
              <ng-container *ngIf="isLoading">
                <div style="text-align: center;">
                  <i class="pi pi-spin pi-spinner spinner spinner-md"></i>
                </div>
              </ng-container>
            </ng-container>
            <ng-template #noNews>
              <div class="no-results">
                <img src="assets/fan.png" alt="Sin resultados">
                <p><b>¡Ups! Aún no guardas nada...</b></p>
                <p>Te invitamos a guardar las Noticias que te gusten usando este icono</p>
                <img src="assets/iconos/icono_guardar.svg" alt="Guardar">
              </div>
            </ng-template>
          </ng-container>
        </div>
        <ng-template #loading>
          <div style="text-align: center;">
            <i class="pi pi-spin pi-spinner spinner spinner-md"></i>
          </div>
        </ng-template>
      </div>


    </section>

    <div class="grid-right pt-100">
      <app-advertisement-type-c *ngIf="showAdTypeC" (loaded)="showAdTypeC = $event"></app-advertisement-type-c>
      <app-banner-community></app-banner-community>
    </div>
  </div>
</div>


<p-toast position="top-left"></p-toast>
