import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';

import { PostImage } from '../../../shared/models/post-image';
import { PostType } from '../../../shared/models/post-type';

import { PostImageCoverValidators } from '../../../shared/validators/post-image-cover-validators';

@Component({
    selector: 'app-edit-image-post-form',
    templateUrl: './edit-image-post-form.component.html',
    styleUrls: ['./edit-image-post-form.component.scss']
})
export class EditImagePostFormComponent implements OnInit {

    @Input() editImagePostLoading: boolean = false;  
    @Input() editablePostImage!: Partial<PostImage>;

    @Output() onUpdate: EventEmitter<Partial<PostImage>> = new EventEmitter<Partial<PostImage>>();
    @Output() onCancel: EventEmitter<void> = new EventEmitter<void>();

    public editImagePostForm!: FormGroup;

    constructor(private fb: FormBuilder) { }

    ngOnInit(): void {

        this.loadEditImagePostForm();
    }
    
    private loadEditImagePostForm(): void {
        
        const { description, cover } = this.editablePostImage;

        this.editImagePostForm = this.fb.group({
            description:    [ description, [ Validators.required, Validators.maxLength(400)] ],
            cover:          [ cover, [ PostImageCoverValidators.mustBeValidImage ] ]
        });
    }   

    public onEditImagePost(): void {

        // En caso que el usuario de click al botón iniciar sin haber ingresado los datos que se le piden.
        this.editImagePostForm.markAllAsTouched();

        // Una vez los datos del formulario sean correctos y tengamos una imagen seleccionada como cover
        // realizamos el envio.
        if ( this.editImagePostForm.valid ) {
            
            const postType: PostType = PostType.IMAGE;
            const { description, cover } = this.editImagePostForm.value;
            
            this.onUpdate.emit({ id: this.editablePostImage.id, description, cover, postType } as Partial<PostImage>);
        }
    }

    public onEditImagePostCancellation(): void {

        this.onCancel.emit();
    }

    get description() {

        return this.editImagePostForm.get('description');
    }

    get cover() {

        return this.editImagePostForm.get('cover');
    }
}
