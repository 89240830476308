import { Component, OnInit } from '@angular/core';

import { LayoutConfigService } from '@app/layout-config/shared/services/layout-config.service';

@Component({
    selector: 'app-profile-settings-page',
    templateUrl: './profile-settings-page.component.html',
    styleUrls: ['./profile-settings-page.component.scss']
})
export class ProfileSettingsPageComponent implements OnInit {

    constructor(
        private layoutConfigService: LayoutConfigService 
    ) {
        
        // Establecemos la configuración inicial de la barra de navegación y del footer.
        layoutConfigService.setLayoutConfig({
            layout: { 
                navbar: { visible: true },
                footer: { visible: false }
              }
        });
    }

    ngOnInit(): void { }
}
