import { Component, OnInit, OnDestroy, Output, EventEmitter } from '@angular/core';

import { SubSink } from 'subsink';

import { AdvertisementService } from '../../shared/services/advertisement.service';
import { AdvertisementType } from '../../shared/models/advertisement-type';
import { Advertisement } from '../../shared/models/advertisement';

@Component({
  selector: 'app-advertisement-type-c',
  templateUrl: './advertisement-type-c.component.html',
  styleUrls: ['./advertisement-type-c.component.scss'],
  providers: [ AdvertisementService ]
})
export class AdvertisementTypeCComponent implements OnInit, OnDestroy {

  @Output() loaded: EventEmitter<boolean> = new EventEmitter<boolean>();

  private subscriptions = new SubSink();

  public ad: Advertisement | null = null;
  public hasAdvertisementDoneLoading: boolean = false;
    
  constructor(
    private readonly advertisement: AdvertisementService
  ) { }

  ngOnInit(): void {
 
    this.hasAdvertisementDoneLoading = false;

    this.subscriptions.sink = this.advertisement.displayAd(AdvertisementType.C).subscribe((advertisement) => {

        this.hasAdvertisementDoneLoading = true;

        // Si no hay anuncio, comunica que no se debe mostrar.
        if ( !advertisement ) {

          this.loaded.emit(false);

          return;
        }

        this.ad = advertisement;

        // Hay anuncio, comunica que se debe mostar.
        this.loaded.emit(true);
    });   
  }

  ngOnDestroy(): void {

    this.subscriptions.unsubscribe();
  }

  public registerCPCActivity(): void {

    // Registramos actividad CPC cuando damos click al anuncio.
    // Tiene que haber un anuncio valido desplegado para realizar esta acción.
    if ( this.ad ) {

      this.subscriptions.sink = this.advertisement.clickOnAd(this.ad).subscribe();
    }

    // Enviamos al usuario a la página del Sponsor
    if ( window ) {

      window.open(this.ad?.sponsorHomePage, '_blank');
    }
  }
}
