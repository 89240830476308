import {Injectable} from '@angular/core';
import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {Observable, of, throwError} from 'rxjs';
import {environment} from '@env/environment';
import {catchError, map} from 'rxjs/operators';
import {DeleteTopicResponse} from '../models/topic-response';

@Injectable({
  providedIn: 'root'
})
export class ControlsTopicService {

  constructor(
    public httpClient: HttpClient,
  ) {
  }

  public remove(id: number): Observable<boolean> {
    console.log(`${environment.host}/api/topics/${id}`);
    return this.httpClient.delete<DeleteTopicResponse>(`${environment.host}/api/topics/${id}`)
      .pipe(
        map((response) => {
          console.log(response);
          return true;
        }),
      );
  }
}
