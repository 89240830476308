import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { SubSink } from 'subsink';

import { PostCommunicatorService } from '../../../shared/services/post-communicator.service';

import { PostAction, PostActionType } from '../../../shared/models/post-action';
import { PostLink } from '../../../shared/models/post-link';
import { PostText } from '../../../shared/models/post-text';
import { PostImage } from '../../../shared/models/post-image';
import { PostVideo } from '../../../shared/models/post-video';

@Component({
    selector: 'app-edit-post-panel',
    templateUrl: './edit-post-panel.component.html',
    styleUrls: ['./edit-post-panel.component.scss']
})
export class EditPostPanelComponent implements OnInit {
    
    private subscriptions = new SubSink();

    public postMedia!: PostLink | PostText | PostImage | PostVideo;
    public postMediaDetailsLoaded: boolean = false;

    // Loading cuando el usuario envia la intención de publicar.
    public loadingPostEditionAction: boolean = false;

    constructor(
        private readonly router: Router,
        private readonly postCommunicator: PostCommunicatorService
    ) {}

    ngOnInit(): void {

        // Escuchamos cual es la respuesta producto de la intención del usuario de crear un Post de Texto.
        this.subscriptions.sink = this.postCommunicator.publicationReceived$.subscribe((postMedia) => {

            this.postMediaDetailsLoaded = true;
            this.postMedia = postMedia;
        });

        // Escuchamos cual es la respuesta producto de la intención del usuario de crear un Post de Imagen.
        this.subscriptions.sink = this.postCommunicator.publishedStatus$.subscribe((postStatus) => {

            this.loadingPostEditionAction = false;
        });
    }

    public onUpdateLinkPost(post: Partial<PostLink>): void {

        this.loadingPostEditionAction = true;

        // Comunicamos nuestra intención de publicar el siguiente Post.
        this.postCommunicator.dispatchIntentionToPublish({
            // Nuestra intención es actualizar una Publicación de Link.
            action: PostActionType.UpdateLinkPost,
            payload: post
        } as PostAction);
    }

    public onUpdateTextPost(post: Partial<PostText>): void {

        this.loadingPostEditionAction = true;

        // Comunicamos nuestra intención de publicar el siguiente Post.
        this.postCommunicator.dispatchIntentionToPublish({
            // Nuestra intención es actualizar una Publicación de Texto.
            action: PostActionType.UpdateTextPost,
            payload: post
        } as PostAction);
    }
    
    public onUpdateImagePost(post: Partial<PostImage>): void {

        this.loadingPostEditionAction = true;
        
        // Comunicamos nuestra intención de publicar el siguiente Post.
        this.postCommunicator.dispatchIntentionToPublish({
            // Nuestra intención es actualizar una Publicación de Imagen.
            action: PostActionType.UpdateImagePost,
            payload: post
        } as PostAction);
    }

    public onUpdateVideoPost(post: Partial<PostVideo>): void {

        // Comunicamos nuestra intención de publicar el siguiente Post.
        this.postCommunicator.dispatchIntentionToPublish({
            // Nuestra intención es actualizar una Publicación de Video.
            action: PostActionType.UpdateVideoPost,
            payload: post
        } as PostAction);
    }

    public onCancelPostUpdate(): void {

        this.router.navigateByUrl('/community');
    }
}
