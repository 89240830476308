import {Component, Input, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {ControlsTopicService} from '../../shared/services/controls-topic.service';
import {MenuItem, MessageService} from 'primeng/api';

@Component({
  selector: 'app-topic-expanded',
  templateUrl: './topic-expanded.component.html',
  styleUrls: ['./topic-expanded.component.scss']
})
export class TopicExpandedComponent implements OnInit {
  @Input() name: string = '';
  @Input() cover: string = '';
  @Input() totalFollowers: number = 0;
  @Input() description: string = '';
  @Input() date: Date = new Date();
  @Input() id: number = 0;
  @Input() isOfficial = 0;

  public menuTopic: MenuItem[] = [];

  constructor(
    private readonly router: Router,
    private  controlsTopicService: ControlsTopicService,
    private messageService: MessageService,
  ) {
  }


  ngOnInit(): void {
  }

  setMenuTopic(id: number): void {
    this.menuTopic = [
      {
        label: 'Editar Topic',
        icon: 'custom-icon edit-icon',
        command: () => {
          this.router.navigate(['/topic/edit', id]);
        }
      },
      {
        label: 'Eliminar Topic',
        icon: 'custom-icon delete-icon',
        command: () => {
          /*this.confirmDelete(id);*/
          this.deleteTopic(id);
        }
      }
    ];
  }

  deleteTopic(id: number): void {
    this.controlsTopicService.remove(id).subscribe({
      next: (res) => {
        if (res) {
          this.messageService.add({
            severity: 'success',
            summary: 'Éxito',
            detail: 'Se eliminó este topic con éxito'
          });
          window.location.reload();
        } else {
          this.messageService.add({
            severity: 'error',
            summary: 'Error',
            detail: 'Ocurrió un error, intentelo más tarde'
          });
        }
      }
    });
  }

  /*  confirmDelete(id: number): void {
      this.confirmationService.confirm({
        message: '¿Seguro que desea eliminar este Topic permanentemente?',
        accept: () => {
          this.deleteTopic(id);
        }
      });
    }

    */
}

