import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { InfiniteScrollModule } from 'ngx-infinite-scroll';

import { TopicModule } from '@app/ui/topic/topic.module';
import { BannerCommunityModule } from '@app/ui/banner-community/banner-community.module';
import { BoardingTeamsModule } from '@app/ui/boarding-teams/boarding-teams.module';
import { TeamsRoutingModule } from './teams-routing.module';

import { TeamTopicsPageComponent } from './pages/team-topics-page/team-topics-page.component';
import { TeamDetailPageComponent } from './pages/team-detail-page/team-detail-page.component';
import {AdvertisementModule} from "@app/ui/advertisement/advertisement.module";

@NgModule({
  declarations: [
    TeamTopicsPageComponent,
    TeamDetailPageComponent
  ],
    imports: [
        CommonModule,
        InfiniteScrollModule,
        TopicModule,
        BannerCommunityModule,
        BoardingTeamsModule,
        TeamsRoutingModule,
        AdvertisementModule
    ]
})
export class TeamsModule { }
