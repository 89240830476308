<div class="post community">
  <!-- Muestra el banner de publicidad del Patrocionador/Dueño -->
  <ng-container *ngIf="hasAdvertisementDoneLoading">
    <div class="row">
      <h4>{{ ad?.title }}</h4>
    </div>

    <div class="row header">
      <p class="min">
        <svg width="15" height="12" viewBox="0 0 15 12" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g clip-path="url(#clip0)">
            <path
              d="M13.1841 0.326172H1.84727C1.06 0.326172 0.430176 0.94645 0.430176 1.7218V10.0956C0.430176 10.8709 1.06 11.4912 1.84727 11.4912H13.1841C13.9713 11.4912 14.6012 10.8709 14.6012 10.0956V1.7218C14.6012 0.94645 13.9713 0.326172 13.1841 0.326172ZM11.4835 6.88562C11.4835 7.36633 11.0899 7.75401 10.6175 7.75401C10.1294 7.75401 9.75153 7.36633 9.75153 6.88562V4.93174L5.04362 9.55282C4.69722 9.89397 4.14612 9.89397 3.79972 9.55282C3.45332 9.21166 3.45332 8.66892 3.79972 8.32776L8.49189 3.70669H6.53944C6.05133 3.70669 5.67344 3.31902 5.67344 2.8383C5.67344 2.35758 6.06708 1.96991 6.53944 1.96991H10.775C11.1686 1.96991 11.4835 2.28005 11.4835 2.66772V6.88562Z"
              fill="#555A5C"/>
          </g>
          <defs>
            <clipPath id="clip0">
              <rect width="14.171" height="11.165" fill="white" transform="translate(0.430176 0.326172)"/>
            </clipPath>
          </defs>
        </svg>
        Promocionado
      </p>
    </div>

    <div class="row media-content">
      <img
        class="advertisement-interaction"
        [src]="ad?.sponsorAdImage"
        alt="Sponsor Image"
        (click)="registerCPCActivity()">
    </div>

    <div class="row">
      <p>{{ ad?.description }}</p>
    </div>
  </ng-container>

  <!-- Este spinner es visible solamente cuando se esta cargando el Anuncio. -->
  <div class="loading" style="text-align: center;" *ngIf="!hasAdvertisementDoneLoading">
    <i class="pi pi-spin pi-spinner spinner spinner-sm"></i>
  </div>
</div>

