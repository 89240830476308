import { Component, OnInit, Input, forwardRef } from '@angular/core';

import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';

import { VideoConverterService } from '../shared/services/video-converter.service';
import { Video } from '../shared/models/video';


@Component({
    selector: 'app-single-video-uploader-field',
    templateUrl: './single-video-uploader-field.component.html',
    styleUrls: ['./single-video-uploader-field.component.scss'],
    providers: [ 
        VideoConverterService,
        {   provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef( () => SingleVideoUploaderFieldComponent ),
            multi: true
        }
    ]
})
export class SingleVideoUploaderFieldComponent implements OnInit, ControlValueAccessor {

    @Input() accept: string = '';
    @Input() maxFileSize: number = 0;

    public videoContent: File | string | null = null;
    public isVideoSelected: boolean = false;

    private onChanged: any = () => {};
    private onTouched: any = () => {};
    
    constructor( private videoConverter: VideoConverterService ) { }

    ngOnInit(): void {

        // Establece las restricciones con lass cuales el servicio va a trabajar.
        this.videoConverter.validMaxFileSize = this.maxFileSize;
        this.videoConverter.allowedMimeTypes = this.accept;
    }

    public writeValue(video: Video | null): void {

        if ( !video ) {

            return;
        }
 
        const { content } = video;

        this.isVideoSelected = true;
        this.videoContent = content;
    }

    public registerOnChange(fn: any): void { 
        
        this.onChanged = fn; 
    }
    
    public registerOnTouched(fn: any): void {
        
        this.onTouched = fn;
    }

    public onDragOver($event: DragEvent): void {
        
        $event.stopPropagation();
        $event.preventDefault();
    }

    public onFileSelected($event: any): void {
        
        $event.preventDefault();

        // Obten el archivo a partir del evento de arrastrar o si ha sido seleccionado a través del input file.
        const video: Video  = this.videoConverter.getVideo($event.dataTransfer || $event.target);

        if ( video.errorCode ) {

            this.onTouched();
            this.onChanged(video);
            
            return;
        } 

        const { content } = video;

        this.isVideoSelected = true;
        this.videoContent = content;

        this.onTouched();
        this.onChanged(video);
    }

    public onVideoContentCleared(): void {

        this.isVideoSelected = false;
        this.videoContent = null;

        this.onTouched();
        this.onChanged(null);
    }
}
