<div *ngIf="showInfoBanner" class="select-info banner">
  <h4>Selecciona tu <b>equipo</b> favorito</h4>
  <p>Tendrás una sección exclusivamente dedicada a tu equipo favorito.</p>
  <img src="assets/pacomemo.png" alt="Tu Equipo">
</div>

<app-team-panel *ngIf="teamsReady && !errorMessage">
  <app-team-group
    [isSelected]="femaleLeagueSelected"
    leagueName="Serouno Femenil"
    leagueImageSrc="assets/iconos/boarding-teams/femenil.png">

    <!--<p>Equipos de la Liga Femenil</p>-->
    <div class="team-parent" *ngFor="let team of femaleLeagueTeams">
      <div class="team" [class.team-selected]="currentTeamId === team.id"
           (click)="onTeamSelection(team)">

        <img [src]="team.logo" [alt]="team.name">
        <!--{{ team.id }} - {{ team.name }}-->
      </div>
    </div>
  </app-team-group>

  <app-team-group
    [isSelected]="maleLeagueSelected"
    leagueName="Serouno Varonil"
    leagueImageSrc="assets/iconos/boarding-teams/serouno.png">

    <!--<p>Equipos de la Liga Varonil</p>-->
    <div class="team-parent" *ngFor="let team of maleLeagueTeams">
      <div class="team" [class.team-selected]="currentTeamId === team.id"
           (click)="onTeamSelection(team)">

        <img [src]="team.logo" [alt]="team.name">
        <!--{{ team.id }} - {{ team.name }}-->
      </div>
    </div>
  </app-team-group>

  <app-team-group
    [isSelected]="expansionLeagueSelected"
    leagueName="Serouno Expansión"
    leagueImageSrc="assets/iconos/boarding-teams/expansion.png">

    <!--<p>Equipos de la Liga Expansión</p>-->
    <div class="team-parent" *ngFor="let team of expansionLeagueTeams">

      <div class="team" [class.team-selected]="currentTeamId === team.id"
           (click)="onTeamSelection(team)">

        <img [src]="team.logo" [alt]="team.name">
      </div>
      <!--{{ team.id }} - {{ team.name }}-->
    </div>
  </app-team-group>

  <button class="button-team" type="button" (click)="onTeamSelected()">Aceptar</button>
</app-team-panel>

<ng-container *ngIf="loading">
  <i class="pi pi-spin pi-spinner button-spinner"></i>
</ng-container>

<ng-container *ngIf="errorMessage">
  <span class="invalid-feedback-message"><small>{{ errorMessage }}</small></span>
</ng-container>
