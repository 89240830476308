import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';

import { Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';

import { TeamsResponse, LeagueResponse } from '../models/teams-response';
import { TeamsByLeague, Team } from '../models/teams';

import { environment } from '@env/environment';

@Injectable()
export class TeamsService {

    constructor( private httpClient:HttpClient ) { }

    public getAllTeamsByLeague():Observable<TeamsByLeague | null>{

        return this.httpClient.get<TeamsResponse>(`${ environment.host }/api/teams/league`)
            .pipe(
                map( (teamsResponse) => this.getLeagues(teamsResponse.content) ),
                tap( (teams) => console.log(teams) ),
                catchError( (err:HttpErrorResponse) => of(null))
            );
    }

    private getLeagues(leagues:LeagueResponse[]):TeamsByLeague {
        
        const teamsByLeague = leagues.map( (currentLeague) => this.getTeams(currentLeague) );
        const [ maleLeagueTeams, femaleLeagueTeams, expansionLeagueTeams ] = teamsByLeague;

        return {
            femaleLeagueTeams,
            maleLeagueTeams,
            expansionLeagueTeams
        };
    }

    private getTeams( league:LeagueResponse ):Team[] {

        const leagueName = league.name;
        const leagueTeams = league.teams;
        
        return leagueTeams.map( (team) => { 
            
            const { id, name, logo, league_id:leagueId } = team;

            return {
                id, 
                name, 
                logo, 
                league: leagueName, 
                leagueId
            };
        });
    }
}
