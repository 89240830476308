<div class="single-post-grid main-grid">
    <div class="grid-left">
        <a [routerLink]="['/community']" class="back">
            <img src="assets/iconos/icono_arrowdown.svg" alt="Regresar">Regresar
        </a>

        <div class="title">
            <h3>Crear Post</h3>
        </div>

        <div class="form">
            <app-topic-joined-type-ahead-list
                [topicId]="selectedTopicId"
                (onSelect)="onSelectTopic($event)"></app-topic-joined-type-ahead-list>

            <ul class="types">
                <li routerLinkActive="active">
                    <a [routerLink]="['./link-post']">Link</a>
                </li>

                <li routerLinkActive="active">
                    <a [routerLink]="['./image-post']">Imagen</a>
                </li>

                <li routerLinkActive="active">
                    <a [routerLink]="['./video-post']">Video</a>
                </li>

                <li routerLinkActive="active">
                    <a [routerLink]="['./text-post']">Texto</a>
                </li>

                <li routerLinkActive="active">
                    <a [routerLink]="['./polling-post']">Votación</a>
                </li>
            </ul>

            <router-outlet></router-outlet>
        </div>
    </div>
    
    <div class="grid-right pt-50">
        <app-recommended-topic-list></app-recommended-topic-list>
        <app-advertisement-type-c *ngIf="showAdTypeC" (loaded)="showAdTypeC = $event"></app-advertisement-type-c>
    </div>
</div>


