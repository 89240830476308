import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {CarouselModule} from 'primeng/carousel';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';

import { NewsRoutingModule } from './news-routing.module';
import { ExtractHostDomainPipe } from './pipes/extract-host-domain.pipe';
import { NewComponent } from './components/new/new.component';
import { NewSlideComponent } from './components/new-slide/new-slide.component';
import { NewsPageComponent } from './pages/news-page/news-page.component';
import { NewDetailPageComponent } from './pages/new-detail-page/new-detail-page.component';
import { NewsScrollerTestPageComponent } from './pages/news-scroller-test-page/news-scroller-test-page.component';
import { ScrollableToBeginningDirective } from './directives/scrollable-to-beginning.directive';
import {ToastModule} from 'primeng/toast';
import {AdvertisementModule} from "@app/ui/advertisement/advertisement.module";
import {BannerCommunityModule} from "@app/ui/banner-community/banner-community.module";
import {TopicModule} from "@app/ui/topic/topic.module";
import {MediaSourceModule} from "@app/ui/media-source/media-source.module";

@NgModule({
    declarations: [
        ExtractHostDomainPipe,
        NewComponent,
        NewSlideComponent,
        NewsPageComponent,
        NewDetailPageComponent,
        NewsScrollerTestPageComponent,
        ScrollableToBeginningDirective],
    exports: [
        ExtractHostDomainPipe
    ],
    imports: [
        CommonModule,
        InfiniteScrollModule,
        NewsRoutingModule,
        CarouselModule,
        ToastModule,
        AdvertisementModule,
        BannerCommunityModule,
        TopicModule,
        MediaSourceModule
    ]
})
export class NewsModule { }
