import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { PrivacyPolicyPageComponent } from './pages/privacy-policy-page/privacy-policy-page.component';
import { TermsAndConditionsPageComponent } from './pages/terms-and-conditions-page/terms-and-conditions-page.component';

const routes: Routes = [
  { path: 'compliance/privacy-policy', component: PrivacyPolicyPageComponent },
  { path: 'compliance/terms-and-conditions', component: TermsAndConditionsPageComponent }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ComplianceAndTransparencyRoutingModule { }
