import { Injectable } from '@angular/core';

import { PostMediaDetailsResponse } from '../models/post-response';
import { PostLinkPayload } from '../models/post-link-payload';
import { PostLink } from '../models/post-link';
import { PostType } from '../models/post-type';

@Injectable({
    providedIn: 'root'
})
export class PostLinkMappingService {

    constructor() { }

    public mapPostLinkPayload(postLink: Partial<PostLink>): Partial<PostLinkPayload> {

        const payload: Partial<PostLinkPayload> = {};
        const { id, description, homeUrl, postType, topicId }  = postLink;
        
        // En caso de que haya un id ya fijado para este Post, esto es común cuando estamos editando un Post.
        if ( id ) {

            payload.id = id;
        }

        // Un post puede tener o no tener un topic asociado.
        if ( topicId ) {

            payload.topic_id = topicId;
        }

        // Cuando estamos editando una Publicación de Link, NO debe venir el tipo de Publicación, este ya lo conoce 
        // la API de antemano a través del identificador del mismo.
        if ( postType ) {

            payload.type_post_id = postType;
        }
        
        payload.description = description?.trim();
        payload.url = homeUrl?.trim();

        return payload;
    }

    public mapPostLinkDetails(postMediaDetailsResponse: PostMediaDetailsResponse): PostLink {

        const { content } = postMediaDetailsResponse;
        const { id, description, url, topic } = content;

        return {
            id, 
            description,
            homeUrl:        url,
            postType:       PostType.LINK,
            topicId:        topic.id
        } as PostLink;
    }
}
