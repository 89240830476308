import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ProfileAvatarFieldComponent } from './profile-avatar-field/profile-avatar-field.component';

@NgModule({
    declarations: [ProfileAvatarFieldComponent],
    imports: [
        CommonModule
    ],
    exports: [
        ProfileAvatarFieldComponent
    ]
})
export class ProfileAvatarFieldModule { }
