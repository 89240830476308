<form [formGroup]="passwordRecoveryForm" (ngSubmit)="onPasswordRecovery()" novalidate autocomplete="off">
    <div class="input">
        <input type="email" placeholder="Ingresa tu correo electrónico" formControlName="email">

        <!-- Posibles Mensajes de error para el campo email -->
        <ng-container *ngIf="email?.invalid && (email?.dirty || email?.touched)">
            <span *ngIf="email?.hasError('required')" class="invalid-feedback-message">
                <small>Ingrese su Correo Electrónico.</small>
            </span>
            <span *ngIf="email?.hasError('email')" class="invalid-feedback-message">
                <small>Ingrese un Correo Electrónico válido.</small>
            </span>
            <span *ngIf="email?.hasError('maxlength')" class="invalid-feedback-message">
                <small>Pruebe con un correo electrónico más corto.</small>
            </span>
        </ng-container>
    </div>

    <button type="submit">
        <ng-container *ngIf="!recoverPasswordLoading; else loading">Enviar</ng-container>

        <ng-template #loading>
            <i class="pi pi-spin pi-spinner button-spinner"></i>
        </ng-template>
    </button>
</form>
