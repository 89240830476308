import { Injectable } from '@angular/core';
import {Observable} from 'rxjs';
import {environment} from '@env/environment';
import {map} from 'rxjs/operators';

import {PostMedia} from '@app/ui/post/shared/models/post-media';
import {PostPolling} from '@app/ui/post/shared/models/post-polling';
import {DetailPostResponse} from '../models/post-response';
import {HttpClient} from '@angular/common/http';
import {DetailPostMappingService} from '../services/detail-post-mapping.service';

@Injectable({
  providedIn: 'root'
})
export class PostService {

  constructor(
    private httpClient: HttpClient,
    private detailPostMappingService: DetailPostMappingService
  ) { }

  public getPostById(postId: number): Observable<PostMedia | PostPolling> {
    return this.httpClient.get<DetailPostResponse>(`${environment.host}/api/posts/${postId}/nosession`)
      .pipe(
        map((postResponse) => {
          const {content} = postResponse;
          return this.detailPostMappingService.mapPost(content);
        })
      );
  }


}
