import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { RecommendedTopicsResponse } from '../models/recommended-topics-response';
import { RecommendedTopic } from '../models/recommended-topic';

import { environment } from '@env/environment';

@Injectable()
export class RecommendedTopicsService {

    public topics$: Observable<RecommendedTopic[]> = this.httpClient.get<RecommendedTopicsResponse>(`${ environment.host }/api/topics/top5`)
        .pipe(
            map((recommendedTopicsResponse) => {

                const { content } = recommendedTopicsResponse;
                const { data:recommendedTopics } = content;

                return recommendedTopics.map((topic) => {

                    const { id, name:title, is_official, cover, totals } = topic;

                    return {
                        id,
                        title,
                        cover,
                        isOfficial: !!is_official,
                        totalFollowers: totals.followers,
                        totalPublishedPosts: totals.posts,
                    };
                });
            })
        );

    constructor( private httpClient: HttpClient ) { }
}
