<ng-container *ngIf="postMediaDetailsLoaded; else loadingCurrentPost">
    <ng-container [ngSwitch]="postMedia.postType">
        <ng-container *ngSwitchCase="1" 
            [ngTemplateOutlet]="editTextPostFormTemplate"
            [ngTemplateOutletContext]="{ $implicit: postMedia }">
        </ng-container>

        <ng-container *ngSwitchCase="2"
            [ngTemplateOutlet]="editLinkPostFormTemplate"
            [ngTemplateOutletContext]="{ $implicit: postMedia }">
        </ng-container>

        <ng-container *ngSwitchCase="3"
            [ngTemplateOutlet]="editImagePostFormTemplate"
            [ngTemplateOutletContext]="{ $implicit: postMedia }">
        </ng-container>

        <ng-container *ngSwitchCase="4"
            [ngTemplateOutlet]="editVideoPostFormTemplate"
            [ngTemplateOutletContext]="{ $implicit: postMedia }">
        </ng-container>
    </ng-container>
</ng-container>

<!-- Formularios de Publicaciones. -->
<ng-template #editTextPostFormTemplate let-postText>
    <app-edit-text-post-form 
        [editablePostText]="postText"
        [editTextPostLoading]="loadingPostEditionAction"  
        (onUpdate)="onUpdateTextPost($event)"
        (onCancel)="onCancelPostUpdate()">
    </app-edit-text-post-form> 
</ng-template>

<ng-template #editLinkPostFormTemplate let-postLink>
    <app-edit-link-post-form 
        [editablePostLink]="postLink"
        [editLinkPostLoading]="loadingPostEditionAction"  
        (onUpdate)="onUpdateLinkPost($event)"
        (onCancel)="onCancelPostUpdate()"></app-edit-link-post-form>
</ng-template>

<ng-template #editImagePostFormTemplate let-postImage>
    <app-edit-image-post-form 
        [editablePostImage]="postImage"
        [editImagePostLoading]="loadingPostEditionAction"  
        (onUpdate)="onUpdateImagePost($event)"
        (onCancel)="onCancelPostUpdate()">
    </app-edit-image-post-form>
</ng-template>

<ng-template #editVideoPostFormTemplate let-postVideo>
    <app-edit-video-post-form 
        [editablePostVideo]="postVideo"  
        (onUpdate)="onUpdateVideoPost($event)"
        (onCancel)="onCancelPostUpdate()">>
    </app-edit-video-post-form>
</ng-template>

<!-- Spinner -->
<ng-template #loadingCurrentPost>
    <div style="text-align: center;">
        <i class="pi pi-spin pi-spinner spinner spinner-lg"></i>
    </div>    
</ng-template>