import {Component, Input, OnInit} from '@angular/core';
// @ts-ignore
import * as Emoji from 'quill-emoji';
import {FormGroup} from '@angular/forms';

declare var Quill: any;

@Component({
  selector: 'app-text-editor',
  templateUrl: './text-editor.component.html',
  styleUrls: ['./text-editor.component.scss']
})
export class TextEditorComponent implements OnInit {

  constructor() {
    Quill.register('modules/emoji', Emoji);
  }

  @Input() model = '';
  @Input() placeholder = 'Mensaje...';
  @Input() controlName = '';
  @Input() parent?: FormGroup;

  ngOnInit(): void {
  }

}
