import { Component, Input, Output, EventEmitter, ChangeDetectionStrategy } from '@angular/core';

import { LikeStatus } from '../../../shared/models/post-media';

@Component({
    selector: 'app-dislike',
    templateUrl: './dislike.component.html',
    styleUrls: ['./dislike.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class DislikeComponent {
    
    @Input() dislike: LikeStatus = LikeStatus.Unsettled;
    @Input() totalDislikes: number = 0;
    @Output() onToggle: EventEmitter<LikeStatus> = new EventEmitter<LikeStatus>();

    constructor() { }

    public toggle(): void {

        this.onToggle.emit( (this.dislike === LikeStatus.Unsettled) ? LikeStatus.Disliked : LikeStatus.Unsettled );
    }
}
