<div
  *ngIf="initialLoadingDone; else showLoadingIndicator"
  class="scrolling-area"
  infiniteScroll
  [infiniteScrollDistance]="3"
  [infiniteScrollThrottle]="100"
  (scrolled)="onScroll()"
  [scrollWindow]="false">

  <div class="main-grid">

    <div class="grid-left">
      <app-tops-community></app-tops-community>

      <div class="create-post">
        <img class="img" src="{{userImg}}" alt="User">
        <a [href]="['/#/post/create/text-post']" class="text-post">
          <svg width="21" height="23" viewBox="0 0 21 23" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M10.2438 5.85352C10.417 5.85352 10.5832 5.92235 10.7057 6.04486C10.8282 6.16738 10.8971 6.33355 10.8971 6.50682V10.4267H14.8169C14.9902 10.4267 15.1563 10.4955 15.2789 10.618C15.4014 10.7405 15.4702 10.9067 15.4702 11.08C15.4702 11.2532 15.4014 11.4194 15.2789 11.5419C15.1563 11.6644 14.9902 11.7333 14.8169 11.7333H10.8971V15.6531C10.8971 15.8264 10.8282 15.9925 10.7057 16.115C10.5832 16.2376 10.417 16.3064 10.2438 16.3064C10.0705 16.3064 9.90434 16.2376 9.78182 16.115C9.6593 15.9925 9.59047 15.8264 9.59047 15.6531V11.7333H5.67064C5.49737 11.7333 5.3312 11.6644 5.20868 11.5419C5.08616 11.4194 5.01733 11.2532 5.01733 11.08C5.01733 10.9067 5.08616 10.7405 5.20868 10.618C5.3312 10.4955 5.49737 10.4267 5.67064 10.4267H9.59047V6.50682C9.59047 6.33355 9.6593 6.16738 9.78182 6.04486C9.90434 5.92235 10.0705 5.85352 10.2438 5.85352V5.85352Z"
                  fill="#29EB4F"/>
            <mask id="path-2-inside-1" fill="white">
              <rect width="20.9058" height="22.1601" rx="0.418115"/>
            </mask>
            <rect width="20.9058" height="22.1601" rx="0.418115" stroke="#29EB4F" stroke-width="2.50869"
                  mask="url(#path-2-inside-1)"/>
          </svg> Crear Post
        </a>
        <a [href]="['/#/post/create/link-post']" class="link-post img-post">
          <img src="assets/iconos/icono_postlink.svg" alt="Post Link">
        </a>
        <a [href]="['/#/post/create/image-post']" class="image-post img-post">
          <img src="assets/iconos/icono_postimg.svg" alt="Post Img">
        </a>
      </div>


      <ng-container *ngFor="let paginatorItemPost of paginatorItemPosts">
        <ng-container
          [ngTemplateOutlet]="postTemplate"
          [ngTemplateOutletContext]="{ $implicit: paginatorItemPost }"></ng-container>
      </ng-container>
      <ng-template #postTemplate let-post>
        <ng-container [ngSwitch]="true">
          <!-- Post de Contenido Media -->
          <ng-container *ngSwitchCase="post.type === 1 || post.type === 2 || post.type === 3 || post.type === 4"
                        [ngTemplateOutlet]="postMediaTemplate"
                        [ngTemplateOutletContext]="{ $implicit: post }"></ng-container>
          <!-- Post de Votación -->
          <ng-container *ngSwitchCase="post.type === 5"
                        [ngTemplateOutlet]="postPollingTemplate"
                        [ngTemplateOutletContext]="{ $implicit: post }"></ng-container>
          <!-- Anuncio de tipo B -->
          <ng-container *ngSwitchDefault
                        [ngTemplateOutlet]="advertisementTypeBTemplate"></ng-container>
        </ng-container>

        <!-- Template para la Publicación de Contenido Media. -->
        <ng-template #postMediaTemplate let-postMedia>
          <app-post-media-expanded [post]="postMedia" [showTopicAsPostHeader]="true" (onBookmark)="onBookmarkPost($event)">
            <!-- Aqui incluimos el contenido Media dependiendo del tipo de Post: texto, link, imagen o video -->

            <!-- Es un Post de Link? -->
            <!-- Link Preview content goes here -->
            <app-link-preview
              *ngIf="postMedia.type === 2"
              [originalLinkSource]="postMedia.media.content.originalLinkSource"
              [metaTags]="postMedia.media.content.metaTags"></app-link-preview>

            <!-- Es un Post de Imagen? -->
            <!-- Image content goes here -->
            <img
              *ngIf="postMedia.type === 3"
              [src]="postMedia.media.content"
              [alt]="postMedia.description">

            <!-- Es un Post de Video? -->
            <!-- Video content goes here -->
            <video
              *ngIf="postMedia.type === 4"
              width="100%"
              height="360px"
              controls>
              <source type="video/mp4" [src]="postMedia.media.content">
            </video>

            <!-- Es un Post de Texto? -->
            <!-- Un Post de Texto no posee ningún tipo de contenido media -->
          </app-post-media-expanded>
        </ng-template>

        <!-- Template para la Publicación de Votación. -->
        <ng-template #postPollingTemplate let-postPolling>
          <!-- Es un Post de Votaciones? -->
          <app-post-polling [post]="postPolling"></app-post-polling>
        </ng-template>

        <!-- Template para el Anuncio de tipo B que se va a insertar. -->
        <ng-template #advertisementTypeBTemplate>
          <app-advertisement-type-b *ngIf="showAdTypeB" (loaded)="showAdTypeB = $event"></app-advertisement-type-b>
        </ng-template>
      </ng-template>
      <div style="text-align: center; margin-bottom: 40px;" *ngIf="loadingMorePosts">
        <i class="pi pi-spin pi-spinner spinner spinner-md"></i>
      </div>

    </div>

    <div class="grid-right pt-50">
      <div class="recommended">
        <app-recommended-topic-list></app-recommended-topic-list>
      </div>
      <app-advertisement-type-c *ngIf="showAdTypeC" (loaded)="showAdTypeC = $event"></app-advertisement-type-c>
    </div>

  </div>


</div>

<ng-template #showLoadingIndicator>
  <!-- Este spinner es cuando se cargan inicialmente los Posts por vez primera. -->
  <div class="loading" style="text-align: center;">
    <i class="pi pi-spin pi-spinner spinner spinner-lg"></i>
  </div>
</ng-template>

