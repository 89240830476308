<div class="user-settings main-grid">
    <section class="grid-full">


      <div class="back mb-40">
        <a [routerLink]="['/account']"><img src="assets/iconos/icono_arrowdown.svg" alt="Regresar">
          Regresar
        </a>
      </div>

        <div class="row">
            <h3><img class="grayscale" src="assets/iconos/icon_ajustesapp.svg" alt="Ajustes">Ajustes <b>Perfil</b></h3>
        </div>

        <ul class="row menu">
            <li class="item" routerLinkActive="active">
                <a [routerLink]="['edit-profile']">Editar Mi cuenta</a>
            </li>
            <li class="item" routerLinkActive="active">
                <a [routerLink]="['change-password']">Contraseña</a>
            </li>
            <li class="item" routerLinkActive="active">
                <a [routerLink]="['notifications']">Notificaciones</a>
            </li>
            <li class="item" routerLinkActive="active">
                <a [routerLink]="['privacy-policy']">Politica de Privacidad</a>
            </li>
            <li class="item" routerLinkActive="active">
                <a [routerLink]="['terms-and-conditions']">Términos y Condiciones</a>
            </li>
        </ul>
    </section>
</div>

<div class="account-container">
  <router-outlet></router-outlet>
</div>

