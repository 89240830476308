import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';

import { InputTextModule } from 'primeng/inputtext';
import { DropdownModule } from 'primeng/dropdown';
import { MenuModule } from 'primeng/menu';
import { ToastModule } from 'primeng/toast';
import { EditorModule } from 'primeng/editor';

import { AdvertisementModule } from "@app/ui/advertisement/advertisement.module";
import { TopicModule } from '@app/ui/topic/topic.module';
import { BannerCommunityModule } from '@app/ui/banner-community/banner-community.module';
import { SingleBase64imageUploaderFieldModule } from '@app/ui/single-base64image-uploader-field/single-base64image-uploader-field.module';
import { SingleVideoUploaderFieldModule } from '@app/ui/single-video-uploader-field/single-video-uploader-field.module';

import { PostManagerRoutingModule } from './post-manager-routing.module';

// Pages.
import { CreatePostPageComponent } from './pages/create-post-page/create-post-page.component';
import { EditPostPageComponent } from './pages/edit-post-page/edit-post-page.component';

// Panels.
import { CreateLinkPostPanelComponent } from './components/create-post/create-link-post-panel/create-link-post-panel.component';
import { CreateImagePostPanelComponent } from './components/create-post/create-image-post-panel/create-image-post-panel.component';
import { CreateVideoPostPanelComponent } from './components/create-post/create-video-post-panel/create-video-post-panel.component';
import { CreateTextPostPanelComponent } from './components/create-post/create-text-post-panel/create-text-post-panel.component';
import { CreatePollingPostPanelComponent } from './components/create-post/create-polling-post-panel/create-polling-post-panel.component';

// Forms.
import { CreateLinkPostFormComponent } from './components/create-post/create-link-post-form/create-link-post-form.component';
import { CreateImagePostFormComponent } from './components/create-post/create-image-post-form/create-image-post-form.component';
import { CreateVideoPostFormComponent } from './components/create-post/create-video-post-form/create-video-post-form.component';
import { CreateTextPostFormComponent } from './components/create-post/create-text-post-form/create-text-post-form.component';
import { CreatePollingPostFormComponent } from './components/create-post/create-polling-post-form/create-polling-post-form.component';

import { EditTextPostFormComponent } from './components/edit-post/edit-text-post-form/edit-text-post-form.component';
import { EditVideoPostFormComponent } from './components/edit-post/edit-video-post-form/edit-video-post-form.component';
import { EditPostPanelComponent } from './components/edit-post/edit-post-panel/edit-post-panel.component';
import { EditImagePostFormComponent } from './components/edit-post/edit-image-post-form/edit-image-post-form.component';
import { EditLinkPostFormComponent } from './components/edit-post/edit-link-post-form/edit-link-post-form.component';

@NgModule({
  declarations: [
    CreatePostPageComponent,
    EditPostPageComponent,
    CreateLinkPostPanelComponent,
    CreateImagePostPanelComponent,
    CreateVideoPostPanelComponent,
    CreateTextPostPanelComponent,
    CreatePollingPostPanelComponent,
    CreateLinkPostFormComponent,
    CreateImagePostFormComponent,
    CreateVideoPostFormComponent,
    CreateTextPostFormComponent,
    CreatePollingPostFormComponent,
    EditTextPostFormComponent,
    EditVideoPostFormComponent,
    EditPostPanelComponent,
    EditImagePostFormComponent,
    EditLinkPostFormComponent,
  ],
    imports: [
        CommonModule,
        ReactiveFormsModule,
        DropdownModule,
        MenuModule,
        InputTextModule,
        ToastModule,
        EditorModule,
        TopicModule,
        BannerCommunityModule,
        SingleBase64imageUploaderFieldModule,
        SingleVideoUploaderFieldModule,
        PostManagerRoutingModule,
        AdvertisementModule
    ]
})
export class PostManagerModule { }
