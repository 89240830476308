import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { NewSharingRoutingModule } from './new-sharing-routing.module';
import { NewPreviewPageComponent } from './pages/new-preview-page/new-preview-page.component';
import {MediaSourceModule} from '@app/ui/media-source/media-source.module';
import {NewsModule} from '@app/news/news.module';


@NgModule({
  declarations: [NewPreviewPageComponent],
  imports: [
    CommonModule,
    NewSharingRoutingModule,
    MediaSourceModule,
    NewsModule
  ]
})
export class NewSharingModule { }
