import { Directive, Input, HostListener, ElementRef, Renderer2, OnInit } from '@angular/core';

@Directive({
    selector: '[appMediaSource]'
})
export class MediaSourceDirective {

    @Input('appMediaSource') currentAsset: string = '';
    @Input('appFallbackAssetMedia') fallbackAsset: string = '';

    constructor(
        private readonly render: Renderer2,
        private readonly elementRef: ElementRef
    ) { }

    @HostListener('error') onError(): void { 
        
        // En caso de que no se pueda leer el contenido media de este host element, muestra 
        // una asset de fallback.
        this.render.setAttribute( this.elementRef.nativeElement, 'src', this.fallbackAsset);
    }

    ngOnInit(): void {

        // Si vienen null o cadena vacia, muestra el asset de fallback.
        if ( !this.currentAsset ) {

            this.render.setAttribute( this.elementRef.nativeElement, 'src', this.fallbackAsset);

            return;
        }

        // Si el contenido media puede ser leido, entonces adelante y muestra el asset en cuestión.
        this.render.setAttribute( this.elementRef.nativeElement, 'src', this.currentAsset);
    }
}
