import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

// Guards
import { AuthenticateService } from './shared/guards/authenticate.service';
import { RedirectIfAuthenticatedService } from './shared/guards/redirect-if-authenticated.service';
import { RedirectIfIsNotFirstTimeUserService } from './shared/guards/redirect-if-is-not-first-time-user.service';
import { RedirectIfTeamSelectedService } from './shared/guards/redirect-if-team-selected.service';

// Pages.
import { LoginComponent } from './pages/login/login.component';
import { TeamPreferenceComponent } from './pages/team-preference/team-preference.component';
import { OneTimeWelcomeScreenComponent } from './pages/one-time-welcome-screen/one-time-welcome-screen.component';

const routes: Routes = [
  { 
    path: 'login', 
    component: LoginComponent, 
    canActivate: [ RedirectIfAuthenticatedService ] 
  },
  { 
    path: 'team-preference', 
    component: TeamPreferenceComponent, 
    canActivate: [ 
      AuthenticateService, // Primero verifica si estamos autentificados (esto es por si queremos acceder sin estar identificados).
      RedirectIfTeamSelectedService // En caso de tener un equipo ya seleccionado llevame a /
    ] 
  }, 
  {
    path: 'welcome',
    component: OneTimeWelcomeScreenComponent,
    canActivate: [
      AuthenticateService, // Primero verifica si estamos autentificados (esto es por si queremos acceder sin estar identificados).
      RedirectIfIsNotFirstTimeUserService // En caso de que esta no sea la primera vez autentificandonos llevame a /
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AuthRoutingModule { }
