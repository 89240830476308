import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class LandingUserService {

    constructor() { }

    public getUsername(): string {

        const username: string | null = localStorage.getItem('usrn');
    
        return username ? username : 'Jhon Doe';
    }

    public getFavoriteTeamName(): string {

        const teamname: string | null = localStorage.getItem('ftnm');
    
        return teamname ? teamname : 'equipo local';
    }
}
