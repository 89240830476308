import {Component, OnDestroy, OnInit} from '@angular/core';
import {ProfileService} from '../../shared/services/profile.service';
import {SubSink} from 'subsink';
import {Notice} from '../../shared/models/notice';
import {Menu} from 'primeng/menu';
import {MenuItem} from 'primeng/api';

@Component({
  selector: 'app-notifications-panel',
  templateUrl: './notifications-panel.component.html',
  styleUrls: ['./notifications-panel.component.scss']
})
export class NotificationsPanelComponent implements OnInit, OnDestroy {

  constructor(
    private profileService: ProfileService
  ) {
  }

  private subscriptions = new SubSink();
  public notificationActive: string = 'all';
  public isLoading = true;
  public loadingMore = false;
  private actualPage = 1;
  public notices: Notice[] = [];
  public noticesActive = '';
  public titleNotice = 'Todas';
  public menuNotices: MenuItem[] = [
    {
      label: 'No leidas',
      icon: 'custom-icon notreaded-icon',
      command: (event) => {
        this.noticesActive = 'notreaded';
        this.titleNotice = 'No leídas';
        this.getNotices();
      }
    },
    {
      label: 'Todas',
      icon: 'custom-icon all-icon',
      command: (event) => {
        this.noticesActive = '';
        this.titleNotice = 'Todas';
        this.getNotices();
      }
    },
    {
      label: 'Leídas',
      icon: 'custom-icon readed-icon',
      command: (event) => {
        this.noticesActive = 'readed';
        this.titleNotice = 'Leídas';
        this.getNotices();
      }
    },
  ];

  ngOnInit(): void {
    this.getNotices();
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  loadMoreNotices(): void {
    if (!this.loadingMore) {
      this.actualPage++;
      this.loadingMore = true;
      this.subscriptions.sink = this.profileService.getNotifications(this.actualPage, this.noticesActive).subscribe({
        next: (notices) => {
          notices.map((item) => {
            this.notices.push(item);
          });
        },
        complete: () => {
          this.loadingMore = false;
        }
      });
    }
  }

  getNotices(paged = false): void {
    this.isLoading = true;
    this.actualPage = 1;
    this.subscriptions.sink = this.profileService.getNotifications(this.actualPage, this.noticesActive).subscribe({
      next: (notices) => {
        this.notices = notices;
      },
      complete: () => {
        this.isLoading = false;
      }
    });
  }

}
