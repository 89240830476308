import {Component, OnInit, OnDestroy} from '@angular/core';
import {Location} from '@angular/common';
import {Router, ActivatedRoute, ActivatedRouteSnapshot} from '@angular/router';
import {SubSink} from 'subsink';
import {MiniNew} from '../../shared/models/new-detail';
import {NewsService} from '../../shared/services/news.service';
import {MessageService} from 'primeng/api';
import {LayoutConfigService} from '@app/layout-config/shared/services/layout-config.service';


@Component({
  selector: 'app-new-detail-page',
  templateUrl: './new-detail-page.component.html',
  styleUrls: ['./new-detail-page.component.scss']
})
export class NewDetailPageComponent implements OnInit, OnDestroy {

  private subscriptions = new SubSink();

  private newId: number = 0;
  public newTitle: string = '';
  public newDescription: string = '';
  public newUrlImage: string = '';
  public newDate!: Date;
  public newHostDomain: string = '';
  public newUrl: string = '';
  public originalUrl: string = '';
  public isUserFollowingThisNew: boolean = false;
  public newLoaded: boolean = false;
  public avatarDomain = '';
  public nextNew: MiniNew | undefined;
  public prevNew: MiniNew | undefined;
  // Flag para mostrar/ocultar los Anuncios.
  public showAdTypeD: boolean = true;

  constructor(
    private router: Router,
    private readonly location: Location,
    private currentRoute: ActivatedRoute,
    private newsService: NewsService,
    private messageService: MessageService,
    private layoutConfigService: LayoutConfigService
  ) {
    layoutConfigService.setLayoutConfig({
      layout: {
        navbar: {visible: true},
        footer: {visible: true}
      }
    });
  }

  ngOnInit(): void {

    // Obtener el detalle de la noticia.
    const routeSnapshot: ActivatedRouteSnapshot = this.currentRoute.snapshot;
    const newId: string = routeSnapshot.paramMap.get('id') || '';

    this.loadNewDetail(newId);
  }

  ngOnDestroy(): void {

    this.subscriptions.unsubscribe();
  }

  public bookmarkThisNew(): void {

    this.isUserFollowingThisNew ? this.unfollowNew() : this.followNew();

    // Cambiamos el estado del Toggle.
    this.isUserFollowingThisNew = !this.isUserFollowingThisNew;
  }

  private loadNewDetail(id: string): void {

    if (isNaN(+id)) {

      this.router.navigateByUrl('/404');

      return;
    }

    this.subscriptions.sink = this.newsService.getNewDetail(+id).subscribe((newDetail) => {
      this.newUrl = 'https://share.serouno.com/new/' + newDetail.id + '/share';
      this.originalUrl = newDetail.url;
      this.newId = newDetail.id;
      this.newTitle = newDetail.title;
      this.newUrlImage = newDetail.urlImage;
      this.newDate = newDetail.date;
      this.newHostDomain = newDetail.hostDomain;
      this.newDescription = newDetail.description;
      this.isUserFollowingThisNew = newDetail.currentUser.isFollower;
      this.avatarDomain = newDetail.favicon;
      this.nextNew = newDetail.nextNew;
      this.prevNew = newDetail.prevNew;
      this.newLoaded = true;
    });
  }

  public goToNew(id: number | undefined): void{
    this.router.navigateByUrl('/', {skipLocationChange: true}).then(() =>
      this.router.navigate(['#/news/' + id]));
  }

  public back(): void {

    this.location.back();
    // this.router.navigateByUrl('/news');
  }

  private followNew(): void {

    this.subscriptions.sink = this.newsService.followNew(this.newId).subscribe({
      complete: () => {
        this.messageService.add({severity: 'success', summary: 'Éxito', detail: 'Has guardado esta noticia.'});
      }
    });
  }

  private unfollowNew(): void {

    this.subscriptions.sink = this.newsService.unfollowNew(this.newId).subscribe({
      complete: () => {
        this.messageService.add({
          severity: 'info',
          summary: 'Éxito',
          detail: 'Has eliminado de tus guardados esta noticia.'
        });

      }
    });
  }

  public shareNew(inputElement: HTMLInputElement): void {
    inputElement.select();
    document.execCommand('copy');
    inputElement.setSelectionRange(0, 0);
    this.messageService.add({
      severity: 'success',
      summary: 'Éxito',
      detail: 'La liga se a guardado en tu portapapeles'
    });
  }
}
