import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

import { PostAction, PostActionStatus } from '../models/post-action';
import { PostLink } from '../../shared/models/post-link';
import { PostText } from '../../shared/models/post-text';
import { PostImage } from '../../shared/models/post-image';
import { PostVideo } from '../../shared/models/post-video';

@Injectable({
    providedIn: 'root'
})
export class PostCommunicatorService {

    constructor() { }
    
    // Nos permite establecer un canal de comunicación entre un Componente Panel y un Componente Page.
    // De esta manera comunicamos la publicación de interés entre el Panel y la Página.
    private incomingActionSource: Subject<PostAction> = new Subject<PostAction>();
    public incomingAction$: Observable<PostAction> = this.incomingActionSource.asObservable();

    // Nos permite establecer un canal de comunicación entre un Componente Page y un Componente Panel.
    // De esta manera comunicamos de vuelta el status de la Publicación a los componentes involucrados. 
    private publishedStatusSource: Subject<PostActionStatus> = new Subject<PostActionStatus>();
    public publishedStatus$: Observable<PostActionStatus> = this.publishedStatusSource.asObservable();

    // Nos permite establecer un canal de comunicación entre un Componente Page y un Componente Panel.
    // De esta manera comunicamos los detalles de la publicación para ser editada.
    private publicationDetailsSource: Subject<PostText | PostLink | PostImage | PostVideo> = new Subject<PostText | PostLink | PostImage | PostVideo>();
    public publicationReceived$: Observable<PostText | PostLink | PostImage | PostVideo> = this.publicationDetailsSource.asObservable();

    public dispatchIntentionToPublish(postAction: PostAction): void {

        this.incomingActionSource.next(postAction);
    }

    public notifyBackActionStatus(postActionStatus: PostActionStatus): void {

        this.publishedStatusSource.next(postActionStatus);
    }

    public sendBackPublication(post: PostText | PostLink | PostImage | PostVideo): void {

        this.publicationDetailsSource.next(post);
    }
}
