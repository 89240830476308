<p class="white">Topic <b>recomendados para ti:</b></p>
<p><span>Checa los Topics favoritos de la gente, no dudes en participar.</span></p>
<div class="items">
    <ng-container *ngIf="recommendedTopics$ | async as recommendedTopics; else loadingRecommendedTopics">
        <div class="item" *ngFor="let topic of recommendedTopics" (click)="redirectToTopic(topic.id)">
            <app-topic-flat
                [title]="topic.title"
                [cover]="topic.cover"
                [isOfficial]="topic.isOfficial"
                [totalFollowers]="topic.totalFollowers"
                [totalPublishedPosts]="topic.totalPublishedPosts"></app-topic-flat>
        </div>
    </ng-container>

    <p *ngIf="errorMessage" class="invalid-feedback-message" style="text-align: center">{{ errorMessage }}</p>

    <ng-template #loadingRecommendedTopics>
        <div style="text-align: center;">
            <i class="pi pi-spin pi-spinner spinner spinner-md"></i>
        </div>
    </ng-template>
</div>


