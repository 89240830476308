import { Injectable } from '@angular/core';
import { Router, CanActivate } from '@angular/router';

import { AuthService } from '../services/auth.service';

@Injectable({
    providedIn: 'root'
})
export class RedirectIfIsNotFirstTimeUserService implements CanActivate {

    constructor(
        private readonly router: Router,
        private readonly auth: AuthService
    ) { }

    canActivate(): boolean {

        // Si no es la primera vez que se autentifica al usuario, ya no muestres la página de Bienvenida y mandalo al home del sitio.
        if ( !this.auth.isFirstTimeLoginAuthentication ) {

            this.auth.isFirstTimeLoginAuthentication = false;
            this.router.navigateByUrl('/');

            return false;
        }

        // En caso que sea la primera vez que un usuario se autentifica, muestra la página de Bienvenida.
        this.auth.isFirstTimeLoginAuthentication = false;

        return true;
    }
}
