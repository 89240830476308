import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';

import { SubSink } from 'subsink';

import { PostCommunicatorService } from '../../../shared/services/post-communicator.service';
import { PostAction, PostActionType } from '../../../shared/models/post-action';
import { PostLink } from '../../../shared/models/post-link';

@Component({
    selector: 'app-create-link-post-panel',
    templateUrl: './create-link-post-panel.component.html',
    styleUrls: ['./create-link-post-panel.component.scss']
})
export class CreateLinkPostPanelComponent implements OnInit, OnDestroy {

    private subscriptions = new SubSink();

    public loadingLinkPostCreationAction: boolean = false;

    constructor(
        private readonly router: Router,
        private readonly postCommunicator: PostCommunicatorService
    ) {

        // Escuchamos cual es la respuesta producto de la intención del usuario de crear un Post de Link.
        this.subscriptions.sink = postCommunicator.publishedStatus$.subscribe((postStatus) => {

            this.loadingLinkPostCreationAction = false;
        });
    }

    ngOnInit(): void {
    }

    ngOnDestroy(): void {

        this.subscriptions.unsubscribe();
    }

    public onCreateLinkPost(post: Partial<PostLink>): void {

        this.loadingLinkPostCreationAction = true;
        
        // Comunicamos nuestra intención de publicar el siguiente Post.
        this.postCommunicator.dispatchIntentionToPublish({
            // Nuestra intención es crear una Publicación de Link.
            action: PostActionType.CreateLinkPost,
            payload: post
        } as PostAction);
    }

    public onCancelPostCreation(): void {

        this.router.navigateByUrl('/community');
    }
}