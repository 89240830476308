<div class="main-grid">
  <div class="grid-left">
    <section class="section password">
      <h4><b>Cambia tu contraseña</b></h4>
      <app-change-password-form
        [changePasswordLoading]="loadingChangingPasswordAction"
        (changePassword)="onChangePassword($event)"></app-change-password-form>
    </section>

  </div>
</div>


