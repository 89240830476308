import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';

import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';

import {LeaguesResponse, TeamsByLeagueResponse} from '../models/teams-response';
import {TeamDetailResponse} from '../models/team-detail-response';
import {TeamDetail} from '../models/team-detail';
import {TeamPreference} from '../models/team-preference';

import {environment} from '@env/environment';

@Injectable({
  providedIn: 'root'
})
export class TeamsService {

  constructor(private httpClient: HttpClient) {
  }

  public getCurrentTeamPreference(): TeamPreference | undefined {

    const rawFavoriteTeamId: string | null = localStorage.getItem('ftid');
    const rawFavoriteTeamName: string | null = localStorage.getItem('ftnm');

    if (!rawFavoriteTeamId || !rawFavoriteTeamName) {

      return undefined;
    }

    if (isNaN(+rawFavoriteTeamId)) {

      return undefined;
    }

    const favoriteTeamId: number = +rawFavoriteTeamId;
    const favoriteTeamName: string = rawFavoriteTeamName;

    return {
      id: favoriteTeamId,
      name: favoriteTeamName
    };
  }

  public getTeamDetail(teamId: number): Observable<TeamDetail> {

    return this.httpClient.get<TeamDetailResponse>(`${environment.host}/api/teams/detail/${teamId}`)
      .pipe(
        map((teamDetailResponse) => {

          const {content} = teamDetailResponse;
          const {id, name, logo: flagIcon, url: homeUrl, social_data} = content;

          return {
            id,
            name,
            flagIcon,
            homeUrl,
            teamFB: social_data.FB,
            teamTW: social_data.TW,
            teamIG: social_data.IN
          };
        })
      );
  }

  public getTeamsByLeague(): Observable<LeaguesResponse[]> {
    
    return this.httpClient.get<TeamsByLeagueResponse>(`${environment.host}/api/teams/league`)
      .pipe(
        map((teamsByLeagueResponse) => {

          const {content} = teamsByLeagueResponse;
          
          return content.map((leagueResponse) => {
          
            const {id, name, icon, teams} = leagueResponse;
          
            return {
              id,
              name,
              icon,
              teams
            };
          });
        })
      );
  }
}
