import { Injectable } from '@angular/core';

import { PostMediaDetailsResponse } from '../models/post-response';
import { PostTextPayload } from '../models/post-text-payload';
import { PostText } from '../models/post-text';
import { PostType } from '../models/post-type';

@Injectable({
    providedIn: 'root'
})
export class PostTextMappingService {

    constructor() { }

    public mapPostTextPayload(postText: Partial<PostText>): Partial<PostTextPayload> {

        const payload: Partial<PostTextPayload> = {};
        const { id, description, postType, topicId }  = postText;

        // Cuando estamos editando una Publicación de Texto es común que venga un identificador del mismo.
        if ( id ) {

            payload.id = id;
        }

        // Una Publicación de Texto puede tener o no tener un Topic asociado. Por lo común, si se esta creando 
        // una publicación sin un Topic asociado, ésta se considerará una Publicación asociada a un Topic General.
        if ( topicId ) {

            payload.topic_id = topicId;
        }
        
        // Cuando estamos editando una Publicación de Texto, NO debe venir el tipo de Publicación, este ya lo conoce 
        // la API de antemano a través del identificador del mismo.
        if ( postType ) {

            payload.type_post_id = postType;
        }

        payload.description = description?.trim();

        return payload;
    }

    public mapPostTextDetails(postMediaDetailsResponse: PostMediaDetailsResponse): PostText {

        const { content:postMediaDetails } = postMediaDetailsResponse;
        const { id, description, topic } = postMediaDetails;

        return {
            id, 
            description,
            postType:       PostType.TEXT,
            topicId:        topic.id,
        } as PostText;
    }
}
