import { Component, OnInit, OnDestroy, Input, forwardRef } from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';

import { SubSink } from 'subsink';

import { ModalService } from '@app/ui/modals/shared/services/modal.service';
import { Team } from '../shared/models/team';

@Component({
  selector: 'app-select-team-field',
  templateUrl: './select-team-field.component.html',
  styleUrls: ['./select-team-field.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => SelectTeamFieldComponent),
    multi: true
}]
})
export class SelectTeamFieldComponent implements OnInit, OnDestroy, ControlValueAccessor {

    @Input() placeholder: string = '';
    @Input() className: string = '';

    public teamId: number | undefined = undefined;
    public teamLabel: string = '';

    // Nos va a permitir monitorear el estado del modal para determinar el estado de nuestro control
    // al abandonarlo (blur event)
    private modalIsOpen: boolean = false;

    private subscriptions = new SubSink();

    private onChanged: any = () => {};
    private onTouched: any = () => {};

    constructor( private modalsService: ModalService ) { }

    ngOnInit(): void {
    }

    ngOnDestroy(): void {

        this.subscriptions.unsubscribe();
    }

    // Implementa los métodos de la interfaz ControlValueAccessor para que el componente SelectTeamFieldComponent tenga un comportamiento como si fuera un elemento
    // de un formulario reactivo. Por defecto, estos 3 métodos se ejecutan al inicio.

    // De donde proviene el valor?
    // 1. Cuando el control es instanciado y se le da un valor por defecto o no.
    // 2. A consecuencia de una operación patchValue o setValue desde el formgroup padre para modificar el contenido del control
    public writeValue(val: Team):void {

        if ( val ) {

            this.selectTeam( val );
        }
    }

    // Le informamos a nuestro formgroup padre que el valor de nuestro control ha cambiado.
    public registerOnChange(fn: any):void {

        this.onChanged = fn;
    }

    // Nos permite asignar a voluntad el estado touched a nuestro control.
    public registerOnTouched(fn: any):void {

        this.onTouched = fn;
    }

    OnOpenDialog(): void {

        this.modalIsOpen = true;

        this.subscriptions.sink = this.modalsService.pickATeamDialog(this.teamId).onClose.subscribe(
            (teamSelected:Team) => {

                this.onTouched();
                this.modalIsOpen = false;

                // Comprobamos si un equipo fue seleccionado del menú modal y este equipo recien seleccionado
                // NO estaba previamente seleccionado, podemos decir que el estado del control ha cambiado.
                if ( teamSelected && teamSelected.id !== this.teamId ) {

                    this.selectTeam( teamSelected );
                    this.onChanged( teamSelected );
                }
        });
    }

    onBlur(): void {

        // Cuando nuestro control pierde el foco (blur event) debemos determinar si fue a consecuencia de la
        // interacción con la ventana modal o a consecuencia de la interacción normal (mouse, tab, etc).
        // 1. Si nuestra ventana modal se encuentra abierta, cuando el usuario empieza a interactuar con ella
        //    nuestro control pierde el foco, por lo tanto, no lo marques como touched.
        // 2. Si nuestra ventana modal se encuentra cerrada, cuando el usuario abandone el control, marcalo
        //    como touched.

        if ( !this.modalIsOpen ) {

            this.onTouched();
        }
    }

    private selectTeam(team: Team): void {

        const { id, name } = team;

        this.teamId = id;
        this.teamLabel = name;
    }
}
