<!-- Muestra el banner de publicidad del Patrocionador/Dueño -->
<img *ngIf="hasAdvertisementDoneLoading"
  [src]="ad?.sponsorAdImage"
  alt="Sponsor Image"
  (click)="registerCPCActivity()"
  class="advertisement advertisement-interaction">

<!-- Este spinner es visible solamente cuando se esta cargando el Anuncio. -->
<div class="loading" style="text-align: center;" *ngIf="!hasAdvertisementDoneLoading">
  <i class="pi pi-spin pi-spinner spinner spinner-sm"></i>
</div>
